import React, { Component } from "react";


import BootstrapTable from "react-bootstrap-table-next";
// import Datetime from 'react-datetime';

import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from "classnames";

import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";




// koneksi
import API_URL, { Post, Get, Delete, JENIS_DESA, JENIS_DUSUN, DESA } from "../function/Koneksi";
import { Tanggal, } from "../function/Format";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// import ReactDatePicker from "react-datepicker";
// import { logo } from "./asset/base64img";
import CetakSurat from "./cetak-surat";
// import ModalUpdate from "./update/modal-update";
// import { Link } from "react-router-dom";



const frmDef = {
  id: 0,
  idbaru: "",
  id_keldes: "",
  nama: ""
};

const cetak = {
  jenis_surat: "",
  nama_desa: "",
  nomor_surat: "",
  tanggal_surat: new Date(),
  detail: [],
  nik: "",
  nama: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  pekerjaan: "",
  kewarganegaraan: "",
  alamat: "",
  jenis_kelamin: "",
  tempat_meninggal: "",
  sebab_meninggal: "",
  waktu_meninggal: "",

  nik2: "",
  nama2: "",
  tempat_lahir2: "",
  tanggal_lahir2: "",
  pekerjaan2: "",
  kewarganegaraan2: "",
  alamat2: "",
  // jenis_kelamin: "",
  tempat_meninggal2: "",
  sebab_meninggal2: "",
  waktu_meninggal2: "",

  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: ""
};

const detailSurat = {
  uuid: "",
  id_jenis: "",
  jenis_surat: "",
  nomor: "",
  tanggal_surat: new Date(),

  nik_anak: "",
  nama_anak: "",
  tempat_lahir_anak: "",
  tanggal_lahir_anak: new Date(),
  jk_anak: "",

  nik_ayah: "",
  nama_ayah: "",
  tempat_lahir_ayah: "",
  tanggal_lahir_ayah: "",
  alamat_ayah: "",

  nik_ibu: "",
  nama_ibu: "",
  tempat_lahir_ibu: "",
  tanggal_lahir_ibu: "",
  alamat_ibu: "",

  nik_pelapor: "",
  nama_pelapor: "",
  tempat_lahir_pelapor: "",
  tanggal_lahir_pelapor: "",
  pekerjaan_pelapor: "",
  agama_pelapor: "",
  alamat_pelapor: "",
  hubungan_pelapor: "",

  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: "",

  jmlAcara: 0,
  meninggal2: "none",
  jamStat: { value: "08:00", label: "08:00" },

  formAyah: "none",
  formIbu: "none",

};

// let jmlAcara = 0;

// const dateFormat = require('dateformat');


class Disposisi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Data table
      data: [],
      dataTidakGelar: [],
      data_detail: [],

      // Cek Verifikasi Penduduk
      status_verifikasi: '',
      penduduk_temp: [],
      penduduk_temp_all: [],
      penduduk_temp_agama: '',
      penduduk_temp_pendidikan: '',
      penduduk_temp_pekerjaan: '',
      penduduk_status: '',

      // Pagination data table
      pagination: [],
      paginationDitolak: [],
      paginationMenungguEksekusi: [],
      paginationPenerbitan: [],
      paginationPending: [],
      paginationSelesai: [],

      status: {
        form: false,
        formTambahGelar: false,
        btnForm: false,
        btnAksi: false,
        btnAksiTidakGelar: false,
        select: false,
        // ======  Data Acc / Approve =====
        formAcc: false,
        btnFormAcc: true,
        tableAcc: "",
        accStat: [],
        // ======  Data Update Surat =====
        formUpdateSurat: false,
        btnFormUpdateSurat: true,
        // formSelectAcc: [
        // 	{ value: 'Disetujui', label: 'Disetujui' },
        // 	{ value: 'Ditolak', label: 'Ditolak' },
        // ],
        // ======  Data Disposisi =====
        formDisposisi: false,
        formKeterangan: false,
        // ======  Detail Surat Kelahiran =====
        dataPenduduk: "none",
        dataPenduduk2: "none",
        dataPenduduk3: "none",
        // == SKGR
        showBio: false,
        showBio2: false,

        // ===== Verifikasi
        pdd_ver: '',
      },

      // Diproses
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      // Menunggu Eksekusi
      awal2: "",
      sebelum2: "",
      hal2: [],
      setelah2: "",
      akhir2: "",
      // Penerbitan
      awal3: "",
      sebelum3: "",
      hal3: [],
      setelah3: "",
      akhir3: "",
      // Pending
      awal4: "",
      sebelum4: "",
      hal4: [],
      setelah4: "",
      akhir4: "",
      // Ditolak
      awal5: "",
      sebelum5: "",
      hal5: [],
      setelah5: "",
      akhir5: "",
      // Selesai
      awal6: "",
      sebelum6: "",
      hal6: [],
      setelah6: "",
      akhir6: "",

      dt: frmDef,
      judul: "",
      // =========== Select 2 ============
      frmPrv: [],
      prvStat: [],
      frmKbkt: [],
      kbktStat: [],
      frmKec: [],
      kecStat: [],
      frmKlds: [],
      kldsStat: [],
      frmAgm: [],
      // =================================
      // =========== Alert ============
      alert: null,
      show: false,
      showCetakSurat: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      // ==============================
      active_tab_icon: "1",
      cetak: cetak,
      surat: {
        uuid: "",
        jenis_surat: "",
        tanggal_surat: new Date()
      },
      detailSurat: detailSurat,
      disposisi: {
        uuid_jenis_surat: "",
        data: [],
        riwayat: []
      },
      catatan: {
        nik: "",
        data: []
      },
      keteranganPenduduk: [],
      catatanPenduduk: [],
      usaha: [
        {
          jenis_usaha: "",
          lokasi: "",
          umur: "",
          luas_kebun: "",
          merk: ""
        }
      ],
      listPerangkatDesa: [],
      accButtonPerangkatDesa: [],
      uuidPerangkatDesa: "",
      // Detail Surat Kelahiran
      frmNik: [],
      nikStat: null,
      frmNik2: [],
      nik2Stat: null,
      frmNik3: [],
      nik3Stat: null,
      dataNik: {
        frmNik: [],
        frmNik2: [],
        frmNik3: []
      },
      dataNikStat: [],

      // Data Suku
      dataSuku: [],
      id_suku: null,
      
      // Data Datuak
      dataDatuak: [],
			id_datuak: null,

      // Cari Data Gelar
      dataCariGelar: {
				nama: ''
			},

      cek_posisi:'Gelar'
    };
  }

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      showCetakSurat: false,
      alert: null
    });
  };

  // Bootstrap tabs function
  toggle_icon(tab) {
    if (this.state.active_tab_icon !== tab) {
      if(tab === 1){
        this.state.cek_posisi = 'Gelar'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      else{
      this.state.cek_posisi = 'Non-Gelar'
      this.state.dataCariGelar= {
				nama: ''
			}
    }
      this.setState({
        active_tab_icon: tab,
      });
    }
    
    this.forceUpdate();
    this.componentDidMount();
  }

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          switch (params.tab) {
            case "penduduk":
              link =
                "?page=" +
                Math.ceil(
                  this.state.pagination.total / this.state.pagination.per_page
                );
              break;

            case "penduduk/tidak-memiliki":
              link =
                "?page=" +
                Math.ceil(
                  this.state.paginationMenungguEksekusi.total /
                  this.state.paginationMenungguEksekusi.per_page
                );
              break;
              default:
                // no default
                break;
          }
          break;
        case ">":
          switch (params.tab) {
            case "penduduk":
              link =
                "?page=" + (parseInt(this.state.pagination.current_page) + 1);
              break;

            case "penduduk/tidak-memiliki":
              link =
                "?page=" +
                (parseInt(this.state.paginationMenungguEksekusi.current_page) +
                  1);
              break;
            default:
              // no default
              break;
          }
          break;
        case "<":
          switch (params.tab) {
            case "penduduk":
              link =
                "?page=" + (parseInt(this.state.pagination.current_page) - 1);
              break;

            case "penduduk/tidak-memiliki":
              link =
                "?page=" +
                (parseInt(this.state.paginationMenungguEksekusi.current_page) -
                  1);
              break;
            default:
              // no default
              break;
          }
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }

    // Get data from db
    if(this.state.cek_posisi === "Gelar"){
      params.tab = "penduduk"
    }
    else{
      params.tab = "penduduk/tidak-memiliki"
    }

    Post("kesukuan/" + params.tab + link, null, this.state.dataCariGelar, (dtkat) => {
      //console.log("COba tampil bos")
      //console.log(dtkat)
      switch (params.tab) {
        case "penduduk":
          this.setState({
            data: dtkat.data.results.data,
            pagination: dtkat.data.results
          });
          break;

        case "penduduk/tidak-memiliki":
          this.setState({
            dataTidakGelar: dtkat.data.results.data,
            paginationMenungguEksekusi: dtkat.data.results
          });
          break;
        default:
          // no default
          break;
      }
      // //console.log(dtkat.results);
      this.pagination(dtkat.data.results, params.tab);
    });
  };

  // Pagination
  pagination = (data, tab) => {
    // const data = this.state.pagination;
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            key={i}
            onClick={() => this.fetch({ page: i, tab: tab })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p, tab: tab })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n, tab: tab })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }

      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1, tab: tab })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page, tab: tab })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }

    switch (tab) {
      case "penduduk":
        this.setState({ awal, sebelum, hal, setelah, akhir });
        break;
      case "penduduk/tidak-memiliki":
        this.setState({
          awal2: awal,
          sebelum2: sebelum,
          hal2: hal,
          setelah2: setelah,
          akhir2: akhir
        });
        break;
      default:
        // no default
        break;
    }
  };


  // Tambah Gelar
  tambahDataGelar = (data) => {
    this.state.status.formTambahGelar = true;
    this.state.judul = "Tambah Gelar";
    
    
    //console.log("PARSING DATA DETAIL PRODUKNYA")
    //console.log(data)
    
    this.state.data_detail.uuid=""
    this.state.data_detail.nik = data.id;
    this.state.data_detail.kk = data.id_kk;
    this.state.data_detail.nama = data.nama;
    this.state.data_detail.alamat = data.alamat;
    this.state.id_suku= null
    this.state.id_datuak= null
    this.state.data_detail.nama_gelar=""
        this.forceUpdate();
    
  }

 
  // ========================================================================

  // ====================== Hapus Data =============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("kesukuan/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Suku",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Suku",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ====================== Hapus Data =============================

  bukaForm = jenis => {
    switch (jenis) {
      case "catatan":
        this.state.status.form = true;
        break;

      case "update":
        this.state.status.formUpdateSurat = true;
        break;

      default:
        break;
    }
    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.status.formTambahGelar = false;
    this.state.status.form = false;

    this.state.data_detail.uuid = "";
    this.state.data_detail.nik = "";
    this.state.data_detail.nama = "";
    this.state.id_suku= null
    this.state.id_datuak= null
    this.state.data_detail.nama_gelar = "";



    this.state.status.accStat = false;
    this.state.status.tableAcc = "none";

    this.forceUpdate();
  };

  tombolAksi = (cell, row) => {
    let btnApproval = true;
    let btnCetak = true;
    let btnAksi = true;
    let btnListDisposisi = true;
    let btnListKeterangan = true;
    let btnTerbit = true;
    let btnAccPending = true;
    let btnUpdate = true;

    let status = row.status;
    // //console.log(row)

    switch (status) {
      case "Diajukan":
        btnListKeterangan = true;
        btnCetak = true;
        btnAksi = true;
        btnApproval = true;
        btnListDisposisi = false;
        btnTerbit = true;
        btnAccPending = true;
        btnUpdate = true;
        break;

      case "Disetujui":
        btnListKeterangan = true;
        btnCetak = true;
        btnAksi = true;
        btnApproval = false;
        btnListDisposisi = true;
        btnTerbit = true;
        btnAccPending = true;
        btnUpdate = true;
        break;

      default:
        btnListKeterangan = true;
        btnCetak = true;
        btnAksi = true;
        btnApproval = true;
        btnListDisposisi = true;
        btnTerbit = true;
        btnAccPending = true;
        btnUpdate = true;
        break;
    }

    let jenisSurat = '';

    switch (row.label) {
      case "Surat Keterangan Kelahiran":
        jenisSurat = "kelahiran"
        break;
      case "Surat Keterangan Jalan":
        jenisSurat = "surat-jalan"
        break;
      case "Surat Keterangan Kematian":
        jenisSurat = "kematian"
        break;
      case "Surat Kuasa SKGR":
        jenisSurat = "skgr"
        break;
      case "Surat Keterangan Tidak Mampu":
        jenisSurat = "tidak-mampu"
        break;
      case "Surat Keterangan Pernah Menikah":
        jenisSurat = "pernah-menikah"
        break;
      case "Surat Pernyataan Tidak Menikah":
        jenisSurat = "tidak-menikah"
        break;
      case "Surat Pernyataan Kehilangan":
        jenisSurat = "kehilangan" 
        break;
      case "Surat Pernyataan Belum Menikah":
        jenisSurat = "belum-menikah"
        break;
      case "Surat Pengantar Pindah Nikah":
        jenisSurat = "pindah-nikah"
        break;
      case "Surat Keterangan Usaha":
        jenisSurat = "usaha"
        break;
      case "Surat Keterangan Penghasilan":
        jenisSurat = "penghasilan"
        break;
      case "Surat Keterangan Kepemilikan Rumah":
        jenisSurat = "kepemilikan-rumah"
        break;
      case "Surat Pengantar SKCK":
        jenisSurat = "skck"
        break;
      case "Surat Keterangan Domisili":
        jenisSurat = "domisili"
        break;
      case "Surat Keterangan Belum Memiliki Rumah":
        jenisSurat = "belum-memiliki-rumah"
        break;
      case "Surat Keterangan Ahli Waris":
        jenisSurat = "ahli-waris"
        break;
      case "Surat Keterangan":
        jenisSurat = "keterangan"
        break;
      case "Surat Izin Keramaian":
        jenisSurat = "keramaian"
        break;
      case "Surat Kuasa":
        jenisSurat = "surat-kuasa"
        break;
      case "Surat Undangan":
        jenisSurat = "undangan"
        break;
    
      default:
        break;
    }

    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {btnUpdate ? (
            ""
          ) : (
              <Button
                // style={{ marginLeft: "5px" }}
                title="Perbaiki data surat"
                size="xs"
                color="success"
                className="btn btn-icon"
                onClick={e => this.updateSurat(e, row)}
                disabled={btnUpdate}
              >
                <i className="fa fa-pencil"></i>
              </Button>
            )}

          {btnApproval ? (
            ""
          ) : (
              <Button
                // style={{ marginLeft: "5px" }}
                title="Pilih penandatangan surat"
                size="xs"
                color="info"
                className="btn btn-icon btn-secondary-custom"
                onClick={() => this.konfirmApprove(row.uuid_surat, row.label, row.nik)}
                disabled={btnApproval}
              >
                <i className="fa fa-check"> Pilih penandatangan</i>
              </Button>
            )}

          {btnAksi ? (
            ""
          ) : (
              <Button
                // style={{ marginLeft: "5px" }}
                title="Ubah Data"
                size="xs"
                color="success"
                className="btn btn-icon"
                onClick={() => this.ubahCatatan(row.nik)}
                disabled={btnAksi}
              >
                <i className="fa fa-clock-o"></i>
              </Button>
            )}

          {btnAccPending ? (
            ""
          ) : (
              <Button
                // style={{ marginLeft: "5px" }}
                title="Selesaikan penangguhan surat"
                size="xs"
                color="info"
                className="btn btn-icon"
                onClick={() =>
                  this.konfirmAccPending(
                    row.uuid_jenis_surat,
                    row.uuid_surat,
                    row.nik
                  )
                }
                disabled={btnAccPending}
              >
                <i className="fa fa-check-circle"></i>
              </Button>
            )}

          {btnCetak ? (
            ""
          ) : (
              <Button
                // style={{ marginLeft: "5px" }}
                size="xs"
                title="Cetak Surat"
                color="info"
                className="btn-icon"
                // onClick={e => this.cetakSurat(e, row)}
                onClick={e => {
                  this.state.showCetakSurat = true;
                  this.forceUpdate();
                  this.setState({
                    alert: (
                      <SweetAlert
                        show={this.state.showCetakSurat}
                        type="info"
                        title="Cetak Surat"
                        cancelBtnBsStyle="danger"
                        showCancel={true}
                        cancelBtnText="Batal"
                        onCancel={this.closeAlert}
                        closeOnOverlayClick={true}
                        showConfirm={false}
                        onConfirm={() => {}}
                      >
                        {/* Pilih Jenis Tanda Tangan Surat */}
                        <span style={{ fontSize: "17px" }}>Pilih Jenis Tanda Tangan Surat</span>
                        <br />
                        <Button
                          color="success"
                          onClick={() => {
                            this.closeAlert();
                          }}
                        >
                          <a href={API_URL + "cetak-surat/" + jenisSurat +  "/" + row.uuid_surat + "?status_barcode=tidak"}>
                            <span style={{ color: "white" }}>Tanda Tangan Asli</span>
                          </a>
                        </Button>{"   "}
                        <Button
                          color="info"
                          onClick={() => {
                            this.closeAlert();
                          }}
                        >
                          <a href={API_URL + "cetak-surat/" + jenisSurat +  "/" + row.uuid_surat}>
                            <span style={{ color: "white" }}>Tanda Tangan Digital</span>
                          </a>
                        </Button>
                      </SweetAlert>
                    )
                  });
                }}
                disabled={btnCetak}
              >
                {/* <a href={API_URL + "cetak-surat/" + jenisSurat +  "/" + row.uuid_surat}> */}
                  <i className="fa fa-print" style={{ color: "white" }}></i>
                {/* </a> */}
              </Button>
            )}

          {btnListDisposisi ? (
            ""
          ) : (
              <Button
                // style={{ marginLeft: "5px" }}
                title="Riwayat Disposisi"
                size="xs"
                color="info"
                className="btn btn-icon btn-secondary-custom"
                onClick={e => this.listDisposisi(row)}
                disabled={btnListDisposisi}
              >
                <i className="fa fa-list"></i>
              </Button>
            )}

          {btnListKeterangan ? (
            ""
          ) : (
              <Button
                // style={{ marginLeft: "5px" }}
                title="Data Keterangan"
                size="xs"
                color="info"
                className="btn btn-icon"
                onClick={e => this.listKeterangan(row)}
                disabled={btnListKeterangan}
              >
                <i className="fa fa-list"></i>
              </Button>
            )}

          {btnTerbit ? (
            ""
          ) : (
              <Button
                // style={{ marginLeft: "5px" }}
                title="Status Penerbitan Surat"
                size="xs"
                color="info"
                className="btn btn-icon btn-secondary-custom"
                onClick={e =>
                  this.konfirmTerbit(
                    row.uuid_jenis_surat,
                    row.uuid_surat,
                    row.nik
                  )
                }
                disabled={btnTerbit}
              >
                <i className="fa fa-calendar-check-o"></i>
              </Button>
            )}
        </div>
      </>
    );
  };
  
  tombolAksiPendudukGelar = (cell, row) => {
    return (
        <>
            <Button  size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
            <Button  size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>  
        </>
    )
}

  tombolAksiTambahGelar = (cell, row) => {
    return (
      <>
        <button onClick={() => this.tambahDataGelar(row)} color="info" className="btn btn-success"><i className="fa fa-plus"></i>  Tambah Gelar</button>
      </>
    )
  }

  getChild = (e, self, parent, child) => {
    this.state.status.select = true;
    this.forceUpdate();
    this.changeSelectValue(e, self);
    if (e) {
      Get(child + "/" + parent + "/" + e.value + "/list", null, data => {
        let frm = [];
        data.results.forEach(dt => {
          frm.push({ value: dt.id, label: dt.nama });
        });
        switch (child) {
          case "kab-kota":
            this.setState({ frmKbkt: frm, frmKlds: [] });
            break;
          case "kecamatan":
            this.setState({ frmKec: frm });
            break;
          case "keldes":
            this.setState({ frmKlds: frm });
            break;
          default:
            // no default
            break;
        }
        this.state.status.select = false;
        this.forceUpdate();
      });
    }
  };

  ubahPilihan = (table, data) => {
    // Get(table+'/find', id, (data) => {
    switch (table) {
      // case 'provinsi': this.setState({prvStat:{value:data.id, label:data.nama}}); break;
      case "keldes":
        this.getChild(
          {
            value: data.data_kecamatan.data_kabkota.data_provinsi.id,
            label: data.data_kecamatan.data_kabkota.data_provinsi.nama
          },
          "provinsi",
          "prov",
          "kab-kota"
        );
        this.getChild(
          {
            value: data.data_kecamatan.data_kabkota.id,
            label: data.data_kecamatan.data_kabkota.nama
          },
          "kab-kota",
          "kab",
          "kecamatan"
        );
        this.getChild(
          { value: data.data_kecamatan.id, label: data.data_kecamatan.nama },
          "kecamatan",
          "kec",
          "keldes"
        );
        this.getChild(
          { value: data.id, label: data.nama },
          "keldes",
          "kel",
          "dusun"
        );
        this.setState({
          prvStat: {
            value: data.data_kecamatan.data_kabkota.data_provinsi.id,
            label: data.data_kecamatan.data_kabkota.data_provinsi.nama
          },
          kbktStat: {
            value: data.data_kecamatan.data_kabkota.id,
            label: data.data_kecamatan.data_kabkota.nama
          },
          kecStat: {
            value: data.data_kecamatan.id,
            label: data.data_kecamatan.nama
          },
          kldsStat: { value: data.id, label: data.nama }
        });
        this.bukaForm("");
        break;
      default:
        // no default
        break;
    }
    // });
  };

  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "provinsi":
          this.setState({
            prvStat: e,
            kbktStat: [],
            kecStat: [],
            kldsStat: []
          });
          break;
        case "kab-kota":
          this.setState({ kbktStat: e, kecStat: [], kldsStat: [] });
          break;
        case "kecamatan":
          this.setState({ kecStat: e, kldsStat: [] });
          break;
        case "keldes":
          this.state.dt.id_keldes = e.value;
          this.setState({ kldsStat: e });
          break;
        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        case "provinsi":
          this.setState({
            prvStat: [],
            kbktStat: [],
            kecStat: [],
            kldsStat: [],
            frmKbkt: [],
            frmKec: [],
            frmKlds: []
          });
          break;
        case "kab-kota":
          this.setState({
            kbktStat: [],
            kecStat: [],
            kldsStat: [],
            frmKec: [],
            frmKlds: []
          });
          break;
        case "kecamatan":
          this.setState({ kecStat: [], kldsStat: [], frmKlds: [] });
          break;
        case "keldes":
          this.state.dt.id_keldes = 0;
          this.setState({ kldsStat: [] });
          break;
        default:
          // no default
          break;
      }
    }

    this.state.status.select = false;
    this.forceUpdate();
  };

  componentDidMount() {
    if (typeof this.props.location.tabProps !== "undefined") {
      this.state.active_tab_icon = this.props.location.tabProps;
    }
    if (typeof this.props.location.state !== "undefined") {
      this.state.active_tab_icon = this.props.location.state.tab;

    }

    // Fetching data proses
    this.fetch({ page: null, where: [], tab: "penduduk" });
    // Fetcing data menunggu eksekusi
    this.fetch({ page: null, where: [], tab: "penduduk/tidak-memiliki" });
    
    // Get Data Suku
    Get('suku/list', null, (data) => {
      //console.log("Data Suku")
      //console.log(data)
			let dataSuku = [];
			data.results.forEach((dt) => {
				dataSuku.push({ value: dt.nama, label: dt.nama });
			});
			this.setState({ dataSuku });
    });

    // Get Data Datuak
    Get('datuak/list', null, (data) => {
      //console.log("Data Datuak")
      //console.log(data)
			let dataDatuak = [];
			data.results.forEach((dt) => {
				dataDatuak.push({ value: dt.nama, label: dt.nama });
			});
			this.setState({ dataDatuak });
    });
    
    this.forceUpdate();
  }

  konfirmApprove = (id, jenis_surat, niknya) => {
    this.state.status.formAcc = true;
    this.state.status.btnFormAcc = false;
    this.state.status.tableAcc = "";
    this.state.surat.uuid = id;
    this.state.surat.jenis_surat = jenis_surat;

    // //console.log("Tampil UUID: " + this.state.surat.uuid)
    // //console.log("Tampil ALL DATA: " + niknya)

    this.setState({
      judul: "Persetujuan Data "
    });
    //console.log("Jenis Surat")
    //console.log(jenis_surat)

    // 78ae703e-de51-4e01-b62d-9a71d6a0ee8e
    if (jenis_surat === 'Surat Undangan' || jenis_surat === 'Surat Keterangan') {
    }
    else {
      Get('penduduk/find', niknya, (data) => {
        // //console.log("Data Find Penduduk");
        // //console.log(data);

        if (data.results === null) {
          Get('temp/penduduk/find', niknya, (temp) => {
            // //console.log("DATA TEMP");
            // //console.log(temp.results);

            if (temp.results.data_kk === null) {
              this.setState({
                penduduk_temp_all: temp.results.data_kk_pend_temp.data_keluarga,
                penduduk_status: 'tambah_kk',
              })
            }
            else if (temp.results.data_kk_pend_temp === null) {
              this.setState({
                penduduk_temp_all: temp.results.data_kk.data_keluarga,
                penduduk_status: 'tambah_nik',
              })
            }
            this.setState({
              status_verifikasi: 'temporary',
              penduduk_temp: temp.results,
              penduduk_temp_agama: temp.results.data_agama.nama,
              penduduk_temp_pendidikan: temp.results.data_pendidikan_terakhir.nama,
              penduduk_temp_pekerjaan: temp.results.data_pekerjaan.nama,
            })
          });
        }
        else {
          // //console.log("Data Permanent")
          // //console.log(data.results);

          this.setState({
            status_verifikasi: 'permanen'
          })
        }

        // //console.log(this.state.layanan);
      });
    }

  };

  konfirmTerbit = (uuid_jenis, uuid_surat, nik) => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Terbitkan Surat"
          confirmBtnBsStyle="warning"
          cancelBtnBsStyle="default"
          type="warning"
          title="Apakah anda yakin ingin menerbitkan surat ini ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.terbitkanSurat(uuid_jenis, uuid_surat, nik)}
        >
          Proses ini tidak dapat dibatalkan, pastikan sudah memilih surat yang
          sesuai...
        </SweetAlert>
      )
    });
  };

  terbitkanSurat = (uuid_jenis, uuid_surat, nik) => {
    //console.log(nik);
    this.hideAlert();
    Get(
      nik !== ""
        ? "list-pengajuan-layanan/surat-selesai/" +
        uuid_jenis +
        "/" +
        uuid_surat +
        "/" +
        nik
        : "list-pengajuan-layanan/surat-selesai/" +
        uuid_jenis +
        "/" +
        uuid_surat +
        "/0",
      null,
      data => {
        var psn = "Menerbitkan";
        // //console.log(data);
        if (data.code === 200) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Penerbitan Surat",
            pesanAlert: "Berhasil " + psn + " Surat"
          });
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Penerbitan Surat",
            pesanAlert: "Gagal " + psn + " Surat"
          });
        }
        this.componentDidMount();
      }
    );
  };

  listDisposisi = data => {
    Get("alur-disposisi/" + data.uuid_jenis_surat, null, dtkat => {
      if (dtkat) {
        this.state.status.formDisposisi = true;
        this.state.surat.uuid = data.uuid;
        this.state.disposisi.data = dtkat.results;
        this.state.disposisi.riwayat = data.status_disposisi;

        this.setState({
          judul: "Disposisi Data "
        });

        this.forceUpdate();
      }
    });
  };

  listKeterangan = data => {
    //console.log(data);
    let catatan = [];

    if (data.catatan !== null) {
      data.catatan.forEach((dt, i) => {
        if (dt.status === 1) {
          catatan.push(dt);
        }
      });
    }

    this.state.keteranganPenduduk = catatan;
    this.state.status.formKeterangan = true;
    this.forceUpdate();
    //console.log(this.state.keteranganPenduduk);
  };

  // ================== Update detail surat =====================
  getPenduduk = (e, jenis_surat, jns) => {
    this.state.status.btnForm = true;
    this.forceUpdate();
    if (e) {
      // this.changeSelectValue(e, sel);
      Get("penduduk/find", e.value, data => {
        //console.log(data);
        let alamat = "";
        if (typeof data.results.data_kk.data_rt !== "undefined") {
          if (JENIS_DESA.toLowerCase() !== 'kelurahan') {
            const data_rt = data.results.data_kk.data_rt;
            const data_rw = data_rt.data_rw;
            const data_dusun = data_rw.data_dusun;
            const data_desa = data_dusun.data_keldes;
            const data_kecamatan = data_desa.data_kecamatan;
            const data_kabkota = data_kecamatan.data_kabkota;
            alamat =
              "RT/RW : " +
              data_rt.nama +
              "/" +
              data_rw.nama +
              ", " + JENIS_DUSUN + " " +
              data_dusun.nama +
              ", " + JENIS_DESA + " " +
              data_desa.nama +
              ", Kecamatan " +
              data_kecamatan.nama +
              ", " +
              data_kabkota.nama;
          } else {
            alamat =
              " RT. " +
              data.results.data_kk.data_rt.nama +
              "/RW. " +
              data.results.data_kk.data_rt.data_rw.nama +
            
              ", " +
              data.results.data_kk.data_rt.data_rw.data_keldes.nama +
              ", Kec. " +
              data.results.data_kk.data_rt.data_rw.data_keldes
                .data_kecamatan.nama +
              ", " +
              data.results.data_kk.data_rt.data_rw.data_keldes
                .data_kecamatan.data_kabkota.nama; 
          }
            
        }

        // Pelapor
        if (jns === "pelapor") {
          this.state.nik2Stat = { value: e.value, label: e.label };
          this.state.dataNikStat.nik2Stat = { value: e.value, label: e.label };
          this.state.detailSurat.pendStat2 = { value: e.value, label: e.label };
          // this.state.tanggal_lahir_pelapor = data.results.tanggal_lahir;
          this.state.detailSurat.nama_pelapor = data.results.nama;
          this.state.detailSurat.tanggal_lahir_pelapor = new Date(
            data.results.tanggal_lahir
          )
          // .toLocaleDateString("id-ID");
          this.state.detailSurat.tempat_lahir_pelapor =
            data.results.tempat_lahir;
          this.state.detailSurat.jk_pelapor = data.results.jk;
          this.state.detailSurat.status_perkawinan_pelapor =
            data.results.status_perkawinan;
          this.state.detailSurat.agama_pelapor = data.results.data_agama.nama;
          this.state.detailSurat.pekerjaan_pelapor =
            data.results.data_pekerjaan.nama;
          this.state.detailSurat.alamat_pelapor = alamat;
          this.state.status.dataPenduduk2 = "block";
          this.state.status.showBio2 = true;

          this.forceUpdate();
        } else {
          // Ibu
          if (jns === "ibu") {
            this.state.nik3Stat = { value: e.value, label: e.label };
            this.state.dataNikStat.nik3Stat = {
              value: e.value,
              label: e.label
            };
            // this.state.tanggal_lahir_ibu = data.results.tanggal_lahir;
            this.state.detailSurat.nik_ibu = e.value;
            this.state.detailSurat.nama_ibu = data.results.nama;
            this.state.detailSurat.tanggal_lahir_ibu = new Date(
              data.results.tanggal_lahir
            )
            // .toLocaleDateString("id-ID");
            this.state.detailSurat.tempat_lahir_ibu = data.results.tempat_lahir;
            this.state.detailSurat.pekerjaan_ibu =
              data.results.data_pekerjaan.nama;
            this.state.detailSurat.alamat_ibu = alamat;
            this.state.status.dataPenduduk3 = "block";
          }
          // Pemohon ( Ayah )
          else if (jns === "pemohon" || jns === "nik") {
            Get("catatan-penduduk/nik", data.results.id, dpddk => {
              //console.log(dpddk);
              if (typeof dpddk.results.data !== "undefined") {
                let pctt = 0;
                let catatan = "";
                if (dpddk.results.data) {
                  dpddk.results.data.forEach(dtp => {
                    if (dtp.status === 0 && dtp.penalti === 1) {
                      pctt = 1;
                      catatan = dtp.catatan;
                    }
                  });
                }
                if (pctt === 0) {
                  switch (jenis_surat) {
                    case "Surat Keterangan Kelahiran":
                      this.state.nikStat = { value: e.value, label: e.label };
                      this.state.dataNikStat.nikStat = {
                        value: e.value,
                        label: e.label
                      };
                      // this.state.tanggal_lahir_ayah = data.results.tanggal_lahir;
                      this.state.detailSurat.nik_ayah = e.value;
                      this.state.detailSurat.nama_ayah = data.results.nama;
                      this.state.detailSurat.tanggal_lahir_ayah = new Date(
                        data.results.tanggal_lahir
                      )
                      // .toLocaleDateString("id-ID");
                      this.state.detailSurat.tempat_lahir_ayah =
                        data.results.tempat_lahir;
                      this.state.detailSurat.pekerjaan_ayah =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.alamat_ayah = alamat;
                      this.state.status.dataPenduduk = "block";
                      break;

                    case "Surat Keterangan Jalan":
                      this.state.detailSurat.pendStat = {
                        value: e.value,
                        label: e.label
                      };
                      this.state.detailSurat.nik = data.results.id;
                      this.state.detailSurat.kk = data.results.id_kk;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;

                      this.state.detailSurat.alamat = alamat;
                      this.state.detailSurat.kewarganegaraan =
                        data.results.kewarganegaraan;

                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.status.showBio = true;
                      break;

                    case "Surat Keterangan Kematian":
                      this.state.nikStat = { value: e.value, label: e.label };
                      this.state.dataNikStat.nikStat = {
                        value: e.value,
                        label: e.label
                      };
                      // this.state.detailSurat.tanggal_lahir =
                      //   data.results.tanggal_lahir;
                      this.state.detailSurat.nama = data.results.nama;
                      this.state.detailSurat.tanggal_lahir = new Date(
                        data.results.tanggal_lahir
                      )
                      // .toLocaleDateString("id-ID");
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.alamat = alamat;
                      this.state.status.dataPenduduk = "block";
                      break;

                    case "Surat Kuasa SKGR":
                      if (jns === "nik") {
                        this.state.detailSurat.agama =
                          data.results.data_agama.nama;
                        this.state.detailSurat.alamat = alamat;
                        this.state.detailSurat.jenis_kelamin = data.results.jk;
                        this.state.detailSurat.jk = data.results.jk;
                        this.state.detailSurat.kk = data.results.id_kk;
                        this.state.detailSurat.nama = data.results.nama;
                        this.state.detailSurat.nik = data.results.id;
                        this.state.detailSurat.pekerjaan =
                          data.results.data_pekerjaan.nama;
                        this.state.detailSurat.pendidikan_terakhir =
                          data.results.data_pendidikan_terakhir.nama;
                        this.state.detailSurat.pendStat = {
                          value: e.value,
                          label: e.label
                        };
                        this.state.detailSurat.status_perkawinan =
                          data.results.status_perkawinan;
                        this.state.detailSurat.tanggal_lahir =
                          data.results.tanggal_lahir;
                        this.state.detailSurat.tempat_lahir =
                          data.results.tempat_lahir;
                        this.state.status.showBio = true;
                      }
                      break;

                    case "Surat Keterangan Tidak Mampu":
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.alamat = alamat;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.kewarganegaraan = "Indonesia";
                      this.state.detailSurat.kk = data.results.id_kk;
                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.tanggal_lahir = new Date(
                        data.results.tanggal_lahir
                      )
                      // .toLocaleDateString("id-ID");
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      // this.state.status.btnForm = false;
                      // this.state.dataNikStat.nikStat = { value: data.results.nik, label: data.results.nik };
                      this.state.dataNikStat.nikStat = {
                        value: e.value,
                        label: e.label
                      };

                      this.state.status.dataPenduduk = "block";
                      break;

                    case "Surat Keterangan Pernah Menikah":
                      this.state.dataNikStat.nikStat = {
                        value: e.value,
                        label: e.label
                      };
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.alamat = alamat;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.tanggal_lahir = new Date(
                        data.results.tanggal_lahir
                      )
                      // .toLocaleDateString("id-ID");
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.status.dataPenduduk = "block";
                      break;

                    case "Surat Pernyataan Tidak Menikah":
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.alamat = alamat;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.nik = data.results.id;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      // this.state.detailSurat.status_perkawinan =
                      //   data.results.status_perkawinan;
                      this.state.detailSurat.status_perkawinan = null
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.nama_ayah_kandung = data.results.nama_ayah;

                      if (data.results.status_perkawinan === "Cerai hidup") {
                        this.state.detailSurat.labelAkta = "cerai";
                      }
                      if (data.results.status_perkawinan === "Cerai mati") {
                        this.state.detailSurat.labelAkta = "kematian";
                      }
                      this.state.status.showBio = true;
                      // this.state.status.btnForm = false;

                      this.state.detailSurat.pendStat = {
                        value: e.value,
                        label: e.label
                      };
                      break;

                    case "Surat Pernyataan Kehilangan":
                      this.state.detailSurat.nik = data.results.id;
                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.alamat = alamat;
                      // this.state.status.btnForm = false;
                      this.state.detailSurat.pendStat = {
                        value: e.value,
                        label: e.label
                      };

                      this.state.status.showBio = true;
                      break;

                    case "Surat Pernyataan Belum Menikah":
                      this.state.detailSurat.nik = data.results.id;
                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.alamat = alamat;
                      this.state.detailSurat.nama_ayah_kandung = data.results.nama_ayah;
                      this.state.status.showBio = true;
                      // this.state.status.btnForm = false;
                      this.state.detailSurat.pendStat = {
                        value: e.value,
                        label: e.label
                      };
                      break;

                    case "Surat Pengantar Pindah Nikah":
                      this.state.detailSurat.nik = data.results.id;
                      this.state.detailSurat.kk = data.results.id_kk;

                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.nama_nasab_p1 =
                        data.results.nama_ayah_kandung;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.alamat = alamat;

                      this.state.detailSurat.pendStat = {
                        value: e.value,
                        label: e.label
                      };
                      this.state.status.showBio = true;
                      break;

                    case "Surat Keterangan Usaha":
                      this.state.detailSurat.nik = data.results.id;
                      this.state.detailSurat.kk = data.results.id_kk;

                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.tanggal_lahir = new Date(data.results.tanggal_lahir);
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.alamat = alamat;

                      this.state.detailSurat.pendStat = {
                        value: e.value,
                        label: e.label
                      };

                      this.state.status.showBio = true;
                      this.state.status.btnForm = false;

                      break;

                    case "Surat Keterangan Penghasilan":
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.tanggal_lahir = new Date(
                        data.results.tanggal_lahir
                      )
                      // .toLocaleDateString("id-ID");
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.pendidikan_terakhir;
                      this.state.detailSurat.kewarganegaraan =
                        data.results.kewarganegaraan;
                      this.state.detailSurat.tujuan_pembuatan =
                        data.results.tujuan_pembuatan;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.alamat = alamat;
                      this.state.dataNikStat.nikStat = {
                        value: e.value,
                        label: e.label
                      };

                      this.state.status.dataPenduduk = "block";
                      this.state.status.btnForm = false;
                      break;

                    case "Surat Keterangan Kepemilikan Rumah":
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.kk = data.results.id_kk;
                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      // this.state.detailSurat.nama_pemohon = data.results.nama_pemohon;
                      this.state.detailSurat.tanggal_lahir = new Date(
                        data.results.tanggal_lahir
                      )
                      // .toLocaleDateString("id-ID");
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.kewarganegaraan = "Indonesia";
                      this.state.detailSurat.alamat = alamat;

                      this.state.status.dataPenduduk = "block";
                      this.state.status.btnForm = false;
                      break;

                    case "Surat Pengantar SKCK":
                      this.state.detailSurat.nik = data.results.id;
                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.alamat = alamat;

                      this.state.status.showBio = true;
                      this.state.status.btnForm = false;

                      this.state.detailSurat.pendStat = {
                        value: e.value,
                        label: e.label
                      };
                      break;

                    case "Surat Keterangan Domisili":
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.kk = data.results.id_kk;
                      this.state.detailSurat.nama = data.results.nama;
                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.tanggal_lahir = new Date(
                        data.results.tanggal_lahir
                      ).toLocaleDateString("id-ID");
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.kewarganegaraan = "Indonesia";
                      this.state.detailSurat.alamat = alamat;

                      this.state.dataNikStat.nikStat = {
                        value: e.value,
                        label: e.label
                      };

                      this.state.status.dataPenduduk = "block";
                      this.state.status.btnForm = false;
                      break;

                    case "Surat Keterangan Belum Memiliki Rumah":
                      this.state.detailSurat.nik = data.results.id;
                      this.state.detailSurat.kk = data.results.id_kk;

                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.alamat = alamat;

                      this.state.status.showBio = true;
                      this.state.status.btnForm = false;

                      this.state.detailSurat.pendStat = {
                        value: e.value,
                        label: e.label
                      };
                      break;

                    case "Surat Keterangan Ahli Waris":
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.nama = data.results.nama;
                      this.state.detailSurat.tanggal_lahir = new Date(
                        data.results.tanggal_lahir
                      ).toLocaleDateString("id-ID");
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.kewarganegaraan = "Indonesia";
                      this.state.detailSurat.alamat = alamat;

                      this.state.dataNikStat.nikStat = {
                        value: e.value,
                        label: e.label
                      };

                      this.state.status.dataPenduduk = "block";
                      this.state.status.btnForm = false;
                      break;

                    case "Surat Keterangan":
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.kk = data.results.id_kk;
                      this.state.detailSurat.nama = data.results.nama;
                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      // this.state.detailSurat.tanggal_lahir = new Date(
                      //   data.results.tanggal_lahir
                      // ).toLocaleDateString("id-ID");
                      this.state.detailSurat.tanggal_lahir = data.results.tanggal_lahir;
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.kewarganegaraan = "Indonesia";
                      this.state.detailSurat.alamat = alamat;
                      this.state.status.dataPenduduk = "block";
                      this.state.status.btnForm = false;
                      break;

                    case "Surat Izin Keramaian":
                      this.state.detailSurat.nik = data.results.id;
                      this.state.detailSurat.kk = data.results.id_kk;

                      this.state.detailSurat.nama_pemohon = data.results.nama;
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.alamat = alamat;

                      this.state.status.showBio = true;
                      this.state.status.btnForm = false;

                      this.state.detailSurat.pendStat = {
                        value: e.value,
                        label: e.label
                      };
                      break;

                    case "Surat Kuasa":
                      this.state.detailSurat.nik = data.results.id;
                      this.state.detailSurat.kk = data.results.id_kk;
                      this.state.detailSurat.nama = data.results.nama;
                      this.state.detailSurat.tempat_lahir =
                        data.results.tempat_lahir;
                      this.state.detailSurat.tanggal_lahir =
                        data.results.tanggal_lahir;
                      this.state.detailSurat.pekerjaan =
                        data.results.data_pekerjaan.nama;
                      this.state.detailSurat.jenis_kelamin = data.results.jk;
                      this.state.detailSurat.jk = data.results.jk;
                      this.state.detailSurat.pendidikan_terakhir =
                        data.results.data_pendidikan_terakhir.nama;
                      this.state.detailSurat.agama =
                        data.results.data_agama.nama;
                      this.state.detailSurat.status_perkawinan =
                        data.results.status_perkawinan;
                      this.state.detailSurat.alamat = alamat;
                      this.state.status.showBio = true;

                      this.state.detailSurat.pendStat = {
                        value: e.value,
                        label: e.label
                      };
                      break;

                    default:
                      break;
                  }

                  this.state.status.btnFormUpdateSurat = false;
                  this.forceUpdate();
                } else {
                  this.state.dataNik.frmNik = [];
                  this.state.dataNikStat.nikStat = null;
                  this.state.detailSurat.agama = "";
                  this.state.detailSurat.alamat = "";
                  this.state.detailSurat.alamat_ayah = "";
                  this.state.detailSurat.dataNik.frmNik = [];
                  this.state.detailSurat.dataNikStat.nikStat = null;
                  this.state.detailSurat.jk = "";
                  this.state.detailSurat.kk = "";
                  this.state.detailSurat.nama = "";
                  this.state.detailSurat.nama_ayah = "";
                  this.state.detailSurat.nama_pemohon = "";
                  this.state.detailSurat.nik = "";
                  this.state.detailSurat.pekerjaan = "";
                  this.state.detailSurat.pekerjaan_ayah = "";
                  this.state.detailSurat.pendidikan_terakhir = "";
                  this.state.detailSurat.pendStat = [];
                  this.state.detailSurat.status_perkawinan = "";
                  this.state.detailSurat.tanggal_lahir = "";
                  // this.state.detailSurat.tanggal_lahir_ayah = "";
                  this.state.detailSurat.tanggal_lahir_ayah = null;
                  this.state.detailSurat.tempat_lahir = "";
                  this.state.detailSurat.tempat_lahir_ayah = "";
                  this.state.status.dataPenduduk = "none";
                  this.state.status.showBio = false;

                  this.state.detailSurat.nik2 = "";
                  this.state.detailSurat.nama_pemohon2 = "";
                  this.state.detailSurat.tempat_lahir2 = "";
                  this.state.detailSurat.tanggal_lahir2 = "";
                  this.state.detailSurat.pekerjaan2 = "";
                  this.state.detailSurat.pendidikan_terakhir2 = "";
                  this.state.detailSurat.agama2 = "";
                  this.state.detailSurat.status_perkawinan2 = "";
                  this.state.detailSurat.alamat2 = "";
                  this.state.detailSurat.pendStat2 = [];
                  this.state.status.showBio2 = false;
                  this.state.status.btnForm = true;

                  this.state.status.btnFormUpdateSurat = true;
                  this.forceUpdate();
                  this.setState({
                    show: true,
                    basicType: "danger",
                    basicTitle: "Peringatan Catatan Warga",
                    pesanAlert:
                      "Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r" +
                      catatan
                  });
                }
              }
            });
          }
          // if nik2, nik3, dst
          else {
            switch (jenis_surat) {
              case "Surat Kuasa SKGR":
                if (jns === "nik2") {
                  this.state.detailSurat.agama2 = data.results.data_agama.nama;
                  this.state.detailSurat.alamat2 = alamat;
                  this.state.detailSurat.jenis_kelamin2 = data.results.jk;
                  this.state.detailSurat.kk2 = data.results.id_kk;
                  this.state.detailSurat.nama2 = data.results.nama;
                  this.state.detailSurat.nik2 = data.results.id;
                  this.state.detailSurat.pekerjaan2 =
                    data.results.data_pekerjaan.nama;
                  this.state.detailSurat.pendidikan_terakhir2 =
                    data.results.data_pendidikan_terakhir.nama;
                  this.state.detailSurat.status_perkawinan2 =
                    data.results.status_perkawinan;
                  this.state.detailSurat.tanggal_lahir2 =
                    data.results.tanggal_lahir;
                  this.state.detailSurat.tempat_lahir2 =
                    data.results.tempat_lahir;
                  this.state.detailSurat.pendStat2 = {
                    value: e.value,
                    label: e.label
                  };
                  this.state.status.showBio2 = true;
                  this.state.status.btnForm = false;
                }
                break;

              case "Surat Keterangan Tidak Mampu":
                if (jns === "nik2") {
                  this.state.detailSurat.agama2 = data.results.data_agama.nama;
                  this.state.detailSurat.alamat2 = alamat;
                  this.state.detailSurat.jenis_kelamin2 = data.results.jk;
                  this.state.detailSurat.kewarganegaraan2 = "Indonesia";
                  this.state.detailSurat.nama2 = data.results.nama;
                  this.state.detailSurat.pekerjaan2 =
                    data.results.data_pekerjaan.nama;
                  this.state.detailSurat.status_perkawinan2 =
                    data.results.status_perkawinan;
                  this.state.detailSurat.tanggal_lahir2 =
                    data.results.tanggal_lahir;
                  this.state.detailSurat.tanggal_lahir2 = new Date(
                    data.results.tanggal_lahir
                  ).toLocaleDateString("id-ID");
                  this.state.detailSurat.tempat_lahir2 =
                    data.results.tempat_lahir;
                  this.state.status.dataPenduduk2 = "block";
                  this.state.status.btnForm = false;
                } else if (jns === "nik3") {
                  this.state.dataNikStat.nik3Stat = {
                    value: e.value,
                    label: e.label
                  };
                  this.state.status.btnForm = false;
                }
                break;

              case "Surat Keterangan Pernah Menikah":
                if (jns === "pasangan") {
                  this.state.dataNikStat.nik3Stat = {
                    value: e.value,
                    label: e.label
                  };

                  this.state.detailSurat.nik_pasangan = e.value;
                  this.state.detailSurat.agama_pasangan = {value: data.results.data_agama.nama, label: data.results.data_agama.nama};
                  this.state.detailSurat.alamat_pasangan = alamat;
                  this.state.detailSurat.jk_pasangan = data.results.jk;
                  this.state.detailSurat.nama_pasangan = data.results.nama;
                  this.state.detailSurat.pekerjaan_pasangan =
                    data.results.data_pekerjaan.nama;
                  this.state.detailSurat.status_perkawinan_pasangan =
                    data.results.status_perkawinan;
                  this.state.detailSurat.tanggal_lahir_pasangan =
                    data.results.tanggal_lahir;
                  this.state.detailSurat.tanggal_lahir_pasangan = new Date(
                    data.results.tanggal_lahir
                  )
                  // .toLocaleDateString("id-ID");
                  this.state.detailSurat.tempat_lahir_pasangan =
                    data.results.tempat_lahir;
                  this.state.status.dataPenduduk3 = "block";
                  this.state.status.btnForm = false;
                }
                break;

              case "Surat Keterangan Domisili":
                if (jns === "nik3") {
                  this.state.dataNikStat.nik3Stat = {
                    value: e.value,
                    label: e.label
                  };
                  this.state.status.btnForm = false;
                }
                break;

              case "Surat Pengantar Pindah Nikah":
                if (jns === "nik2") {
                  this.state.detailSurat.nik_pasangan = data.results.id;
                  this.state.detailSurat.kk_pasangan = data.results.id_kk;

                  this.state.detailSurat.nama_pasangan = data.results.nama;
                  this.state.detailSurat.tempat_lahir_pasangan =
                    data.results.tempat_lahir;
                  this.state.detailSurat.tanggal_lahir_pasangan =
                    data.results.tanggal_lahir;
                  this.state.detailSurat.pekerjaan_pasangan =
                    data.results.data_pekerjaan.nama;
                  this.state.detailSurat.jenis_kelamin_pasangan =
                    data.results.jk;
                  this.state.detailSurat.pendidikan_terakhir_pasangan =
                    data.results.data_pendidikan_terakhir.nama;
                  this.state.detailSurat.agama_pasangan =
                    data.results.data_agama.nama;
                  this.state.detailSurat.status_perkawinan_pasangan =
                    data.results.status_perkawinan;
                  this.state.detailSurat.nama_nasab_p2 =
                    data.results.nama_ayah_kandung;
                  this.state.detailSurat.alamat_pasangan = alamat;

                  this.state.detailSurat.pendStat2 = {
                    value: e.value,
                    label: e.label
                  };

                  this.state.status.showBio2 = true;
                  this.state.status.btnForm = false;
                }
                break;

              case "Surat Keterangan Ahli Waris":
                if (jns === "nik2") {
                  this.state.detailSurat.tanggal_lahir2 =
                    data.results.tanggal_lahir;
                  this.state.status.btnForm = false;

                  this.state.detailSurat.nama2 = data.results.nama;
                  this.state.detailSurat.tanggal_lahir2 = new Date(
                    data.results.tanggal_lahir
                  ).toLocaleDateString("id-ID");
                  this.state.detailSurat.tempat_lahir2 =
                    data.results.tempat_lahir;
                  this.state.detailSurat.jenis_kelamin2 = data.results.jk;
                  this.state.detailSurat.status_perkawinan2 =
                    data.results.status_perkawinan;
                  this.state.detailSurat.agama2 = data.results.data_agama.nama;
                  this.state.detailSurat.pekerjaan2 =
                    data.results.data_pekerjaan.nama;
                  this.state.detailSurat.kewarganegaraan2 = "Indonesia";
                  this.state.detailSurat.alamat2 = alamat;
                  this.state.status.dataPenduduk2 = "block";

                  this.state.dataNikStat.nik2Stat = {
                    value: e.value,
                    label: e.label
                  };
                } else if (jns === "nik3") {
                  this.state.dataNikStat.nik3Stat = {
                    value: e.value,
                    label: e.label
                  };
                }
                break;

              case "Surat Kuasa":
                if (jns === "nik2") {
                  this.state.detailSurat.nik2 = data.results.id;
                  this.state.detailSurat.kk2 = data.results.id_kk;

                  this.state.detailSurat.nama2 = data.results.nama;
                  this.state.detailSurat.tempat_lahir2 =
                    data.results.tempat_lahir;
                  this.state.detailSurat.tanggal_lahir2 =
                    data.results.tanggal_lahir;
                  this.state.detailSurat.pekerjaan2 =
                    data.results.data_pekerjaan.nama;
                  this.state.detailSurat.jenis_kelamin2 = data.results.jk;
                  this.state.detailSurat.pendidikan_terakhir2 =
                    data.results.data_pendidikan_terakhir.nama;
                  this.state.detailSurat.agama2 = data.results.data_agama.nama;
                  this.state.detailSurat.status_perkawinan2 =
                    data.results.status_perkawinan;
                  this.state.detailSurat.alamat2 = alamat;
                  this.state.status.showBio2 = true;
                  this.state.detailSurat.pendStat2 = {
                    value: e.value,
                    label: e.label
                  };

                  if (
                    this.state.detailSurat.pendStat2.length !== 0 &&
                    this.state.detailSurat.pendStat.length !== 0
                  ) {
                    this.state.status.btnForm = false;
                  }
                }
                break;

              default:
                break;
            }
          }
        }
        this.forceUpdate();
      });
    } else {
      if (jns === "pelapor") {
        this.state.dataNikStat.nik2Stat = null;
        this.state.detailSurat.nama_pelapor = "";
        this.state.detailSurat.tanggal_lahir_pelapor = null;
        this.state.detailSurat.tempat_lahir_pelapor = "";
        this.state.detailSurat.agama_pelapor = "";
        this.state.detailSurat.pekerjaan_pelapor = "";
        this.state.detailSurat.alamat_pelapor = "";
        this.state.detailSurat.jk_pelapor = "";
        this.state.detailSurat.status_perkawinan_pelapor = "";
        this.state.status.dataPenduduk2 = "none";
      } else {
        if (jns === "ibu") {
          this.state.dataNikStat.nik3Stat = null;
          this.state.detailSurat.nik_ibu = "";
          this.state.detailSurat.nama_ibu = "";
          // this.state.detailSurat.tanggal_lahir_ibu = "";
          this.state.detailSurat.tanggal_lahir_ibu = null;
          this.state.detailSurat.tempat_lahir_ibu = "";
          this.state.detailSurat.pekerjaan_ibu = "";
          this.state.detailSurat.alamat_ibu = "";
          this.state.status.dataPenduduk3 = "none";
        }
        // Selain jns ibu dan pelapor (nik, nik2, nik3, pemohon, pasangan)
        else {
          if (jns === "nik3" || jns === "pasangan") {
            this.state.dataNikStat.nik3Stat = null;
            this.state.status.dataPenduduk3 = "none";
          } else if (jns === "nik2") {
            this.state.dataNikStat.nik2Stat = null;
            this.state.detailSurat.pendStat2 = null;
            this.state.status.dataPenduduk2 = "none";

            this.state.detailSurat.agama2 = "";
            this.state.detailSurat.alamat2 = "";
            this.state.detailSurat.nama_pemohon2 = "";
            this.state.detailSurat.nama2 = "";
            this.state.detailSurat.nik2 = "";
            this.state.detailSurat.pekerjaan2 = "";
            this.state.detailSurat.pendidikan_terakhir2 = "";
            this.state.detailSurat.tanggal_lahir2 = "";
            this.state.detailSurat.tempat_lahir2 = "";

            this.state.detailSurat.status_perkawinan2 = "";
            this.state.status.showBio2 = false;
          } else if ((jns === "nik") | (jns === "pemohon")) {
            this.state.dataNikStat.nikStat = null;
            this.state.detailSurat.pendStat = null;
            this.state.status.dataPenduduk = "none";
            this.state.status.showBio = false;

            this.state.detailSurat.agama = "";
            this.state.detailSurat.alamat = "";
            this.state.detailSurat.jenis_kelamin = "";
            this.state.detailSurat.nama_pemohon = "";
            this.state.detailSurat.nik = "";
            this.state.detailSurat.pekerjaan = "";
            this.state.detailSurat.pendidikan_terakhir = "";
            this.state.detailSurat.status_perkawinan = "";
            this.state.detailSurat.tanggal_lahir = "";
            this.state.detailSurat.tempat_lahir = "";

            this.state.detailSurat.alamat_ayah = "";
            this.state.detailSurat.nik_ayah = "";
            this.state.detailSurat.nama_ayah = "";
            this.state.detailSurat.pekerjaan_ayah = "";
            // this.state.detailSurat.tanggal_lahir_ayah = "";
            this.state.detailSurat.tanggal_lahir_ayah = null;
            this.state.detailSurat.tempat_lahir_ayah = "";

            this.state.detailSurat.labelAkta = "";
          }

          this.state.detailSurat.status_perkawinan = "";

          this.state.status.btnForm = true;
        }
      }
      this.state.status.btnForm = true;
      this.forceUpdate();
    }
  };

  handleInputChangeNIK = (e, fld) => {
    if (e) {
      if (fld === "nik") {
        // let nikStat = {...this.state.detailSurat.nikStat};
        let nikStat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmNik = [];
          datanik.data.results.data.forEach(dt => {
            frmNik.push({ value: dt.id, label: dt.id });
          });
          this.state.dataNik.frmNik = frmNik;
          this.state.dataNikStat.nikStat = nikStat;
          this.forceUpdate();
          this.setState({ frmNik, nikStat });
        });
      } else {
        // //console.log('masuk sini?');

        if (fld === "nik2") {
          let nik2Stat = { value: e, label: e };
          let param = {
            nik: e
          };
          Post("penduduk", null, param, datanik => {
            let frmNik2 = [];
            datanik.data.results.data.forEach(dt => {
              frmNik2.push({ value: dt.id, label: dt.id });
            });
            this.state.dataNik.frmNik2 = frmNik2;
            this.state.dataNikStat.nik2Stat = nik2Stat;
            // //console.log('masuk sini');
            this.forceUpdate();
            this.setState({ frmNik2, nik2Stat });
          });
        } else {
          let nik3Stat = { value: e, label: e };
          let param = {
            nik: e
          };
          Post("penduduk", null, param, datanik => {
            //console.log(datanik.data.results);
            let frmNik3 = [];
            datanik.data.results.data.forEach(dt => {
              frmNik3.push({ value: dt.id, label: dt.id });
            });
            this.state.dataNik.frmNik3 = frmNik3;
            this.state.dataNikStat.nik3Stat = nik3Stat;
            this.forceUpdate();
            this.setState({ frmNik3, nik3Stat });
          });
        }
      }
    }
  };

  changeInputText = (e, fld) => {
    switch (fld) {
      case "tempat_lahir_anak":
        this.state.detailSurat.tempat_lahir_anak = e.target.value;
        this.forceUpdate();
        break;
      case "nik_ayah":
        this.state.detailSurat.nik_ayah = e.target.value;
        this.forceUpdate();
        break;
      case "nik_ibu":
        this.state.detailSurat.nik_ibu = e.target.value;
        this.forceUpdate();
        break;
      case "nama_ayah":
        this.state.detailSurat.nama_ayah = e.target.value;
        this.forceUpdate();
        break;
      case "nama_ibu":
        this.state.detailSurat.nama_ibu = e.target.value;
        this.forceUpdate();
        break;
      case "tempat_lahir_ayah":
        this.state.detailSurat.tempat_lahir_ayah = e.target.value;
        this.forceUpdate();
        break;
      case "tempat_lahir_ibu":
        this.state.detailSurat.tempat_lahir_ibu = e.target.value;
        this.forceUpdate();
        break;
      case "pekerjaan_ayah":
        this.state.detailSurat.pekerjaan_ayah = e.target.value;
        this.forceUpdate();
        break;
      case "pekerjaan_ibu":
        this.state.detailSurat.pekerjaan_ibu = e.target.value;
        this.forceUpdate();
        break;
      case "alamat_ayah":
        this.state.detailSurat.alamat_ayah = e.target.value;
        this.forceUpdate();
        break;
      case "alamat_ibu":
        this.state.detailSurat.alamat_ibu = e.target.value;
        this.forceUpdate();
        break;
      case "nik_pasangan":
        this.state.detailSurat.nik_pasangan = e.target.value;
        this.forceUpdate();
        break;
      case "nama_pasangan":
        this.state.detailSurat.nama_pasangan = e.target.value;
        this.forceUpdate();
        break;
      case "nama_nasab_p2":
        this.state.detailSurat.nama_nasab_p2 = e.target.value;
        this.forceUpdate();
        break;
      case "tempat_lahir_pasangan":
        this.state.detailSurat.tempat_lahir_pasangan = e.target.value;
        this.forceUpdate();
        break;
      case "pekerjaan_pasangan":
        this.state.detailSurat.pekerjaan_pasangan = e.target.value;
        this.forceUpdate();
        break;
      case "alamat_pasangan":
        this.state.detailSurat.alamat_pasangan = e.target.value;
        this.forceUpdate();
        break;
      case "status_perkawinan_pasangan":
        this.state.detailSurat.status_perkawinan_pasangan = e.target.value;
        this.forceUpdate();
        break;
      case "nik2":
        this.state.detailSurat.nik2 = e.target.value;
        this.forceUpdate();
        break;
      case "nama2":
        this.state.detailSurat.nama2 = e.target.value;
        this.forceUpdate();
        break;
      case "tempat_lahir2":
        this.state.detailSurat.tempat_lahir2 = e.target.value;
        this.forceUpdate();
        break;
      case "pekerjaan2":
        this.state.detailSurat.pekerjaan2 = e.target.value;
        this.forceUpdate();
        break;
      case "alamat2":
        this.state.detailSurat.alamat2 = e.target.value;
        this.forceUpdate();
        break;
      default:
        // no default
        break;
      }
  };

  dataIbu = () => {
    this.state.status.dataPenduduk3 =
      this.state.detailSurat.formIbu === "" ? "none" : "";
    this.state.detailSurat.formIbu = this.state.detailSurat.formIbu === "" ? "none" : "";
    this.state.detailSurat.nik_ibu = "";
    this.state.detailSurat.nama_ibu = "";
    this.state.detailSurat.tempat_lahir_ibu = "";
    this.state.detailSurat.tanggal_lahir_ibu = null;
    this.state.detailSurat.pekerjaan_ibu = "";
    this.state.detailSurat.alamat_ibu = "";
    this.state.dataNikStat.nik3Stat = null;
    this.forceUpdate();
  };

  dataAyah = () => {
    this.state.status.dataPenduduk = this.state.detailSurat.formAyah === "" ? "none" : "";
    this.state.detailSurat.formAyah = this.state.detailSurat.formAyah === "" ? "none" : "";
    this.state.detailSurat.nik_ayah = "";
    this.state.detailSurat.nama_ayah = "";
    this.state.detailSurat.tempat_lahir_ayah = "";
    this.state.detailSurat.tanggal_lahir_ayah = null;
    this.state.detailSurat.pekerjaan_ayah = "";
    this.state.detailSurat.alamat_ayah = "";
    this.state.dataNikStat.nikStat = null;
    this.forceUpdate();
  };

  dataPasangan = () => {
    let viewFormPasangan = this.state.detailSurat.formPasangan === "" ? "none" : "";

    // if (this.state.dataNikStat.nik3Stat !== null) {
    //   this.state.status.showBio2 = true;
    // } else {
    //   this.state.status.showBio2 = viewFormPasangan;
    // }
    this.state.status.showBio2 = this.state.detailSurat.formPasangan === "" ? false : true;

    this.state.detailSurat.formPasangan = viewFormPasangan;
    // this.state.status.dataPenduduk3 =
    //   this.state.status.dataPenduduk3 === "" ? "none" : "";
    this.state.status.dataPenduduk3 = viewFormPasangan;
    this.state.dataNikStat.nik3Stat = null;
    this.state.detailSurat.pendStat2 = null;
    this.state.detailSurat.nama_nasab_p2 = "";
    this.state.detailSurat.nama_pasangan = "";
    this.state.detailSurat.jenis_kelamin_pasangan = "";
    this.state.detailSurat.jkkkStat = null;
    this.state.detailSurat.tempat_lahir_pasangan = "";
    this.state.detailSurat.tanggal_lahir_pasangan = null;
    this.state.detailSurat.pekerjaan_pasangan = "";
    this.state.detailSurat.agama_pasangan = null;
    this.state.detailSurat.nik_pasangan = "";
    this.state.detailSurat.alamat_pasangan = "";
    this.state.detailSurat.status_perkawinan_pasangan = "";
    this.forceUpdate();
  };

  dataPenerimaKuasa = () => {
    // if (this.state.detailSurat.pendStat2 !== null) {
    //   this.state.status.showBio2 = true;
    // } else {
    //   let viewFormPenerimaKuasa = this.state.detailSurat.formPenerimaKuasa === "" ? "none" : "";
    //   this.state.status.showBio2 = viewFormPenerimaKuasa;
    // }

    let viewFormPenerimaKuasa = this.state.detailSurat.formPenerimaKuasa === "" ? "none" : "";
    this.state.status.showBio2 = viewFormPenerimaKuasa;

    this.state.detailSurat.pendStat2 = null;
    this.state.detailSurat.formPenerimaKuasa =
      this.state.detailSurat.formPenerimaKuasa === "" ? "none" : "";
    this.state.detailSurat.nik2 = "";
    this.state.detailSurat.nama2 = "";
    this.state.detailSurat.tempat_lahir2 = "";
    // this.state.detailSurat.tanggal_lahir2 = "";
    this.state.detailSurat.tanggal_lahir2 = null;
    this.state.detailSurat.pekerjaan2 = "";
    this.state.detailSurat.alamat2 = "";
    this.forceUpdate();
  };


  handleAddShareholder = () => {
    this.state.detailSurat.usaha = this.state.detailSurat.usaha.concat([
      { name: "" }
    ]);
    this.forceUpdate();
    //console.log(this.state.detailSurat.usaha);
    // this.setState({
    //   usaha: this.state.usaha.concat([{ name: "" }])
    // });
  };

  handleRemoveShareholder = idx => () => {
    //console.log(this.state.detailSurat.usaha);

    this.state.detailSurat.usaha = this.state.detailSurat.usaha.filter(
      (s, sidx) => idx !== sidx
    );
    this.forceUpdate();
    //console.log(this.state.detailSurat.usaha);
    // this.setState({
    //   usaha: this.state.detailSurat.usaha.filter((s, sidx) => idx !== sidx)
    // });
  };

  hapusUsaha = id => {
    let data = this.state.detailSurat.usaha;
    data.splice(id, 1);
    // this.setState({ dataUsaha: data });
    this.state.detailSurat.usaha = data;
    this.forceUpdate();
  };

  addUsaha = e => {
    e.preventDefault();
    if (this.state.detailSurat.jenisForm === "usaha") {
      if (
        document.getElementById("jenis_usaha").value !== "" &&
        document.getElementById("nama_tempat_usaha").value !== "" &&
        document.getElementById("lokasi").value !== ""
      ) {
        let data = {
          jenis_usaha: document.getElementById("jenis_usaha").value,
          nama_tempat_usaha: document.getElementById("nama_tempat_usaha").value,
          lokasi: document.getElementById("lokasi").value
        };
        // this.setState({ dataUsaha: [...this.state.dataUsaha, data] });
        this.state.detailSurat.usaha = [...this.state.detailSurat.usaha, data];
        this.forceUpdate();
        document.getElementById("jenis_usaha").value = "";
        document.getElementById("nama_tempat_usaha").value = "";
        document.getElementById("lokasi").value = "";
        // document.getElementById("umur").value = "";
        // document.getElementById("lokasi").value = "";
        // document.getElementById("luas_kebun").value = "";
      } else {
        this.setState({
          show: true,
          basicType: "warning",
          basicTitle: "Data Belum Lengkap",
          pesanAlert: "Silahkan lengkapi data usaha"
        });
      }
    } else {
      if (
        document.getElementById("jenis_usaha").value !== "" &&
        document.getElementById("umur").value !== "" &&
        document.getElementById("lokasi").value !== "" &&
        document.getElementById("luas_kebun").value !== ""
      ) {
        let data = {
          jenis_usaha: document.getElementById("jenis_usaha").value,
          umur: document.getElementById("umur").value,
          lokasi: document.getElementById("lokasi").value,
          luas_kebun: document.getElementById("luas_kebun").value
        };
        // this.setState({ dataUsaha: [...this.state.dataUsaha, data] });
        this.state.detailSurat.usaha = [...this.state.detailSurat.usaha, data];
        this.forceUpdate();
        document.getElementById("jenis_usaha").value = "";
        // document.getElementById("nama_tempat_usaha").value = "";
        document.getElementById("lokasi").value = "";
        document.getElementById("umur").value = "";
        document.getElementById("lokasi").value = "";
        document.getElementById("luas_kebun").value = "";
      } else {
        this.setState({
          show: true,
          basicType: "warning",
          basicTitle: "Data Belum Lengkap",
          pesanAlert: "Silahkan lengkapi data perkebunan"
        });
      }
    }
  };

  tambahMeninggal = e => {
    e.preventDefault();
    if (this.state.detailSurat.meninggal2 === "none") {
      this.state.detailSurat.meninggal2 = "inherit";
      // this.setState({ meninggal2: "inherit" });
    } else {
      this.state.detailSurat.meninggal2 = "none";
      this.state.dataNikStat.nik2Stat = null;
      // this.setState({ meninggal2: "none" });
    }
    this.forceUpdate();
  };

  // ================== Update detail surat =====================

  cetakSurat = (e, data) => {
    e.preventDefault();

    var jenis_surat = "";
    let kop = "";
    const label_surat = data.label;

    switch (label_surat) {
      case "Surat Keterangan Tidak Mampu":
        jenis_surat = "tidak-mampu";
        break;
      case "Surat Keterangan Domisili":
        jenis_surat = "domisili";
        break;
      case "Surat Keterangan Penghasilan":
        jenis_surat = "penghasilan";
        break;
      case "Surat Keterangan Kematian":
        jenis_surat = "kematian";
        break;
      case "Surat Keterangan Pernah Menikah":
        jenis_surat = "pernah-menikah";
        break;
      case "Surat Keterangan Kelahiran":
        jenis_surat = "kelahiran";
        break;
      case "Surat Kuasa SKGR":
        jenis_surat = "kuasa";
        break;
      case "Surat Kuasa":
        jenis_surat = "kuasa";
        break;
      case "Surat Keterangan Ahli Waris":
        jenis_surat = "ahli-waris";
        break;

      case "Surat Undangan":
        kop = "surat-undangan";
        break;

      default:
        jenis_surat = "grup";
        break;
    }

    // get data detail for cetak
    var cetak = { ...this.state.cetak };

    Get(
      kop === ""
        ? "surat-keterangan/" + jenis_surat + "/find"
        : "surat-undangan/find",
      data.uuid_surat,
      data => {
        //console.log(data.results);

        cetak.uuid = data.results.uuid;
        cetak.id_jenis = data.results.id_jenis;
        cetak.jenis_surat = label_surat;
        cetak.nomor = data.results.nomor;
        cetak.tanggal_surat = data.results.tanggal_surat;

        cetak.nama_desa = DESA;
        cetak.nik = data.results.nik;
        cetak.kk = data.results.kk;

        // Cek undefined
        cetak.nama_pemohon =
          typeof data.results.nama_pemohon !== "undefined"
            ? data.results.nama_pemohon.toUpperCase()
            : "";
        cetak.nama =
          typeof data.results.nama !== "undefined"
            ? data.results.nama.toUpperCase()
            : "";
        cetak.tanggal_lahir =
          typeof data.results.tanggal_lahir !== "undefined"
            ? new Date(data.results.tanggal_lahir)
            : "";
        cetak.nomor_surat =
          typeof data.results.nomor_surat !== "undefined"
            ? data.results.nomor_surat
            : "";
        cetak.tempat_lahir =
          typeof data.results.tempat_lahir !== "undefined"
            ? data.results.tempat_lahir.toLowerCase()
            : "";

        cetak.kewarganegaraan =
          typeof data.results.kewarganegaraan !== "undefined"
            ? data.results.kewarganegaraan
            : "";
        cetak.status_perkawinan =
          typeof data.results.status_perkawinan !== "undefined"
            ? data.results.status_perkawinan
            : "";
        cetak.tujuan_pembuatan =
          typeof data.results.tujuan_pembuatan !== "undefined"
            ? data.results.tujuan_pembuatan
            : "";
        cetak.pendidikan_terakhir =
          typeof data.results.pendidikan_terakhir !== "undefined"
            ? data.results.pendidikan_terakhir
            : "";
        cetak.jenis_kelamin =
          typeof data.results.jenis_kelamin !== "undefined"
            ? data.results.jenis_kelamin
            : "";
        cetak.nama_ayah_kandung =
          typeof data.results.nama_ayah !== "undefined"
            ? data.results.nama_ayah
            : "";

        cetak.alamat =
          typeof data.results.alamat !== "undefined" ? data.results.alamat : "";
        cetak.agama =
          typeof data.results.agama !== "undefined" ? data.results.agama : "";
        cetak.pekerjaan =
          typeof data.results.pekerjaan !== "undefined"
            ? data.results.pekerjaan
            : "";
        cetak.status_ttd = data.results.status_ttd;
        cetak.jabatan_ttd = data.results.jabatan_ttd.toLowerCase();
        cetak.jabatan_pengganti_ttd = data.results.jabatan_pengganti_ttd;
        cetak.nama_ttd = data.results.nama_ttd;
        cetak.nip_ttd = data.results.nip_ttd;

        if (typeof data.results.status_ttd2 !== "undefined") {
          cetak.status_ttd2 = data.results.status_ttd2;
          cetak.jabatan_ttd2 = data.results.jabatan_ttd2;
          cetak.jabatan_pengganti_ttd2 = data.results.jabatan_pengganti_ttd2;
          cetak.nama_ttd2 = data.results.nama_ttd2;
          cetak.nip_ttd2 = data.results.nip_ttd2;
        }
        // //console.log(cetak)
        // //console.log('sampai sini')

        switch (label_surat) {
          case "Surat Keterangan Tidak Mampu":
            cetak.detail = data.results.data_detail_tidak_mampu;
            break;

          case "Surat Keterangan Domisili":
            let detailkeluarga = "";

            if (data.results.data_detail_domisili.length) {
              detailkeluarga = (
                <>
                  <p>
                    Adapun anggota keluarganya berjumlah{" "}
                    {data.results.data_detail_domisili.length} orang, yaitu :
                  </p>
                  <table
                    border="1"
                    width="100%"
                    cellpadding="0"
                    cellspacing="0"
                    style={{ fontSize: "14px" }}
                  >
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Nama</th>
                        <th>L/P</th>
                        <th>Tempat/Tanggal Lahir</th>
                        <th>Pekerjaan</th>
                        <th>Pendidikan</th>
                        <th>SHDK</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.results.data_detail_domisili.map((dt, i) => {
                        return (
                          <tr style={{ textTransform: "capitalize" }}>
                            <td style={{ padding: "5px 5px 5px 5px" }}>{i + 1}</td>
                            <td style={{ padding: "5px 5px 5px 5px" }}>
                              {dt.nama.toLowerCase()}
                            </td>
                            <td style={{ padding: "5px 5px 5px 5px" }}>
                              {dt.jk.toLowerCase() === "laki-laki" ? "L" : "P"}
                            </td>
                            <td style={{ padding: "5px 5px 5px 5px" }}>
                              {dt.tempat_lahir.toLowerCase()},{" "}
                              {Tanggal(new Date(dt.tanggal_lahir))}
                            </td>
                            <td style={{ padding: "5px 5px 5px 5px" }}>
                              {dt.pekerjaan.toLowerCase()}
                            </td>
                            <td style={{ padding: "5px 5px 5px 5px" }}>
                              {dt.pendidikan.toLowerCase()}
                            </td>
                            <td style={{ padding: "5px 5px 5px 5px" }}>
                              {dt.shdk.toLowerCase()}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              );
            }
            cetak.detailkeluarga = detailkeluarga;
            break;

          case "Surat Keterangan Penghasilan":
            cetak.penghasilan = data.results.penghasilan;
            break;

          case "Surat Keterangan Belum Memiliki Rumah":
            cetak.lokasi = data.results.lokasi;
            break;

          case "Surat Keterangan Usaha":
            data.results.data_detail_suket.forEach(dt => {
              switch (dt.nama_item) {
                case "usaha":
                  cetak.detail = JSON.parse(dt.isi_item);
                  break;
                default:
                  // no default
                  break;
              }
            });
            //console.log(cetak)
            //console.log('sampai sini')
            break;

          case "Surat Keterangan Jalan":
            //console.log(data.results.data_detail_suket);
            //console.log(cetak);
            data.results.data_detail_suket.forEach(dt => {
              if (dt.nama_item === "rencana_berangkat") {
                cetak.detail.rencana_berangkat = new Date(dt.isi_item);
              }
              if (dt.nama_item === "barang") {
                cetak.detail.barang = dt.isi_item;
              }
              if (dt.nama_item === "tempat_lahir_supir") {
                cetak.detail.tempat_lahir_supir = dt.isi_item;
              }
              if (dt.nama_item === "tanggal_lahir_supir") {
                cetak.detail.tanggal_lahir_supir = new Date(dt.isi_item);
              }
              if (dt.nama_item === "nama_supir") {
                cetak.detail.nama_supir = dt.isi_item;
              }
              if (dt.nama_item === "no_pol") {
                cetak.detail.no_pol = dt.isi_item.toUpperCase();
              }
              if (dt.nama_item === "transportasi") {
                cetak.detail.transportasi = dt.isi_item;
              }
              if (dt.nama_item === "pengikut") {
                cetak.detail.pengikut = dt.isi_item;
              }
              if (dt.nama_item === "tujuan_perjalanan") {
                cetak.detail.tujuan_perjalanan = dt.isi_item;
              }
            });
            // this.forceUpdate();
            //console.log(cetak);

            break;

          case "Surat Pengantar Pindah Nikah":
            data.results.data_detail_suket.forEach(dt => {
              if (dt.nama_item === "nama_nasab_p1") {
                cetak.nama_nasab_p1 = dt.isi_item.toUpperCase();
              }

              if (dt.nama_item === "nama_nasab_p2") {
                cetak.nama_nasab_p2 = dt.isi_item.toUpperCase();
              }
              if (dt.nama_item === "nama_pasangan") {
                cetak.nama_pasangan = dt.isi_item;
              }
              if (dt.nama_item === "jenis_kelamin_pasangan") {
                cetak.jenis_kelamin_pasangan = dt.isi_item;
              }
              if (dt.nama_item === "tempat_lahir_pasangan") {
                cetak.tempat_lahir_pasangan = dt.isi_item.toLowerCase();
              }
              if (dt.nama_item === "tanggal_lahir_pasangan") {
                cetak.tanggal_lahir_pasangan = dt.isi_item;
              }
              if (dt.nama_item === "pekerjaan_pasangan") {
                cetak.pekerjaan_pasangan = dt.isi_item;
              }
              if (dt.nama_item === "agama_pasangan") {
                cetak.agama_pasangan = dt.isi_item;
              }
              if (dt.nama_item === "nik_pasangan") {
                cetak.nik_pasangan = dt.isi_item;
              }
              if (dt.nama_item === "alamat_pasangan") {
                cetak.alamat_pasangan = dt.isi_item;
              }
              if (dt.nama_item === "tempat_nikah") {
                cetak.tempat_nikah = dt.isi_item;
              }
              this.forceUpdate();
            });

            cetak.lokasi = data.results.lokasi;

            break;

          case "Surat Keterangan Kematian":
            cetak.detail.waktu_meninggal = data.results.waktu_meninggal;
            // cetak.detail.waktu_meninggal = new Date(data.results.waktu_meninggal);
            cetak.detail.tempat_meninggal = data.results.tempat_meninggal.toLowerCase();
            cetak.detail.sebab_meninggal = data.results.sebab_meninggal.toLowerCase();
            cetak.detail.tempat_makam = data.results.tempat_makam;

            cetak.detail.nik_pelapor = data.results.nik_pelapor;
            cetak.detail.nama_pelapor = data.results.nama_pelapor;
            // cetak.detail.tanggal_lahir_pelapor = new Date(data.results.tanggal_lahir_pelapor).toLocaleDateString('id-ID');
            cetak.detail.tanggal_lahir_pelapor = new Date(
              data.results.tanggal_lahir_pelapor
            );
            cetak.detail.tempat_lahir_pelapor = data.results.tempat_lahir_pelapor.toLowerCase();
            // cetak.detail.jk_pelapor = data.results.jenis_kelamin_pelapor;
            // cetak.detail.status_perkawinan_pelapor = data.results.status_perkawinan_pelapor.toLowerCase();
            cetak.detail.agama_pelapor = data.results.agama_pelapor.toLowerCase();
            cetak.detail.alamat_pelapor = data.results.alamat_pelapor;
            cetak.detail.pekerjaan_pelapor = data.results.pekerjaan_pelapor.toLowerCase();
            cetak.detail.hubungan_pelapor = data.results.hubungan_pelapor.toLowerCase();

            cetak.detail.status_ttd2 = data.results.status_ttd2;
            cetak.detail.jabatan_ttd2 = data.results.jabatan_ttd2;
            cetak.detail.jabatan_pengganti_ttd2 =
              data.results.jabatan_pengganti_ttd2;
            cetak.detail.nama_ttd2 = data.results.nama_ttd2;
            cetak.detail.nip_ttd2 = data.results.nip_ttd2;
            break;

          case "Surat Keterangan Pernah Menikah":
            cetak.nik_pasangan = data.results.nik_pasangan;
            cetak.nama_pasangan = data.results.nama_pasangan;
            cetak.tanggal_lahir_pasangan = data.results.tanggal_lahir_pasangan;
            cetak.tempat_lahir_pasangan = data.results.tempat_lahir_pasangan.toLowerCase();
            cetak.jk_pasangan = data.results.jenis_kelamin_pasangan;
            cetak.status_perkawinan_pasangan =
              data.results.status_perkawinan_pasangan;
            cetak.agama_pasangan = data.results.agama_pasangan;
            cetak.alamat_pasangan = data.results.alamat_pasangan;
            cetak.pekerjaan_pasangan = data.results.pekerjaan_pasangan;
            cetak.status_perkawinan_pasangan =
              data.results.status_perkawinan_pasangan;

            cetak.tanggal_menikah = data.results.tanggal_menikah;
            // this.state.tanggal_menikah = new Date(data.results.tanggal_menikah);
            cetak.tempat_menikah = data.results.tempat_menikah;
            cetak.wali_nikah = data.results.wali_nikah;
            cetak.petugas_nikah = data.results.petugas_nikah;
            cetak.mas_kawin = data.results.mas_kawin;
            cetak.saksi_nikah = data.results.saksi_nikah;

            cetak.nik_pelapor = data.results.nik_pelapor;
            cetak.nama_pelapor = data.results.nama_pelapor;
            cetak.tanggal_lahir_pelapor = data.results.tanggal_lahir_pelapor;
            cetak.tempat_lahir_pelapor = data.results.tempat_lahir_pelapor.toLowerCase();
            cetak.jk_pelapor = data.results.jenis_kelamin_pelapor;
            cetak.status_perkawinan_pelapor =
              data.results.status_perkawinan_pelapor;
            cetak.agama_pelapor = data.results.agama_pelapor;
            cetak.alamat_pelapor = data.results.alamat_pelapor;
            cetak.pekerjaan_pelapor = data.results.pekerjaan_pelapor;
            cetak.status_perkawinan_pelapor =
              data.results.status_perkawinan_pelapor;
            break;

          case "Surat Pernyataan Belum Menikah":
            //console.log(data.results.data_detail_suket);
            data.results.data_detail_suket.forEach(dt => {
              if (dt.nama_item === "nama_saksi2") {
                cetak.nama_saksi2 = dt.isi_item.toUpperCase();
              }
              if (dt.nama_item === "hubungan_saksi2") {
                cetak.hubungan_saksi2 = dt.isi_item;
              }
              if (dt.nama_item === "nama_saksi1") {
                cetak.nama_saksi1 = dt.isi_item.toUpperCase();
              }
              if (dt.nama_item === "hubungan_saksi1") {
                cetak.hubungan_saksi1 = dt.isi_item;
              }
              if (dt.nama_item === "nama_ayah") {
                cetak.nama_ayah_kandung = dt.isi_item.toUpperCase();
              }
            });
            break;

          case "Surat Pernyataan Tidak Menikah":
            data.results.data_detail_suket.forEach(dt => {
              if (dt.nama_item === "nomor_surat_akta") {
                cetak.nomor_surat_akta = dt.isi_item;
              }
              if (dt.nama_item === "tanggal_surat_akta") {
                cetak.tanggal_surat_akta = dt.isi_item;
              }
              if (dt.nama_item === "nama_saksi") {
                cetak.nama_saksi = dt.isi_item;
              }
              if (dt.nama_item === "hubungan_saksi") {
                cetak.hubungan_saksi = dt.isi_item;
              }
              if (dt.nama_item === "nama_ayah") {
                cetak.nama_ayah_kandung = dt.isi_item;
              }
            });
            break;

          case "Surat Keterangan Kelahiran":
            cetak.nama_anak = data.results.nama_anak.toUpperCase();
            cetak.tanggal_lahir_anak = new Date(
              data.results.tanggal_lahir_anak
            );
            cetak.tempat_lahir_anak = data.results.tempat_lahir_anak.toLowerCase();
            cetak.jk_anak = data.results.jk_anak.toLowerCase();

            cetak.nik_ayah = data.results.nik_ayah;
            cetak.nama_ayah = data.results.nama_ayah;
            cetak.tempat_lahir_ayah = data.results.tempat_lahir_ayah.toLowerCase();
            cetak.tanggal_lahir_ayah = new Date(
              data.results.tanggal_lahir_ayah
            );
            cetak.pekerjaan_ayah = data.results.pekerjaan_ayah.toLowerCase();
            cetak.alamat_ayah = data.results.alamat_ayah;

            cetak.nik_ibu = data.results.nik_ibu;
            cetak.nama_ibu = data.results.nama_ibu;
            cetak.tempat_lahir_ibu = data.results.tempat_lahir_ibu.toLowerCase();
            cetak.tanggal_lahir_ibu = new Date(data.results.tanggal_lahir_ibu);
            cetak.pekerjaan_ibu = data.results.pekerjaan_ibu.toLowerCase();
            cetak.alamat_ibu = data.results.alamat_ibu;

            cetak.nik_pelapor = data.results.nik_pelapor;
            cetak.nama_pelapor = data.results.nama_pelapor;
            cetak.tanggal_lahir_pelapor = new Date(
              data.results.tanggal_lahir_pelapor
            );
            cetak.tempat_lahir_pelapor = data.results.tempat_lahir_pelapor.toLowerCase();
            cetak.jk_pelapor = data.results.jenis_kelamin_pelapor;
            cetak.status_perkawinan_pelapor =
              data.results.status_perkawinan_pelapor;
            cetak.agama_pelapor = data.results.agama_pelapor.toLowerCase();
            cetak.alamat_pelapor = data.results.alamat_pelapor;
            cetak.pekerjaan_pelapor = data.results.pekerjaan_pelapor.toLowerCase();
            cetak.hubungan_pelapor = data.results.hubungan_pelapor.toLowerCase();
            break;

          case "Surat Kuasa SKGR":
            data.results.data_detail_suket.forEach(dt => {
              if (dt.nama_item === "tanggal_skgr") {
                cetak.tanggal_skgr = new Date(dt.isi_item);
              }
              if (dt.nama_item === "atas_nama") {
                cetak.atas_nama = dt.isi_item.toUpperCase();
              }
              if (dt.nama_item === "luas_tanah") {
                cetak.luas_tanah = dt.isi_item;
              }
              if (dt.nama_item === "no_reg") {
                cetak.no_reg = dt.isi_item;
              }
              if (dt.nama_item === "lokasi_tanah") {
                cetak.lokasi_tanah = dt.isi_item;
              }
            });

            cetak.kepada = data.results.kepada;

            cetak.nik2 = data.results.nik2;
            cetak.nama2 = data.results.nama2;
            cetak.kepada2 = data.results.kepada2;
            cetak.tempat_lahir2 = data.results.tempat_lahir2;
            cetak.tanggal_lahir2 = data.results.tanggal_lahir2;
            cetak.pekerjaan2 = data.results.pekerjaan2;
            cetak.alamat2 = data.results.alamat2;

            cetak.kuasa_atas = data.results.kuasa_atas;
            cetak.kuasa_untuk = data.results.kuasa_untuk;
            cetak.tujuan_kuasa = data.results.tujuan_kuasa;
            break;

          case "Surat Pernyataan Kehilangan":
            let acara = [];
            let tujuan = data.results.tujuan_pembuatan.split(",");
            tujuan.forEach(dt => {
              acara.push({ nama: dt });
            });
            cetak.acara = acara;
            cetak.lokasi = data.results.lokasi;
            break;

          case "Surat Keterangan Ahli Waris":
            cetak.detail = data.results.detail_ahli_waris;

            let cek = data.results.nik.split(",");

            if (cek.length === 1) {
              cetak.nik = data.results.nik;
              cetak.nama = data.results.nama;
              cetak.tanggal_lahir =
                data.results.tanggal_lahir

              cetak.tempat_lahir = data.results.tempat_lahir;
              cetak.alamat = data.results.alamat;
              cetak.jenis_kelamin = data.results.jenis_kelamin;
              cetak.pekerjaan = data.results.pekerjaan;

              cetak.tanggal_lahir = data.results.tanggal_lahir;

              cetak.waktu_meninggal = data.results.waktu_meninggal;
              cetak.waktu_meninggal = data.results.waktu_meninggal;
              cetak.tempat_meninggal = data.results.tempat_meninggal;
              cetak.sebab_meninggal = data.results.sebab_meninggal;

              // this.state.nikStat = {
              // 	value: data.results.nik,
              // 	label: data.results.nik
              // };
              // this.state.status.dataPenduduk = "block";
            } else {
              let nama = data.results.nama.split(",");
              cetak.nama = nama[0];
              cetak.nama2 = nama[1];

              let tanggal_lahir = data.results.tanggal_lahir.split(",");
              //console.log(tanggal_lahir)
              cetak.tanggal_lahir =
                tanggal_lahir[0]

              cetak.tanggal_lahir2 =
                tanggal_lahir[1]


              let tempat_lahir = data.results.tempat_lahir.split(",");
              cetak.tempat_lahir = tempat_lahir[0];
              cetak.tempat_lahir2 = tempat_lahir[1];

              let alamat = data.results.alamat.split("|");
              cetak.alamat = alamat[0];
              cetak.alamat2 = alamat[1];

              let jenis_kelamin = data.results.alamat.split(",");
              cetak.jenis_kelamin = jenis_kelamin[0];
              cetak.jenis_kelamin2 = jenis_kelamin[1];

              let pekerjaan = data.results.pekerjaan.split(",");
              cetak.pekerjaan = pekerjaan[0];
              cetak.pekerjaan2 = pekerjaan[1];

              let kewarganegaraan = data.results.kewarganegaraan.split(",");
              cetak.kewarganegaraan = kewarganegaraan[0];
              cetak.kewarganegaraan2 = kewarganegaraan[1];

              let waktu_meninggal = data.results.waktu_meninggal.split(",");
              cetak.waktu_meninggal = waktu_meninggal[0];
              cetak.waktu_meninggal2 = waktu_meninggal[1];
              cetak.waktu_meninggal = waktu_meninggal[0];
              cetak.waktu_meninggal2 = waktu_meninggal[1];

              let tempat_meninggal = data.results.tempat_meninggal.split(",");
              cetak.tempat_meninggal = tempat_meninggal[0];
              cetak.tempat_meninggal2 = tempat_meninggal[1];

              let sebab_meninggal = data.results.sebab_meninggal.split(",");
              cetak.sebab_meninggal = sebab_meninggal[0];
              cetak.sebab_meninggal2 = sebab_meninggal[1];

              // this.state.nikStat = { value: cek[0], label: cek[0] };
              // this.state.status.dataPenduduk = "block";
              // this.state.nik2Stat = { value: cek[1], label: cek[1] };
              // this.state.status.dataPenduduk2 = "block";
            }
            break;

          case "Surat Kuasa":
            let detailRincian = [];
            data.results.data_detail_suket.forEach(dt => {
              let nama_rincian = dt.nama_item;
              let isi_rincian = dt.isi_item;
              detailRincian.push({
                nama_rincian: nama_rincian,
                isi_rincian: isi_rincian
              });
            });

            cetak.kepada = data.results.kepada;

            cetak.pendStat = {
              value: data.results.nik,
              label: data.results.nik
            };
            cetak.pendStat2 = {
              value: data.results.nik2,
              label: data.results.nik2
            };

            cetak.nik2 = data.results.nik2;
            cetak.nama2 = data.results.nama2;
            cetak.kepada2 = data.results.kepada2;
            cetak.tempat_lahir2 = data.results.tempat_lahir2;
            cetak.tanggal_lahir2 = data.results.tanggal_lahir2;
            cetak.pekerjaan2 = data.results.pekerjaan2;
            cetak.alamat2 = data.results.alamat2;

            cetak.kuasa_atas = data.results.kuasa_atas;
            cetak.kuasa_untuk = data.results.kuasa_untuk;
            cetak.tujuan_kuasa = data.results.tujuan_kuasa;

            cetak.usaha = detailRincian;

            this.state.status.showBio = true;
            this.state.status.showBio2 = true;
            this.state.status.btnForm = false;
            break;

          case "Surat Undangan":
            let frmJam = [];
            let jamnya = "";
            for (let i = 1; i <= 24; i++) {
              jamnya = i;
              if (i < 10) {
                jamnya = "0" + i;
              }
              jamnya += ":00";
              frmJam.push({ value: jamnya, label: jamnya });
            }
            cetak.frmJam = frmJam;

            let tgl = data.results.tanggal_surat;
            let wkt = data.results.waktu;
            // //console.log(wkt.slice(11,16));
            cetak.tanggal_surat = new Date(tgl);
            cetak.tanggal_acara = new Date(wkt);
            cetak.jamStat = {
              value: wkt.slice(11, 16),
              label: wkt.slice(11, 16)
            };

            // this.setState({
            // 	tanggal_surat: new Date(tgl),
            // 	tanggal_acara: new Date(wkt),
            // 	jamStat: { value: wkt.slice(11, 16), label: wkt.slice(11, 16) }
            // });

            cetak.id_pengaju = data.results.id_pengaju;
            cetak.sifat = data.results.sifat;
            cetak.kepada = data.results.kepada;
            cetak.perihal = data.results.perihal;
            cetak.waktu = data.results.waktu.slice(11, 16);
            cetak.tempat = data.results.tempat;
            //console.log(data.results.acara)
            let acara2 = JSON.parse(data.results.acara).forEach((dt, i) => {
              if (dt.nama !== '') {
                return (
                  <tr key={i}>
                    <td style={{ width: "2%" }}>{i + 1}.</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {dt.nama.toLowerCase()}
                    </td>
                  </tr>
                );
              } else {
                return ''
              }
            });

            // cetak.acara = JSON.parse(data.results.acara);
            cetak.acara = acara2;
            break;

          case "Surat Izin Keramaian":
            //console.log(data.results.data_detail_suket);
            data.results.data_detail_suket.forEach(dt => {
              if (dt.nama_item === "tgl_mulai") {
                // cetak.tanggal_mulai = new Date(dt.isi_item);
                cetak.tanggal_mulai = dt.isi_item;
              }

              if (dt.nama_item === "tgl_selesai") {
                //console.log("dt.isi_item");
                //console.log(dt.isi_item);
                if (dt.isi_item !== null) {
                  // cetak.tanggal_selesai = new Date(dt.isi_item);
                  cetak.tanggal_selesai = dt.isi_item;
                }
              }
              if (dt.nama_item === "tempat") {
                cetak.tempat = dt.isi_item;
              }
              if (dt.nama_item === "hiburan") {
                cetak.hiburan = dt.isi_item;
              }
              //console.log("sampai sini");

              this.forceUpdate();
            });
            cetak.lokasi = data.results.lokasi;
            break;

          case "Surat Keterangan":
            cetak.detail = data.results.data_detail_suket;
            break;
          default:
            // no default
            break;
        }

        //console.log(cetak);

        this.setState({ cetak });

        this.forceUpdate();

        setTimeout(function () {
          const content = document.getElementById("cetak-surat").innerHTML;
          const mywindow = window.open("", "Print", "height=600,width=1000");

          mywindow.document.write("<html><head><title>Print</title>");
          mywindow.document.write("</head><body >");
          mywindow.document.write(content);
          mywindow.document.write("</body></html>");

          mywindow.document.close();
          mywindow.focus();
          mywindow.print();
          setTimeout(function () {
            mywindow.close();
          }, 1000);
        }, 100);
      }
    );
  };

  terbilang = a => {
    // pastikan kita hanya berususan dengan tipe data numeric
    let angka = parseFloat(a);
    let hasil_bagi = 0;
    let hasil_mod = 0;
    // array bilangan
    // sepuluh dan sebelas merupakan special karena awalan 'se'
    const bilangan = [
      "",
      "satu",
      "dua",
      "tiga",
      "empat",
      "lima",
      "enam",
      "tujuh",
      "delapan",
      "sembilan",
      "sepuluh",
      "sebelas"
    ];
    if (angka === 1) {
      return "setahun";
    }
    // pencocokan dimulai dari satuan angka terkecil
    else if (angka < 12) {
      // mapping angka ke index array bilangan
      return bilangan[angka] + " tahun";
    } else if (angka < 20) {
      // bilangan 'belasan'
      // misal 18 maka 18 - 10 = 8
      return bilangan[angka - 10] + " belas tahun";
    } else if (angka < 100) {
      // bilangan 'puluhan'
      // misal 27 maka 27 / 10 = 2.7 (integer => 2) 'Dua'
      // untuk mendapatkan sisa bagi gunakan modulus
      // 27 mod 10 = 7 'Tujuh'
      hasil_bagi = parseInt(angka / 10);
      hasil_mod = angka % 10;
      return bilangan[hasil_bagi] + " puluh " + bilangan[hasil_mod] + " tahun";
    } else if (angka < 200) {
      // bilangan 'seratusan' (itulah indonesia knp tidak satu ratus saja? :))
      // misal 151 maka 151 = 100 = 51 (hasil berupa 'puluhan')
      // daripada menulis ulang rutin kode puluhan maka gunakan
      // saja fungsi rekursif dengan memanggil fungsi terbilang(51)
      return "Seratus " + this.terbilang(angka - 100);
    } else if (angka < 1000) {
      // bilangan 'ratusan'
      // misal 467 maka 467 / 100 = 4,67 (integer => 4) 'Empat'
      // sisanya 467 mod 100 = 67 (berupa puluhan jadi gunakan rekursif terbilang(67))
      hasil_bagi = parseInt(angka / 100);
      hasil_mod = angka % 100;
      return (
        bilangan[hasil_bagi] + " Ratus " + this.terbilang(hasil_mod) + " tahun"
      );
    } else if (angka < 2000) {
      // bilangan 'seribuan'
      // misal 1250 maka 1250 - 1000 = 250 (ratusan)
      // gunakan rekursif terbilang(250)
      return "Seribu " + this.terbilang(angka - 100);
    } else if (angka < 1000000) {
      // bilangan 'ribuan' (sampai ratusan ribu
      hasil_bagi = parseInt(angka / 1000); // karena hasilnya bisa ratusan jadi langsung digunakan rekursif
      hasil_mod = angka % 1000;
      return (
        this.terbilang(hasil_bagi) +
        " Ribu " +
        this.terbilang(hasil_mod) +
        " tahun"
      );
    } else if (angka < 1000000000) {
      // bilangan 'jutaan' (sampai ratusan juta)
      // 'satu puluh' => SALAH
      // 'satu ratus' => SALAH
      // 'satu juta' => BENAR
      // @#$%^ WT*

      // hasil bagi bisa satuan, belasan, ratusan jadi langsung kita gunakan rekursif
      hasil_bagi = parseInt(angka / 1000000);
      hasil_mod = angka % 1000000;
      return (
        this.terbilang(hasil_bagi) +
        " Juta " +
        this.terbilang(hasil_mod) +
        " tahun"
      );
    } else if (angka < 1000000000000) {
      // bilangan 'milyaran'
      hasil_bagi = parseInt(angka / 1000000000);
      // karena batas maksimum integer untuk 32bit sistem adalah 2147483647
      // maka kita gunakan fmod agar dapat menghandle angka yang lebih besar
      hasil_mod = angka % 1000000000;
      return (
        this.terbilang(hasil_bagi) +
        " Milyar " +
        this.terbilang(hasil_mod) +
        " tahun"
      );
    } else if (angka < 1000000000000000) {
      // bilangan 'triliun'
      hasil_bagi = angka / 1000000000000;
      hasil_mod = angka % 1000000000000;
      return (
        this.terbilang(hasil_bagi) +
        " Triliun " +
        this.terbilang(hasil_mod) +
        " tahun"
      );
    } else {
      return "Wow...";
    }
  };

  gantiTgl = (date, fld) => {
    switch (fld) {
      case "tanggal_surat":
        // this.state.surat.tanggal_surat = date;
        // this.state.detailSurat.tanggal_surat = date;
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD HH:mm:ss');
          this.state.surat.tanggal_surat = new Date(tgl);
          this.state.detailSurat.tanggal_surat = new Date(tgl);
        }
        break;
      case "tanggal_acara":
        // this.setState({ tanggal_acara: date });
        // this.state.detailSurat.tanggal_acara = date;
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD HH:mm:ss');
          this.state.detailSurat.tanggal_acara = new Date(tgl);
        }
        break;
      case "tanggal_lahir_anak":
        // this.state.detailSurat.tanggal_lahir_anak = date;
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD HH:mm:ss');
          this.state.detailSurat.tanggal_lahir_anak = new Date(tgl);
        }
        break;
      case "tanggal_lahir_supir":
        // this.state.detailSurat.tanggal_lahir_supir = date;
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD HH:mm:ss');
          this.state.detailSurat.tanggal_lahir_supir = new Date(tgl);
        }
        break;
      case "rencana_berangkat":
        // this.state.detailSurat.rencana_berangkat = date;
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD HH:mm:ss');
          this.state.detailSurat.rencana_berangkat = new Date(tgl);
        }
        break;
      case "waktu_meninggal":
        // this.state.detailSurat.waktu_meninggal = date;
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD HH:mm:ss');
          this.state.detailSurat.waktu_meninggal = new Date(tgl);
        }
        break;
      case "waktu_meninggal2":
        // this.state.detailSurat.waktu_meninggal2 = date;
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD HH:mm:ss');
          this.state.detailSurat.waktu_meninggal2 = new Date(tgl);
        }
        break;
      case "tanggal_skgr":
        // this.state.detailSurat.tanggal_skgr = date;
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD HH:mm:ss');
          this.state.detailSurat.tanggal_skgr = new Date(tgl);
        }
        break;
      case "tanggal_menikah":
        // this.state.detailSurat.tanggal_menikah = date;
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD HH:mm:ss');
          this.state.detailSurat.tanggal_menikah = new Date(tgl);
        }
        break;
      case "tanggal_surat_akta":
        // this.state.detailSurat.tanggal_surat_akta = date;
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD HH:mm:ss');
          this.state.detailSurat.tanggal_surat_akta = new Date(tgl);
        }
        break;
      case "tanggal_mulai":
        // this.state.detailSurat.tanggal_mulai = date;
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD HH:mm:ss');
          this.state.detailSurat.tanggal_mulai = new Date(tgl);
        }
        break;
      case "tanggal_selesai":
        // this.state.detailSurat.tanggal_selesai = date;
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD HH:mm:ss');
          this.state.detailSurat.tanggal_selesai = new Date(tgl);
        }
        break;
      case "tanggal_lahir_ayah":
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD');
          this.state.detailSurat.tanggal_lahir_ayah = new Date(tgl);
        }
        break;
      case "tanggal_lahir_ibu":
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD');
          this.state.detailSurat.tanggal_lahir_ibu = new Date(tgl);
        }
        break;
      case "tanggal_lahir_pasangan":
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD');
          this.state.detailSurat.tanggal_lahir_pasangan = new Date(tgl);
        }
        break;
      case "tanggal_lahir_penerima_kuasa":
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD');
          this.state.detailSurat.tanggal_lahir2 = new Date(tgl);
        }
        break;
      default:
        // no default
        break;
    }
    this.forceUpdate();
  };

  pilihPerangkatDesa = (uuid, id) => {
    let temp = this.state.accButtonPerangkatDesa.forEach(x => 1);
    temp[id] = 0;
    this.state.accButtonPerangkatDesa = temp;
    this.state.uuidPerangkatDesa = uuid;
    if (this.state.status.accStat) {
      this.state.status.btnFormAcc = false;
    }
    this.forceUpdate();
  };

  changeSelectValue2 = (e, sel) => {
    if (e) {
      switch (sel) {
        case "jam":
          this.state.detailSurat.jam = e.value;
          this.state.detailSurat.jamStat = e;
          // this.setState({ jamStat: e });
          break;
        case "jk_anak":
          this.state.detailSurat.jk_anak = e.value;
          break;
        case "agama_pasangan":
          this.state.detailSurat.agama_pasangan = {value: e.value, label: e.label};
          this.state.detailSurat.agmStat = {value: e.value, label: e.label};
          break;
        case "status_perkawinan":
          this.state.detailSurat.status_perkawinan = {value: e.value, label: e.label};
          break;
        case "jenis_kelamin_pasangan":
          this.state.detailSurat.jkkkStat = {value: e.value, label: e.label};
          this.state.detailSurat.jenis_kelamin_pasangan = e.value;
          break;
        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        case "jam":
          this.state.detailSurat.jam = 0;
          this.state.detailSurat.jamStat = { value: "08:00", label: "08:00" };
          // this.setState({ jamStat: { value: "08:00", label: "08:00" } });
          break;
        case "jk_anak":
          this.state.detailSurat.jk_anak = '';
          break;
        case "agama_pasangan":
          this.state.detailSurat.agama_pasangan = null;
          break;
        case "status_perkawinan":
          this.state.detailSurat.status_perkawinan = null;
          break;
        case "jenis_kelamin_pasangan":
          this.state.detailSurat.jenis_kelamin_pasangan = null;
          break;
        default:
          // no default
          break;
      }
    }
    this.forceUpdate();
  };


  // Cari Data penduduk gelar
	cariDataGelar = (e) => {
    
    //console.log("Tab Keberapa")
    //console.log(this.state.active_tab_icon)

    if(this.state.active_tab_icon===1){
      this.state.cek_posisi = "Gelar"
    }
    else{
      this.state.cek_posisi = "Non-Gelar"
    }

		if (e.key === 'Enter') {
      //console.log("Search")
			//console.log(e.target.value)
			let dataCariGelar = {
        q: e.target.value
			};
			this.setState({ dataCariGelar });
			setTimeout(
        function() {
          // mywindow.close();
					this.fetch();
				}.bind(this),
				500
        );
      }
      else{
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      this.forceUpdate();
	};

  // Untuk form pada tab menunggu eksekusi
  simpanGelar = e => {
    e.preventDefault();
        this.state.status.btnForm = true;
        this.forceUpdate();
        let addData = {
            uuid : document.getElementById("uuid").value,
            nik : this.state.data_detail.nik,
            nama_suku : this.state.id_suku.label,
            nama_datuak : this.state.id_datuak.label,
            nama_gelar : document.getElementById("nama_gelar").value,
        };

        //console.log("Data Simpan")
        //console.log(addData)
        
        let psn = '';
        let resstat = 204;
        let metode = "create";
        if (addData.uuid === '')
        {
            psn = "Tambah"; 
            resstat = 201;
            addData.uuid = null;
            
        } 
        else 
        {
            psn = "Ubah"; 
            resstat = 200;
            metode = "update";
        }
        Post('kesukuan/'+metode,addData.uuid,addData, (res) => {
            this.state.status.btnForm = false;
            this.state.status.formTambahGelar = false;
            this.state.status.tutupForm = false;
            this.forceUpdate();
            if (res.status === resstat)
            {
                this.setState({ show: true, data_detail: {nik:"", nama: ""}, basicType:'success', basicTitle:'Data Gelar', pesanAlert:'Berhasil '+psn+' Data' });
            }
            else
            {
                this.setState({ show: true, basicType:'danger', basicTitle:'Data Gelar', pesanAlert:'Gagal '+psn+' Data' });
            }
            this.componentDidMount();
        });

  };


  // ============================== Ubah Data ===============================
    
  ubahData = (id) => {
    this.state.status.btnAksi = true;
    this.state.status.formTambahGelar = true;
    this.state.judul = "Ubah Suku";
    this.forceUpdate();
    Get('kesukuan/find',id, (data) => {
      //console.log("Data Ubah")
      //console.log(data)
      this.state.data_detail.uuid = data.results.uuid;
      this.state.data_detail.nik = data.results.nik;
      this.state.data_detail.nama = data.results.data_penduduk.nama;
      this.state.id_suku= { value: data.results.nama_suku, label: data.results.nama_suku }
      this.state.id_datuak= { value: data.results.nama_datuak, label: data.results.nama_datuak }
      this.state.data_detail.nama_gelar = data.results.nama_gelar;

          this.forceUpdate();
        this.state.status.btnAksi = false;
        this.forceUpdate();
        this.bukaForm();
    });
}



  // Making dynamic function
	onChangeSelect = (e, data) => {
		if (e) {
			switch (data) {
				case 'id_suku':
					this.setState({ id_suku: { value: e.value, label: e.label } });
					break;

				case 'id_datuak':
					this.setState({ id_datuak: { value: e.value, label: e.label } });
					break;
        default:
          // no default
          break;
			}
		} else {
			switch (data) {
				case 'id_suku':
					this.setState({ id_suku: '' });
					break;

				case 'id_datuak':
					this.setState({ id_datuak: '' });
					break;
        default:
          // no default
          break;

			}
		}
  }

  getSelect = (e, table) => {
		if (e) {
			let find_data = {
				q: e
      }
      
      //console.log("Data Cari")
      //console.log(find_data)
			Post(table, null, find_data, (data) => {
        //console.log("Data Get Select")
        //console.log(data)
				if (data.data.result) {
					switch (table) {
						case 'suku':
							let dataSuku = [];
							data.results.data.forEach(dt => {
								dataSuku.push({ value: dt.nama, label: dt.nama });
							});
							this.setState({ dataSuku });
							break;

						case 'datuak':
							let dataDatuak = [];
							data.results.data.forEach(dt => {
								dataDatuak.push({ value: dt.nama, label: dt.nama });
							});
							this.setState({ dataDatuak });
							break;
            default:
              // no default
              break;

					}
				}
			});
		} else {
			Get(table, null, data => {
				if (data.result) {
					switch (table) {
						case 'suku':
							let dataSuku = [];
							data.result.data.forEach(dt => {
								dataSuku.push({ value: dt.nama, label: dt.nama });
							});
							this.setState({ dataSuku });
							break;

						case 'datuak':
							let dataDatuak = [];
							data.result.data.forEach(dt => {
								dataDatuak.push({ value: dt.nama, label: dt.nama });
							});
							this.setState({ dataDatuak });
							break;
            default:
              // no default
              break;

					}
				}
			});
		}
	}
  
  render() {
    // var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
    // var size = this.state.pagination.total;
    //console.log("Tab Keberapa?")
    //console.log(this.state.active_tab_icon)
    //console.log(this.state.cek_posisi)

    //console.log("Data Cari Gelar")
    //console.log(this.state.dataCariGelar)

    // var from = this.state.pagination.from;
    // var page = this.state.pagination.current;
    // var to = this.state.pagination.to;
    // var total = this.state.pagination.total;
    var data = this.state.data;
    // var dataDitolak = this.state.dataDitolak;
    var dataTidakGelar = this.state.dataTidakGelar;

    // var no = 0;
    // const frmDef = {
    //   id: 0,
    //   idbaru: "",
    //   id_keldes: "",
    //   nama: ""
    // };
    // const { SearchBar } = Search;

    // Data diproses
    const columns = [
      {
        dataField: "nik",
        text: "NIK"
        // sort: true
      },

      {
        dataField: "data_penduduk.nama",
        text: "Nama"
        // sort: true
      },

      {
        dataField: "nama_suku",
        text: "Suku"
        // sort: true
      },

      {
        dataField: "nama_datuak",
        text: "Datuak"
        // sort: true
      },

      {
        dataField: "nama_gelar",
        text: "Gelar"
        // sort: true
      },

      {
        dataField: "aksi",
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksiPendudukGelar
      }
    ];

    // Data ditolak
    const columns2 = [

      {
        dataField: "id_kk",
        text: "No. KK"
        // sort: true
      },

      {
        dataField: "id",
        text: "NIK"
        // sort: true
      },

      {
        dataField: "nama",
        text: "Nama"
        // sort: true
      },

      {
        dataField: "alamat",
        text: "Alamat"
        // sort: true
      },

      {
        dataField: "aksi",
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksiTambahGelar
      }
    ];


    // const defaultSorted = [
    //   {
    //     dataField: "nama",
    //     order: "asc"
    //   }
    // ];


    // const pageButtonRenderer = ({
    //   page,
    //   active,
    //   disable,
    //   title,
    //   onPageChange
    // }) => {
    //   const handleClick = (e) => {
    //     e.preventDefault();
    //     this.fetch({ where: [], page: page });
    //   };
    //   var classname = '';
    //   classname = 'btn btn-success';
    //   return (
    //     <li className="page-item pl-1" key={page}>
    //       <a href="#" onClick={handleClick} className={classname}>{page}</a>
    //     </li>
    //   );
    // };

    // const options = {
    //   alwaysShowAllBtns: true,
    //   pageButtonRenderer,
    //   // custom: true,
    //   paginationTotalRenderer: () => (
    //     <span className="react-bootstrap-table-pagination-total">
    //       Showing { from} to { to} of { total} Results
    //     </span>
    //   ),
    //   // paginationSize: size,
    //   totalSize: total
    // };

    // no = 0;
    // const tableTTD = this.state.listPerangkatDesa.map((dt, i) => {
    //   no++;
    //   return (
    //     <tr key={i}>
    //       <td>{no}</td>
    //       <td>{dt.jabatan}</td>
    //       <td>{dt.jabatan_pengganti ? dt.jabatan_pengganti : "-"}</td>
    //       <td>{dt.nama}</td>
    //       <td>{dt.nip ? dt.nip : "-"}</td>
    //       <td>{dt.status ? dt.status : "-"}</td>
    //       <td>
    //         <Button
    //           size="xs"
    //           onClick={() => this.pilihPerangkatDesa(dt.uuid, i)}
    //           color={
    //             this.state.accButtonPerangkatDesa.length > 0
    //               ? this.state.accButtonPerangkatDesa[i] === 0
    //                 ? "default"
    //                 : "danger"
    //               : "danger"
    //           }
    //           className="btn-icon"
    //         >
    //           {this.state.accButtonPerangkatDesa.length > 0
    //             ? this.state.accButtonPerangkatDesa[i] === 0
    //               ? "Dipilih"
    //               : "Pilih"
    //             : "Pilih"}
    //         </Button>
    //       </td>
    //     </tr>
    //   );
    // });

    return (
      <div>
        {/* <Breadcrumb title="Disposisi Surat" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Disposisi Surat</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      {/* <Button size="sm" color="success" className="btn-square" onClick={() => {
                        this.setState({ judul: "Tambah Dusun", dt: frmDef, prvStat: [], kbktStat: [], kecStat: [], kldsStat: [] }); this.bukaForm();
                      }}>Tambah Data</Button> */}
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <Nav tabs className="tabs-color">
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "1"
                        })}
                        onClick={() => {
                          this.toggle_icon("1");
                        }}
                      >
                        <i className="fa fa-user-plus"></i>
                        
                        Penduduk Bersuku
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "2"
                        })}
                        onClick={() => {
                          this.toggle_icon("2");
                        }}
                      >
                        <i className="fa fa-user-times"></i>

                        Penduduk Tidak Bersuku
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.active_tab_icon}>
                    <TabPane tabId="1">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                          <div className="row">
										<div className="col-lg-4">
											<input
												type="text"
												className="form-control"
												id="cariTable"
												placeholder="Cari Data berdasarkan nama penduduk"
												onKeyPress={this.cariDataGelar}
											/>
										</div>
									</div>
                            <BootstrapTable
                              keyField="uuid_surat"
                              data={data}
                              columns={columns}
                            />
                            <div className="pull-right text-white">
                              {this.state.awal}
                              {this.state.sebelum}
                              {this.state.hal.map(dt => {
                                return dt;
                              })}
                              {this.state.setelah}
                              {this.state.akhir}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">

                          <div className="row">
										<div className="col-lg-4">
											<input
												type="text"
												className="form-control"
												id="cariTable"
												placeholder="Cari Data berdasarkan nama penduduk"
												onKeyPress={this.cariDataGelar}
											/>
										</div>
									</div>
                            <BootstrapTable
                              keyField="uuid_surat"
                              data={dataTidakGelar}
                              columns={columns2}
                            />
                            <div className="pull-right text-white">
                              {this.state.awal2}
                              {this.state.sebelum2}
                              {this.state.hal2.map(dt => {
                                return dt;
                              })}
                              {this.state.setelah2}
                              {this.state.akhir2}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />


        {/* Modal Tambah Gelar */}
        
        <Modal
          open={this.state.status.formTambahGelar}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpanGelar}>
            <div className="modal-header">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.data_detail.uuid}
              />

<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									NIK
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nik"
										type="text"
										placeholder="NIK"
                    defaultValue={this.state.data_detail.nik}
                    readOnly
									/>
								</Col>
							</FormGroup>

{this.state.data_detail.uuid
?
<>
</>
:
<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									No.KK
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="kk"
										type="text"
										placeholder="No. KK"
										defaultValue={this.state.data_detail.kk}
                    readOnly
									/>
								</Col>
							</FormGroup>

}
              
<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama"
										type="text"
										placeholder="Nama"
										defaultValue={this.state.data_detail.nama}
										readOnly
									/>
								</Col>
							</FormGroup>

              {this.state.data_detail.uuid
?
<>
</>
:
              <FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Alamat
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="alamat"
										type="text"
										placeholder="Alamat"
										defaultValue={this.state.data_detail.alamat}
										readOnly
									/>
								</Col>
							</FormGroup>
  }
              <FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama Suku
								</Label>
								<Col sm="9">
                <Select
										classNamePrefix="select"
										onChange={(e) => {
											this.onChangeSelect(e, 'id_suku');
										}}
										defaultValue={this.state.id_suku}
										value={this.state.id_suku}
										name="Agama"
										options={this.state.dataSuku}
                    placeholder="Pilih Suku"
                    onInputChange={(e) => this.getSelect(e, 'suku')}
										isClearable
									/>
								</Col>
							</FormGroup>

              <FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama Datuak
								</Label>
								<Col sm="9">
                <Select
										classNamePrefix="select"
										onChange={(e) => {
											this.onChangeSelect(e, 'id_datuak');
										}}
										defaultValue={this.state.id_datuak}
										value={this.state.id_datuak}
										name="Datuak"
										options={this.state.dataDatuak}
                    placeholder="Pilih Datuak"
                    onInputChange={(e) => this.getSelect(e, 'datuak')}
										isClearable
									/>
								</Col>
							</FormGroup>

              <FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama Gelar
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama_gelar"
										type="text"
                    placeholder="Nama Gelar"
                    defaultValue={this.state.data_detail.nama_gelar} 
									/>
								</Col>
							</FormGroup>

                     
                    </div>
                 
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                // disabled={this.state.status.btnFormAcc}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                // disabled={this.state.status.btnFormAcc}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>


        {/* Data Cetak Surat */}
        <CetakSurat
          cetak={this.state.cetak}
          jenis={this.state.cetak.jenis_surat}
        />
      </div>
    );
  }
}

export default Disposisi;