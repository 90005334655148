import React, { Component } from "react";


import BootstrapTable from "react-bootstrap-table-next";

import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";

import { Link, Redirect } from "react-router-dom";



// koneksi
import { Post, Get, Delete, Nama_DESA, JENIS_DUSUN } from "../function/Koneksi";
import { Tanggal, } from "../function/Format";
// import { logo } from "./asset/base64img";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";



const frmDef = {
  uuid: "",
  id_jenis: "",
  id_pengaju: "",
  tanggal_surat: new Date(),
  nik: "",
  nama: "",
  kepada: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  pekerjaan: "",
  alamat: "",

  nik2: "",
  nama2: "",
  kepada2: "",
  tempat_lahir2: "",
  tanggal_lahir2: "",
  pekerjaan2: "",
  alamat2: "",
  kuasa_untuk: "",
  kuasa_atas: "",
  tujuan_kuasa: "",

  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: "",

  //detail
  detail: []
};
const cetak = {
  nama_desa: "",
  tanggal_surat: "",
  nomor_surat: "",
  nik: "",
  nama: "",
  kepada: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  pekerjaan: "",
  alamat: "",

  nik2: "",
  nama2: "",
  kepada2: "",
  tempat_lahir2: "",
  tanggal_lahir2: "",
  pekerjaan2: "",
  alamat2: "",
  kuasa_untuk: "",
  kuasa_atas: "",
  tujuan_kuasa: "",

  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: "",
  detail: []
};
class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: true,
        btnAksi: []
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDef,
      judul: "Tambah Surat Kuasa ",
      // ============ Alert ==============
      frmJam: [],
      jamStat: [],
      // ===================================
      // ============ Alert ==============
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      successAlert: false,

      // ===================================
      // ============ Tanggal ==============
      tanggal_surat: new Date(),
      // ===================================
      // ============ Cetak ==============
      cetak: cetak,
      // ===================================
      // ==============penduduk=========
      pendPrv: [],
      prvPend: [],
      showBio: false,
      showBio2: false,
      tanggal_mulai: new Date(),
      tanggal_selesai: null,
      usaha: [{ jenis: "", lokasi: "", umur: "" }],
      //acc
      formAcc: false,
      formSelectAcc: [
        { value: "Disetujui", label: "Disetujui" },
        { value: "Ditolak", label: "Ditolak" }
      ],
      frmTTD: [],
      accButton: [],
      accuuid: "",
      btnFormAcc: true,
      tableAcc: "none",
      datDisposisi: [],
      riwayatDisposisi: []
    };
  }

  // ================== dinamic form ===================
  handleNameChange = evt => {
    this.setState({ name: evt.target.value });
  };

  handleShareholderNameChange = idx => evt => {
    const newusaha = this.state.usaha.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, name: evt.target.value };
    });

    this.setState({ usaha: newusaha });
  };
  handleAddShareholder = () => {
    this.setState({
      usaha: this.state.usaha.concat([{ name: "" }])
    });

    // For testing
    if (this.props.getDataKeterangan) {
      this.props.getDataKeterangan(this.state.usaha)
    }
  };

  handleRemoveShareholder = idx => () => {
    this.setState({
      usaha: this.state.usaha.filter((s, sidx) => idx !== sidx)
    });

    // For testing
    if (this.props.getDataKeterangan) {
      this.props.getDataKeterangan(this.state.usaha)
    }
  };
  // ================== Tanggal ===================

  gantiTgl = (date, fld) => {
    switch (fld) {
      case "tanggal_surat":
        this.setState({ tanggal_surat: date });
        break;
      case "tanggal_lahir_pasangan":
        this.setState({ tanggal_lahir_pasangan: date });
        break;
      case "tanggal_lahir2":
        this.setState({ tanggal_lahir2: date });
        break;
      case "tanggal_usaha":
        this.setState({ tanggal_usaha: date });
        break;
      default:
        // no default
        break;
    }
  };

  // ==============================================

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      btnForm: false,
      successAlert: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get(
      "surat-keterangan/kuasa/" + this.props.location.state.uuid + "" + link,
      null,
      dtkat => {
        if(dtkat.results){
          this.setState({ data: dtkat.results.data, pagination: dtkat.results });
          this.pagination(dtkat.results);

          // For testing
          if (this.props.getListSuratKuasa) {
            this.props.getListSuratKuasa(dtkat.results.data)
          }
        }
      }
    );
  };
  // Cari Data Table
  cariData = e => {
    if (e.key ===  "Enter") {
      let addSuratUndangan = {
        q: e.target.value
      };
      Post(
        "surat-keterangan/kuasa/" + this.props.location.state.uuid,
        null,
        addSuratUndangan,
        data => {
          this.setState({
            data: data.data.results.data,
            pagination: data.data.results
          });
          this.pagination(data.data.results);
        }
      );
    }
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    //console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i ===  data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  //change select
  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "jk":
          this.setState({ 
            jkStat: e, 
            dt:{
              ...this.state.dt,
              jk: e.value,
            },
          });
          break;
        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        case "jk":
          this.setState({ 
            jkStat: e, 
            dt:{
              ...this.state.dt,
              jk: 0,
            },
          });
          break;
        default:
          // no default
          break;
      }
    }

    this.setState({
      status:{
        ...this.state.status,
        select: false,
      },
    })
    this.forceUpdate();
  };

  // ========================================================================

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    this.setState({
      status:{
        ...this.state.status,
        btnForm: true,
      },
    })
    this.forceUpdate();

    let uuid = document.getElementById("uuid").value;
    //detail
    let detail = [];
    this.state.usaha.forEach((dt, idx) => {
      let nama_rincian = document.getElementById("nama_rincian" + idx).value;
      let isi_rincian = document.getElementById("isi_rincian" + idx).value;
      detail.push({ nama_item: nama_rincian, isi_item: isi_rincian });
    });

    let addSkck = {
      id_jenis: this.props.location.state.uuid,

      // nik: document.getElementById("nik").value,
      nik_pelapor: this.state.pendStat.value,
      nik: this.state.pendStat.value,
      nama: document.getElementById("nama_pemohon").value,
      tempat_lahir: document.getElementById("tempat_lahir").value,
      tanggal_lahir: document.getElementById("tanggal_lahir").value,
      pekerjaan: document.getElementById("pekerjaan").value,
      alamat: document.getElementById("alamat").value,

      // nik2: document.getElementById("nik2").value,
      nik2: this.state.pendStat2.value,
      nama2: document.getElementById("nama2").value,
      tempat_lahir2: document.getElementById("tempat_lahir2").value,
      tanggal_lahir2: document.getElementById("tanggal_lahir2").value,
      pekerjaan2: document.getElementById("pekerjaan2").value,
      alamat2: document.getElementById("alamat2").value,

      kuasa_atas: document.getElementById("kuasa_atas").value,
      kuasa_untuk: document.getElementById("kuasa_untuk").value,
      tujuan_kuasa: document.getElementById("tujuan_kuasa").value,

      detail: detail
    };

    //console.log("Data Simpan Kuasa")
    //console.log(addSkck)

    let psn = "";
    let resstat = 204;
    let metode = "create";
    if (uuid === "") {
      psn = "Tambah";
      resstat = 201;
      uuid = null;
    } else {
      psn = "Ubah";
      resstat = 200;
      metode = "update";
    }
    Post("surat-keterangan/kuasa/" + metode, uuid, addSkck, res => {

      // For testing
      if (this.props.resSubmit) {
        this.props.resSubmit(res.status)
      }
      this.tutupForm();
      this.setState({
        status:{
          ...this.state.status,
          btnForm: false,
          form: false,
        },
      })
      this.forceUpdate();
      if (res.status === resstat) {
        this.setState({
          showBio: false,
          show: true,
          basicType: "success",
          basicTitle: "Data Permohonan Kuasa",
          pesanAlert: "Berhasil " + psn + " Data",
          successAlert: true
        });
      } else {
        this.setState({
          showBio: false,
          show: true,
          basicType: "danger",
          basicTitle: "Data Permohonan Kuasa",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================
  // list disposisi

  listDisposisi = data => {
    //console.log(data);
    Get("alur-disposisi/" + data.id_jenis, null, dtkat => {
      //console.log(dtkat);
      if (dtkat) {
        //console.log(dtkat.results);

        this.setState({
          idSurat: data.uuid,
          datDisposisi: dtkat.results,
          formDisposisi: true,
          riwayatDisposisi: data.data_status_disposisi
        });
      }
    });
  };
  //disposisi
  disposisi = (label, id) => {
    //console.log(label);
    Get("disposisi/tidak-mampu/" + label + "/" + id, null, dtkat => {
      if (dtkat.code === 201) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Disposisi",
          pesanAlert: "Berhasil  Disposisi Data"
        });
        this.componentDidMount();
        this.tutupForm();
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Disposisi",
          pesanAlert: "Disposisi telah dilakukan"
        });
        this.tutupForm();
      }
    });
    //console.log(id);
  };
  // ============================== Ubah Data ===============================

  ubahData = id => {
    // this.state.status.btnAksi = true;
    // this.componentDidMount();
    Get("surat-keterangan/kuasa/find", id, data => {
      if (typeof data.results !==  "undefined") {
        let detailRincian = [];
        data.results.data_detail_suket.forEach(dt => {
          let nama_rincian = dt.nama_item;
          let isi_rincian = dt.isi_item;
          detailRincian.push({
            nama_rincian: nama_rincian,
            isi_rincian: isi_rincian
          });
        });

        this.setState({
          dt:{
            ...this.state.dt,
            uuid: data.results.uuid,
            nomor: data.results.nomor,
            nik: data.results.nik,
            nama: data.results.nama,
            kepada: data.results.kepada,
            tempat_lahir: data.results.tempat_lahir,
            tanggal_lahir: data.results.tanggal_lahir,
            pekerjaan: data.results.pekerjaan,
            alamat: data.results.alamat,
            nik2: data.results.nik2,
            nama2: data.results.nama2,
            kepada2: data.results.kepada2,
            tempat_lahir2: data.results.tempat_lahir2,
            tanggal_lahir2: data.results.tanggal_lahir2,
            pekerjaan2: data.results.pekerjaan2,
            alamat2: data.results.alamat2,
            kuasa_atas: data.results.kuasa_atas,
            kuasa_untuk: data.results.kuasa_untuk,
            tujuan_kuasa: data.results.tujuan_kuasa,
          },
          status:{
            ...this.state.status,
            btnForm: false,
          },
          tanggal_surat: new Date(data.results.tanggal_surat),
          pendStat: { value: data.results.nik, label: data.results.nik + ' (' + data.results.nama + ')' },
          pendStat2: { value: data.results.nik2, label: data.results.nik2 + ' (' + data.results.nama2 + ')' },
          showBio: true,
          showBio2: true,
          judul: "Ubah Permohonan Kuasa",
          usaha: detailRincian,
        })

        this.forceUpdate();
        // this.state.status.btnAksi = false;
        // this.componentDidMount();
        this.bukaForm();
      }
    });
  };

  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("surat-keterangan/kuasa/delete", id, res => {
      if (res ===  200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Surat Izin",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Surat Izin",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.setState({
      status:{
        ...this.state.status,
        form: true,
      },
    })

    this.forceUpdate();
  };

  tutupForm = () => {
    this.setState({
      status:{
        ...this.state.status,
        btnForm: true,
        form: false,
      },
      usaha: [{ jenis: "", lokasi: "", umur: "" }],
      showBio: false,
      showBio2: false,
      pendStat: [],
      pendStat2: [],
      formDisposisi: false,
      formAcc: false,
      tableAcc: "none",
      accStat: [],
    })
    this.forceUpdate();
  };

  persetujuan = (id, jawaban) => {
    this.hideAlert();
    Get(
      "surat-keterangan/kuasa/persetujuan/" + jawaban + "/" + id,
      null,
      res => {
        if (res.code === 201) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
  };

  tombolAksi = (cell, row) => {
    // let btnApproval = false;
    // let btnCetak = false;
    let btnAksi = false;
    let status = "";
    row.data_status.forEach(dt => {
      status = dt.status;
    });
    if (status ===  "Diajukan") {
      // btnCetak = true;
      btnAksi = true;
      // btnApproval = true;
    } else {
      btnAksi = true;
      // btnApproval = true;
      if (status ===  "Ditolak") {
        // btnCetak = true;
      } else if (status ===  "Disetujui") {
        // btnCetak = true;
        btnAksi = false;
        // btnApproval = false;
      } else if ((status ===  "Menunggu Penerbitan") | (status ===  "Selesai")) {
        // btnAksi = false;
        // btnCetak = false;
        // btnApproval = false;
      } else {
        // btnCetak = true;
      }
    }
    return (
      <>
        {/* <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={() => this.konfirmApprove(row.uuid)}
          disabled={btnApproval}
        >
          <i className="fa fa-check"></i>
        </Button>{" "}
        &nbsp;&nbsp; */}
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        {/* &nbsp;&nbsp;
        <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>{" "}
        &nbsp;&nbsp;
        <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={e => this.cetakSurat(e, row)}
          disabled={btnCetak}
        >
          <i className="fa fa-print"></i>
        </Button>
        &nbsp;&nbsp; */}
        {/* //list */}
        {/* <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={e => this.listDisposisi(row)}
        >
          <i className="fa fa-list"></i>
        </Button> */}
      </>
    );
  };

  changeAcara = e => {
    document.getElementById(e.target.id).value = e.target.value;
  };

  //change nik
  handleInputChangeNIK = (e, fld) => {
    if (e) {
      if (fld ===  "nik") {
        let pendStat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmPend = [];
          datanik.data.results.data.forEach(dt => {
            frmPend.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          });
          this.setState({ frmPend, pendStat });
        });
      } else {
        let pendStat2 = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmPend2 = [];
          datanik.data.results.data.forEach(dt => {
            frmPend2.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          });
          this.setState({ frmPend2, pendStat2 });
        });
      }
    }
  };
  // ambil data penduduk
  getPenduduk = (e, label) => {
    if (e) {
      Get("penduduk/find/" + e.value, null, data => {
        let alamat = '';
        // if (JENIS_DESA.toLowerCase() ===  'kelurahan') {
        //   alamat =
        //   " RT. " +
        //   data.results.data_kk.data_rt.nama +
        //   "/RW. " +
        //   data.results.data_kk.data_rt.data_rw.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes.nama +
        //   ", Kec. " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes
        //     .data_kecamatan.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes
        //     .data_kecamatan.data_kabkota.nama; 
        // } else {
        //   alamat =
        //   " RT. " + 
        //   data.results.data_kk.data_rt.nama +
        //   "/RW. " +
        //   data.results.data_kk.data_rt.data_rw.nama +
        //   ", " + JENIS_DUSUN + " " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
        //   ", Kec. " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
        //     .data_kecamatan.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
        //     .data_kecamatan.data_kabkota.nama; 
        // }
        if (localStorage.getItem('JENIS_DESA').toLowerCase() ===  'kelurahan') {
          alamat =
          " RT. " +
          data.results.data_kk.data_rt.nama +
          "/RW. " +
          data.results.data_kk.data_rt.data_rw.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_keldes.nama +
          ", Kec. " +
          data.results.data_kk.data_rt.data_rw.data_keldes
            .data_kecamatan.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_keldes
            .data_kecamatan.data_kabkota.nama; 
        } else {
          if (data.results.data_kk.id_rt ===  '0') {
            alamat = localStorage.getItem('JENIS_DESA') + " " + data.results.data_keldes.nama + 
            ", Kec. " + data.results.data_keldes.data_kecamatan.nama + 
            ", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
            ", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
          } else {
            alamat =
            " RT. " + 
            data.results.data_kk.data_rt.nama +
            "/RW. " +
            data.results.data_kk.data_rt.data_rw.nama +
            ", " + JENIS_DUSUN + " " +
            data.results.data_kk.data_rt.data_rw.data_dusun.nama +
            ", " + localStorage.getItem('JENIS_DESA') + " " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
            ", Kec. " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
              .data_kecamatan.nama +
            ", " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
              .data_kecamatan.data_kabkota.nama; 
          }
        }

        // let daerah = data.results.data_kk.data_rt.data_rw.data_dusun.nama +
        //       ", " +
        //       data.results.data_kk.data_rt.data_rw.data_keldes.nama +
        //       ", " +
        //       data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
        //       ", " +
        //       data.results.data_kk.results.dataKkbj.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota
        //         .nama +
        //       ", " +
        //       data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota
        //         .data_provinsi.nama

        if (label ===  "nik") {
          Get("catatan-penduduk/nik", data.results.id, dpddk => {
            let pctt = 0;
            let catatan = "";
            if (dpddk.results.data) {
              dpddk.results.data.forEach(dtp => {
                if (dtp.status ===  0 && dtp.penalti ===  1) {
                  pctt = 1;
                  catatan = dtp.catatan;
                }
              });
            }
            if (pctt ===  0) {
              this.setState({
                dt:{
                  ...this.state.dt,
                  nik: data.results.id,
                  kk: data.results.id_kk,
                  nama: data.results.nama,
                  tempat_lahir: data.results.tempat_lahir,
                  tanggal_lahir: data.results.tanggal_lahir,
                  pekerjaan: data.results.data_pekerjaan.nama,
                  jenis_kelamin: data.results.jk,
                  pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
                  agama: data.results.data_agama.nama,
                  status_perkawinan: data.results.status_perkawinan,
                  alamat: alamat,
                },
                showBio: true,
                pendStat: { value: e.value, label: e.label },
              })
              this.forceUpdate();
            } else {
              this.setState({
                showBio: false,
                btnForm: true,
                dt:{
                  ...this.state.dt,
                  nik: "",
                  nama_pemohon: "",
                  tempat_lahir: "",
                  tanggal_lahir: "",
                  pekerjaan: "",
                  pendidikan_terakhir: "",
                  agama: "",
                  status_perkawinan: "",
                  alamat: "",
                },
                show: true,
                basicType: "danger",
                basicTitle: "Peringatan Catatan Warga",
                pesanAlert:
                  "Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r" +
                  catatan,
                pendStat: null,
                // frmPend: []
              });
              this.forceUpdate();
            }
            
            // For testing
            if (this.props.selectOnChange) {
              this.props.selectOnChange(this.state.dt)
            }
          });
        }
        if (label ===  "nik2") {
          this.setState({
            dt:{
              ...this.state.dt,
              nik2: data.results.id,
              kk2: data.results.id_kk,
              nama2: data.results.nama,
              tempat_lahir2: data.results.tempat_lahir,
              tanggal_lahir2: data.results.tanggal_lahir,
              pekerjaan2: data.results.data_pekerjaan.nama,
              jenis_kelamin2: data.results.jk,
              pendidikan_terakhir2: data.results.data_pendidikan_terakhir.nama,
              agama2: data.results.data_agama.nama,
              status_perkawinan2: data.results.status_perkawinan,
              alamat2: alamat,
            },
            showBio2: true,
            pendStat2: { value: e.value, label: e.label },
          })

          // For testing
          if (this.props.selectOnChange2) {
            this.props.selectOnChange2(this.state.dt)
          }
        }

        if (
          this.state.pendStat2.length !==  0 &&
          this.state.pendStat.length !==  0
        ) {
          this.setState({
            status:{
              ...this.state.status,
              btnForm: false,
            },
          })
        }
        // this.state.status.btnAksi = false;
        this.forceUpdate();
      });
    } else {
      if (label ===  "nik") {
        this.setState({
          dt:{
            ...this.state.dt,
            nik: "",
            nama_pemohon: "",
            tempat_lahir: "",
            tanggal_lahir: "",
            pekerjaan: "",
            pendidikan_terakhir: "",
            agama: "",
            status_perkawinan: "",
            alamat: "",
          },
          showBio: false,
          btnForm: true,
          pendStat: [],
        })
      }
      if (label ===  "nik2") {
        this.setState({
          dt:{
            ...this.state.dt,
            nik: "",
            nama_pemohon2: "",
            tempat_lahir2: "",
            tanggal_lahir2: "",
            pekerjaan2: "",
            pendidikan_terakhir2: "",
            agama2: "",
            status_perkawinan2: "",
            alamat2: "",
          },
          showBio2: false,
          btnForm: true,
          pendStat2: [],
        })
      }
      this.setState({
        status:{
          ...this.state.status,
          btnForm: true,
        },
      })

      this.forceUpdate();

      // this.state.status.btnAksi = false;
    }
  };
  ///acc
  // ========================================================================
  // ============================= Approve Data ===============================
  konfirmApprove = id => {
    this.setState({
      judul: "Persetujuan Data ",
      formAcc: true,
      btnFormAcc: true,
      idaccSurat: id
    });
  };
  //change select
  changAcc = (e, sel) => {
    if (e) {
      switch (sel) {
        case "status-acc":
          this.setState({
            accStat: e,
          })
          break;
        default:
          // no default
          break;
      }
      if (e.value ===  "Disetujui") {
        this.setState({
          tableAcc: "",
          btnFormAcc: true,
        })
        if (this.state.accButton.length > 0) {
          this.setState({
            btnFormAcc: false,
          })
        }
      } else {
        this.setState({
          tableAcc: "none",
          btnFormAcc: false,
        })
      }
    } else {
      switch (sel) {
        case "status-acc":
          this.setState({
            accStat: e,
          })
          break;
        default:
          // no default
          break;
      }
      this.setState({
        tableAcc: "none",
        btnFormAcc: true,
      })
    }
    this.setState({
      status:{
        ...this.state.status,
        select: false,
      },
    })
    this.forceUpdate();
  };
  simpanAcc = e => {
    e.preventDefault();
    this.setState({
      btnFormAcc: true,
    })

    let add = {
      tanggal_surat: new Date(this.state.tanggal_surat)
        .toISOString()
        .slice(0, 10),
      uuid: this.state.accuuid
    };
    Post(
      "surat-keterangan/kuasa/persetujuan/" +
        this.state.accStat.value +
        "/" +
        this.state.idaccSurat,
      null,
      add,
      res => {
        if (res.status === 201) {
          this.setState({
            formAcc: false,
            show: true,
            basicType: "success",
            tableAcc: "none",
            accStat: [],
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            btnFormAcc: false,

            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
  };

  pilih = (uuid, id) => {
    let temp = this.state.accButton.map(x => 1);
    temp[id] = 0;
    this.setState({
      accButton: temp,
      accuuid: uuid,
    })
    if (this.state.accStat) {
      this.setState({
        btnFormAcc: false,
      })
    }
    this.forceUpdate();
  };
  componentDidMount() {
    if (this.props.location.state ===  undefined) {
      this.setState({ redirect: true });
    } else {
      this.fetch({ page: null, where: [] });

      Get("penduduk", null, data => {
        let frmPend = [];
        let frmPend2 = [];
        data.results.data.forEach(dt => {
          frmPend.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          frmPend2.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
        });
        this.setState({ frmPend, frmPend2 });

        // For testing
        if (this.props.getListNIK) {
          this.props.getListNIK(frmPend)
        }
      });
      Get("select-enum/penduduk/jk", null, data => {
        let frmJk = [];
        data.forEach(dt => {
          frmJk.push({ value: dt, label: dt });
        });
        this.setState({ frmJk });
      });
      Get("tanda-tangan/list", null, data => {
        data.results.forEach((dt, i) => {
          if (dt.aktif ===  1) {
            let temp = this.state.accButton.map(x => 1);
            temp[i] = 0;
            this.setState({
              accButton: temp,
              accuuid: dt.uuid,
            })
          }
        });
        this.forceUpdate();
        this.setState({ frmTTD: data.results });
      });
    }
  }

  cetakSurat = (e, data) => {
    e.preventDefault();

    // let detail;

    let detailRincian = [];
    data.data_detail_suket.forEach(dt => {
      let nama_rincian = dt.nama_item;
      let isi_rincian = dt.isi_item;
      detailRincian.push({
        nama_rincian: nama_rincian,
        isi_rincian: isi_rincian
      });
    });
    this.setState({
      cetak: {
        nama_desa: Nama_DESA,
        tanggal_surat: data.tanggal_surat,

        nomor_surat: data.nomor,
        nik: data.nik,
        nama: data.nama.toLowerCase(),
        tempat_lahir: data.tempat_lahir.toLowerCase(),
        tanggal_lahir: Tanggal(data.tanggal_lahir),
        pekerjaan: data.pekerjaan.toLowerCase(),
        alamat: data.alamat.toLowerCase(),
        nik2: data.nik2,
        nama2: data.nama2.toLowerCase(),
        tempat_lahir2: data.tempat_lahir2.toLowerCase(),
        tanggal_lahir2: Tanggal(data.tanggal_lahir2),
        pekerjaan2: data.pekerjaan2.toLowerCase(),
        alamat2: data.alamat2.toLowerCase(),

        tujuan_kuasa: data.tujuan_kuasa.toLowerCase(),
        kuasa_untuk: data.kuasa_untuk,
        kuasa_atas: data.kuasa_atas,

        status_ttd: data.status_ttd,
        jabatan_ttd: data.jabatan_ttd,
        jabatan_pengganti_ttd: data.jabatan_pengganti_ttd,
        nama_ttd: data.nama_ttd,
        nip_ttd: data.nip_ttd ? "NIP. " + data.nip_ttd : "",
        detail: detailRincian
      }
    });
    setTimeout(function() {
      const content = document.getElementById("cetak-surat").innerHTML;
      const mywindow = window.open("", "Print", "height=600,width=1000");

      mywindow.document.write("<html><head><title>Print</title>");
      mywindow.document.write("</head><body >");
      mywindow.document.write(content);
      mywindow.document.write("</body></html>");

      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
      setTimeout(function() {
        mywindow.close();
      }, 2000);
    }, 200);
  };

  //
  gantiTglMulai = date => {
    this.setState({ tanggal_mulai: date });
  };
  gantiTglSelesai = date => {
    this.setState({ tanggal_selesai: date });
  };
  // cek redirect jiksa state kosong
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };
  render() {
    
    //console.log("Data Render")
    //console.log("Data Kuasa")
    //console.log("====================")
    // var size = Math.ceil(
    //   this.state.pagination.total / this.state.pagination.per_page
    // );
    // var size = this.state.pagination.total;
    // var from = this.state.pagination.from;
    // var page = this.state.pagination.current;
    // var to = this.state.pagination.to;
    // var total = this.state.pagination.total;
    var data = this.state.data;

    let frmDef = {
      uuid: "",
      id_jenis: "",
      id_pengaju: "",
      tanggal_surat: "",
      nik: "",
      nama: "",
      kepada: "",
      tempat_lahir: "",
      tanggal_lahir: "",
      pekerjaan: "",
      alamat: "",

      nik2: "",
      nama2: "",
      kepada2: "",
      tempat_lahir2: "",
      tanggal_lahir2: "",
      pekerjaan2: "",
      alamat2: "",
      kuasa_untuk: "",
      kuasa_atas: "",
      tujuan_kuasa: "",

      status_ttd: "",
      jabatan_ttd: "",
      jabatan_pengganti_ttd: "",
      nama_ttd: "",
      nip_ttd: "",

      detail: []
    };
    // const { SearchBar } = Search;
    const columns = [
      {
          dataField: "no",
          text: "No",
          isDummyField: true,
          csvExport: false,
          headerAlign: 'center',
				  align: 'center',
          formatter: (key, obj, index) =>{          
              let current_pagenum = this.state.pagination.current_page;
              let total_records_per_page = this.state.pagination.per_page;
              let row_index = (index+1);
              let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              return serial_num; 
          }
      },
      {
        dataField: "tanggal_surat",
        headerAlign: 'center',
				align: 'center',
        text: "Tanggal",
        formatter: (cell, obj) => {
          let plainTime = '-'
					if (obj.created_at) {
						let date = new Date(obj.created_at)
						date = date.toLocaleString('id-ID')
						
						const datetime = date.split(' ')
						
	
						date = datetime[0]
						let time = datetime[1]
	
						const splitDate = date.split('/')
						time = time.replaceAll('.', ':')
	
						console.log(date)
						const list_bulan = [
							'Januari',
							'Februari',
							'Maret',
							'April',
							'Mei',
							'Juni',
							'Juli',
							'Agustus',
							'September',
							'Oktober',
							'November',
							'Desember'
						];

						plainTime = `${splitDate[0]} ${list_bulan[parseInt(splitDate[1]) - 1]} ${splitDate[2]}  ${time}`;
					}

					return plainTime
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "nomor",
        headerAlign: 'center',
				align: 'center',
        text: "Nomor surat",
        formatter: (cell, obj) => {
          return obj.nomor ===  "" ? "-" : obj.nomor;
        },
        sort: true
      },
      {
        dataField: "status",
        headerAlign: 'center',
				align: 'center',
        text: "Status",
        formatter: (cell, obj) => {
          let status = "-";
          obj.data_status.forEach(dt => {
            status = dt.status;
          });
          if (status ===  "Diajukan") {
            this.setState({
              status:{
                ...this.state.status,
                btnAksi: true,
              }
            })
          } else {
            this.setState({
              ...this.state.status,
              btnAksi: false,
            })
          }
          this.forceUpdate();
          return status;
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "aksi",
        headerAlign: 'center',
				align: 'center',
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    // const defaultSorted = [
    //   {
    //     dataField: "nama",
    //     order: "asc"
    //   }
    // ];

    // const pageButtonRenderer = ({
    //   page,
    //   active,
    //   disable,
    //   title,
    //   onPageChange
    // }) => {
    //   const handleClick = e => {
    //     e.preventDefault();
    //     this.fetch({ where: [], page: page });
    //   };
    //   var classname = "";
    //   classname = "btn btn-primary-custom";
    //   return (
    //     <li className="page-item pl-1" key={page}>
    //       <a href="#" onClick={handleClick} className={classname}>
    //         {page}
    //       </a>
    //     </li>
    //   );
    // };

    // const options = {
    //   alwaysShowAllBtns: true,
    //   pageButtonRenderer,
    //   // custom: true,
    //   paginationTotalRenderer: () => (
    //     <span className="react-bootstrap-table-pagination-total">
    //       Showing {from} to {to} of {total} Results
    //     </span>
    //   ),
    //   paginationSize: size,
    //   totalSize: total
    // };
    const usaha = this.state.usaha.map((dt, idx) => {
      let no = idx + 1;
      return (
        <div className="shareholder">
          <FormGroup className="row">
            <Col sm="3">
              <input
                data-testid={"nama rincian" + no}
                name={"nama_rincian" + no}
                className="form-control"
                id={"nama_rincian" + idx}
                type="text"
                required
                placeholder={"Nama Rincian " + (idx + 1)}
                defaultValue={dt.nama_rincian}
              />
            </Col>
            <Col sm="7">
              <input
                data-testid={"isi rincian" + no}
                name={"isi_rincian" + no}
                className="form-control"
                id={"isi_rincian" + idx}
                type="text"
                required
                placeholder={"Isi Rincian" + (idx + 1)}
                defaultValue={dt.isi_rincian}
              />
            </Col>
            <Col sm="2">
              <div className="float-right">
                <button
                  data-testid="btnHapusKeterangan"
                  type="button"
                  className="btn btn-danger small"
                  onClick={this.handleRemoveShareholder(idx)}
                >
                  -
                </button>
              </div>
            </Col>
          </FormGroup>
        </div>
     );
    });
    let no = 0;
    const tableTTD = this.state.frmTTD.map((dt, i) => {
      no++;
      return (
        <tr key={i}>
          <td>{no}</td>
          <td>{dt.jabatan}</td>
          <td>{dt.jabatan_pengganti ? dt.jabatan_pengganti : "-"}</td>
          <td>{dt.nama}</td>
          <td>{dt.nip ? dt.nip : "-"}</td>
          <td>{dt.status ? dt.status : "-"}</td>
          <td>
            <Button
              size="xs"
              onClick={() => this.pilih(dt.uuid, i)}
              color={
                this.state.accButton.length > 0
                  ? this.state.accButton[i] ===  0
                    ? "default"
                    : "danger"
                  : "danger"
              }
              className="btn-icon"
            >
              {this.state.accButton.length > 0
                ? this.state.accButton[i] ===  0
                  ? "Dipilih"
                  : "Pilih"
                : "Pilih"}
            </Button>
          </td>
        </tr>
      );
    });
    return (
      <div data-testid="page-surat kuasa">
        {this.renderRedirect()}

        {/* <Breadcrumb title="Data Surat Kuasa " parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="info"
          onCancel={this.closeAlert}
          showConfirm={false}
        >
          {this.state.pesanAlert}
          {this.state.successAlert ? (
            <div>
              <br />
              <Button
                color="success"
                onClick={() => {
                  this.closeAlert();
                  this.bukaForm();
                }}
              >
                Tambah Data
              </Button>{" "}
              <Button
                color="info"
                className="btn btn-secondary-custom"
                // onClick={() => {
                //   this.closeAlert();
                // }}
              >
                <Link
                  data-testid="btn-eksekusi_surat"
                  onClick={() => {
                    // For testing
                    if (this.props.clickEksekusiSurat) {
                      this.props.clickEksekusiSurat()
                    }
                  }}
                  to={{
                    pathname: process.env.PUBLIC_URL + "/admin/disposisi",
                    tabProps: "2"
                  }}
                >
                  <span style={{ color: "white" }}>Eksekusi Surat</span>
                </Link>
              </Button>
            </div>
          ) : (
            ""
          )}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Surat Surat Kuasa </h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      <Button
                        data-testid="tblCreateSuratKuasa"
                        size="sm"
                        color="success"
                        // className="btn-square"
                        onClick={() => {
                          this.setState({
                            judul: "Tambah Surat Kuasa",
                            dt: frmDef,
                            usaha: [{ jenis: "", lokasi: "", umur: "" }],
                            showBio: false,
                            showBio2: false,
                            pendStat: [],
                            pendStat2: []
                          });
                          this.bukaForm();
                        }}
                      >
                        Tambah Data
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <BootstrapTable keyField="id" data={data} columns={columns} />
                  <div className="pull-right text-white">
                    {this.state.awal}
                    {this.state.sebelum}
                    {this.state.hal.map(dt => {
                      return dt;
                    })}
                    {this.state.setelah}
                    {this.state.akhir}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form 
            data-testid="form-create" 
            className="theme-form" 
            onSubmit={this.simpan}
          >
            <div className="modal-header">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="nik"
                >
                  Kuasa Dari{" "}
                </Label>
                <Col sm="9">
                  <div data-testid="selectNIK">
                    <Select
                      inputId="nik"
                      isDisabled={this.state.status.select}
                      classNamePrefix="select"
                      onChange={e => {
                        this.getPenduduk(e, "nik");
                      }}
                      defaultValue={this.state.pendStat}
                      value={this.state.pendStat}
                      name="penduduk"
                      options={this.state.frmPend}
                      placeholder="Pilih NIK"
                      onInputChange={e => this.handleInputChangeNIK(e, "nik")}
                      isClearable
                    />
                  </div>
                </Col>
              </FormGroup>
              <div
                style={{ display: this.state.showBio ===  false ? "none" : "" }}
              >
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    NIK
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="nik"
                  type="hidden"
                  readOnly
                  placeholder="NIK"
                  value={this.state.dt.nik}
                />
                {/* </Col>
                </FormGroup> */}
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="nama_pemohon"
                      readOnly
                      type="text"
                      placeholder="nama_pemohon"
                      value={this.state.dt.nama}
                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tempat Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      readOnly
                      id="tempat_lahir"
                      type="text"
                      placeholder="Tempat lahir"
                      value={this.state.dt.tempat_lahir}
                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tanggal Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tanggal_lahir"
                      readOnly
                      type="text"
                      placeholder="Tanggal Lahir"
                      value={this.state.dt.tanggal_lahir}
                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  readOnly
                  id="pekerjaan"
                  type="hidden"
                  placeholder="Pekerjaan"
                  value={this.state.dt.pekerjaan}
                />
                {/* </Col>
                </FormGroup> */}

                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="alamat"
                  type="hidden"
                  readOnly
                  placeholder="Alamat"
                  value={this.state.dt.alamat}
                />
                {/* </Col>
                </FormGroup> */}
              </div>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="nik2"
                >
                  Yang Diberi Kuasa{" "}
                </Label>
                <Col sm="9">
                  <div data-testid="selectNIK2">
                    <Select
                      inputId="nik2"
                      isDisabled={this.state.status.select}
                      classNamePrefix="select"
                      onChange={e => {
                        this.getPenduduk(e, "nik2");
                      }}
                      defaultValue={this.state.pendStat2}
                      value={this.state.pendStat2}
                      name="penduduk"
                      options={this.state.frmPend2}
                      placeholder="Pilih NIK"
                      onInputChange={e => this.handleInputChangeNIK(e, "nik2")}
                      isClearable
                    />
                  </div>
                </Col>
              </FormGroup>
              <div
                style={{ display: this.state.showBio2 ===  false ? "none" : "" }}
              >
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    NIK
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="nik2"
                  readOnly
                  type="hidden"
                  required
                  placeholder="NIK"
                  defaultValue={this.state.dt.nik2}
                />
                {/* </Col>
                </FormGroup> */}
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="nama2"
                      required
                      type="text"
                      readOnly
                      placeholder="Nama"
                      defaultValue={this.state.dt.nama2}
                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tempat Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      required
                      id="tempat_lahir2"
                      readOnly
                      type="text"
                      placeholder="Tempat lahir"
                      defaultValue={this.state.dt.tempat_lahir2}
                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tanggal Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tanggal_lahir2"
                      readOnly
                      type="text"
                      placeholder="Tanggal Lahir"
                      value={this.state.dt.tanggal_lahir2}
                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  required
                  id="pekerjaan2"
                  readOnly
                  type="hidden"
                  placeholder="Pekerjaan"
                  defaultValue={this.state.dt.pekerjaan2}
                />
                {/* </Col>
                </FormGroup> */}
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="alamat2"
                  readOnly
                  type="hidden"
                  required
                  placeholder="Alamat"
                  defaultValue={this.state.dt.alamat2}
                />
                {/* </Col>
                </FormGroup> */}
              </div>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Kuasa Untuk
                </Label>
                <Col sm="9">
                  <input
                    data-testid="kuasa untuk"
                    name="kuasa_untuk"
                    className="form-control"
                    id="kuasa_untuk"
                    type="text"
                    required
                    placeholder="Kuasa Untuk"
                    defaultValue={this.state.dt.kuasa_untuk}
                  />
                </Col>
              </FormGroup>{" "}
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Kuasa Atas
                </Label>
                <Col sm="9">
                  <input
                    data-testid="kuasa atas"
                    name="kuasa_atas"
                    className="form-control"
                    id="kuasa_atas"
                    type="text"
                    required
                    placeholder="Kuasa Atas"
                    defaultValue={this.state.dt.kuasa_atas}
                  />
                </Col>
              </FormGroup>{" "}
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tujuan Kuasa
                </Label>
                <Col sm="9">
                  <input
                    data-testid="tujuan kuasa"
                    name="tujuan_kuasa"
                    className="form-control"
                    id="tujuan_kuasa"
                    type="text"
                    required
                    placeholder="Tujuan Atas"
                    defaultValue={this.state.dt.tujuan_kuasa}
                  />
                </Col>
              </FormGroup>{" "}
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                Rincian Data
              </Label>
              {usaha}
              <button
                data-testid="btnTambahKeterangan"
                type="button"
                onClick={this.handleAddShareholder}
                className="btn btn-success small"
              >
                Tambah rincian
              </button>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                data-testid="btnSubmitCreate"
                type="submit"
                className="btn btn-primary-custom"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formAcc}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpanAcc}>
            <div className="modal-header">
              <h5 className="modal-title">Konfirmasi Surat</h5>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Surat
                </Label>
                <Col sm="9">
                  {/* <Datepicker
                    id="tanggal_surat"
                    todayButton={"Today"}
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.tanggal_surat}
                    onChange={e => this.gantiTgl(e, "tanggal_surat")}
                    className="form-control"
                  /> */}
                </Col>
              </FormGroup>

              <Col sm="12">
                <table className="table table-bordered table-stripes table-hovered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Jabatan</th>
                      <th>Jabatan Pengganti</th>
                      <th>Nama</th>
                      <th>NIP</th>
                      <th>Status</th>
                      <th>
                        <i className="fa fa-check"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{tableTTD}</tbody>
                </table>
              </Col>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary-custom">
                Selesai
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formDisposisi}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form">
            <div className="modal-header">
              <h5 className="modal-title">Disposisi Surat</h5>
            </div>
            {/* <div className="modal-body">
                <table className="table table-bordered table-stripes table-hovered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Jabatan</th>
                      <th>Level</th>
                      <th>TTD</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.datDisposisi.map((dt,i)=>{
                  return(
                    <tr key={i}>
                      <td>{i+1}</td>
                      <td>{dt.data_jabatan.nama}</td>
                      <td>{dt.level}</td>
                      <td>

                      <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => {
                        this.disposisi(dt.uuid,this.state.idSurat)}
                      }
                      disabled={this.state.status.btnForm}
                    >
                      Disposisi
                    </button>
                      </td>
                    </tr>
                    )})}
                  </tbody>
                </table>
              </div>
              */}
            <div className="modal-body">
              <table className="table table-bordered table-stripes table-hovered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Status</th>
                    <th>Pejabat</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.riwayatDisposisi.map((dt, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          {dt.status ===  0
                            ? "Menunggu Disposisi"
                            : dt.status ===  1
                            ? "Telah di disposisi"
                            : "Ditolak"}
                        </td>
                        <td>{dt.data_perangkat.data_jabatan.nama}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.btnFormAcc}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.btnFormAcc}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
        <div id="cetak-surat" style={{ display: "none" }}>
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    "
            }}
          />
          <table
            border="0"
            width="90%"
            style={{
              marginTop: "30px",
              marginLeft: "auto",
              marginRight: "auto",
              verticalAlign: "top"
            }}
          >
            <tr>
              <td align="center" style={{ paddingBottom: "20px" }}>
                {" "}
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    textDecoration: "underline"
                  }}
                >
                  surat kuasa
                </span>
                {/* <br />
                {this.state.cetak.nomor_surat} */}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <p style={{ textIndent: "0px" }}>
                  Yang bertanda tangan dibawah ini yakni :{" "}
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <table border="0" width="100%">
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Nama Lengkap
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.nama}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat / Tanggal Lahir
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.tempat_lahir},{" "}
                      {this.state.cetak.tanggal_lahir}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      NIK
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.nik}</td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Pekerjaan
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.pekerjaan}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Alamat
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.alamat}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <p style={{ textIndent: "0px" }}>
                  Dengan ini memberi kuasa kepada :{" "}
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <table border="0" width="100%">
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Nama Lengkap
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.nama2}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat / Tanggal Lahir
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.tempat_lahir2},{" "}
                      {this.state.cetak.tanggal_lahir2}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      NIK
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.nik2}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Pekerjaan
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.pekerjaan2}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Alamat
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.alamat2}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <p style={{ textIndent: "0px" }}>
                  Untuk {this.state.cetak.kuasa_untuk}{" "}
                  {this.state.cetak.kuasa_atas}
                  {this.state.cetak.tujuan_kuasa ===  "" ? ". " : ""}{" "}
                  {this.state.cetak.tujuan_kuasa !==  ""
                    ? this.state.cetak.tujuan_kuasa + ". "
                    : ""}
                  Adapun rincian data tersebut adalah :
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <table border="0" width="100%">
                  {this.state.cetak.detail.map(dt => {
                    return (
                      <tr>
                        <td
                          width="35%"
                          style={{ paddingLeft: "50px", textTransform: "capitalize" }}
                          // style={{  }}
                        >
                          {dt.nama_rincian.toLowerCase()}
                        </td>
                        <td>:</td>
                        <td style={{ textTransform: "capitalize" }}>
                          {dt.isi_rincian.toLowerCase()}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <span style={{ paddingLeft: "0px" }}>
                  Demikianlah surat kuasa ini dibuat dengan sebenarnya untuk
                  dapat dipergunakan seperlunya.
                </span>
              </td>
            </tr>
          </table>
          <table border="0" width="100%">
            <tr>
              <td style={{ textAlign: "justify" }} width="50%">
                &nbsp;
              </td>
              <td style={{ textAlign: "center" }} width="50%">
                {this.state.cetak.nama_desa},{" "}
                {Tanggal(new Date(this.state.cetak.tanggal_surat))}{" "}
              </td>
            </tr>
          </table>
          <table border="0" width="100%" style={{ marginTop: "20px" }}>
            <tr>
              <td
                width="50%"
                style={{ paddingLeft: "20px", textAlign: "center" }}
              >
                <span style={{ fontWeight: "bold" }}>Yang diberi kuasa,</span>
              </td>
              <td
                width="50%"
                style={{ paddingLeft: "20px", textAlign: "center" }}
              >
                <span style={{ fontWeight: "bold" }}>Yang memberi kuasa,</span>
              </td>
            </tr>
            <tr>
              <td height="50" colspan="2">
                &nbsp;
              </td>
            </tr>
            <tr>
              <td width="50%" align="center">
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    borderBottom: "1px solid black"
                  }}
                >
                  {this.state.cetak.nama2}
                </span>
              </td>
              <td width="50%" align="center">
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    borderBottom: "1px solid black"
                  }}
                >
                  {this.state.cetak.nama}
                </span>
              </td>
            </tr>
          </table>

          <table border="0" width="100%" style={{ marginTop: "20px" }}>
            <tr>
              <td
                width="100%"
                style={{ paddingLeft: "20px", textAlign: "center" }}
              >
                <span>Mengetahui :</span>
              </td>
            </tr>
            <tr>
              <td
                width="100%"
                style={{ paddingLeft: "20px", textAlign: "center" }}
              >
                <span style={{ fontWeight: "bold" }}>
                  {this.state.cetak.status_ttd}{" "}
                  <span style={{ textTransform: "uppercase" }}>
                    {this.state.cetak.jabatan_ttd}
                  </span>
                  {this.state.cetak.jabatan_pengganti_ttd ? (
                    <>
                      <br />
                      {this.state.cetak.jabatan_pengganti_ttd}
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td height="50" colspan="2">
                &nbsp;
              </td>
            </tr>
            <tr>
              <td width="100%" align="center">
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    borderBottom: "1px solid black"
                  }}
                >
                  {this.state.cetak.nama_ttd}
                </span>
                <br />
                {this.state.cetak.nip_ttd}
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
}

export default BasicTable;
