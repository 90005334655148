import React, { Component } from "react";

import { Link} from "react-router-dom";

// Import custom components
// import UserPanel from "./user-panel.component";

// koneksi
// import { STATUS_LAYANAN_KUA, STATUS_LAYANAN_CAPIL, GetMarketplace, ID_DESA } from "../../../function/Koneksi";

class SidebarAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paid: this.props.paid,
      lisensi: this.props.lisensi,
    };
  }

  cekStatus(){
    if (this.state.lisensi == null || this.state.paid == null) {
      return '/admin/layanan/join-us'
    } else if (this.state.paid && this.state.lisensi) {
      return true
    } else if (this.state.paid && this.state.lisensi == false) {
      return '/admin/layanan/expired'
    }
  }

  render() {
    return (
      <div className="bayu-sidebar custom-scrollbar bayu-sidebar-open">
        <ul className="sidebar-menu">
          <div className="dropdown-basic">
            <div className="row">
              <div className="dropdown">
                <Link to={process.env.PUBLIC_URL}>
                  <div className="btn-group m-1">
                    <button
                      type="button"
                      className="dropbtn btn-primary-custom"
                      style={{ borderRadius: "10px", width: "155px" }}
                    >
                      <i className="fa fa-home"></i> Dashboard
                    </button>
                  </div>
                </Link>
              </div>
              <div className="dropdown">
                <div className="btn-group m-1">
                  <button
                    type="button"
                    className="dropbtn btn-primary-custom"
                    style={{ borderRadius: "10px", width: "155px" }}
                  >
                    <i className="fa fa-envelope"></i> Administrasi
                    <span>
                      <i className="icofont icofont-arrow-down"></i>
                    </span>
                  </button>
                  <div className="dropdown-content">
                    <Link
                      to={{
                        pathname: this.cekStatus ? "/admin/verifikasi" : this.cekStatus,
                        state: { title: "Verifikasi Data" }
                      }}
                    >
                      <i className="icon-check-box"></i> Verifikasi Data
                    </Link>
                    <Link to={
                      this.cekStatus ? "/admin/disposisi" : this.cekStatus
                      }>
                      <i className="icon-arrow-right"></i> Disposisi Surat
                    </Link>
                    <Link to={ 
                      this.cekStatus ? "/admin/laporan" : this.cekStatus
                      }>
                      <i className="fa fa-files-o"></i> Laporan
                    </Link>
                    <div className="liyu2">
                      <a >
                        <i className="icon-pencil-alt"></i> Perubahan Data
                        <span style={{ float: "right" }}>
                          <i className="fa fa-angle-right"></i>
                        </span>
                      </a>
                      <div className="liyu3">
                        <Link
                          to={
                            this.cekStatus ? "/admin/perubahan-data/nik-kk" : this.cekStatus
                          }
                        >
                          <i className="fa fa-user"></i> Nik & KK
                        </Link>
                        <Link
                          to={
                            this.cekStatus ? "/admin/perubahan-data/pindah-kk" : this.cekStatus
                          }
                        >
                          <i className="fa fa-user"></i> Pindah KK
                        </Link>
                        <Link
                          to={
                            this.cekStatus ? "/admin/perubahan-data/pengajuan" : this.cekStatus
                          }
                        >
                          <i className="fa fa-user"></i> Pengajuan
                        </Link>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="dropdown">
                <div className="btn-group m-1">
                  <button
                    type="button"
                    className="dropbtn btn-primary-custom"
                    style={{ borderRadius: "10px", width: "155px" }}
                  >
                    <i className="icofont icofont-users-alt-4"></i> Kependudukan
                    <span>
                      <i className="icofont icofont-arrow-down"></i>
                    </span>
                  </button>
                  <div className="dropdown-content">
                    <Link to={
                      this.cekStatus ? "/admin/penduduk" : this.cekStatus
                      }>
                      <i className="fa fa-user"></i> Penduduk
                    </Link>
                    <Link to={ 
                      this.cekStatus ? "/admin/kartu-keluarga" : this.cekStatus
                      }>
                      <i className="fa fa-users"></i> Kartu Keluarga
                    </Link>
                    {
                      localStorage.getItem('JENIS_APLIKASI').toLowerCase() === "amanat nagari"
                      ?
                      <>
                        <Link to={ 
                          this.cekStatus ? "/admin/suku" : this.cekStatus
                          }>
                          <i className="fa fa-users"></i> Suku
                        </Link>
                        <Link to={ 
                          this.cekStatus ? "/admin/datuak" : this.cekStatus
                          }>
                          <i className="fa fa-users"></i> Datuak
                        </Link>
                        <Link to={
                          this.cekStatus ? "/admin/penduduksuku" : this.cekStatus
                          }>
                          <i className="fa fa-users"></i> Penduduk Suku
                        </Link>
                      </>
                      :
                      <>
                      </>
                    }
                  </div>
                </div>
              </div>

              <div className="dropdown">
                <div className="btn-group m-1">
                  <button
                    type="button"
                    className="dropbtn btn-primary-custom"
                    style={{ borderRadius: "10px", width: "155px" }}
                  >
                    <i className="fa fa-desktop"></i> Layanan
                    <span>
                      <i className="icofont icofont-arrow-down"></i>
                    </span>
                  </button>
                  <div className="dropdown-content">
                    {
                      localStorage.getItem('STATUS_LAYANAN_CAPIL') === '1'?
                      <>
                        <Link
                          to={
                            this.cekStatus ? "/admin/layanan/capil/disdukcapil" : this.cekStatus
                          }
                        >
                          <i className="fa fa-university"></i> Disdukcapil
                        </Link>
                      </>
                      :
                      <></>
                    }

                    {
                      localStorage.getItem('STATUS_LAYANAN_KUA') === '1'?
                      <>
                        <Link
                          to={ 
                            this.cekStatus ? "/admin/layanan/kua/kua" : this.cekStatus
                          }
                        >
                          <i className="fa fa-university"></i> KUA
                        </Link>
                      </>
                      :
                      <></>
                    }
                   
                    <Link
                      to={ 
                        this.cekStatus ? "/admin/layanan/kecamatan/beranda" : this.cekStatus
                      }
                    >
                      <i className="fa fa-university"></i> Kecamatan
                    </Link>
                  </div>
                </div>
              </div>

              <div className="dropdown">
                <div className="btn-group m-1">
                  <button
                    type="button"
                    className="dropbtn btn-primary-custom"
                    style={{ borderRadius: "10px", width: "155px" }}
                  >
                    <i className="icofont icofont-ui-note"></i> Informasi
                    <span>
                      <i className="icofont icofont-arrow-down"></i>
                    </span>
                  </button>
                  <div className="dropdown-content">
                    <div className="liyu2">
                      <a >
                        <i className="fa fa-money"></i> Bantuan Sosial
                        <span style={{ float: "right" }}>
                          <i className="fa fa-angle-right"></i>
                        </span>
                      </a>
                      <div className="liyu3">
                        <Link
                          to={
                            this.cekStatus ? "/admin/bansos/master-kategori-bansos" : this.cekStatus
                          }
                        >
                          <i className="fa fa-database"></i> Data Kategori Bansos
                        </Link>
                        <Link
                          to={
                            this.cekStatus ? "/admin/bansos/master-bansos" : this.cekStatus
                          }
                        >
                          <i className="fa fa-database"></i> Data Bansos
                        </Link>
                        <Link
                          to={
                            this.cekStatus ? "/admin/bansos/statistik" : this.cekStatus
                          }
                        >
                          <i className="fa fa-database"></i> Data Statistik
                        </Link>
                      </div>
                    </div>
                    <Link to={ 
                      this.cekStatus ? "/admin/berita" : this.cekStatus
                      }>
                      <i className="icon-rss-alt"></i> Berita & Pengumuman
                    </Link>
                    {/* <Link to={ this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL + "/admin/banner" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'}>
                      <i className="icon-gallery"></i> Banner
                    </Link> */}
                    <Link to={ 
                      this.cekStatus ? "/admin/catatan" : this.cekStatus
                      }>
                      <i className="fa fa-edit"></i> Catatan
                    </Link>
                    <Link to={ 
                      this.cekStatus ? "/admin/wisata" : this.cekStatus
                      }>
                      <i className="fa fa-plane"></i> Wisata
                    </Link>
                    {/* <Link to={ this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL + "/admin/notif-desa" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'}>
                      <i className="fa fa-bullhorn"></i> Notifikasi Desa
                    </Link> */}
                  </div>
                </div>
              </div>
              <div className="dropdown">
                <div className="btn-group m-1">
                  <button
                    type="button"
                    className="dropbtn btn-primary-custom"
                    style={{ borderRadius: "10px", width: "155px" }}
                  >
                    <i className="fa fa-bar-chart"></i> Data Potensi
                    <span>
                      <i className="icofont icofont-arrow-down"></i>
                    </span>
                  </button>
                  <div className="dropdown-content">
                    <Link to={ 
                      this.cekStatus ? "/admin/data-potensi" : this.cekStatus
                      }>
                      <i className="icofont icofont-growth"></i> Grafik
                    </Link>
                    <Link
                      to={ 
                        this.cekStatus ? "/admin/laporan-potensi" : this.cekStatus
                      }
                    >
                      <i className="fa fa-files-o"></i> Laporan
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ul>
      </div>
    );
  }
}

export default SidebarAdmin;
