import React, { useContext } from 'react'
import Modal from 'react-responsive-modal';
import { Form, FormGroup, Label, Col,  } from 'reactstrap';
// import Datepicker from 'react-datepicker';
import Select from 'react-select';
// import { ID_DESA } from "../../function/Koneksi";


function EditPenyaksianTanah({ detail_surat_context }) {

  const detailSuratProps = useContext(detail_surat_context);

  const dateFormat = require('dateformat');

  return (
    <div>
      <Modal
        open={detailSuratProps.status.formUpdateSurat}
        onClose={detailSuratProps.tutupForm}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <Form className="theme-form" onSubmit={(e) => detailSuratProps.simpanUpdate(e, detailSuratProps.detailSurat.jenis_surat)}>
        <div className="modal-header">
            <h5 className="modal-title">Detail {detailSuratProps.detailSurat.jenis_surat}</h5>
          </div>
          <div className="modal-body">
            <p>
              Data identitas akan terisi secara otomatis saat NIK dipilih.
              Jika ada ketidak sesuaian data, silakan ubah di data master
              penduduk.
              </p>
            <input
              className="form-control"
              id="uuid"
              type="hidden"
              placeholder=""
              defaultValue={detailSuratProps.detailSurat.uuid}
            />
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                NIK
                </Label>
              <Col sm="9">
                <Select
                  classNamePrefix="select"
                  onChange={e => {
                    detailSuratProps.getPenduduk(e, detailSuratProps.detailSurat.jenis_surat, 'nik');
                  }}
                  defaultValue={detailSuratProps.dataNikStat.nikStat}
                  value={detailSuratProps.dataNikStat.nikStat}
                  name="NIK"
                  options={detailSuratProps.dataNik.frmNik}
                  placeholder="Pilih NIK"
                  onInputChange={e => detailSuratProps.handleInputChangeNIK(e, "nik")}
                  required={true}
                  isClearable
                />
              </Col>
            </FormGroup>
            <div style={{ display: detailSuratProps.status.dataPenduduk }}>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Nama Pemohon
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="nama"
                    type="text"
                    placeholder="Nama Pemohon"
                    value={detailSuratProps.detailSurat.nama_pemohon}
                    readOnly
                    style={{ backgroundColor:"#c9d1d8" }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tempat Lahir
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="tempat_lahir"
                    type="text"
                    placeholder="Tempat Lahir"
                    value={detailSuratProps.detailSurat.tempat_lahir}
                    readOnly
                    style={{ backgroundColor:"#c9d1d8" }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Lahir
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="tanggal_lahir"
                    type="text"
                    placeholder="Tanggal Lahir"
                    value={dateFormat(detailSuratProps.detailSurat.tanggal_lahir, 'dd/mm/yyyy')}
                    readOnly
                    style={{ backgroundColor:"#c9d1d8" }}
                  />
                </Col>
              </FormGroup>
            </div>
            <div style={{ display: "none" }}>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  KK
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="kk"
                    type="text"
                    placeholder="Nomor KK"
                    value={detailSuratProps.detailSurat.kk}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Jenis Kelamin
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="jenis_kelamin"
                    type="text"
                    placeholder="Jenis Kelamin"
                    value={detailSuratProps.detailSurat.jenis_kelamin}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Pekerjaan
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="pekerjaan"
                    type="text"
                    placeholder="Pekerjaan"
                    value={detailSuratProps.detailSurat.pekerjaan}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Pendidikan Terakhir
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="pendidikan_terakhir"
                    type="text"
                    placeholder="Pendidikan Terakhir"
                    value={detailSuratProps.detailSurat.pendidikan_terakhir}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Kewarganegaraan
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="kewarganegaraan"
                    type="text"
                    placeholder="Pekerjaan"
                    value={detailSuratProps.detailSurat.kewarganegaraan}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Status Perkawinan
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="status_perkawinan"
                    type="text"
                    placeholder="Status Perkawinan"
                    value={detailSuratProps.detailSurat.status_perkawinan}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Agama
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="agama"
                    type="text"
                    placeholder="Agama"
                    value={detailSuratProps.detailSurat.agama}
                    readOnly
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Alamat
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="alamat"
                    type="text"
                    placeholder="Alamat"
                    value={detailSuratProps.detailSurat.alamat}
                    readOnly
                  />
                </Col>
              </FormGroup>
            </div>

            
            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                Keperluan Untuk
              </Label>
              <Col sm="9">
                <textarea
                  className="form-control"
                  id="tujuan_pembuatan"
                  type="text"
                  placeholder="Keperluan Untuk"
                  defaultValue={detailSuratProps.detailSurat.tujuan_pembuatan}
                  required
                />
                <small> <font color='red'><b>Contoh:</b> Sebahagian/tanah Kebun tersebut dengan ukuran 2 Are dijadikan maskawin atas pernikahan antara Lk. Fulan dengan perempuan An. Fulanah Binti Dika.</font></small>
              </Col>
            </FormGroup>

            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                Jenis Tanah
              </Label>
              <Col sm="9">
                <input
                  className="form-control"
                  id="jenis_tanah"
                  type="text"
                  placeholder="Contoh: Perkebunan"
                  defaultValue={detailSuratProps.detailSurat.jenis_tanah}
                  required
                />
              </Col>
            </FormGroup>

            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                Lokasi Tanah
              </Label>
              <Col sm="9">
                <input
                  className="form-control"
                  id="lokasi_tanah"
                  type="text"
                  placeholder="Lokasi Tanah"
                  defaultValue={detailSuratProps.detailSurat.lokasi_tanah}
                  required
                />
              </Col>
            </FormGroup>

            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                Luas Tanah
              </Label>
              <Col sm="9">
                <input
                  className="form-control"
                  id="luas_tanah"
                  type="text"
                  placeholder="Luas Tanah"
                  defaultValue={detailSuratProps.detailSurat.luas_tanah}
                  required
                />
              </Col>
            </FormGroup>

            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                Batas Utara
              </Label>
              <Col sm="9">
                <input
                  className="form-control"
                  id="batas_utara"
                  type="text"
                  placeholder="Batas Utara"
                  defaultValue={detailSuratProps.detailSurat.batas_utara}
                  required
                />
              </Col>
            </FormGroup>

            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                Batas Timur
              </Label>
              <Col sm="9">
                <input
                  className="form-control"
                  id="batas_timur"
                  type="text"
                  placeholder="Batas Timur"
                  defaultValue={detailSuratProps.detailSurat.batas_timur}
                  required
                />
              </Col>
            </FormGroup>

            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                Batas Selatan
              </Label>
              <Col sm="9">
                <input
                  className="form-control"
                  id="batas_selatan"
                  type="text"
                  placeholder="Batas Selatan"
                  defaultValue={detailSuratProps.detailSurat.batas_selatan}
                  required
                />
              </Col>
            </FormGroup>

            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                Batas Barat
              </Label>
              <Col sm="9">
                <input
                  className="form-control"
                  id="batas_barat"
                  type="text"
                  placeholder="Batas Barat"
                  defaultValue={detailSuratProps.detailSurat.batas_barat}
                  required
                />
              </Col>
            </FormGroup>
            
            
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-warning"
              onClick={detailSuratProps.tutupForm}
            >
              Tutup
              </button>
            <button
              type="submit"
              className="btn btn-success"
            // disabled={detailSuratProps.status.btnForm}
            >
              Simpan
              </button>
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default EditPenyaksianTanah