import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Col,
} from 'reactstrap';
// import Datepicker from 'react-datepicker';
import Select from 'react-select';
import Datetime from 'react-datetime';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { Get, JENIS_DUSUN, Post } from '../../function/Koneksi';

const ModalSuratPenghasilanOrtu = ({ data, optionData, setAlert, isOpen, setIsOpen, statusRefresh, setStatusRefresh, propsData }) => {
	const dateFormat = require('dateformat');

	const frmDex = {
		uuid: "",
		id_jenis: "",
		nik: "",
		kk: "",
		nama_pemohon: "",
		tempat_lahir: "",
		tanggal_lahir: "",
		pekerjaan: "",
		pendidikan_terakhir: "",
		agama: "",
		status_perkawinan: "",
		jenis_kelamin: "",
		alamat: "",
		kewarganegaraan: "WNI",
		tujuan_pembuatan: "",
		nik_pelapor: "",
		detail_surat: {
			nik_ayah: "",
			nama_ayah: "",
			tempat_lahir_ayah: "",
			tanggal_lahir_ayah: "",
			pekerjaan_ayah: null,
			alamat_ayah: "",
			penghasilan_ayah: "",
			nik_ibu: "",
			nama_ibu: "",
			tempat_lahir_ibu: "",
			tanggal_lahir_ibu: "",
			pekerjaan_ibu: null,
			alamat_ibu: "",
			penghasilan_ibu: "",
			sekolah: ""
		}
	}

	const initFormData = {
		frmNik: optionData.frmNik,
		nikStat: null,
		frmNik2: optionData.frmNik,
		nik2Stat: null,
		frmNik3: optionData.frmNik,
		nik3Stat: null,
		listPekerjaan: optionData.listPekerjaan,
		formAyah: "none",
		formIbu: "none",
		status: {
			form: false,
			btnForm: false,
			btnAksi: [],
			dataPenduduk: "none",
			dataPenduduk2: "none",
			dataPenduduk3: "none"
		},
		show: false,
		basicTitle: "",
		basicType: "default",
		pesanAlert: "",
	}

	const [formData, setFormData] = useState(initFormData)
	const [props, setProps] = useState(propsData)
	const [dt, setDt] = useState(data ? data : frmDex)

	useEffect(() => {
		if (document.getElementById("modalForm")) {
			document.getElementById("modalForm").reset()
		}

		setDt(data ? data : frmDex)
		setFormData(data ? {
			...initFormData,
			nikStat: optionData.nikStat,
			nik2Stat: optionData.nik2Stat,
			nik3Stat: optionData.nik3Stat,
			formAyah: optionData.formAyah,
			formIbu: optionData.formIbu,
			status: optionData.status,
		} : initFormData)
	}, [isOpen])

	const handleInputChangeNIK = (e, fld) => {
		if (e) {
			let nikStat = { value: e, label: e };

			Post("penduduk", null, { nik: e }, datanik => {
				let frmNik = [];
				datanik.data.results.data.forEach(dt => {
					frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
				});

				switch (fld) {
					case "nik":
						setFormData({
							...formData,
							frmNik,
							nikStat
						})
						break;

					case "nik2":
						setFormData({
							...formData,
							frmNik2: frmNik,
							nik2Stat: nikStat
						})
						break;

					case "nik3":
						setFormData({
							...formData,
							frmNik3: frmNik,
							nik3Stat: nikStat
						})
						break;

					default:
						break;
				}

			});

		}
	};

	const changeInputText = (e, fld) => {
		switch (fld) {
			case "nik_ayah":
				setDt({
					...dt,
					detail_surat: {
						...dt.detail_surat,
						nik_ayah: e.target.value,
					},
				})
				break;
			case "nama_ayah":
				setDt({
					...dt,
					detail_surat: {
						...dt.detail_surat,
						nama_ayah: e.target.value,
					},
				})
				break;
			case "tempat_lahir_ayah":
				setDt({
					...dt,
					detail_surat: {
						...dt.detail_surat,
						tempat_lahir_ayah: e.target.value,
					},
				})
				break;
			case "pekerjaan_ayah":
				setDt({
					...dt,
					detail_surat: {
						...dt.detail_surat,
						pekerjaan_ayah: {
							label: e.target.value,
							value: e.target.value,
						},
					},
				})
				break;
			case "alamat_ayah":
				setDt({
					...dt,
					detail_surat: {
						...dt.detail_surat,
						alamat_ayah: e.target.value,
					},
				})
				break;
			case "penghasilan_ayah":
				setDt({
					...dt,
					detail_surat: {
						...dt.detail_surat,
						penghasilan_ayah: e.target.value,
					},
				})
				break;
			case "nik_ibu":
				setDt({
					...dt,
					detail_surat: {
						...dt.detail_surat,
						nik_ibu: e.target.value,
					},
				})
				break;
			case "nama_ibu":
				setDt({
					...dt,
					detail_surat: {
						...dt.detail_surat,
						nama_ibu: e.target.value,
					},
				})
				break;
			case "tempat_lahir_ibu":
				setDt({
					...dt,
					detail_surat: {
						...dt.detail_surat,
						tempat_lahir_ibu: e.target.value,
					},
				})
				break;
			case "pekerjaan_ibu":
				setDt({
					...dt,
					detail_surat: {
						...dt.detail_surat,
						pekerjaan_ibu: {
							label: e.target.value,
							value: e.target.value,
						},
					},
				})
				break;
			case "alamat_ibu":
				setDt({
					...dt,
					detail_surat: {
						...dt.detail_surat,
						alamat_ibu: e.target.value,
					},
				})
				break;
			case "penghasilan_ibu":
				setDt({
					...dt,
					detail_surat: {
						...dt.detail_surat,
						penghasilan_ibu: e.target.value,
					},
				})
				break;
			default:
				// no default
				break;
		}
	};

	const gantiTgl = (date, fld) => {
		switch (fld) {
			case "tanggal_lahir_ayah":
				if (typeof date._isValid !== 'undefined') {
					let tgl = date.format('YYYY-MM-DD');
					setDt({
						...dt,
						detail_surat: {
							...dt.detail_surat,
							tanggal_lahir_ayah: new Date(tgl),
						},
					})
				}
				break;
			case "tanggal_lahir_ibu":
				if (typeof date._isValid !== 'undefined') {
					let tgl = date.format('YYYY-MM-DD');
					setDt({
						...dt,
						detail_surat: {
							...dt.detail_surat,
							tanggal_lahir_ibu: new Date(tgl),
						},
					})
				}
				break;
			default:
				// no default
				break;
		}
	};

	const getPenduduk = (e, sel, jns) => {
		if (e) {
			// this.changeSelectValue(e, sel);
			Get("penduduk/find", e.value, data => {
				console.log(data)
				let alamat = '';
				if (localStorage.getItem('JENIS_DESA').toLowerCase() === 'kelurahan') {
					alamat =
						"RT. " +
						data.results.data_kk.data_rt.nama +
						"/RW. " +
						data.results.data_kk.data_rt.data_rw.nama +
						", " +
						data.results.data_kk.data_rt.data_rw.data_keldes.nama +
						", Kec. " +
						data.results.data_kk.data_rt.data_rw.data_keldes
							.data_kecamatan.nama +
						", " +
						data.results.data_kk.data_rt.data_rw.data_keldes
							.data_kecamatan.data_kabkota.nama;
				} else {
					if (data.results.data_kk.id_rt === '0') {
						alamat = localStorage.getItem('JENIS_DESA') + " " + data.results.data_keldes.nama +
							", Kec. " + data.results.data_keldes.data_kecamatan.nama +
							", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
							", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama;
					} else {
						alamat =
							" RT. " +
							data.results.data_kk.data_rt.nama +
							"/RW. " +
							data.results.data_kk.data_rt.data_rw.nama +
							", " + JENIS_DUSUN + " " +
							data.results.data_kk.data_rt.data_rw.data_dusun.nama +
							", " + localStorage.getItem('JENIS_DESA') + " " +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
							", Kec. " +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
								.data_kecamatan.nama +
							", " +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
								.data_kecamatan.data_kabkota.nama;
					}
				}

				if (jns === "pelapor") {
					setFormData({
						...formData,
						nik2Stat: e,
						status: {
							...formData.status,
							dataPenduduk2: "block",
						},
					})
					setDt({
						...dt,
						nik: e.value,
						nik_pelapor: e.value,
						kk: data.results.id_kk,
						nama_pemohon: data.results.nama,
						tempat_lahir: data.results.tempat_lahir,
						tanggal_lahir: new Date(
							data.results.tanggal_lahir
						),
						pekerjaan: data.results.data_pekerjaan.nama,
						pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
						agama: data.results.data_agama.nama,
						status_perkawinan: data.results.status_perkawinan,
						jenis_kelamin: data.results.jk,
						alamat: alamat,
						kewarganegaraan: 'WNI',
					})

					// For testing
					if (props.selectOnChange2) {
						props.selectOnChange2(this.state.dt)
					}
				} else {
					if (jns === "ibu") {
						setFormData({
							...formData,
							nik3Stat: e,
							status: {
								...formData.status,
								dataPenduduk3: "block"
							}
						})
						setDt({
							...dt,
							detail_surat: {
								...dt.detail_surat,
								nama_ibu: data.results.nama,
								tempat_lahir_ibu: data.results.tempat_lahir,
								tanggal_lahir_ibu: new Date(
									data.results.tanggal_lahir
								),
								pekerjaan_ibu: {
									value: data.results.data_pekerjaan.nama,
									label: data.results.data_pekerjaan.nama,
								},
								alamat_ibu: alamat,
							},
						})

						// For testing
						if (props.selectOnChange3) {
							props.selectOnChange3(this.state.dt)
						}
					} else { // Nik ayah
						// TANPA PENGECEKAN CATATAN PENDUDUK
						setFormData({
							...formData,
							nikStat: e,
							status: {
								...formData.status,
								dataPenduduk: "block"
							}
						})
						setDt({
							...dt,
							detail_surat: {
								...dt.detail_surat,
								nama_ayah: data.results.nama,
								tempat_lahir_ayah: data.results.tempat_lahir,
								tanggal_lahir_ayah: new Date(
									data.results.tanggal_lahir
								),
								pekerjaan_ayah: {
									value: data.results.data_pekerjaan.nama,
									label: data.results.data_pekerjaan.nama,
								},
								alamat_ayah: alamat,
							},
						})
					}
				}
			});
		} else {
			switch (jns) {
				case "pelapor":
					setDt({
						...dt,
						nama_pemohon: '',
						tempat_lahir: '',
						tanggal_lahir: null,
						pekerjaan: '',
						pendidikan_terakhir: '',
						agama: '',
						status_perkawinan: '',
						jenis_kelamin: '',
						alamat: '',
					})
					setFormData({
						...formData,
						status: {
							...formData.status,
							dataPenduduk2: "none"
						},
						nik2Stat: null
					})
					break;

				case "ibu":
					setDt({
						...dt,
						detail_surat: {
							...dt.detail_surat,
							nama_ibu: '',
							tempat_lahir_ibu: '',
							tanggal_lahir_ibu: null,
							pekerjaan_ibu: null,
							alamat_ibu: '',
						},
					})
					setFormData({
						...formData,
						status: {
							...formData.status,
							dataPenduduk3: "none"
						},
						nik3Stat: null
					})
					break;

				case "ayah":
					setDt({
						...dt,
						detail_surat: {
							...dt.detail_surat,
							nama_ayah: '',
							tempat_lahir_ayah: '',
							tanggal_lahir_ayah: null,
							pekerjaan_ayah: null,
							alamat_ayah: '',
						},
					})
					setFormData({
						...formData,
						status: {
							...formData.status,
							dataPenduduk: "none"
						},
						nikStat: null
					})
					break;

				default:
					break;
			}
		}
	};

	const simpan = (e) => {
		e.preventDefault();
		console.log('tesdfs');
		console.log(formData);

		let bodyRaw = {
			...dt,
			// tanggal_lahir: dateFormat(new Date(dt.tanggal_lahir), 'yyyy-mm-dd HH:MM:ss'),
			tanggal_lahir: dateFormat(new Date(dt.tanggal_lahir), 'yyyy-mm-dd'),
			tujuan_pembuatan: document.getElementById('tujuan_pembuatan').value,
			detail_surat: {
				...dt.detail_surat,
				nik_ayah: formData.nikStat ? formData.nikStat.value : dt.detail_surat.nik_ayah,
				nik_ibu: formData.nik3Stat ? formData.nik3Stat.value : dt.detail_surat.nik_ibu,
				tanggal_lahir_ayah: dateFormat(dt.detail_surat.tanggal_lahir_ayah, 'yyyy-mm-dd'),
				tanggal_lahir_ibu: dateFormat(dt.detail_surat.tanggal_lahir_ibu, 'yyyy-mm-dd'),
				penghasilan_ayah: document.getElementById('penghasilan_ayah').value.replace(/[^\d]/g, ''),
				penghasilan_ibu: document.getElementById('penghasilan_ibu').value.replace(/[^\d]/g, ''),
				pekerjaan_ayah: dt.detail_surat.pekerjaan_ayah.label,
				pekerjaan_ibu: dt.detail_surat.pekerjaan_ibu.label,
				sekolah: document.getElementById('sekolah').value
			}
		}
		if (dt.uuid === '') {
			bodyRaw.id_jenis = props.location.state.uuid
		}
		console.log(bodyRaw);

		let validasiPelapor = formData.nik2Stat;
		let validasiAyah = false;
		let validasiIbu = false;

		// validasi ayah
		// Jika input nik ayah otomatis
		if (formData.formAyah === "none") {
			if (formData.nikStat) {
				validasiAyah = true;
			}
		} else {  // jika input nik ayah manual
			if (dt.detail_surat.pekerjaan_ayah !== null) {
				validasiAyah = true;
			}
		}

		// validasi ibu
		// Jika input nik ibu otomatis
		if (formData.formIbu === "none") {
			if (formData.nik3Stat) {
				validasiIbu = true;
			}
		} else {  // jika input nik ibu manual
			if (dt.pekerjaan_ibu !== null) {
				validasiIbu = true;
			}
		}

		// if (formData.formAyah !== "none" && dt.detail_surat.nik_ayah.toString().length !== 16) {
		// 	this.setState({
		// 		show: true,
		// 		basicType: "warning",
		// 		basicTitle: "Data Keterangan Penghasilan Orang Tua",
		// 		pesanAlert: "NIK Ayah tidak terdiri dari 16 angka"
		// 	});
		// } else if (formData.formIbu !== "none" && dt.detail_surat.nik_ibu.toString().length !== 16) {
		// 	this.setState({
		// 		show: true,
		// 		basicType: "warning",
		// 		basicTitle: "Data Keterangan Penghasilan Orang Tua",
		// 		pesanAlert: "NIK Ibu tidak terdiri dari 16 angka"
		// 	});
		// } else 
		if (
			validasiPelapor &&
			validasiAyah &&
			validasiIbu
		) {

			//console.log("Data Simpan Pindah Nikah")
			//console.log(bodyRaw)
			console.log(bodyRaw);

			let psn = "";
			let resstat = 204;
			let metode = "store";
			if (bodyRaw.uuid === "") {
				psn = "Tambah";
				resstat = 201;
				bodyRaw.uuid = null;
			} else {
				psn = "Ubah";
				resstat = 200;
				metode = "update";
			}
			Post(
				"surat-keterangan/new-format/" + metode,
				bodyRaw.uuid,
				bodyRaw,
				res => {

					// For testing
					if (props.resSubmit) {
						props.resSubmit(res.status)
					}

					// this.tutupForm();
					setFormData({
						...formData,
						status: {
							...formData.status,
							btnForm: false,
							form: false,
						}
					})
					setIsOpen(false)
					if (res.status === resstat) {
						const alertData = {
							show: true,
							basicType: "success",
							basicTitle: "Data Surat Keterangan Penghasilan Orang Tua",
							pesanAlert: "Berhasil " + psn + " Data",
							successAlert: true,
						}
						setAlert(alertData)
					} else {
						const alertData = {
							show: true,
							basicType: "danger",
							basicTitle: "Data Surat Keterangan Penghasilan Orang Tua",
							pesanAlert: "Gagal " + psn + " Data"
						}
						setAlert(alertData)
					}

					// Refresh list
					setStatusRefresh(true)
				}
			);
		} else {
			const alertData = {
				show: true,
				basicType: "info",
				basicTitle: "Data Belum Lengkap",
				pesanAlert: "Mohon isi seluruh data dengan benar"
			}
			setAlert(alertData)
		}
	};

	return (
		<Modal
			open={isOpen}
			onClose={() => {
				setIsOpen(false)
			}}
			// classNames="modal-custom"
			closeOnEsc={false}
			closeOnOverlayClick={true}
			style={{
				maxwidth: 'unset'
			}}
		>
			<Form
				id="modalForm"
				data-testid="form-create"
				className="theme-form"
				onSubmit={simpan}
			>
				<div className="modal-header">
					<h5 className="modal-title">{dt.uuid == '' ? 'Tambah' : 'Edit'} Surat Keterangan Penghasilan Orang Tua</h5>
				</div>
				<div className="modal-body">
					<p>
						Data identitas akan terisi secara otomatis saat NIK dipilih.
						Jika ada ketidak sesuaian data, silakan ubah di data master
						penduduk.
					</p>
					<input
						className="form-control"
						id="uuid"
						type="hidden"
						placeholder=""
						defaultValue={dt.uuid}
					/>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="nik2"
						>
							NIK Pelapor
						</Label>
						<Col sm="9">
							<div data-testid="selectNIK2">
								<Select
									inputId="nik2"
									classNamePrefix="select"
									onChange={e => {
										getPenduduk(e, "nik2", "pelapor");
									}}
									defaultValue={formData.nik2Stat}
									value={formData.nik2Stat}
									name="NIK2"
									options={formData.frmNik2}
									placeholder="Pilih NIK Pelapor"
									onInputChange={e => handleInputChangeNIK(e, "nik2")}
									isClearable
								/>
							</div>
						</Col>
					</FormGroup>
					<div style={{ display: formData.status ? formData.status.dataPenduduk2 : "none" }}>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Nama Pelapor
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="nama_pelapor"
									type="text"
									placeholder="Nama Pelapor"
									value={dt.nama_pemohon}
									readOnly
									style={{ backgroundColor: "#c9d1d8" }}
									disabled
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Tempat Lahir
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="tempat_lahir_pelapor"
									type="text"
									placeholder="Tempat Lahir"
									value={dt.tempat_lahir}
									readOnly
									style={{ backgroundColor: "#c9d1d8" }}
									disabled
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Tanggal Lahir
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="tanggal_lahir_pelapor"
									type="text"
									placeholder="Tanggal Lahir"
									value={new Date(dt.tanggal_lahir).toLocaleDateString("id-ID")}
									readOnly
									style={{ backgroundColor: "#c9d1d8" }}
									disabled
								/>
							</Col>
						</FormGroup>
					</div>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="inputEmail3"
						>
							Tujuan Pembuatan
						</Label>
						<Col sm="9">
							<input
								data-testid="tujuan pembuatan"
								name="tujuan_pembuatan"
								className="form-control"
								id="tujuan_pembuatan"
								type="text"
								placeholder="Contoh : Beasiswa"
								defaultValue={dt.tujuan_pembuatan}
								// value={dt.tujuan_pembuatan}
								onChange={e => changeInputText(e, "tujuan_pembuatan")}
								required
							/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="nik"
						>
							NIK Ayah
						</Label>
						<Col sm="9">
							{formData.formAyah === "none" ? (
								<div data-testid="selectNIK">
									<Select
										inputId="nik"
										classNamePrefix="select"
										onChange={e => {
											getPenduduk(e, "nik", "ayah");
										}}
										defaultValue={formData.nikStat}
										value={formData.nikStat}
										name="NIK"
										options={formData.frmNik}
										placeholder="Pilih NIK"
										onInputChange={e => handleInputChangeNIK(e, "nik")}
										isClearable
									/>
								</div>
							) : (
								<input
									data-testid="nik_ayah"
									name="nik_ayah"
									className="form-control"
									id="nik_ayah"
									type={formData.formAyah === "none" ? "hidden" : "number"}
									placeholder="Nik ayah"
									defaultValue={dt.detail_surat.nik_ayah}
									value={dt.detail_surat.nik_ayah}
									required
									onChange={e => changeInputText(e, "nik_ayah")}
								/>
							)}

							<small id="emailHelp" className="form-text text-muted">
								Data NIK ayah {formData.formAyah === "none" ? "tidak" : ""}{" "}
								tersedia?{" "}
								<Button
									// to={{
									// 	pathname: props.location.pathname,
									// 	state: props.location.state
									// }}
									className="btn btn-sm"
									data-testid="btnInputManual"
									onClick={() => {
										if (formData.formAyah === "none") {
											if (props.getJenisInput) {
												props.getJenisInput("manual")
											}
										}
										else {
											if (props.getJenisInput) {
												props.getJenisInput("auto")
											}
										}
										setDt({
											...dt,
											detail_surat: {
												...dt.detail_surat,
												nama_ayah: "",
												tempat_lahir_ayah: "",
												tanggal_lahir_ayah: "",
												pekerjaan_ayah: null,
												alamat_ayah: "",
											}
										})
										setFormData({
											...formData,
											status: {
												...formData.status,
												dataPenduduk: formData.formAyah === "" ? "none" : "",
											},
											formAyah: formData.formAyah === "" ? "none" : "",
											nikStat: null
										})
									}}
								>
									{" "}
									Klik disini
								</Button>
							</small>
						</Col>
					</FormGroup>

					<div style={{ display: formData.status ? formData.status.dataPenduduk : "none" }}>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Nama Ayah
							</Label>
							<Col sm="9">
								<input
									data-testid="nama_ayah"
									name="nama_ayah"
									className="form-control"
									id="nama_ayah"
									type="text"
									placeholder="Nama Ayah"
									value={dt.detail_surat.nama_ayah}
									//   readOnly
									readOnly={formData.formAyah === "" ? false : true}
									required
									onChange={e => changeInputText(e, "nama_ayah")}

									style={formData.formAyah === "" ? { backgroundColor: "" } : { backgroundColor: "#c9d1d8" }}
									disabled={formData.formAyah === "" ? false : true}
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Tempat Lahir Ayah
							</Label>
							<Col sm="9">
								<input
									data-testid="tempat_lahir_ayah"
									name="tempat_lahir_ayah"
									className="form-control"
									id="tempat_lahir_ayah"
									type="text"
									placeholder="Tempat Lahir Ayah"
									value={dt.detail_surat.tempat_lahir_ayah}
									onChange={e =>
										changeInputText(e, "tempat_lahir_ayah")
									}
									readOnly={formData.formAyah === "" ? false : true}
									required
									style={formData.formAyah === "" ? { backgroundColor: "" } : { backgroundColor: "#c9d1d8" }}
									disabled={formData.formAyah === "" ? false : true}
								//   readOnly
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Tanggal Lahir Ayah
							</Label>
							<Col sm="9">
								{formData.formAyah === '' ?
									(<Datetime
										inputProps={{ "data-testid": "tanggal_lahir_ayah", placeholder: 'Tanggal Lahir Ayah', required: true }}
										inputId="tanggal_lahir_ayah"
										name="tanggal_lahir_ayah"
										locale="id-ID"
										timeFormat={false}
										dateFormat="DD/MM/yyyy"
										input={true}
										closeOnTab={true}
										closeOnSelect={true}
										initialValue={dt.detail_surat.tanggal_lahir_ayah}
										value={dt.detail_surat.tanggal_lahir_ayah}
										onChange={e => {
											gantiTgl(e, 'tanggal_lahir_ayah')
										}}
									/>)
									:
									(<input
										className="form-control"
										id="tanggal_lahir_ayah"
										//   type="text"
										type={formData.formAyah === "" ? "date" : "text"}
										placeholder="Tanggal Lahir"
										// value={dt.tanggal_lahir_ayah}
										value={dateFormat(dt.detail_surat.tanggal_lahir_ayah, 'dd/mm/yyyy')}
										//   readOnly
										readOnly={formData.formAyah === "" ? false : true}
										required
										onChange={e =>
											changeInputText(e, "tanggal_lahir_ayah")
										}
										style={formData.formAyah === "" ? { backgroundColor: "" } : { backgroundColor: "#c9d1d8" }}
										disabled={formData.formAyah === "" ? false : true}
									/>)
								}
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Pekerjaan Ayah
							</Label>
							<Col sm="9">
								{formData.formAyah !== "none" ? (
									<div data-testid="selectPekerjaanAyah">
										<Select
											inputId="pekerjaan_ayah"
											classNamePrefix="select"
											onChange={e => {
												console.log(e);
												if (e) {
													setDt({
														...dt,
														detail_surat: {
															...dt.detail_surat,
															pekerjaan_ayah: e
														}
													})
												}
											}}
											defaultValue={dt.detail_surat.pekerjaan_ayah}
											value={dt.detail_surat.pekerjaan_ayah}
											name="Pekerjaan Ayah"
											options={optionData.listPekerjaan}
											placeholder="Pilih Pekerjaan Ayah"
											// onInputChange={e => handleInputChangeNIK(e, "nik")}
											isClearable
										/>
									</div>
								) : (
									<input
										data-testid="pekerjaan_ayah"
										name="pekerjaan_ayah"
										className="form-control"
										id="pekerjaan_ayah"
										type="text"
										placeholder="Pekerjaan Ayah"
										defaultValue={dt.detail_surat.pekerjaan_ayah ? dt.detail_surat.pekerjaan_ayah.label : ''}
										onChange={e =>
											changeInputText(e, "pekerjaan_ayah")
										}
										readOnly={formData.formAyah === "" ? false : true}
										required
										style={formData.formAyah === "" ? { backgroundColor: "" } : { backgroundColor: "#c9d1d8" }}
										disabled={formData.formAyah === "" ? false : true}
									/>
								)}
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Alamat Ayah
							</Label>
							<Col sm="9">
								<input
									data-testid="pekerjaan_ayah"
									name="alamat_ayah"
									className="form-control"
									id="alamat_ayah"
									type="text"
									placeholder="Alamat Ayah"
									value={dt.detail_surat.alamat_ayah}
									onChange={e =>
										changeInputText(e, "alamat_ayah")
									}
									readOnly={formData.formAyah === "" ? false : true}
									required
									style={formData.formAyah === "" ? { backgroundColor: "" } : { backgroundColor: "#c9d1d8" }}
									disabled={formData.formAyah === "" ? false : true}
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Penghasilan Ayah
							</Label>
							<Col sm="9">
								<NumberFormat
									data-testid="penghasilan ayah"
									id="penghasilan_ayah"
									name="penghasilan_ayah"
									placeholder="Contoh : 1.500.000"
									thousandSeparator={true}
									prefix={'Rp. '}
									className="form-control"
									defaultValue={dt.detail_surat.penghasilan_ayah}
									value={dt.detail_surat.penghasilan_ayah}
									onChange={e => changeInputText(e, "penghasilan_ayah")}
									required={formData.status && formData.status.dataPenduduk !== 'none'}
								/>
							</Col>
						</FormGroup>
					</div>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="nik3"
						>
							NIK Ibu
						</Label>
						<Col sm="9">
							{formData.formIbu === "none" ? (
								<div data-testid="selectNIK3">
									<Select
										inputId="nik3"
										classNamePrefix="select"
										onChange={e => {
											getPenduduk(e, "nik3", "ibu");
										}}
										defaultValue={formData.nik3Stat}
										value={formData.nik3Stat}
										name="NIK3"
										options={formData.frmNik3}
										placeholder="Pilih NIK IBU"
										onInputChange={e => handleInputChangeNIK(e, "nik3")}
										isClearable
									/>
								</div>
							) : (
								<input
									data-testid="nik_ibu"
									name="nik_ibu"
									className="form-control"
									id="nik_ibu"
									type={formData.formIbu === "none" ? "hidden" : "number"}
									placeholder="Nik Ibu"
									defaultValue={dt.detail_surat.nik_ibu}
									value={dt.detail_surat.nik_ibu}
									onChange={e => changeInputText(e, "nik_ibu")}
									required
								/>
							)}
							<small class="form-text text-muted">
								{" "}
								Data NIK ibu {formData.formIbu === "none"
									? "tidak"
									: ""}{" "}
								tersedia?{" "}
								<Button
									className="btn btn-sm"
									data-testid="btnInputManual2"
									onClick={() => {
										if (formData.formIbu === "none") {
											if (props.getJenisInput2) {
												props.getJenisInput2("manual")
											}
										}
										else {
											if (props.getJenisInput2) {
												props.getJenisInput2("auto")
											}
										}
										setDt({
											...dt,
											detail_surat: {
												...dt.detail_surat,
												nama_ibu: "",
												tempat_lahir_ibu: "",
												tanggal_lahir_ibu: "",
												pekerjaan_ibu: null,
												alamat_ibu: "",
											}
										})
										setFormData({
											...formData,
											status: {
												...formData.status,
												dataPenduduk3: formData.formIbu === "" ? "none" : "",
											},
											formIbu: formData.formIbu === "" ? "none" : "",
											nik3Stat: null
										})
									}}
								>
									{" "}
									Klik disini
								</Button>
							</small>
						</Col>
					</FormGroup>
					<div style={{ display: formData.status ? formData.status.dataPenduduk3 : "none" }}>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Nama Ibu
							</Label>
							<Col sm="9">
								<input
									data-testid="nama_ibu"
									name="nama_ibu"
									className="form-control"
									id="nama_ibu"
									type="text"
									placeholder="Nama Ibu"
									//   value={dt.nama_ibu}
									defaultValue={dt.detail_surat.nama_ibu}
									value={dt.detail_surat.nama_ibu}
									onChange={e => changeInputText(e, "nama_ibu")}
									readOnly={formData.formIbu === "" ? false : true}
									required
									style={formData.formIbu === "" ? { backgroundColor: "" } : { backgroundColor: "#c9d1d8" }}
									disabled={formData.formIbu === "" ? false : true}
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Tempat Lahir Ibu
							</Label>
							<Col sm="9">
								<input
									data-testid="tempat_lahir_ibu"
									name="tempat_lahir_ibu"
									className="form-control"
									id="tempat_lahir_ibu"
									type="text"
									placeholder="Tempat Lahir Ibu"
									defaultValue={dt.detail_surat.tempat_lahir_ibu}
									value={dt.detail_surat.tempat_lahir_ibu}
									onChange={e =>
										changeInputText(e, "tempat_lahir_ibu")
									}
									readOnly={formData.formIbu === "" ? false : true}
									required
									style={formData.formIbu === "" ? { backgroundColor: "" } : { backgroundColor: "#c9d1d8" }}
									disabled={formData.formIbu === "" ? false : true}
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Tanggal Lahir Ibu
							</Label>
							<Col sm="9">
								{formData.formIbu === '' ?
									(<Datetime
										inputProps={{ "data-testid": "tanggal_lahir_ibu", placeholder: 'Tanggal Lahir Ibu', required: true }}
										inputId="tanggal_lahir_ibu"
										name="tanggal_lahir_ibu"
										locale="id-ID"
										timeFormat={false}
										dateFormat="DD/MM/yyyy"
										input={true}
										closeOnTab={true}
										closeOnSelect={true}
										initialValue={dt.detail_surat.tanggal_lahir_ibu}
										value={dt.detail_surat.tanggal_lahir_ibu}
										onChange={e => {
											gantiTgl(e, 'tanggal_lahir_ibu')
										}}
									/>)
									:
									(<input
										className="form-control"
										id="tanggal_lahir_ibu"
										type={formData.formIbu === "" ? "date" : "text"}
										placeholder="Tanggal Lahir"
										// value={dt.detail_surat.tanggal_lahir_ibu}
										value={dateFormat(dt.detail_surat.tanggal_lahir_ibu, 'dd/mm/yyyy')}
										//   readOnly
										onChange={e =>
											changeInputText(e, "tanggal_lahir_ibu")
										}
										defaultValue={dt.detail_surat.tanggal_lahir_ibu}
										readOnly={formData.formIbu === "" ? false : true}
										required
										style={formData.formIbu === "" ? { backgroundColor: "" } : { backgroundColor: "#c9d1d8" }}
										disabled={formData.formIbu === "" ? false : true}
									/>)
								}

							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Pekerjaan Ibu
							</Label>
							<Col sm="9">
								{formData.formIbu !== "none" ? (
									<div data-testid="selectPekerjaanIbu">
										<Select
											inputId="pekerjaan_ibu"
											classNamePrefix="select"
											onChange={e => {
												console.log(e);
												if (e) {
													setDt({
														...dt,
														detail_surat: {
															...dt.detail_surat,
															pekerjaan_ibu: e
														}
													})
												}
											}}
											defaultValue={dt.detail_surat.pekerjaan_ibu}
											value={dt.detail_surat.pekerjaan_ibu}
											name="Pekerjaan Ibu"
											options={optionData.listPekerjaan}
											placeholder="Pilih Pekerjaan Ibu"
											isClearable
										/>
									</div>
								) : (
									<input
										data-testid="pekerjaan_ibu"
										name="pekerjaan_ibu"
										className="form-control"
										id="pekerjaan_ibu"
										type="text"
										placeholder="Pekerjaan Ibu"
										defaultValue={dt.detail_surat.pekerjaan_ibu ? dt.detail_surat.pekerjaan_ibu.label : ''}
										onChange={e =>
											changeInputText(e, "pekerjaan_ibu")
										}
										readOnly={formData.formIbu === "" ? false : true}
										required
										style={formData.formIbu === "" ? { backgroundColor: "" } : { backgroundColor: "#c9d1d8" }}
										disabled={formData.formIbu === "" ? false : true}
									/>
								)}
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Alamat Ibu
							</Label>
							<Col sm="9">
								<input
									data-testid="pekerjaan_ibu"
									name="alamat_ibu"
									className="form-control"
									id="alamat_ibu"
									type="text"
									placeholder="Alamat Ibu"
									value={dt.detail_surat.alamat_ibu}
									onChange={e =>
										changeInputText(e, "alamat_ibu")
									}
									readOnly={formData.formIbu === "" ? false : true}
									required
									style={formData.formIbu === "" ? { backgroundColor: "" } : { backgroundColor: "#c9d1d8" }}
									disabled={formData.formIbu === "" ? false : true}
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Penghasilan Ibu
							</Label>
							<Col sm="9">
								<NumberFormat
									data-testid="penghasilan ibu"
									id="penghasilan_ibu"
									name="penghasilan_ibu"
									placeholder="Contoh : 1.500.000"
									thousandSeparator={true}
									prefix={'Rp. '}
									className="form-control"
									defaultValue={dt.detail_surat.penghasilan_ibu}
									value={dt.detail_surat.penghasilan_ibu}
									required={formData.status && formData.status.dataPenduduk3 !== 'none'}
								/>
							</Col>
						</FormGroup>
					</div>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="inputEmail3"
						>
							Nama Sekolah / Universitas
						</Label>
						<Col sm="9">
							<input
								data-testid="sekolah"
								name="sekolah"
								className="form-control"
								id="sekolah"
								type="text"
								placeholder="Contoh : SDN 013 Pekanbaru"
								defaultValue={dt.detail_surat.sekolah}
								// value={dt.detail_surat.sekolah}
								onChange={e => changeInputText(e, "sekolah")}
								required
							/>
						</Col>
					</FormGroup>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-warning"
						onClick={() => {
							// Tutup modal
							setFormData({
								...formData,
								status: {
									...formData.status,
									form: false
								}
							})
							setIsOpen(false)
						}}
					>
						Tutup
					</button>
					<button
						data-testid="btnSubmitCreate"
						type="submit"
						className="btn btn-primary-custom"
						disabled={formData.status && formData.status.btnForm}
					>
						Simpan
					</button>
				</div>
			</Form>
		</Modal>
	);
}

export default ModalSuratPenghasilanOrtu;
