import React, { Component } from 'react';

// import Custom Componenets
// import Breadcrumb from '../components/common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
// 	PaginationProvider,
// 	PaginationTotalStandalone,
// 	PaginationListStandalone
// } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
// import Dropzone from 'react-dropzone';
// import Resizer from 'react-image-file-resizer';
// import axios from 'axios';

// import Select from 'react-select';

// koneksi
import { Post, Delete, Get, } from '../function/Koneksi';
import { DataToken } from '../function/Format';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import axios from 'axios';

import L from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],  
	iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const frmDex = {
	uuid: '',
	nama: '',
	deskripsi: "",
	kumpulan_foto: [],
	alamat: "",
	lat: 0,
	long: 0,
	url_maps: ""
}

class Wisata extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			data_cek: '',
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			tmpImages: [],
			loading: false,
			loadingPreview: false,
			imagePreview: null,
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			dt: frmDex,
			curStaticLocation: [0, 0],
			currentLocation: [0, 0],
			judul: 'Tambah Banner Desa',
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			editMode: false
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	pagination = data => {
		// const data = this.state.pagination;
		//console.log(data);
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "primary-custom";
				if (i === data.current_page) {
					warna = "danger";
				}
				hal.push(
					<a
						onClick={() => this.fetch({ page: i })}
						className={
							"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
						}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						onClick={() => this.fetch({ page: p })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{"<"}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						onClick={() => this.fetch({ page: n })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{">"}
					</a>
				);
			}
			awal = (
				<a
					onClick={() => this.fetch({ page: 1 })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{"<<"}
				</a>
			);
			akhir = (
				<a
					onClick={() => this.fetch({ page: data.last_page })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{">>"}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};

	fetch = (params = {}) => {
		this.setState({ loading: true })
		let link = "";
		if (params.page) {
			switch (params.page) {
				case ">>":
					link =
						"?page=" +
						Math.ceil(
							this.state.pagination.total / this.state.pagination.per_page
						);
					break;
				case ">":
					link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case "<":
					link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case "<<":
					link = "?page=1";
					break;
				default:
					link = "?page=" + params.page;
					break;
			}
		}
		Get("wisata" + link, null, dtkat => {
			// console.log(dtkat);

			if (dtkat.results) {
				this.setState({
					data: dtkat.results.data,
					pagination: dtkat.results,
					loading: false
				})
				this.pagination(dtkat.results);
			}

		});
	};

	uploadImages() {
		return Promise.all(this.state.tmpImages.map((obj, i) => {
			const selectedFile = document.getElementById('foto-' + (i + 1)).files[0]

			let formData = new FormData()
			formData.append("files", selectedFile)

			return this.postImage(formData)
		}))
			.then((results) => {
				// console.log(results);
				return results
			})
			.catch((err) => {
				// console.log(err)
				return []
			})
	}

	async postImage(formData) {
		let datatable = await axios.post(`${localStorage.getItem('SET_API_URL') + 'upload-file/wisata'}`, formData, {
			headers: { Authorization: `Bearer ${sessionStorage.getItem("access_token")}` },
			validateStatus: false
		});
		return datatable
	}

	// ================== simpan data ==================
	simpan = ((e) => {
		e.preventDefault();

		this.setState({ loadingPreview: true })

		this.uploadImages().then((res) => {
			// console.log(res);
			let getImages = []

			res.map((val, i) => {
				if (val.status === 200) {
					getImages.push(val.data.results)
				}
			})

			// console.log(getImages);

			let bodyData = {
				uuid: document.getElementById("uuid").value,
				nama: document.getElementById('nama').value,
				deskripsi: document.getElementById('deskripsi').value,
				kumpulan_foto: JSON.stringify(getImages),
				alamat: document.getElementById('alamat').value,
				lat: this.state.dt.lat,
				long: this.state.dt.long,
				url_maps: document.getElementById('url_maps').value
			}

			let psn = "";
			let resstat = 204;
			let metode = "create";
			if (bodyData.uuid === "") {
				psn = "tambah";
				resstat = 201;
				bodyData.uuid = null;
			} else {
				psn = "ubah";
				resstat = 200;
				metode = "update";

				let keepImages = this.state.dt.kumpulan_foto

				if (getImages.length > 0) {
					getImages.map((obj) => {
						keepImages.push(obj)
					})
				}
				// console.log(keepImages);
				bodyData.kumpulan_foto = JSON.stringify(keepImages)
			}

			// console.log(bodyData);

			Post('wisata/' + metode, bodyData.uuid, bodyData, res => {
				// console.log(res);
				if (res.status === resstat) {
					this.setState({
						loadingPreview: false,
						show: true,
						basicType: "success",
						basicTitle: "Data Wisata",
						pesanAlert: "Berhasil " + psn + " Data"
					});

					document.getElementById('form-data').reset()
					this.fetch();
					this.setState({
						dt: frmDex,
						status: {
							...this.state.status,
							form: false
						}
					})
				} else if (res.status === 422) {
					this.setState({
						loadingPreview: false,
						show: true,
						basicType: "danger",
						basicTitle: "Data Wisata",
						pesanAlert: "Gagal " + psn + " data, nama wisata sudah pernah digunakan."
					});
				} else {
					this.setState({
						loadingPreview: false,
						show: true,
						basicType: "danger",
						basicTitle: "Data Wisata",
						pesanAlert: "Gagal " + psn + " Data"
					});
				}
			})
		})
		// .catch((err) => console.log(err))

		// console.log(getImages);



	});
	// ========================================================================

	// ============================= Hapus Data ===============================
	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	};

	hapusData = (id) => {
		this.hideAlert();
		let psn = 'Hapus';
		Delete('banner/delete', id, (res) => {
			if (res === 200) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Banner Desa',
					pesanAlert: 'Berhasil ' + psn + ' Data'
				});
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Banner Desa',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
			this.componentDidMount();
		});
	};
	// ========================================================================

	bukaForm = () => {
		this.setState({
			status: {
				...this.state.status,
				form: true,
			},
		})
		this.forceUpdate();
	};

	tutupForm = () => {
		this.setState({
			formDisposisi: false,
			status: {
				...this.state.status,
				form: false,
			},
		})

		this.forceUpdate();
	};

	ubahData = data => {
		this.setState({
			judul: "Ubah Data Wisata",
			editMode: true
		})

		if (data) {
			this.setState({
				tmpImages: [],
				dt: {
					...this.state.dt,
					uuid: data.uuid,
					nama: data.nama,
					deskripsi: data.deskripsi,
					kumpulan_foto: JSON.parse(data.kumpulan_foto),
					alamat: data.alamat,
					lat: data.lat,
					long: data.long,
					url_maps: data.url_maps
				},
				currentLocation: [data.lat, data.long],
				status: {
					...this.state.status,
					form: true
				}
			})
		}
	}

	componentDidMount() {
		this.fetch()
		this.getLocation()
	}

	getLocation() {
		if ("geolocation" in navigator) {
      console.log("Geolocation Available");
			navigator.geolocation.getCurrentPosition(function(position) {
				const lat = (position.coords.latitude)
				const long = (position.coords.longitude)

				const currentLocation = [lat, long]
				this.setState({ currentLocation, curStaticLocation: currentLocation })
				this.forceUpdate()
				
			}.bind(this));
    } else {
      console.log("Geolocation Not Available");
    }
	}

	render() {
		var data = this.state.data;

		const columns = [
			{
				dataField: "no",
				text: "No",
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
				align: 'center',
				formatter: (key, obj, index) => {
					let current_pagenum = this.state.pagination.current_page;
					let total_records_per_page = this.state.pagination.per_page;
					let row_index = (index + 1);
					let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
					return serial_num;
				}
			},
			{
				dataField: 'nama',
				headerAlign: 'center',
				align: 'center',
				text: 'Nama',
				sort: false
			},
			{
				dataField: 'lat',
				text: 'Latitude',
				headerAlign: 'center',
				align: 'center',
				sort: false
			},
			{
				dataField: 'long',
				text: 'Longitude',
				headerAlign: 'center',
				align: 'center',
				sort: false
			},
			{
				dataField: 'url_maps',
				text: 'Link Google Maps',
				headerAlign: 'center',
				align: 'center',
				sort: false
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				headerAlign: 'center',
				align: 'center',
				formatter: function (cell, row) {
					return (
						<div>
							<Button
								size="xs"
								color="success"
								className="btn-icon mr-2"
								onClick={() => this.ubahData(row)}
							// disabled={btnAksi}
							>
								<i className="fa fa-pencil"></i>
							</Button>
							<Button
								size="xs"
								color="danger"
								className="btn-icon"
								onClick={() => {
									this.setState({
										alert: (
											<SweetAlert
												showCancel
												confirmBtnText="Hapus"
												confirmBtnBsStyle="danger"
												cancelBtnBsStyle="success"
												type="danger"
												title="Yakin ingin hapus data ?"
												onCancel={this.hideAlert}
												// onConfirm={() => this.hapusData(id)}
												onConfirm={() => {
													Delete('wisata/delete', row.uuid, (res) => {
														if (res === 200) {
															this.setState({ 
																show: true, 
																basicType:'success',
																basicTitle:'Data Wisata',
																pesanAlert:'Berhasil hapus data' 
															});
															this.fetch()
														} else {
															this.setState({ 
																show: true, 
																basicType:'danger', 
																basicTitle:'Data Wisata',
																pesanAlert:'Gagal hapus data' 
															});
														}
														this.setState({ alert: null })
													})
												}}
											>
												Proses ini tidak dapat dibatalkan!
											</SweetAlert>
										)
									});
								}}
								disabled={this.state.status.btnAksi}
							>
								<i className="fa fa-trash"></i>
							</Button>
						</div>
					)
				}.bind(this)
			}
		]

		return (
			<div>
				{/* <Breadcrumb title="Data Banner Desa" parent="Admin" /> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				<SweetAlert
					show={this.state.loadingPreview}
					showCancel={false}
					showConfirm={false}
				>
					<div align="center">
						{/* <h5 className="modal-title mb-3" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5> */}
						<img
							alt="loading"
							src={require("../assets/images/tes-loading.gif")}
							style={{
								borderRadius: "10px",
								width: '150px'
							}}
						/>
					</div>
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div className="row">
										{/* <div className="col-sm-6" /> */}
										<div className="col-sm-12 text-right">
											<Button
												size="sm"
												color="success"
												className="btn-square"
												onClick={() => {
													this.setState({
														judul: 'Tambah Lokasi Wisata',
														dt: frmDex,
														tmpImages: ['kosong'],
														editMode: false,
														currentLocation: this.state.curStaticLocation
													});
													// document.getElementById('form-data').reset()
													this.bukaForm();
												}}
											>
												Tambah Data
											</Button>
										</div>
									</div>
								</div>

								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-md-12">
											{this.state.loading ? (
												<div className="row">
													<div className="col-sm-12" align="center">
														<img
															alt="loading"
															src={require("../assets/images/tes-loading.gif")}
															style={{
																borderRadius: "10px",
																width: '100px'
															}} />
													</div>
												</div>
											) : (
												<>
													<BootstrapTable
														keyField="id"
														data={data}
														columns={columns}
													/>
													<div className="pull-right text-white">
														{this.state.awal}
														{this.state.sebelum}
														{this.state.hal.map(dt => {
															return dt;
														})}
														{this.state.setelah}
														{this.state.akhir}
													</div>
												</>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal
					open={this.state.imagePreview ? true : false}
					onClose={() => {
						this.setState({ imagePreview: null })
					}}
					closeOnEsc={true}
					closeOnOverlayClick={true}
					styles={{ modal: { width: '50%', maxWidth: 'unset' } }}
				>
					<div className="row">
						<div className="col-md-12 mt-4">
							<img src={this.state.imagePreview ? this.state.imagePreview : ''} alt="image-wisata-preview" style={{ width: '100%' }} />
						</div>
					</div>
				</Modal>
				<Modal
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: '80%' } }}
				>
					<Form id="form-data" className="theme-form" onSubmit={(e) => { this.simpan(e) }}>
						<input className="form-control" id="uuid" type="hidden" defaultValue={this.state.dt.uuid} value={this.state.dt.uuid} />
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							{/* <input
								className="form-control"
								id="uuid"
								type="hidden"
								placeholder="UUID"
								defaultValue={this.state.dt.uuid}
								value={this.state.dt.uuid}
							/> */}

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama Wisata
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama"
										type="text"
										placeholder="Contoh : Air Terjun 7 Tingkat"
										defaultValue={this.state.dt.nama}
										minLength={3}
										maxLength={75}
										onChange={(e) => {
											// console.log(e.target.value);
											this.setState({
												dt: {
													...this.state.dt,
													nama: e.target.value
												}
											})
										}}
										required
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Deskripsi
								</Label>
								<Col sm="9">
									<textarea className='form-control' name="deskripsi" id="deskripsi" cols="30" defaultValue={this.state.dt.deskripsi} rows="4" required />
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Alamat
								</Label>
								<Col sm="9">
									<textarea className='form-control' name="alamat" id="alamat" cols="30" defaultValue={this.state.dt.alamat} rows="3" required />
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Link Google Maps
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="url_maps"
										type="text"
										defaultValue={this.state.dt.url_maps}
										required
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Pilih lokasi wisata
								</Label>
								<Col sm="9">
									<MapContainer center={this.state.currentLocation} zoom={12} scrollWheelZoom={false} id="mapwisata" >
										<TileLayer
											attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
											url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
										/>
										<Marker 
											draggable={true}
											position={this.state.currentLocation} 
											eventHandlers={{
												dragend: (event) => {
													const lat = event.target._latlng.lat
													const long = event.target._latlng.lng

													this.setState({
														currentLocation: [lat, long],
														dt: {
															...this.state.dt,
															lat,
															long
														}
													})
													this.forceUpdate()
												}
											}}
										>
											<Popup>
												{this.state.dt.nama}
											</Popup>
										</Marker>
									</MapContainer>
								</Col>
							</FormGroup>
							{/* <FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Latitude (Lat)
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="lat"
										type="number"
										step="any"
										min={0}
										defaultValue={this.state.dt.lat}
										required
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Longitude (Long)
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="long"
										type="number"
										step="any"
										min={0}
										defaultValue={this.state.dt.long}
										required
									/>
								</Col>
							</FormGroup> */}
							{/* Buat dynamic input type file with name[] */}
							{this.state.editMode == false ?
								this.state.tmpImages.map((val, i) => {
									let nmbr = (i + 1)
									return (
										<FormGroup className="row">
											<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
												Foto {nmbr}
											</Label>
											<Col sm={nmbr == 1 ? "9" : '7'}>
												<input
													className="form-control"
													name='foto[]'
													id={"foto-" + (nmbr)}
													type="file"
													required
													accept='image/png, image/jpeg'
												/>
											</Col>
											{nmbr > 1 ? (
												<div className="col-sm-1" style={{ display: 'flex', justifyContent: "center" }}>
													<Button
														size="md"
														color="danger"
														className="btn-icon"
														onClick={() => {
															let currentTmpImages = this.state.tmpImages
															currentTmpImages.splice(i, 1)
															this.setState({ tmpImages: currentTmpImages })
														}}
													>
														<i className="fa fa-trash"></i>
													</Button>
												</div>
											) : ''}
										</FormGroup>
									)
								})
								: ( // Tampilan modal ketika edit
									<>
										<div className='row mb-4'>
											{this.state.dt.kumpulan_foto.map((val, j) => {
												return (
													<div className="col-sm-3" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
														<h6 className='text-center'>Foto {j + 1}</h6>
														<img src={val} alt={"foto-image-" + j} style={{ width: '100px', height: '100px', objectFit: 'cover', cursor: 'pointer' }}
															onClick={(e) => {
																this.setState({ imagePreview: val })
															}}
														/>
														<Button
															size="md"
															color="danger"
															className="mt-2"
															onClick={(e) => {
																// e.preventDefault()
																// delete kumpulan foto
																let currentKumpulanFoto = this.state.dt.kumpulan_foto
																currentKumpulanFoto.splice(j, 1)
																this.setState({
																	dt: {
																		...this.state.dt,
																		kumpulan_foto: currentKumpulanFoto
																	}
																})
																this.forceUpdate()
															}}
														>
															Hapus
														</Button>
													</div>
												)
											})}
										</div>
										{this.state.tmpImages.map((val, k) => {
											let nmbr2 = this.state.dt.kumpulan_foto.length + (k + 1)

											return (
												<FormGroup className="row">
													<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
														Foto {nmbr2}
													</Label>
													<Col sm="7">
														<input
															className="form-control"
															name='foto[]'
															id={"foto-" + (k + 1)}
															type="file"
															required
															accept='image/png, image/jpeg'
														/>
													</Col>
													<div className="col-sm-1" style={{ display: 'flex', justifyContent: "center" }}>
														<Button
															size="md"
															color="danger"
															className="btn-icon"
															onClick={() => {
																let currentTmpImages = this.state.tmpImages
																currentTmpImages.splice(k, 1)
																this.setState({ tmpImages: currentTmpImages })
															}}
														>
															<i className="fa fa-trash"></i>
														</Button>
													</div>
												</FormGroup>
											)
										})}
									</>

								)
							}
							<FormGroup className="row">
								{/* <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3"/> */}
								<Col sm="12">
									<Button
										size="sm"
										color="success"
										className="btn-square"
										onClick={() => {
											let jmlFoto = this.state.tmpImages
											jmlFoto.push({
												file: 'foto-' + (jmlFoto.length + 1)
											})
											this.setState({ tmpImages: jmlFoto })
										}}
									>
										Tambah foto
									</Button>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm}>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default Wisata;
