import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { Col, FormGroup, Label, Form } from 'reactstrap';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { Post, Get, JENIS_DUSUN} from '../function/Koneksi';
import Datetime from 'react-datetime';

// import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

const dateFormat = require('dateformat');

export default class SuratKuasaSKGR extends Component {
	constructor(props) {
		super(props);
		this.state = {
			//keyboard
			focus: false,
			display: 'none',
			layoutName: 'default',
			inputName: 'input1',
			input: {},
			inputPattern: /^\d+$/,
			//end keyboard
			dt: {
				uuid: '',
				id_jenis: '',
				nomor: '',
				tanggal_surat: new Date(),

				nik: '',
				kk: '',
				nama: '',
				tempat_lahir: '',
				tanggal_lahir: null,
				jenis_kelamin: '',
				pekerjaan: '',
				pendidikan_terakhir: '',
				kewarganegaraan: '',
				agama: '',
				alamat: '',
				status_perkawinan: '',
				tujuan_pembuatan: '',

				nik2: '',
				nama2: '',
				tempat_lahir2: '',
				tanggal_lahir2: null,
				pekerjaan2: '',
				alamat2: '',

				status_ttd: '',
				jabatan_ttd: '',
				jabatan_pengganti_ttd: '',
				nama_ttd: '',
				nip_ttd: '',
				status_ttd2: '',
				jabatan_ttd2: '',
				jabatan_pengganti_ttd2: '',
				nama_ttd2: '',
				nip_ttd2: ''
			},
			Basic: false,
			showBio2: false,
			showBio: false,
			formPenerimaKuasa: 'none',
			status: {
				form: false,
				btnForm: true,
				btnAksi: [],
				select: false
			},
			frmPend2: [],
			pendStat2: null,
			frmNik2: [],
			nik2Stat: null,
			tanggal_surat: new Date(),
			tanggal_skgr: null,

			// List Dropdown
			frmPkr: [],
			pkrStat: null,
			
			// Pilih Data Keluarga
			frmNikKeluarga: [],
			nikStatTerlapor: null,
			dt_keluarga:[],
			dataKeluarga: "none",
		};
	}

	// ====================keyboard=============================

	showKeyboard = () => {
		if (!this.state.focus || this.state.display) {
			this.setState({
				focus: true,
				display: ''
			});
		}

		this.forceUpdate();
	};

	onChange = (input) => {
		this.setState({ input });
		//console.log('Input changed', input);
	};

	onKeyPress = (button) => {
		//console.log('Button pressed', button);
		if (button === '{shift}' || button === '{lock}') this.handleShift();
	};

	handleShift = () => {
		const layoutName = this.state.layoutName;

		this.setState({
			layoutName: layoutName === 'default' ? 'shift' : 'default'
		});
	};

	onChangeInput = (event) => {
		//console.log('===onchange===');
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				this.keyboard.setInput(inputVal);
			},
			() => {
				if (typeof this.keyboard !== 'undefined') {
					this.keyboard.setInput(inputVal);
				}
			}
		);
	};

	onInputChanged = (data) => {
		this.setState({ input: data });
	};

	onInputSubmitted = (data) => {
		//console.log('Input submitted:', data);
	};

	onChangeAll = (inputObj) => {
		this.setState({
			input: inputObj
		});

		//console.log('Input changed', inputObj);
	};

	setActiveInput = (inputName) => {
		this.setState(
			{
				inputName: inputName
			},
			() => {
				//console.log('Active input', inputName);
			}
		);
	};

	onOpenModal = (key) => {
		//console.log(key);
		this.setState({ [key]: true });
	};

	onCloseModal = (key) => {
		this.setState({ 
			[key]: false, 
			pendStat2: null, 
			display: 'none', 
			input: '',
			formPenerimaKuasa: 'none',
			tanggal_skgr: null,
			showBio2: false,
			btnForm: true,
			pkrStat: null,
			dt:{
				...this.state.dt,
				nik2: '',
				nama_pemohon2: '',
				tempat_lahir2: '',
				tanggal_lahir2: null,
				pekerjaan2: '',
				pendidikan_terakhir2: '',
				agama2: '',
				status_perkawinan2: '',
				alamat2: '',
			},
			dt_keluarga:{
				nik: '',
				kk: '',
				nama: '',
				agama: '',
				alamat: '',
				jenis_kelamin: '',
				pekerjaan: '',
				status_perkawinan: '',
				tanggal_lahir: '',
				tempat_lahir: '',
				pendidikan_terakhir: '',
			},
			dataKeluarga:'none',
			nikStatTerlapor:null,
		});
		this.forceUpdate();

		document.getElementById("pendStat2").value = ''
		document.getElementById("kuasa_untuk").value = ''
		document.getElementById("kuasa_atas").value = ''
		document.getElementById("tujuan_kuasa").value = ''
		document.getElementById("atas_nama").value = ''
		document.getElementById("no_reg").value = ''
		document.getElementById("lokasi_tanah").value = ''
		document.getElementById("luas_tanah").value = ''
	};

	handleClick = (event) => {
		alert(event);
	};

	// ambil data penduduk
	getPenduduk = (e, label) => {
		if (e) {
			this.changeSelectValue(e, label);
			Get('penduduk/find/' + e.value, null, (data) => {
				//console.log(data.results.data_kk);
				let alamat = '';
				if (localStorage.getItem('JENIS_DESA').toLowerCase() === 'kelurahan') {
					alamat =
					" RT. " +
					data.results.data_kk.data_rt.nama +
					"/RW. " +
					data.results.data_kk.data_rt.data_rw.nama +
					", " +
					data.results.data_kk.data_rt.data_rw.data_keldes.nama +
					", Kec. " +
					data.results.data_kk.data_rt.data_rw.data_keldes
						.data_kecamatan.nama +
					", " +
					data.results.data_kk.data_rt.data_rw.data_keldes
						.data_kecamatan.data_kabkota.nama; 
				} else {
					if (data.results.data_kk.id_rt === '0') {
						alamat = localStorage.getItem('JENIS_DESA') + " " + data.results.data_keldes.nama + 
						", Kec. " + data.results.data_keldes.data_kecamatan.nama + 
						", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
						", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
					} else {
						alamat =
						"RT. " + 
						data.results.data_kk.data_rt.nama +
						"/RW. " +
						data.results.data_kk.data_rt.data_rw.nama +
						", " + JENIS_DUSUN + " " +
						data.results.data_kk.data_rt.data_rw.data_dusun.nama +
						", " + localStorage.getItem('JENIS_DESA') + " " +
						data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
						", Kec. " +
						data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
						.data_kecamatan.nama +
						", " +
						data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
						.data_kecamatan.data_kabkota.nama; 
					}
				}

				if (label === "nikTerlapor") {
					this.setState({
						dt_keluarga:{
							nik: e.value,
							kk: data.results.id_kk,
							nama: data.results.nama,
							agama: data.results.data_agama.nama,
							alamat: alamat,
							jenis_kelamin: data.results.jk,
							pekerjaan: data.results.data_pekerjaan.nama,
							status_perkawinan: data.results.status_perkawinan,
							tanggal_lahir: data.results.tanggal_lahir,
							tempat_lahir: data.results.tempat_lahir,
							pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
						},
						dataKeluarga: 'block',
					})
				}
				else if (label === 'nik2') {
					this.setState({
						pendStat2: { value: e.value, label: e.label },
						showBio2: true,
						dt:{
							...this.state.dt,
							agama2: data.results.data_agama.nama,
							alamat2: alamat,
							jenis_kelamin2: data.results.jk,
							kk2: data.results.id_kk,
							nama2: data.results.nama,
							nik2: data.results.id,
							pekerjaan2: data.results.data_pekerjaan.nama,
							pendidikan_terakhir2: data.results.data_pendidikan_terakhir.nama,
							status_perkawinan2: data.results.status_perkawinan,
							tanggal_lahir2: new Date(data.results.tanggal_lahir),
							tempat_lahir2: data.results.tempat_lahir,
						}
					})
				}

				// if (this.state.pendStat2.length !== 0 && this.state.pendStat.length !== 0) {
				// 	this.state.status.btnForm = false;
				// }
				// this.state.status.btnAksi = false;
				this.forceUpdate();
			});
		} else {
			if (label === 'nik') {
				this.setState({
					showBio: false,
					btnForm: true,
					pendStat: [],
					dt:{
						...this.state.dt,
						nik: '',
						nama_pemohon: '',
						tempat_lahir: '',
						tanggal_lahir: '',
						pekerjaan: '',
						pendidikan_terakhir: '',
						agama: '',
						status_perkawinan: '',
						alamat: '',
					}
				})
			}
			if (label === 'nik2') {
				this.setState({
					showBio2: false,
					btnForm: true,
					pendStat2: null,
					dt:{
						...this.state.dt,
						nik2: '',
						nama_pemohon2: '',
						tempat_lahir2: '',
						tanggal_lahir2: null,
						pekerjaan2: '',
						pendidikan_terakhir2: '',
						agama2: '',
						status_perkawinan2: '',
						alamat2: '',
					}
				})
			}
			this.setState({
				status:{
					...this.state.status,
					btnForm: true,
				}
			})

			this.forceUpdate();

			// this.state.status.btnAksi = false;
		}
	};

	//change nik
	handleInputChangeNIK = (e, fld) => {
		if (e) {
			if (fld === "nikTerlapor") {
				let newArray = this.state.frmNikKeluarga;
	
				newArray = newArray.filter(day => day !== e);

				this.setState({
					frmNikKeluarga:newArray,
				})
			}
			else if (fld === 'nik') {
				let pendStat = { value: e, label: e };
				let param = {
					nik: e
				};
				Post('penduduk', null, param, (datanik) => {
					let frmPend = [];
					datanik.data.results.data.forEach((dt) => {
						frmPend.push({ value: dt.id, label: dt.id });
					});
					this.setState({ frmPend, pendStat });
				});
			} else {
				let nik2Stat = { value: e, label: e };
				let param = {
					nik: e
				};
				Post('penduduk', null, param, (datanik) => {
					let frmNik2 = [];
					datanik.data.results.data.forEach((dt) => {
						frmNik2.push({ value: dt.id, label: dt.id });
					});
					this.setState({ frmNik2, nik2Stat });
				});
			}
		}
	};

	gantiTgl = (date, fld) => {
		switch (fld) {
			case 'tanggal_surat':
				this.setState({ tanggal_surat: date });
				break;
			case 'tanggal_lahir_pasangan':
				this.setState({ tanggal_lahir_pasangan: date });
				break;
			case 'tanggal_lahir2':
				if (typeof date._isValid !== 'undefined') {
          			// this.setState({ tanggal_selesai: date.format("YYYY-MM-DD HH:mm:ss") });
					// this.state.dt.tanggal_lahir2 = date.format('YYYY-MM-DD');
					// this.forceUpdate();
					this.setState({ tanggal_lahir2: new Date(date.format('YYYY-MM-DD')) });
        		}
				break;
			case 'tanggal_skgr':
				if (typeof date._isValid !== 'undefined') {
					this.setState({ tanggal_skgr: new Date(date.format('YYYY-MM-DD')) });
				}
				break;
			default:
				// no default
				break;
		}
	};

	changeInputText = (e, fld) => {
		switch (fld) {
			case 'nik2':
				//console.log(e.target.value);
				let btnForm = true
				if (e) {
					btnForm = false;
				}
				this.setState({
					status:{
						...this.state.status,
						btnForm: btnForm,
					},
					dt:{
						...this.state.dt,
						nik2: e.target.value,
					}
				})
				this.forceUpdate();
				break;
			case 'nama2':
				//console.log(e.target.value);
				this.setState({
					dt:{
						...this.state.dt,
						nama2: e.target.value,
					}
				})
				this.forceUpdate();
				break;
			case 'tempat_lahir2':
				//console.log(e.target.value);
				this.setState({
					dt:{
						...this.state.dt,
						tempat_lahir2: e.target.value,
					}
				})
				this.forceUpdate();
				break;
			case 'tanggal_lahir2':
				//console.log(e.target.value);
				// this.state.dt.tanggal_lahir2 = e.target.value;
				this.forceUpdate();
				break;
			case 'pekerjaan2':
				//console.log(e.target.value);
				this.setState({
					dt:{
						...this.state.dt,
						pekerjaan2: e.target.value,
					}
				})
				this.forceUpdate();
				break;
			case 'alamat2':
				//console.log(e.target.value);
				this.setState({
					dt:{
						...this.state.dt,
						alamat2: e.target.value,
					}
				})
				this.forceUpdate();
				break;
			default:
				// no default
				break;
		}
	};

	componentDidMount() {
		// Get list anggota keluarga from parent
		if (this.props.anggotaKeluarga) {
			this.setState({ frmNikKeluarga: this.props.anggotaKeluarga })
		}
		// Get("penduduk/kk/" + this.props.data.results.id_kk, null, data => {
		// 	console.log("Data Keluarga")
		// 	console.log(data)
		// 	if(data.results){
		// 	  let frmNikKeluarga = [];
		// 	  data.results.forEach(dt => {
		// 		frmNikKeluarga.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
		// 	  });
		// 	  this.setState({ frmNikKeluarga });
			  
		// 	  // For testing
		// 	  if (this.props.getListNIKTerlapor) {
		// 		this.props.getListNIKTerlapor(frmNikKeluarga)
		// 	  }
		// 	}
		// });

		// LIST PENDUDUK DEFAULT SEDANG TIDAK DIPERLUKAN
		// Get('penduduk', null, (data) => {
		// 	let frmPend2 = [];
		// 	if(data.results){
		// 		data.results.data.forEach((dt) => {
		// 			frmPend2.push({ value: dt.id, label: dt.id });
		// 		});
		// 		this.setState({ frmPend2 });
		// 	}
		// });

		// Get list pekerjaan from parent
		if (this.props.listPekerjaan) {
			this.setState({ frmPkr: this.props.listPekerjaan })
		}
		// Get List Pekerjaan
		// Get('pekerjaan/list', null, (data) => {
		// 	let frmPkr = [];
		// 	if(data.results){
		// 		data.results.forEach((dt) => {
		// 			frmPkr.push({ value: dt.uuid, label: dt.nama });
		// 		});
		// 		this.setState({ frmPkr });
		// 	}
		// });

	}

	dataPenerimaKuasa = () => {
		//console.log(this.props.data);
		let showBio2 = true;
		if (this.state.pendStat2 !== null) {
			showBio2 = true;
		} else {
			showBio2 = this.state.showBio2 === false ? true : false;
		}

		this.setState({
			showBio2: showBio2,
			pendStat2: null,
			formPenerimaKuasa: this.state.formPenerimaKuasa === '' ? 'none' : '',
			dt:{
				...this.state.dt,
				nik2: '',
				nama2: '',
				tempat_lahir2: '',
				tanggal_lahir2: null,
				pekerjaan2: '',
				alamat2: '',
			}
		})
		this.forceUpdate();
	};

	simpan = (e) => {
		e.preventDefault();
		this.setState({ Basic: false })
		this.props.setLoadingPreview(true)

		const nikPenerimaKuasa = document.getElementById("pendStat2").value

		if (nikPenerimaKuasa.trim() === '') {
			this.props.getAntrian('Surat Kuasa SKGR', 'gagal', true);
		}
		else if (nikPenerimaKuasa.length !== 16) {
			this.props.getAntrian('Surat Kuasa SKGR', 'cek digit nik');
		} 
		else if (this.state.showBio2 !== "none" && this.state.dt.pekerjaan2!=='' && this.state.nikStatTerlapor) {
			//detail
			let formDetail = [
				{
					nama_item: 'atas_nama',
					isi_item: document.getElementById('atas_nama').value
				},
				{
					nama_item: 'no_reg',
					isi_item: document.getElementById('no_reg').value
				},
				{
					nama_item: 'tanggal_skgr',
					isi_item: dateFormat(this.state.tanggal_skgr, 'yyyy-mm-dd')
				},
				{
					nama_item: 'luas_tanah',
					isi_item: document.getElementById('luas_tanah').value
				},
				{
					nama_item: 'lokasi_tanah',
					isi_item: document.getElementById('lokasi_tanah').value
				}
			];
			//console.log(formDetail);

			// let alamat = '';
			// if (JENIS_DESA.toLowerCase() === 'kelurahan') {
			// 	alamat =
			// 		' RT. ' +
			// 		this.props.data.results.data_kk.data_rt.nama +
			// 		'/RW. ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.nama +
			// 		', ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.nama +
			// 		', Kec. ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
			// 		', ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
			// } else {
			// 		if (this.props.data.results.data_kk.id_rt === '0') {
			// 			alamat = JENIS_DESA + " " + this.props.data.results.data_keldes.nama + 
			// 			", Kec. " + this.props.data.results.data_keldes.data_kecamatan.nama + 
			// 			", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.nama +
			// 			", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
			// 		} else {
			// 			alamat =
			// 			" RT. " + 
			// 			this.props.data.results.data_kk.data_rt.nama +
			// 			"/RW. " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.nama +
			// 			", " + JENIS_DUSUN + " " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
			// 			", " + JENIS_DESA + " " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
			// 			", Kec. " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
			// 				.data_kecamatan.nama +
			// 			", " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
			// 				.data_kecamatan.data_kabkota.nama; 
			// 		}
			// }

			let addData = {
				id_jenis: this.props.id_jenis,
				nik_pelapor: this.props.data.results.id,
				nik: this.state.dt_keluarga.nik,
				kk: this.state.dt_keluarga.kk,
				nama: this.state.dt_keluarga.nama,
				tempat_lahir: this.state.dt_keluarga.tempat_lahir,
				tanggal_lahir: this.state.dt_keluarga.tanggal_lahir,
				pekerjaan: this.state.dt_keluarga.pekerjaan,
				agama: this.state.dt_keluarga.agama,
				status_perkawinan: this.state.dt_keluarga.status_perkawinan,
				alamat: this.state.dt_keluarga.alamat,
				jenis_kelamin: this.state.dt_keluarga.jenis_kelamin,
				pendidikan_terakhir: this.state.dt_keluarga.pendidikan_terakhir,

				// nik2: this.state.dt.nik2,
				nik2: document.getElementById('pendStat2').value,
				// nama2: this.state.dt.nama2,
				nama2: document.getElementById('nama2').value,
				// tempat_lahir2: this.state.dt.tempat_lahir2,
				tempat_lahir2: document.getElementById('tempat_lahir2').value,
				// tanggal_lahir2: document.getElementById('tanggal_lahir2').value,
				// tanggal_lahir2: this.state.dt.tanggal_lahir2,
				tanggal_lahir2: dateFormat(this.state.dt.tanggal_lahir2, 'yyyy-mm-dd'),
				pekerjaan2: this.state.dt.pekerjaan2,
				// pekerjaan2: this.state.dt.pekerjaan2,
				alamat2: document.getElementById('alamat2').value,
				// alamat2: this.state.dt.alamat2,

				kuasa_atas: document.getElementById('kuasa_atas').value,
				kuasa_untuk: document.getElementById('kuasa_untuk').value,
				tujuan_kuasa: document.getElementById('tujuan_kuasa').value,

				detail: formDetail
			};
			//console.log(addData)

			// let psn = '';
			let resstat = 201;
			let metode = 'create';

			Post('surat-keterangan/kuasa/' + metode, null, addData, (res) => {
				this.onCloseModal('Basic');

				let dataAntrian = {
					nama: this.props.data.results.nama,
					nik: this.props.data.results.id,
					keterangan: '',
					uuid_surat:res.data.results,
				};
	
				if (res.status === resstat) {
					setTimeout(function () {
						Get('persetujuan/kiosk/' + dataAntrian.uuid_surat + '/' + this.props.id_jenis, null,  (res2) => {
							//console.log("Hasil Update Status")
							//console.log(res2)
							if (res2.code === 201) {
								//console.log("Berhasil Ganti Status Menjadi Selesai")
		
								this.props.getAntrian('Surat Kuasa SKGR', 'berhasil', false, dataAntrian);
							}
							else{
								//console.log("Gagal Ganti Status Menjadi Selesai")
		
								this.props.getAntrian('Surat Kuasa SKGR', 'gagal');
							}
						});
					}.bind(this),1000)
					// this.props.getAntrian('Surat Kuasa SKGR', 'berhasil', false, dataAntrian);

					// reset cache
					document.getElementById('atas_nama').value = '';
					document.getElementById('no_reg').value = '';
					document.getElementById('luas_tanah').value = '';
					document.getElementById('lokasi_tanah').value = '';
					document.getElementById('kuasa_atas').value = '';
					document.getElementById('kuasa_untuk').value = '';
					document.getElementById('tujuan_kuasa').value = '';
					this.setState({
						formPenerimaKuasa: 'none',
						pendStat2: null,
						tanggal_skgr: null,
						showBio: false,
						showBio2: false,
						pkrStat: null,
						dt:{
							...this.state.dt,
							nik2: '',
							nama2: '',
							tempat_lahir2: '',
							tanggal_lahir: null,
							pekerjaan2: '',
							alamat2: '',
						}
					})
					this.forceUpdate();
				} else {
					this.props.getAntrian('Surat Kuasa SKGR', 'gagal');
				}
			});
		} else {
			this.props.getAntrian('Surat Kuasa SKGR', 'gagal', true);
		}

	};

	// Cari NIK
	handleChange = (e, cek) => {
		switch (cek) {
			case 'cari_nik1':
				this.setState({
					pendStat2: e.target.value
				});
				this.forceUpdate();
				break;
			default:
				// no default
				break;
		}
	};

	cariNIK = (e, sel) => {
		e.preventDefault();

		let id_cari = '';
		if (sel === 'cari_nik1') {
			// id_cari = this.state.pendStat2;
			id_cari = this.state.pendStat2;
		}

		if (id_cari.length !== 16) {
			this.setState({
				pendStat2: null,
				showBio2: false,
				btnForm: true,
				dt:{
					...this.state.dt,
					agama2: '',
					alamat2: '',
					jenis_kelamin2: '',
					kk2: '',
					nama2: '',
					nik2: '',
					pekerjaan2: '',
					pendidikan_terakhir2: '',
					status_perkawinan2: '',
					tanggal_lahir2: null,
					tempat_lahir2: '',
				}
			})
			this.forceUpdate();
			//console.log('Panjang NIK adalah 16 digit');
			this.props.getAntrian('Surat Kuasa SKGR', 'cek digit nik');
		} else {
			//console.log('Mantap bos');

			Get('penduduk/find', id_cari, (data) => {
				if (data.results) {
					//console.log('Berhasil Tampilkan Penduduk');
					//console.log(data.results);

					let alamat = '';
					if (localStorage.getItem('JENIS_DESA').toLowerCase() === 'kelurahan') {
						alamat =
						"RT. " +
						data.results.data_kk.data_rt.nama +
						"/RW. " +
						data.results.data_kk.data_rt.data_rw.nama +
						", " +
						data.results.data_kk.data_rt.data_rw.data_keldes.nama +
						", Kec. " +
						data.results.data_kk.data_rt.data_rw.data_keldes
							.data_kecamatan.nama +
						", " +
						data.results.data_kk.data_rt.data_rw.data_keldes
							.data_kecamatan.data_kabkota.nama; 
					} else {
						if (data.results.data_kk.id_rt === '0') {
							alamat = localStorage.getItem('JENIS_DESA') + " " + data.results.data_keldes.nama + 
							", Kec. " + data.results.data_keldes.data_kecamatan.nama + 
							", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
							", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
						} else {
							alamat =
							" RT. " + 
							data.results.data_kk.data_rt.nama +
							"/RW. " +
							data.results.data_kk.data_rt.data_rw.nama +
							", " + JENIS_DUSUN + " " +
							data.results.data_kk.data_rt.data_rw.data_dusun.nama +
							", " + localStorage.getItem('JENIS_DESA') + " " +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
							", Kec. " +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
							.data_kecamatan.nama +
							", " +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
							.data_kecamatan.data_kabkota.nama; 
						}
					}

					this.setState({
						pkrStat: { value: data.results.data_pekerjaan.uuid, label: data.results.data_pekerjaan.nama },
						pendStat2: id_cari,
						showBio2: true,
						dt:{
							...this.state.dt,
							agama2: data.results.data_agama.nama,
							alamat2: alamat,
							jenis_kelamin2: data.results.jk,
							kk2: data.results.id_kk,
							nama2: data.results.nama,
							nik2: data.results.id,
							pekerjaan2: data.results.data_pekerjaan.nama,
							pendidikan_terakhir2: data.results.data_pendidikan_terakhir.nama,
							status_perkawinan2: data.results.status_perkawinan,
							tanggal_lahir2: new Date(data.results.tanggal_lahir),
							tempat_lahir2: data.results.tempat_lahir,
							// this.state.dt.tanggal_lahir2 = data.results.tanggal_lahir;
						}
					})

					this.forceUpdate();
				} else {
					this.setState({
						showBio2: false,
						btnForm: true,
						pendStat2: null,
						dt:{
							...this.state.dt,
							nik2: '',
							nama_pemohon2: '',
							tempat_lahir2: '',
							tanggal_lahir2: null,
							pekerjaan2: '',
							pendidikan_terakhir2: '',
							agama2: '',
							status_perkawinan2: '',
							alamat2: '',
						},
					})
					this.forceUpdate();
					this.props.getAntrian('Surat Kuasa SKGR', 'cari nik');
				}
			});
		}
	};


	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case "nikTerlapor":
					this.setState({ nikStatTerlapor: e });
					break;
				case 'pekerjaan_penerima':
					this.setState({ 
						pkrStat: e, 
						dt:{
							...this.state.dt,
							pekerjaan2: e.label,
						}
					});
					break;
				default:
					// no default
					break;
			}
		} else {
			switch (sel) {
				case "nikTerlapor":
					this.setState({ nikStatTerlapor: e });
					break;
				case 'pekerjaan_penerima':
					this.setState({ 
						pkrStat: e, 
						dt:{
							...this.state.dt,
							pekerjaan2: "",
						}
					});
					break;
				default:
					// no default
					break;
			}
		}

		this.forceUpdate();
	};


	render() {
		const styleImgIcon = {
			height: '42,58px',
			width: '65,83px'
		};

		const { Basic } = this.state;

		return (
			<div>
				<Modal
					classNames={{ overlay: 'card-scrollable' }}
					open={Basic}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					onClose={() => this.onCloseModal('Basic')}
					styles={{ modal: { width: '80%' } }}
				>
					<Form onSubmit={this.simpan}>
						<div
							// style={{ marginBottom: this.state.display === '' ? '200px' : '0px' }}
							onMouseDown={(e) => {
								let slider = document.querySelector('.card-scrollable');
								// //console.log(e);
								this.setState({
									isDown: true,
									startY: e.pageY - slider.offsetTop,
									scrollUp: slider.scrollTop,
								})
								this.forceUpdate();
								// //console.log(this.state.startY);
								// this.state.scrollUp = this.scrollUp;
							}}
							onMouseLeave={() => {
								this.setState({ isDown: false });
							}}
							onMouseUp={() => {
								this.setState({ isDown: false });
							}}
							onMouseMove={(e) => {
								let slider = document.querySelector('.card-scrollable');

								if (!this.state.isDown) {
									return;
								}

								e.preventDefault();
								const y = e.pageY - slider.offsetTop;
								const startY = this.state.startY;
								const walk = y - startY;
								slider.scrollTop = this.state.scrollUp - walk;
								// //console.log({ y, startY });
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title" align="center">
									Surat Kuasa SKGR
								</h5>
							</div>

							<div className="modal-body">
								{/* <FormGroup className="row">
									<Label className="col-sm-3 col-form-label">NIK Pemohon</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nik"
											disabled
											type="text"
											placeholder="NIK"
											value={this.props.data.results.id}
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Nama Lengkap Pemohon</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama_pemohon"
											disabled
											type="text"
											placeholder=" Nama Lengkap"
											value={this.props.data.results.nama}
										/>
									</Col>
								</FormGroup> */}
								<FormGroup className="row">
									<Label
									className="col-sm-3 col-form-label"
									htmlFor="nik"
									>
									NIK Terlapor
									</Label>
									<Col sm="9">
									<div data-testid="selectNIKTerlapor">
										<Select
										inputId="nikTerlapor"
										classNamePrefix="select"
										onChange={e => {
											this.getPenduduk(e, "nikTerlapor");
										}}
										defaultValue={this.state.nikStatTerlapor}
										value={this.state.nikStatTerlapor}
										name="NIK"
										options={this.state.frmNikKeluarga}
										placeholder="Pilih NIK Terlapor"
										onInputChange={e => this.handleInputChangeNIK(e, "nikTerlapor")}
										isClearable
										/>
									</div>
									</Col>
								</FormGroup>

								<div style={{ display: this.state.dataKeluarga }}>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Nama Terlapor
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="nama"
										type="text"
										placeholder="Nama Terlapor"
										value={this.state.dt_keluarga.nama}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Tempat Lahir
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="tempat_lahir"
										type="text"
										placeholder="Tempat Lahir"
										value={this.state.dt_keluarga.tempat_lahir}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Tanggal Lahir
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="tanggal_lahir"
										type="text"
										placeholder="Tanggal Lahir"
										value={this.state.dt_keluarga.tanggal_lahir}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
								</div>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Yang Diberi Kuasa{' '}
									</Label>

									{this.state.formPenerimaKuasa === 'none' ? (
										<>
											<div className="col-sm-7">
												<input
													name="pendStat2"
													id="pendStat2"
													// value={this.state.pendStat2}
													onChange={(e) => this.handleChange(e, 'cari_nik1')}
													style={{ marginBottom: '10px' }}
													className="form-control"
													placeholder="Masukkan NIK Yang Diberi Kuasa"
													minLength="16"
													type="number"
													required
													// onFocus={(e) => {
													// 	this.setState({ display: '' });
													// 	//console.log(e.target);
													// 	this.setActiveInput('cari_nik1');
													// }}
													value={this.state.pendStat2}
													// onChange={(e) => this.onChangeInput(e)}
												/>
											</div>
											<div className="col-sm-2" style={{ textAlign: 'center' }}>
												<button
													type="button"
													className="btn btn-warning"
													onClick={(e) => this.cariNIK(e, 'cari_nik1')}
												>
													Cari
												</button>
											</div>
										</>
									) : (
										<Col sm="9">
											<input
												className="form-control"
												// id="nik2"
												id="pendStat2"
												type={this.state.formPenerimaKuasa === 'none' ? 'hidden' : 'number'}
												placeholder="Nik Yang Diberi kuasa"
												// defaultValue={this.state.dt.nik2}
												// value={this.state.dt.nik2}
												required
												// onChange={(e) => this.changeInputText(e, 'nik2')}
												// onFocus={(e) => {
												// 	this.setState({ display: '' });
												// 	//console.log(e.target);
												// 	this.setActiveInput('cari_nik1');
												// }}
												// value={this.state.input['cari_nik1'] || ''}
												// onChange={(e) => this.onChangeInput(e)}
											/>
										</Col>
									)}

									<Col sm="3"> &nbsp; </Col>
									<Col sm="6" align="left">
										<small id="emailHelp" className="form-text text-muted">
											Data NIK {this.state.formPenerimaKuasa === 'none' ? 'tidak' : ''} tersedia?{' '}
											<Link
												to={{
													pathname: this.props.myroute,
													state: this.props.data
												}}
												onClick={this.dataPenerimaKuasa}
											>
												{' '}
												Klik disini
											</Link>
										</small>
									</Col>
								</FormGroup>
								<div style={{ display: this.state.showBio2 === false ? 'none' : '' }}>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Nama
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="nama2"
												type="text"
												placeholder="Nama"
												onChange={(e) => this.changeInputText(e, 'nama2')}
												defaultValue={this.state.dt.nama2}
												readOnly={this.state.formPenerimaKuasa === '' ? false : true}
												required
												// onFocus={(e) => {
												// 	this.setState({ display: '' });
												// 	//console.log(e.target);
												// 	this.setActiveInput('nama2');
												// }}
												value={
													this.state.formPenerimaKuasa === '' ? (
														this.state.dt.nama2
													) : (
														this.state.dt.nama2
													)
												}
												// onChange={(e) => this.onChangeInput(e)}
											/>
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Tempat Lahir
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="tempat_lahir2"
												type="text"
												placeholder="Tempat lahir"
												// defaultValue={this.state.dt.tempat_lahir2}

												onChange={(e) => this.changeInputText(e, 'tempat_lahir2')}
												defaultValue={this.state.dt.tempat_lahir2}
												// value={this.state.dt.tempat_lahir2}
												readOnly={this.state.formPenerimaKuasa === '' ? false : true}
												required
												// onFocus={(e) => {
												// 	this.setState({ display: '' });
												// 	//console.log(e.target);
												// 	this.setActiveInput('tempat_lahir2');
												// }}
												value={
													this.state.formPenerimaKuasa === '' ? (
														this.state.dt.tempat_lahir2
													) : (
														this.state.dt.tempat_lahir2
													)
												}
												// onChange={(e) => this.onChangeInput(e)}
											/>
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Tanggal Lahir
										</Label>
										<Col sm="9">
											{this.state.formPenerimaKuasa === 'none' ? (
												<input
													className="form-control"
													id="tanggal_lahir2"
													type="text"
													readOnly
													required
													placeholder="Tanggal Lahir"
													value={ dateFormat(this.state.dt.tanggal_lahir2, 'dd/mm/yyyy')}
												/>
											) : (
												<Datetime
													locale="id-ID"
													dateFormat="DD/MM/yyyy"
													closeOnSelect={true}
													input={true}
													inputProps={{ required: true, placeholder: 'Tanggal Lahir ' }}
													closeOnTab={true}
													timeFormat={false}
													initialValue={this.state.dt.tanggal_lahir2}
													value={this.state.dt.tanggal_lahir2}
													onChange={(e) => {
														this.gantiTgl(e, 'tanggal_lahir2');
													}}
												/>
											)}
											{/* <input
											className="form-control"
											readOnly
											id="tanggal_lahir2"
											// readOnly
											type={this.state.formPenerimaKuasa === '' ? 'date' : 'text'}
											placeholder="Tanggal Lahir"
											// value={this.state.dt.tanggal_lahir2}

											onChange={(e) => this.changeInputText(e, 'tanggal_lahir2')}
											defaultValue={this.state.dt.tanggal_lahir2}
											value={this.state.dt.tanggal_lahir2}
											readOnly={this.state.formPenerimaKuasa === '' ? false : true}
											required
										/> */}
										</Col>
									</FormGroup>
								</div>
								<div style={{ display: this.state.formPenerimaKuasa }}>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Pekerjaan
										</Label>
										<Col sm="9">
											{this.state.formPenerimaKuasa === 'none'
												?
													<input
														className="form-control"
														id="pekerjaan_pasangan"
														type="text"
														placeholder="Pekerjaan"
														required
														value={this.state.dt.pekerjaan2}
														readOnly
													/>
												:
													<Select
														isDisabled={this.state.status.select}
														classNamePrefix="select"
														onChange={(e) => {
															this.changeSelectValue(e, 'pekerjaan_penerima');
														}}
														defaultValue={this.state.pkrStat}
														value={this.state.pkrStat}
														name="Pekerjaan"
														options={this.state.frmPkr}
														placeholder="Pilih Pekerjaan"
														isClearable
													/>
											}
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Alamat
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="alamat2"
												// readOnly
												type="text"
												required={this.state.formPenerimaKuasa === '' ? true : false}
												placeholder="Alamat"
												// defaultValue={this.state.dt.alamat2}
												onChange={(e) => this.changeInputText(e, 'alamat2')}
												// defaultValue={this.state.dt.alamat2}
												// value={this.state.dt.alamat2}
												readOnly={this.state.formPenerimaKuasa === '' ? false : true}
												// onFocus={(e) => {
												// 	this.setState({ display: '' });
												// 	//console.log(e.target);
												// 	this.setActiveInput('alamat2');
												// }}
												value={
													this.state.formPenerimaKuasa === '' ? (
														this.state.dt.alamat2
													) : (
														this.state.dt.alamat2
													)
												}
												// onChange={(e) => this.onChangeInput(e)}
											/>
										</Col>
									</FormGroup>
								</div>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Kuasa Untuk
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="kuasa_untuk"
											type="text"
											required
											placeholder="Contoh : Membangun gedung"
											defaultValue={this.state.dt.kuasa_untuk}
											// onFocus={(e) => {
											// 	this.setState({ display: '' });
											// 	//console.log(e.target);
											// 	this.setActiveInput('kuasa_untuk');
											// }}
											// value={this.state.input['kuasa_untuk'] || ''}
											// onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Kuasa Atas
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="kuasa_atas"
											type="text"
											required
											placeholder="Contoh : Imaduddin Syukra"
											// defaultValue={this.state.dt.kuasa_atas}
											// onFocus={(e) => {
											// 	this.setState({ display: '' });
											// 	//console.log(e.target);
											// 	this.setActiveInput('kuasa_atas');
											// }}
											// value={this.state.input['kuasa_atas'] || ''}
											// onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Tujuan Kuasa
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="tujuan_kuasa"
											type="text"
											required
											placeholder="Contoh : sebagai pinjaman bank"
											// defaultValue={this.state.dt.tujuan_kuasa}
											// onFocus={(e) => {
											// 	this.setState({ display: '' });
											// 	//console.log(e.target);
											// 	this.setActiveInput('tujuan_kuasa');
											// }}
											// value={this.state.input['tujuan_kuasa'] || ''}
											// onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row" style={{ marginBottom: '0px' }}>
									<Label className="col-sm-3 col-form-label" style={{ fontWeight: 700 }}>
										Detail Data SKGR
									</Label>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Atas nama
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="atas_nama"
											type="text"
											required
											placeholder="Atas Nama"
											// defaultValue={this.state.dt.atas_nama}
											// onFocus={(e) => {
											// 	this.setState({ display: '' });
											// 	//console.log(e.target);
											// 	this.setActiveInput('atas_nama');
											// }}
											// value={this.state.input['atas_nama'] || ''}
											// onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										No. Reg
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="no_reg"
											type="text"
											required
											placeholder="No. Reg"
											// defaultValue={this.state.dt.no_reg}
											// onFocus={(e) => {
											// 	this.setState({ display: '' });
											// 	//console.log(e.target);
											// 	this.setActiveInput('no_reg');
											// }}
											// value={this.state.input['no_reg'] || ''}
											// onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Tanggal
									</Label>
									<Col sm="9">
										<Datetime
											locale="id-ID"
											dateFormat="DD/MM/yyyy"
											closeOnSelect={true}
											input={true}
											inputProps={{ required: true, placeholder: 'Tanggal SKGR' }}
											closeOnTab={true}
											timeFormat={false}
											initialValue={this.state.tanggal_skgr}
											value={this.state.tanggal_skgr}
											onChange={(e) => {
												this.gantiTgl(e, 'tanggal_skgr');
											}}
										/>
									</Col>
								</FormGroup>{' '}
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Lokasi Tanah
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="lokasi_tanah"
											type="text"
											required
											placeholder="Lokasi Tanah"
											// onFocus={(e) => {
											// 	this.setState({ display: '' });
											// 	//console.log(e.target);
											// 	this.setActiveInput('lokasi_tanah');
											// }}
											// value={this.state.input['lokasi_tanah'] || ''}
											// onChange={(e) => this.onChangeInput(e)}
											// defaultValue={this.state.dt.lokasi_tanah}
										/>
									</Col>
								</FormGroup>{' '}
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Luas Tanah
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="luas_tanah"
											type="text"
											required
											placeholder="Luas Tanah"
											// onFocus={(e) => {
											// 	this.setState({ display: '' });
											// 	//console.log(e.target);
											// 	this.setActiveInput('luas_tanah');
											// }}
											// value={this.state.input['luas_tanah'] || ''}
											// onChange={(e) => this.onChangeInput(e)}
											// defaultValue={this.state.dt.luas_tanah}
										/>
									</Col>
								</FormGroup>{' '}
							</div>

							<div
								className="modal-footer"
								style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
							>
								<button
									type="button"
									className="btn btn-warning"
									onClick={() => this.onCloseModal('Basic')}
								>
									Tutup
								</button>
								<button type="submit" className="btn btn-success">
									Buat Surat
								</button>
							</div>
						</div>
					</Form>

					{/* {this.state.display === '' ? (
						<div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', zIndex: 4 }}>
							<Keyboard
								style={{ marginTop: '40px', zIndex: 3 }}
								keyboardRef={(r) => (this.keyboard = r)}
								inputName={this.state.inputName}
								layoutName={this.state.layoutName}
								onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
								onKeyPress={(button) => this.onKeyPress(button)}
							/>
						</div>
					) : (
						''
					)} */}
				</Modal>

				<div
					className="card kiosk-card"
					onClick={() => this.onOpenModal(`Basic`)}
					style={{
						marginTop: '20px',
						marginBottom: '20px',
						marginLeft: '10px',
						marginRight: '10px',
						height: '170px',
						minWidth: 'max-content',
						borderRadius: '20px'
					}}
				>
					<div className="card-body">
						<div
							className="row"
							style={{ display: 'flex', justifyContent: 'center', marginBottom: '12px' }}
						>
							<img
								src={require('../assets/images/kiosk/surat/skgr.png')}
								alt="logo"
								height="65"
								style={styleImgIcon}
							/>
						</div>
						<div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
							<p
								className="mb-0"
								style={{
									fontSize: '20px',
									fontWeight: '600',
									color: '#000000'
								}}
							>
								Kuasa SKGR
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
