import React, { Component, } from 'react';
// import { Button, Col, Row } from 'reactstrap';
import Slider from 'react-slick';
// import { FormControl } from 'react-bootstrap';

import SuratIzinKeramainan from './SuratIzinKeramainan';
import SuratKeteranganUsaha from './SuratKeteranganUsaha';
import SuratKeteranganDomisili from './SuratKeteranganDomisili';
import SuratJalan from './SuratJalan';
import SuratKeteranganAhliWaris from './SuratKeteranganAhliWaris';
import SuratKelahiran from './SuratKelahiran';
import SuratBelumMemilikiRumah from './SuratBelumMemilikiRumah';
import SuratKematian from './SuratKematian';
import SuratKepemilikanRumah from './SuratKepemilikanRumah';
import SuratPernahMenikah from './SuratPernahMenikah';
import SuratPenghasilan from './SuratPenghasilan';
import SuratTidakMampu from './SuratTidakMampu';
import SuratKuasaSKGR from './SuratKuasaSKGR';
import SuratPindahNikah from './SuratPindahNikah';
import SuratSKCK from './SuratSKCK';
import SuratBelumMenikah from './SuratBelumMenikah';
import SuratKehilangan from './SuratKehilangan';
import SuratTidakMenikah from './SuratTidakMenikah';

import API_URL, { Get, GetAwait, JENIS_DESA, KECAMATAN, Nama_DESA, NAMA_KABUPATEN, Post, PROVINSI,PostDemo, GetDemo } from '../function/Koneksi';
import Modal from 'react-responsive-modal';
import Iframe from 'react-iframe'
// import SuratPenghasilan from './SuratPenghasilan';
// import SuratTidakMampu from './SuratTidakMampu';
// import SuratKuasaSKGR from './SuratKuasaSKGR';
// import SuratSKCK from './SuratSKCK';
import '../assets/css/site.css';

import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
// import SuratKehilangan from './SuratKehilangan';
// import SuratPernahMenikah from './SuratPernahMenikah';
// import SuratPindahNikah from './SuratPindahNikah';
import { Redirect } from 'react-router-dom';

let LIST_LAYANAN_DESA = JSON.parse(localStorage.getItem('LAYANAN_DESA'));
export default class Layanan extends Component {
	constructor(props) {
		super(props);
		this.nextSlide = this.nextSlide.bind(this);
		this.prevSlide = this.prevSlide.bind(this);
		this.state = {
			navigate: false,
			status: {
				frmNomorAntrian: false,
				modalKonfirmasi: false,
				modalKonfirmasi2: false,
				messageAntrian: '',
				messageAntrian2: true,
				jenisSurat: '',
				statusSurat: '',
				frmPrintKonfirmasi: false,
				frmPrint: false,
			},
			layanan: [],
			perizinan: [],
			show: false,
			basicType: 'info',
			basicTitle: '',
			pesanAlert: '',
			nomorAntrian: 0,
			anggotaKeluarga: [],
			tmplistPenduduk: [],
			listAgama: [],
			listPekerjaan: [],
			listShdk: [],
			
			// Loading
			loading: true,
			loadingPreview: false,
			
			get_data_surat:[],
			get_jenis_surat: "",
			data_cek: '',
		};
	}

	logOut(e) {
		e.preventDefault();
		// sessionStorage.removeItem('access_token');
		this.setState({ navigate: true });
	}

	getPreData = async () => {
		const dataPenduduk = this.props.location.state;

		// Data KK
		const dataKK = await GetAwait("penduduk/kk/" + dataPenduduk.results.id_kk, null)
		// Data Agama
		const dataAgama = await GetAwait("agama/list", null)
		// Data Pekerjaan
		const dataPekerjaan = await GetAwait("pekerjaan/list", null)
		// Data Shdk
		const dataShdk = await GetAwait("shdk/list", null)
		
		// Jika data KK berhasil
		if(dataKK.data.results){
			let anggotaKeluarga = [];
			let tmplistPenduduk = {};
			dataKK.data.results.forEach(dt => {
				anggotaKeluarga.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
				tmplistPenduduk = {
					...tmplistPenduduk,
					[dt.id]: dt
				}
			});
			this.setState({ anggotaKeluarga, tmplistPenduduk });

			// Jika dataAgama berhasil
			if(dataAgama.data.results){
				let listAgama = [];
				dataAgama.data.results.forEach((dt) => {
					listAgama.push({ value: dt.uuid, label: dt.nama });
				});
				this.setState({ listAgama });
			}

			// Jika dataPekerjaan berhasil
			if(dataPekerjaan.data.results){
				let listPekerjaan = [];
				dataPekerjaan.data.results.forEach((dt) => {
					listPekerjaan.push({ value: dt.uuid, label: dt.nama });
				});
				this.setState({ listPekerjaan });
			}

			// Jika dataShdk berhasil
			if(dataShdk.data.results){
				let listShdk = [];
				dataShdk.data.results.forEach((dt) => {
					listShdk.push({ value: dt.uuid, label: dt.nama });
				});
				this.setState({ listShdk });
			}

			// Get jenis surat
			this.getData();
		}
	}

	componentDidMount() {
		LIST_LAYANAN_DESA = JSON.parse(localStorage.getItem("LAYANAN_DESA"));

		//console.log("Data didmount")
		//console.log(LIST_LAYANAN_DESA.layanan_kiosk)

		if(LIST_LAYANAN_DESA.layanan_kiosk === 'undefined' || LIST_LAYANAN_DESA.layanan_kiosk===null || LIST_LAYANAN_DESA.layanan_kiosk === '[]' || LIST_LAYANAN_DESA.layanan_kiosk === ""){
			this.setState({
				data_cek: "Kosong",
			})
			this.forceUpdate()
		}
		else{
			this.setState({
				data_cek: "Tidak Kosong",
			})
			this.forceUpdate()
		}

		if (navigator.onLine) {
			// Get data ws
			this.getPreData()
		} else {
			this.setState({
				show: true,
				basicType: 'info',
				basicTitle: 'Login',
				pesanAlert: 'Jaringan internet tidak aktif...'
			});
		}
	}

	getData = (fil = null) => {
		// //console.log(fil);
		if (fil) {
			let filJenisSurat = {
				nama: fil
			};
			PostDemo('jenis-surat/list', null, filJenisSurat, (data) => {
				let layanan = [];
				let perizinan = [];
				// data.data.results.forEach((dt) => {
				// 	if (dt.kategori === 'Layanan Masyarakat') {
				// 		layanan.push(dt);
				// 	}
				// 	if (dt.kategori === 'Perizinan Masyarakat') {
				// 		perizinan.push(dt);
				// 	}
				// });
				this.setState({ layanan: data.data.results, perizinan: data.data.results, loading: false, });
			});
		} else {
			GetDemo('jenis-surat/list', null, (data) => {
				let layanan = [];
				let perizinan = [];
				//console.log(data);
				if (typeof data !== 'undefined') {
					// data.results.forEach((dt) => {
					// 	if (dt.kategori === 'Layanan Masyarakat') {
					// 		layanan.push(dt);
					// 	}
					// 	if (dt.kategori === 'Perizinan Masyarakat') {
					// 		perizinan.push(dt);
					// 	}
					// });
					this.setState({ layanan: data.results, perizinan: data.results, loading: false, });
				}

				// //console.log(this.state.layanan);
			});
		}
	};

	isComplete = (stat, noted = null) => {
		if (stat === false) {
			if (noted !== null) {
				this.setState({
					show: true,
					basicType: 'info',
					basicTitle: 'Data Surat',
					pesanAlert:
						'Pemohon memiliki catatan yang belum selesai, hubungi petugas kantor ' + JENIS_DESA.toLowerCase() + ' untuk info lebih lanjut'
				});
			} else {
				this.setState({
					show: true,
					basicType: 'info',
					basicTitle: 'Data Surat',
					pesanAlert: 'Data Surat Belum Lengkap. Silahkan lengkapi seluruh data yang dibutuhkan pada kolom yang tersedia'
				});
			}
		}
	};

	getNomorAntrian = (jenis_surat, status, uncomplete = false, data) => {
		this.setState({ loadingPreview: false })
		if (status === 'berhasil') {
			//console.log("Mari Kita Coba Print")
			//console.log("Data Get Nomor Buskuqoweqwe")
			//console.log(data)

			let set_jenis = '';
			let layanan_jenis = '';
			switch (jenis_surat) {
				case 'Surat Keterangan Belum Memiliki Rumah':
					set_jenis = 'belum-memiliki-rumah';

					if(LIST_LAYANAN_DESA.cetak_layanan_kiosk.belum_memiliki_rumah===true){
						layanan_jenis = true;
					}
					else{
						layanan_jenis = false;
					}
					break;
				case 'Surat Pernyataan Belum Menikah':
					set_jenis = 'belum-menikah';

					if(LIST_LAYANAN_DESA.cetak_layanan_kiosk.belum_menikah===true){
						layanan_jenis = true;
					}
					else{
						layanan_jenis = false;
					}
					break;
				case 'Surat Izin Keramaian':
					set_jenis = 'keramaian';

					if(LIST_LAYANAN_DESA.cetak_layanan_kiosk.keramaian===true){
						layanan_jenis = true;
					}
					else{
						layanan_jenis = false;
					}
					break;
				case 'Surat Keterangan Jalan':
					set_jenis = 'surat-jalan';

					if(LIST_LAYANAN_DESA.cetak_layanan_kiosk.jalan===true){
						layanan_jenis = true;
					}
					else{
						layanan_jenis = false;
					}
					break;
				case 'Surat Pernyataan Kehilangan':
					set_jenis = 'kehilangan';

					if(LIST_LAYANAN_DESA.cetak_layanan_kiosk.kehilangan===true){
						layanan_jenis = true;
					}
					else{
						layanan_jenis = false;
					}
					break;
				case 'Surat Keterangan Kelahiran':
					set_jenis = 'kelahiran';

					if(LIST_LAYANAN_DESA.cetak_layanan_kiosk.kelahiran===true){
						layanan_jenis = true;
					}
					else{
						layanan_jenis = false;
					}
					break;
				case 'Surat Keterangan Kematian':
					set_jenis = 'kematian';

					if(LIST_LAYANAN_DESA.cetak_layanan_kiosk.kematian===true){
						layanan_jenis = true;
					}
					else{
						layanan_jenis = false;
					}
					break;
				case 'Surat Keterangan Kepemilikan Rumah':
					set_jenis = 'kepemilikan-rumah';

					if(LIST_LAYANAN_DESA.cetak_layanan_kiosk.kepemilikan_rumah===true){
						layanan_jenis = true;
					}
					else{
						layanan_jenis = false;
					}
					break;
				case 'Surat Keterangan Ahli Waris':
					set_jenis = 'ahli-waris';

					if(LIST_LAYANAN_DESA.cetak_layanan_kiosk.ahli_waris===true){
						layanan_jenis = true;
					}
					else{
						layanan_jenis = false;
					}
					break;
				case 'Surat Keterangan Domisili':
					set_jenis = 'domisili';

					if(LIST_LAYANAN_DESA.cetak_layanan_kiosk.domisili===true){
						layanan_jenis = true;
					}
					else{
						layanan_jenis = false;
					}
					break;
				case 'Surat Keterangan Usaha':
					set_jenis = 'usaha';

					if(LIST_LAYANAN_DESA.cetak_layanan_kiosk.usaha===true){
						layanan_jenis = true;
					}
					else{
						layanan_jenis = false;
					}
					break;
				case 'Surat Kuasa SKGR':
					set_jenis = 'skgr';

					if(LIST_LAYANAN_DESA.cetak_layanan_kiosk.skgr===true){
						layanan_jenis = true;
					}
					else{
						layanan_jenis = false;
					}
					break;
				case 'Surat Keterangan Penghasilan':
					set_jenis = 'penghasilan';

					if(LIST_LAYANAN_DESA.cetak_layanan_kiosk.penghasilan===true){
						layanan_jenis = true;
					}
					else{
						layanan_jenis = false;
					}
					break;
				case 'Surat Keterangan Pernah Menikah':
					set_jenis = 'pernah-menikah';

					if(LIST_LAYANAN_DESA.cetak_layanan_kiosk.pernah_menikah===true){
						layanan_jenis = true;
					}
					else{
						layanan_jenis = false;
					}
					break;
				case 'Surat Pengantar Pindah Nikah':
					set_jenis = 'pindah-nikah';

					if(LIST_LAYANAN_DESA.cetak_layanan_kiosk.pindah_menikah===true){
						layanan_jenis = true;
					}
					else{
						layanan_jenis = false;
					}
					break;
				case 'Surat Pengantar SKCK':
					set_jenis = 'skck';

					if(LIST_LAYANAN_DESA.cetak_layanan_kiosk.skck===true){
						layanan_jenis = true;
					}
					else{
						layanan_jenis = false;
					}
					break;
				case 'Surat Keterangan Tidak Mampu':
					set_jenis = 'tidak-mampu';

					if(LIST_LAYANAN_DESA.cetak_layanan_kiosk.tidak_mampu===true){
						layanan_jenis = true;
					}
					else{
						layanan_jenis = false;
					}
					break;
				case 'Surat Keterangan Tidak Menikah':
					set_jenis = 'tidak-menikah';

					if(LIST_LAYANAN_DESA.cetak_layanan_kiosk.tidak_menikah===true){
						layanan_jenis = true;
					}
					else{
						layanan_jenis = false;
					}
					break;
				default:
					set_jenis = '';
					layanan_jenis = '';
					break;
				// ================================
				// case 'Surat Keterangan Tidak Mampu':
				// 	set_jenis = 'tidak-mampu';
				// 	break;
				// case 'Surat Keterangan Domisili':
				// 	set_jenis = 'domisili';
				// 	break;
				// case 'Surat Keterangan Ahli Waris':
				// 	set_jenis = 'ahli-waris';
				// 	break;
				// case 'Surat Keterangan Kelahiran':
				// 	set_jenis = 'kelahiran';
				// 	break;
				// case 'Surat Keterangan Kematian':
				// 	set_jenis = 'kematian';
				// 	break;
				// case 'Surat Keterangan Penghasilan':
				// 	set_jenis = 'penghasilan';
				// 	break;
				// case 'Surat Keterangan Pernah Menikah':
				// 	set_jenis = 'pernah-menikah';
				// 	break;
				// case 'Surat Kuasa':
				// 	set_jenis = 'kuasa';
				// 	break;
				// case 'Surat Kuasa SKGR':
				// 	set_jenis = 'kuasa';
				// 	break;
				// case 'Surat Undangan':
				// 	kop_surat = 'surat-undangan';
				// 	set_jenis = '';
				// 	break;

				
			}
			layanan_jenis = true

			layanan_jenis = true

			//console.log("AAAHAHAHAHAHA")
			//console.log(layanan_jenis)

			this.setState({
				status:{
					...this.state.status,
					jenisSurat: jenis_surat+' Berhasil Dibuat',
					messageAntrian:
						'Cetak Surat Anda Sekarang?',
				}
			})

			if(layanan_jenis===true){
				this.setState({
					status:{
						...this.state.status,
						frmPrintKonfirmasi: true,
						modalKonfirmasi: false,
					}
				})
			}
			else{
				this.setState({
					status:{
						...this.state.status,
						frmPrintKonfirmasi: false,
						modalKonfirmasi: true,
					}
				})
			}
			this.setState({
				status:{
					...this.state.status,
					statusSurat: status,
				},
				get_data_surat: data,
				get_jenis_surat: set_jenis,
			})
			this.forceUpdate();
			// Post('antrian/create', null, data, (res) => {
			// 	// //console.log(data);
			// 	//console.log(res);
			// 	if (res.status ==== 201) {
			// 		this.state.nomorAntrian = res.data.results.nomor;
			// 		this.state.status.jenisSurat = jenis_surat;
			// 		this.state.status.messageAntrian =
			// 			'Silahkan menunggu nomor antrian anda dipanggil untuk mengambil surat. Terima kasih';
			// 		this.state.status.frmNomorAntrian = true;
			// 		this.state.status.statusSurat = status;
			// 		this.forceUpdate();
			// 	} else {
			// 		this.state.show = true;
			// 		this.state.basicType = 'info';
			// 		this.state.basicTitle = 'Data Surat';
			// 		this.state.pesanAlert =
			// 			'Mohon maaf, surat anda tidak berhasil diajukan. Mohon ajukan surat lagi atau hubungi pegawai di kantor ' + JENIS_DESA.toLowerCase() + '.';
			// 		this.state.status.statusSurat = status;
			// 		this.forceUpdate();
			// 	}
			// });
		} else if (status === 'cari nik') {
			this.setState({
				show: true,
				basicType: 'info',
				basicTitle: 'Data Tidak Ditemukan',
				pesanAlert: 'Silahkan Periksa Kembali NIK',
			})
		} else if (status === 'cek digit nik') {
			this.setState({
				show: true,
				basicType: 'warning',
				basicTitle: 'NIK yang anda masukkan Salah',
				pesanAlert: 'Jumlah angka NIK kurang 16 angka. Silahkan cek NIK kembali',
			})
		} else if (status === 'cekDataPewaris') {
			this.setState({
				show: true,
				basicType: 'info',
				basicTitle: 'Data Ahli Waris',
				pesanAlert: 'Silahkan pilih NIK dan hubungan keluarga sebelum menambahkan data pewaris',
			})
		} else if (status === 'cekDataPengikut') {
			this.setState({
				show: true,
				basicType: 'info',
				basicTitle: 'Data ' + jenis_surat,
				pesanAlert: 'Silahkan pilih NIK dan hubungan keluarga sebelum menambahkan data pengikut',
			})
		} else if (status === 'cekDataShdk') {
			this.setState({
				show: true,
				basicType: 'info',
				basicTitle: 'Data Status Hubungan Dalam Keluarga',
				pesanAlert: 'Silahkan pilih status hubungan dalam keluarga sebelum menambahkan data',
			})
		} else {
			if (uncomplete) {
				this.setState({
					show: true,
					basicType: 'info',
					basicTitle: 'Data Surat',
					pesanAlert: 'Data Belum Lengkap. Silahkan Lengkapi Seluruh Data yang diperlukan',
				})
			} else {
				this.setState({
					show: true,
					basicType: 'info',
					basicTitle: 'Data Surat',
					pesanAlert:
						'Mohon maaf, surat anda tidak berhasil diajukan. Mohon ajukan surat lagi atau hubungi pegawai di kantor ' + JENIS_DESA.toLowerCase() + '.',
				})
			}
		}

		this.setState({
			status:{
				...this.state.status,
				statusSurat: status,
			}
		})
		this.forceUpdate();
	};

	tutupForm = () => {
		this.setState({
			status:{
				...this.state.status,
				frmNomorAntrian: false,
				frmPrintKonfirmasi: false,
			}
		})
		// this.state.status.modalKonfirmasi = true;
		// this.state.status.messageAntrian2 = true;
		this.forceUpdate();
	};

	cetakAntrian = () => {
		// let halaman_antrian = document.getElementById('halaman_print_antrian');
		// let antrian = document.getElementById('kertas_antrian').contentWindow;

		// antrian.document.open();
		// antrian.document.write(halaman_antrian.innerHTML);
		// antrian.document.close();
		// antrian.focus();
		// antrian.print();
		// window.print();
		// const printContents = document.getElementById('kertas_antrian').innerHTML;
		// const originalContents = document.body.innerHTML;

		// document.body.innerHTML = printContents;

		window.print();

		// document.body.innerHTML = originalContents;
	};

	nextSlide() {
		this.slider.slickNext();
	}

	prevSlide() {
		this.slider.slickPrev();
	}

	render() {
		
		//console.log("List Layanan");
		//console.log(LIST_LAYANAN_DESA);
		//console.log(LIST_LAYANAN_DESA.cetak_layanan_kiosk);

		//console.log("ID SURAT IFREAMENYA")
		//console.log(this.state.get_data_surat.uuid_surat)
		if (this.state.navigate || typeof this.props.location.state === 'undefined') {
			return <Redirect to="/" push={true} />;
		}

		// //console.log('Data yang diparsing');
		// //console.log(this.props.location.state);

		var Merge_settings = {
			className: 'center',
			// centerMode: true,
			dots: true,
			infinite: true,
			swipe: false,
			// speed: 100,
			slidesToShow: 4,
			slidesToScroll: 4,
			rows: 2,
			margin: 2,
			// initialSlide: 0,
			//   slidesPerRow: 2,
			// centerMode: true,
			// variableWidth: true,
			adaptiveHeight: true,

			responsive: [
				{
					breakpoint: 1280,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						dots: true,
						infinite: true
						// speed: 100
					}
				},
				{
					breakpoint: 980,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
						initialSlide: 2

						// dots: true,
						// infinite: true,
						// speed: 100
					}
				},
				{
					breakpoint: 680,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		};

		const background = require('../assets/images/auth-layer.png');

		const dataPenduduk = this.props.location.state;
		// //console.log(dataPenduduk);

		let LIST_LAYANAN = LIST_LAYANAN_DESA.layanan_kiosk
		const tampilLayanan = this.state.layanan.map((dt) => {
			// let link = dt.nama.toLowerCase().replace(/ /g, '-');
			let nama = dt.nama.toLowerCase();
			// let condition = false;
			// //console.log(nama);

			switch (nama) {
				case 'surat izin keramaian':
					if (LIST_LAYANAN !== null && LIST_LAYANAN.keramaian) {
						return (
							<SuratIzinKeramainan
								data={dataPenduduk}
								anggotaKeluarga={this.state.anggotaKeluarga}
								tmplistPenduduk={this.state.tmplistPenduduk}
								id_jenis={dt.uuid}
								isComplete={(stat) => this.isComplete(stat)}
								getAntrian={(jenis_surat, status, uncomplete, data) =>
									this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
								setLoadingPreview={(e) => {
									this.setState({ loadingPreview: e })
								}}
							/>
						);
					}
					break;

				case 'surat pengantar pindah nikah':
					if (LIST_LAYANAN !== null && LIST_LAYANAN.pindah_menikah) {
						return (
							<SuratPindahNikah
								data={dataPenduduk}
								anggotaKeluarga={this.state.anggotaKeluarga}
								tmplistPenduduk={this.state.tmplistPenduduk}
								listAgama={this.state.listAgama}
								listPekerjaan={this.state.listPekerjaan}
								id_jenis={dt.uuid}
								isComplete={(stat) => this.isComplete(stat)}
								getAntrian={(jenis_surat, status, uncomplete, data) =>
									this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
								setLoadingPreview={(e) => {
									this.setState({ loadingPreview: e })
								}}
							/>
						);
					}
					break;

				case 'surat keterangan domisili':
					if (LIST_LAYANAN !== null && LIST_LAYANAN.domisili) {
						return (
							<SuratKeteranganDomisili
								data={dataPenduduk}
								anggotaKeluarga={this.state.anggotaKeluarga}
								tmplistPenduduk={this.state.tmplistPenduduk}
								id_jenis={dt.uuid}
								isComplete={(stat) => this.isComplete(stat)}
								getAntrian={(jenis_surat, status, uncomplete, data) =>
									this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
								setLoadingPreview={(e) => {
									this.setState({ loadingPreview: e })
								}}
							/>
						);
					}
					break;

				case 'surat keterangan usaha':
					if (LIST_LAYANAN !== null && LIST_LAYANAN.usaha) {
						return (
							<SuratKeteranganUsaha
								data={dataPenduduk}
								anggotaKeluarga={this.state.anggotaKeluarga}
								tmplistPenduduk={this.state.tmplistPenduduk}
								id_jenis={dt.uuid}
								isComplete={(stat) => this.isComplete(stat)}
								getAntrian={(jenis_surat, status, uncomplete, data) =>
									this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
								setLoadingPreview={(e) => {
									this.setState({ loadingPreview: e })
								}}
							/>
						);
					}
					break;

				case 'surat keterangan jalan':
					if (LIST_LAYANAN !== null && LIST_LAYANAN.jalan) {
						return (
							<SuratJalan
								data={dataPenduduk}
								anggotaKeluarga={this.state.anggotaKeluarga}
								tmplistPenduduk={this.state.tmplistPenduduk}
								id_jenis={dt.uuid}
								isComplete={(stat) => this.isComplete(stat)}
								getAntrian={(jenis_surat, status, uncomplete, data) =>
									this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
								setLoadingPreview={(e) => {
									this.setState({ loadingPreview: e })
								}}
							/>
						);
					}
					break;

				case 'surat keterangan ahli waris':
					if (LIST_LAYANAN !== null && LIST_LAYANAN.ahli_waris) {
						return (
							<SuratKeteranganAhliWaris
								data={dataPenduduk}
								anggotaKeluarga={this.state.anggotaKeluarga}
								tmplistPenduduk={this.state.tmplistPenduduk}
								id_jenis={dt.uuid}
								isComplete={(stat) => this.isComplete(stat)}
								getAntrian={(jenis_surat, status, uncomplete, data) =>
									this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
								setLoadingPreview={(e) => {
									this.setState({ loadingPreview: e })
								}}
							/>
						);
					}
					break;

				case 'surat keterangan kelahiran':
					if (LIST_LAYANAN !== null && LIST_LAYANAN.kelahiran) {
						return (
							<SuratKelahiran
								data={dataPenduduk}
								anggotaKeluarga={this.state.anggotaKeluarga}
								tmplistPenduduk={this.state.tmplistPenduduk}
								listAgama={this.state.listAgama}
								listPekerjaan={this.state.listPekerjaan}
								id_jenis={dt.uuid}
								isComplete={(stat) => this.isComplete(stat)}
								getAntrian={(jenis_surat, status, uncomplete, data) =>
									this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
								setLoadingPreview={(e) => {
									this.setState({ loadingPreview: e })
								}}
							/>
						);
					}
					break;

				case 'surat keterangan belum memiliki rumah':
					if (LIST_LAYANAN !== null && LIST_LAYANAN.belum_memiliki_rumah) {
						return (
							<SuratBelumMemilikiRumah
								data={dataPenduduk}
								anggotaKeluarga={this.state.anggotaKeluarga}
								tmplistPenduduk={this.state.tmplistPenduduk}
								id_jenis={dt.uuid}
								isComplete={(stat) => this.isComplete(stat)}
								getAntrian={(jenis_surat, status, uncomplete, data) =>
									this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
								setLoadingPreview={(e) => {
									this.setState({ loadingPreview: e })
								}}
							/>
						);
					}
					break;

				case 'surat keterangan kematian':
					if (LIST_LAYANAN !== null && LIST_LAYANAN.kematian) {
						return (
							<SuratKematian
								data={dataPenduduk}
								anggotaKeluarga={this.state.anggotaKeluarga}
								tmplistPenduduk={this.state.tmplistPenduduk}
								id_jenis={dt.uuid}
								isComplete={(stat) => this.isComplete(stat)}
								getAntrian={(jenis_surat, status, uncomplete, data) =>
									this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
								setLoadingPreview={(e) => {
									this.setState({ loadingPreview: e })
								}}
							/>
						);
					}
					break;

				case 'surat keterangan penghasilan':
					if (LIST_LAYANAN !== null && LIST_LAYANAN.penghasilan) {
						return (
							<SuratPenghasilan
								data={dataPenduduk}
								anggotaKeluarga={this.state.anggotaKeluarga}
								tmplistPenduduk={this.state.tmplistPenduduk}
								id_jenis={dt.uuid}
								isComplete={(stat) => this.isComplete(stat)}
								getAntrian={(jenis_surat, status, uncomplete, data) =>
									this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
								setLoadingPreview={(e) => {
									this.setState({ loadingPreview: e })
								}}
							/>
						);
					}
					break;

				case 'surat keterangan tidak mampu':
					if (LIST_LAYANAN !== null && LIST_LAYANAN.tidak_mampu) {
						return (
							<SuratTidakMampu
								data={dataPenduduk}
								anggotaKeluarga={this.state.anggotaKeluarga}
								tmplistPenduduk={this.state.tmplistPenduduk}
								id_jenis={dt.uuid}
								isComplete={(stat) => this.isComplete(stat)}
								getAntrian={(jenis_surat, status, uncomplete, data) =>
									this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
								setLoadingPreview={(e) => {
									this.setState({ loadingPreview: e })
								}}
							/>
						);
					}
					break;

				case 'surat kuasa skgr':
					if (LIST_LAYANAN !== null && LIST_LAYANAN.skgr) {
						return (
							<SuratKuasaSKGR
								data={dataPenduduk}
								anggotaKeluarga={this.state.anggotaKeluarga}
								tmplistPenduduk={this.state.tmplistPenduduk}
								listPekerjaan={this.state.listPekerjaan}
								id_jenis={dt.uuid}
								isComplete={(stat) => this.isComplete(stat)}
								getAntrian={(jenis_surat, status, uncomplete, data) =>
									this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
								setLoadingPreview={(e) => {
									this.setState({ loadingPreview: e })
								}}
							/>
						);
					}
					break;

				case 'surat pengantar skck':
					if (LIST_LAYANAN !== null && LIST_LAYANAN.skck) {
						return (
							<SuratSKCK
								data={dataPenduduk}
								anggotaKeluarga={this.state.anggotaKeluarga}
								tmplistPenduduk={this.state.tmplistPenduduk}
								id_jenis={dt.uuid}
								isComplete={(stat) => this.isComplete(stat)}
								getAntrian={(jenis_surat, status, uncomplete, data) =>
									this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
								setLoadingPreview={(e) => {
									this.setState({ loadingPreview: e })
								}}
							/>
						);
					}
					break;

				case 'surat pernyataan kehilangan':
					if (LIST_LAYANAN !== null && LIST_LAYANAN.kehilangan) {
						return (
							<SuratKehilangan
								data={dataPenduduk}
								anggotaKeluarga={this.state.anggotaKeluarga}
								tmplistPenduduk={this.state.tmplistPenduduk}
								id_jenis={dt.uuid}
								isComplete={(stat) => this.isComplete(stat)}
								getAntrian={(jenis_surat, status, uncomplete, data) =>
									this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
								setLoadingPreview={(e) => {
									this.setState({ loadingPreview: e })
								}}
							/>
						);
					}
					break;

				case 'surat keterangan pernah menikah':
					if (LIST_LAYANAN !== null && LIST_LAYANAN.pernah_menikah) {
						return (
							<SuratPernahMenikah
								data={dataPenduduk}
								anggotaKeluarga={this.state.anggotaKeluarga}
								tmplistPenduduk={this.state.tmplistPenduduk}
								listAgama={this.state.listAgama}
								listPekerjaan={this.state.listPekerjaan}
								id_jenis={dt.uuid}
								isComplete={(stat, noted) => this.isComplete(stat, noted)}
								getAntrian={(jenis_surat, status, uncomplete, data) =>
									this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
								setLoadingPreview={(e) => {
									this.setState({ loadingPreview: e })
								}}
							/>
						);
					}
					break;

				case 'surat keterangan kepemilikan rumah':
					if (LIST_LAYANAN !== null && LIST_LAYANAN.kepemilikan_rumah) {
						return (
							<SuratKepemilikanRumah
								data={dataPenduduk}
								anggotaKeluarga={this.state.anggotaKeluarga}
								tmplistPenduduk={this.state.tmplistPenduduk}
								id_jenis={dt.uuid}
								isComplete={(stat) => this.isComplete(stat)}
								getAntrian={(jenis_surat, status, uncomplete, data) =>
									this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
								setLoadingPreview={(e) => {
									this.setState({ loadingPreview: e })
								}}
							/>
						);
					}
					break;

				case 'surat pernyataan belum menikah':
					if (LIST_LAYANAN !== null && LIST_LAYANAN.belum_menikah) {
						return (
							<SuratBelumMenikah
								data={dataPenduduk}
								anggotaKeluarga={this.state.anggotaKeluarga}
								tmplistPenduduk={this.state.tmplistPenduduk}
								listShdk={this.state.listShdk}
								id_jenis={dt.uuid}
								isComplete={(stat) => this.isComplete(stat)}
								getAntrian={(jenis_surat, status, uncomplete, data) =>
									this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
								setLoadingPreview={(e) => {
									this.setState({ loadingPreview: e })
								}}
							/>
						);
					}
					break;

				case 'surat pernyataan tidak menikah':
					if (LIST_LAYANAN !== null && LIST_LAYANAN.tidak_menikah) {
						return (
							<SuratTidakMenikah
								data={dataPenduduk}
								anggotaKeluarga={this.state.anggotaKeluarga}
								tmplistPenduduk={this.state.tmplistPenduduk}
								id_jenis={dt.uuid}
								isComplete={(stat) => this.isComplete(stat)}
								getAntrian={(jenis_surat, status, uncomplete, data) =>
									this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
								setLoadingPreview={(e) => {
									this.setState({ loadingPreview: e })
								}}
							/>
						);
					}
					break;

				default:
					break;
			}
		});

		let logoAplikasi = (
			<img
				src={require('../assets/images/digdes.webp').default}
				alt="logo"
				style={{
					height: '35px'
					// width: '45px',
					// marginTop: '-10px'
				}}
			/>
    )

    switch (localStorage.getItem('JENIS_APLIKASI').toLowerCase()) {
		case 'halaman kampung':
			logoAplikasi = (
						<img
							src={require('../assets/images/hk_icon.png')}
							alt="logo"
							style={{
								height: '35px'
								// width: '45px',
								// marginTop: '-10px'
							}}
						/>
			)
			break;
		case 'amanat nagari':
			logoAplikasi = (
						<img
							src={require('../assets/images/amanat_nagari_logo.png')}
							alt="logo"
							style={{
								height: '35px'
								// width: '45px',
								// marginTop: '-10px'
							}}
						/>
			)
			break;
		default:
			logoAplikasi = (
				<img
					src={require('../assets/images/digdes.webp').default}
					alt="logo"
					style={{
						height: '35px'
						// width: '45px',
						// marginTop: '-10px'
					}}
				/>
			)
			break;
    }

		return (
			<>
				<div
					id="bg-kiosk"
					style={{
						// background: 'linear-gradient(135deg, #07057f, #09759e)',
						// background: 'linear-gradient(135deg, #59dc23, #0b9e09)',
						// background: 'linear-gradient(135deg, #a75c1d, #262aad)',
						// background: 'linear-gradient(135deg, #a5d434, #67e00e)',
						// background: 'linear-gradient(135deg, #46ecc3, #017cff)',
						// background: 'linear-gradient(135deg, #3F51B5, #017cff)',
						// background: 'linear-gradient(135deg, #607D8B, #28a745)',
						// background: 'linear-gradient(135deg, #aff1eb, #17a2b8)',
						// height: '100vh',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-evenly'
					}}
				>
					<div
						className="row"
						style={{
							// padding: '20px 20px 0px 20px',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
							position: 'relative',
							zIndex: 1
						}}
					>
						<div
							// className="card-body"
							style={{
								// backgroundColor: '#ffffffc7',
								// background: 'linear-gradient(45deg, #e4e4e4, #a8b6c3)',
								width: 'max-content',
								// width: '33%',
								height: 'max-content',
								borderRadius: '45px',
								padding: '1em 2em',
								boxShadow: '0 4px 8px 0 #0000003d, 0 6px 20px 0 #0000003d',
								zIndex: 2
							}}
						>
							<div
								className="media"
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center'
								}}
							>
								{logoAplikasi}

								<div
									className="media-body align-self-left"
									style={{
										padding: '0px 10px',
										fontWeight: '600'
									}}
								>
									<span
										style={{
											fontSize: '1.7em',
											// color: '#464646',
											color: '#ffffff',
											fontWeight: '600'
										}}
									>
										Kios Pelayanan Masyarakat
									</span>
								</div>
							</div>
						</div>
						<div
							id="logout-kiosk"
							className="logout-kiosk"
							style={{
								// width: '33%',
								position: 'absolute',
								top: 15,
								right: 40,
								width: 'max-content',
								height: 'max-content',
								borderRadius: '45px',
								padding: '0.5em 1em',
								// backgroundColor: '#ffffff',
								backgroundColor: '#ff0000',
								boxShadow: '0 4px 8px 0 #0000003d, 0 6px 20px 0 #0000003d',
								// boxShadow: '0 0 30px 0 #ffffffd1 , 0 0 20px 0 #ffffffd1 ',
								cursor: 'pointer',
								zIndex: 100
							}}
							onClick={() => {
								this.setState({
									status:{
										...this.state.status,
										modalKonfirmasi2: true,
									}
								})
								this.forceUpdate();
							}}
						>
							<h5
								style={{
									fontSize: '1.2em',
									color: '#ffffff',
									fontWeight: '500',
									marginBottom: '0px'
								}}
							>
								Keluar
							</h5>
						</div>
					</div>


				{this.state.loading ? (
					<div className="row">
						<div className="col-sm-12" align="center">
							<img 
							alt=""
							src={require("../assets/images/tes-loading.gif")} 
							style={{
								// width: "100%",
								borderRadius: "10px",
								width: '100px'
							}}/> 
						</div>
					</div>
				) : 
				<>
					<div style={{ padding: '0px 15px' }}>
						{
							this.state.data_cek === "Kosong"
							?
							<>
								<div align="center">
									<div
										style={{
											width: '53%',
											height: 'max-content',
											// width: '627px',
											// height: '346px',
											// marginLeft: '65px',
											// marginTop: '18px',
											borderRadius: '19px',
											backgroundSize: 'cover',
											backgroundImage: `url(${background})`
										}}
									>
										<div style={{ padding: '59px 75px 55px 75px' }}>
											<h4>Layanan Tidak Tersedia, <br/>Silahkan Hubungi Admin</h4>
										</div>
									</div>
								</div>
							</>
							:
							<>
							<Slider ref={(c) => (this.slider = c)} {...Merge_settings}>
								{tampilLayanan}
							</Slider>
							<div style={{ textAlign: 'center', marginTop: '10px' }}>
								<button className="btn btn-primary-custom" style={{ margin: '0px 5px' }} onClick={this.prevSlide}>
									Sebelumnya
								</button>
								<button className="btn btn-primary-custom" style={{ margin: '0px 5px' }} onClick={this.nextSlide}>
									Selanjutnya
								</button>
							</div>
							</>
						}
						
					</div>
				</>
				}

					<div
						className="row"
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<h5 style={{ fontSize: '1em', color: '#ffffff', textShadow: '1px 0px 5px #000000' }}>
							Kantor {Nama_DESA}, {KECAMATAN}, {NAMA_KABUPATEN}, {PROVINSI}
						</h5>
						<h5 style={{ fontSize: '1em', color: '#ffffff', textShadow: '1px 0px 5px #000000' }}>
							© PT. Solusi Data Madani, Pekanbaru, Riau.
						</h5>
					</div>
				</div>
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					cancelBtnBsStyle="info"
					onCancel={() => {
						this.setState({
							show: false
						});
					}}
					showConfirm={false}
				>
					{this.state.pesanAlert}
				</SweetAlert>
				
				<Modal
					open={this.state.status.frmNomorAntrian}
					// open={true}
					// open={true}
					// onClose={false}
					styles={{ modal: { width: '400px' } }}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<div
						id="kertas_antrian"
						className="modal-body"
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							alignItems: 'center'
						}}
					>
						<div className="row" style={{ textAlign: 'center' }}>
							<h4>{this.state.status.jenisSurat}</h4>
						</div>
						<div
							style={{
								// width: 'fit-content',
								padding: '10px 25px',
								margin: '10px 30px',
								borderRadius: '35px',
								border: '5px solid'
								// display: this.state.status.messageAntrian2 ? '' : 'none'
							}}
						>
							<div className="row" style={{ textAlign: 'center' }}>
								<div className="col-sm-12">
									<h5 style={{ fontSize: '25px' }}>No. Antrian Anda</h5>
								</div>
							</div>
							<div className="row" style={{ textAlign: 'center' }}>
								<div className="col-sm-12">
									<h1 style={{ fontSize: '60px' }}>{this.state.nomorAntrian}</h1>
								</div>
							</div>
						</div>
						<div className="row" style={{ textAlign: 'center' }}>
							<h5 style={{ fontSize: '22px' }}>
								Silahkan menunggu nomor antrian anda dipanggil untuk mengambil surat. Terima kasih
							</h5>
							{/* <h5 style={{ fontSize: '22px' }}>{this.state.status.messageAntrian}</h5> */}
						</div>
					</div>
					<div
						className="modal-footer"
						style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
					>
						<button
							onClick={() => {
								// this.tutupForm();
								this.cetakAntrian();
								this.setState({
									status:{
										...this.state.status,
										frmNomorAntrian: false,
										modalKonfirmasi: true,
									}
								})
								this.forceUpdate();
							}}
							className="btn btn-info"
						>
							Cetak Nomor Antrian
						</button>
						<button
							onClick={() => {
								this.setState({
									status:{
										...this.state.status,
										frmNomorAntrian: false,
										modalKonfirmasi: true,
									}
								})
								this.forceUpdate();
							}}
							className="btn btn-success"
						>
							Tutup
						</button>
					</div>
				</Modal>

				<Modal
					open={this.state.status.frmPrintKonfirmasi}
					// open={true}
					// open={true}
					onClose={() => {
						this.setState({
							status:{
								...this.state.status,
								frmPrintKonfirmasi: false,
								modalKonfirmasi: true,
							}
						})
					}}
					styles={{ modal: { width: '800px' } }}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<div
						id="kertas_antrian"
						className="modal-body"
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							alignItems: 'center'
						}}
					>
						<div className="row" style={{ textAlign: 'center' }}>
							<h4>{this.state.status.jenisSurat}</h4>
						</div>
						
						<div className="row" style={{ textAlign: 'center' }}>
							<h5 style={{ fontSize: '22px' }}>
								Apakah Anda Ingin Mencetak Surat Yang Telah Anda Buat?
							</h5>
						</div>
					</div>
					<div
						className="modal-footer"
						style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
					>

						<button
							onClick={() => {
								this.setState({
									status:{
										...this.state.status,
										frmPrintKonfirmasi: false,
										modalKonfirmasi: true,
									}
								})
								this.forceUpdate();
							}}
							className="btn btn-warning"
						>
							Tutup
						</button>

						<button
							onClick={() => {
								this.setState({
									status:{
										...this.state.status,
										frmPrintKonfirmasi: false,
										frmPrint: true,
									},
									loadingPreview: true,
								})
								// this.tutupForm();
								// this.cetakAntrian();
								// this.state.status.modalKonfirmasi = true;
								// this.cetakSurat()
								this.forceUpdate();
							}}
							className="btn btn-info"
						>
							Cetak Sekarang
						</button>
						
					</div>
				</Modal>

				<SweetAlert
					show={this.state.loadingPreview}
					showCancel = {false}
					showConfirm={false}
				>
					<div align="center">
						<h5 className="modal-title" style={{ textAlign: "center", color: 'black' }}>Sedang Dalam Proses</h5>
						<br/>
						<img 
							alt="loading"
							src={require("../assets/images/tes-loading.gif")} 
							style={{
								// width: "100%",
								borderRadius: "10px",
								width: '150px'
							}}
						/> 
						<br/><br/>
					</div>
				</SweetAlert>

				<Modal
					className="custom-map-modal"
					open={this.state.status.frmPrint}
					onClose={() => {
						this.setState({
							status:{
								...this.state.status,
								modalKonfirmasi: true,
								frmPrint: false,
							}
						})
					}}
					styles={{ 
						modal: {
							width: '100%',
							height: '100%',
							maxHeight: 'unset',
							maxWidth: 'unset',
						  }
					}}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<div
						className="modal-body"
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							height: '90%',
							alignItems: 'center'
						}}
					>
						<span><h4><b>Pilih gambar <i class="fa fa-print"></i> untuk mencetak surat yang telah anda buat</b></h4></span><br/>
						{/* <div className="row" style={{ textAlign: 'center' }}> */}
							{/* <h5>Silahkan menunggu nomor antrian anda dipanggil untuk mengambil surat. Terima kasih</h5> */}
							{/* <h3>Apakah anda ingin membuat surat lagi ?</h3> */}
							
							<Iframe 
								id="myFrame" 
								src={
									API_URL +
									'cetak-surat/' +
									this.state.get_jenis_surat +
									'/' +
									this.state.get_data_surat.uuid_surat +
									'?show=Ya'
								}
								// styles={
								// 	{display: 'none'}
								// }
								onLoad={(e) => {
									this.setState({
										loadingPreview: false
									})
								}}
								width="100%"
								height="100%"
								className="myClassname"
								display={this.state.loadingPreview ? "none" : "block"}
								position="relative"
							/>
						{/* </div> */}
					</div>
					<div
						className="modal-footer"
						style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
					>
						<button
							onClick={() => {
								this.setState({
									status:{
										...this.state.status,
										modalKonfirmasi: true,
										frmPrint: false,
									}
								})
								this.forceUpdate();
							}}
							className="btn btn-success"
						>
							Tutup
						</button>
						{/* <button
							onClick={() => {
								this.state.status.modalKonfirmasi = false;
								this.forceUpdate();
							}}
							className="btn btn-primary"
						>
							Buat surat lagi
						</button>
						<button onClick={this.logOut.bind(this)} className="btn btn-success">
							Tidak
						</button> */}
					</div>
				</Modal>

				<Modal
					open={this.state.status.modalKonfirmasi}
					// open={true}
					// open={true}
					onClose={() => {
						this.setState({
							status:{
								...this.state.status,
								modalKonfirmasi: false,
							}
						})
					}}
					styles={{ modal: { width: '400px' } }}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<div
						className="modal-body"
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							alignItems: 'center'
						}}
					>
						<div className="row" style={{ textAlign: 'center' }}>
							{/* <h5>Silahkan menunggu nomor antrian anda dipanggil untuk mengambil surat. Terima kasih</h5> */}
							<h3>Apakah anda ingin membuat surat lagi ?</h3>
						</div>
					</div>
					<div
						className="modal-footer"
						style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
					>
						<button
							onClick={() => {
								this.setState({
									status:{
										...this.state.status,
										modalKonfirmasi: false,
									}
								})
								this.forceUpdate();
							}}
							className="btn btn-info"
						>
							Buat surat lagi
						</button>
						<button onClick={this.logOut.bind(this)} className="btn btn-success">
							Tidak
						</button>
					</div>
				</Modal>




				<Modal
					open={this.state.status.modalKonfirmasi2}
					// open={true}
					// open={true}
					onClose={false}
					styles={{ modal: { width: '400px' } }}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<div
						className="modal-body"
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							alignItems: 'center'
						}}
					>
						<div className="row" style={{ textAlign: 'center' }}>
							<h3>Apakah anda yakin ingin keluar ?</h3>
						</div>
					</div>
					<div
						className="modal-footer"
						style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
					>
						<button onClick={this.logOut.bind(this)} className="btn btn-success">
							Ya
						</button>
						<button
							onClick={() => {
								this.setState({
									status:{
										...this.status,
										modalKonfirmasi2: false,
									}
								})
								this.forceUpdate();
							}}
							className="btn btn-info"
						>
							Tidak
						</button>
					</div>
				</Modal>
			</>
		);
	}
}