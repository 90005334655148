import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  // Input,
  // FormText,
  // ButtonGroup,
  // CustomInput,
  // Row,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";

import { Link, Redirect } from "react-router-dom";

// koneksi
import { Post, Get, Delete, JENIS_DUSUN } from "../function/Koneksi";
// import { Tanggal, } from "../function/Format";
// import { logo } from "./asset/base64img";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

const frmDef = {
  uuid: "",
  nomor: "",
  tanggal_surat: new Date(),
  
  id_jenis : "",
  nik : "",
  kk : "",
  nama_pemohon : "",
  tempat_lahir : "",
  tanggal_lahir : "",
  pekerjaan : "",
  pendidikan_terakhir : "",
  agama : "",
  status_perkawinan : "",
  jenis_kelamin : "",
  alamat : "",
  kewarganegaraan : "",
  tujuan_pembuatan : "",
  nik_pelapor : "",
  detail_surat : {
      jenis_tanah : "",
      lokasi_tanah : "",
      luas_tanah : "",
      batas_utara : "",
      batas_timur : "",
      batas_selatan : "",
      batas_barat : ""
  },

  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: "",
  status_ttd2: "",
  jabatan_ttd2: "",
  jabatan_pengganti_ttd2: "",
  nama_ttd2: "",
  nip_ttd2: ""
};

// let detail = [];

class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: [],
        dataPenduduk: "none",
        dataPenduduk2: "none"
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDef,
      judul: "Tambah Surat Keterangan Penyaksian Tanah",
      shdk: "",
      detail: [],
      // ============ Select 2 ==============
      frmJam: [],
      jamStat: [],
      frmPkr: [],
      pkrStat: [],
      frmAgm: [],
      agmStat: [],
      frmNik: [],
      nikStat: null,
      frmNik2: [],
      nik2Stat: null,
      frmNik3: [],
      nik3Stat: null,
      // ===================================
      // ============ Alert ==============
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      successAlert: false,

      // ===================================
      // ============ Tanggal ==============
      tanggal_surat: new Date(),
      tanggal_lahir: new Date(),
      waktu_meninggal: new Date(),
      waktu_meninggal2: new Date(),
      // ===================================
      // ===================================
      // =============== acc ===============
      formAcc: false,
      formSelectAcc: [
        { value: "Disetujui", label: "Disetujui" },
        { value: "Ditolak", label: "Ditolak" }
      ],
      frmTTD: [],
      accButton: [],
      accuuid: "",
      btnFormAcc: true,
      tableAcc: "none",
      //list disposisi
      datDisposisi: [],
      riwayatDisposisi: []
    };
  }
  // ===============================================

  // ================== Tanggal ===================

  gantiTgl = (date, fld) => {
    switch (fld) {
      case "tanggal_surat":
        this.setState({ tanggal_surat: date });
        break;
      case "waktu_meninggal":
        this.setState({ waktu_meninggal: date });
        break;
      case "waktu_meninggal2":
        this.setState({ waktu_meninggal2: date });
        break;
      default:
        // no default
        break;
      // case 'tanggal_lahir': this.setState({tanggal_lahir:date});break;
    }
  };

  // ==============================================

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      successAlert: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get(
      "surat-keterangan/tidak-mampu/" + this.props.location.state.uuid + link,
      null,
      dtkat => {
        console.log('Data Show')
        console.log(dtkat)
        if(dtkat.results){
          this.setState({ data: dtkat.results.data, pagination: dtkat.results });
          this.pagination(dtkat.results);
          
           // For testing
           if (this.props.getListSuratKeteranganTidakMampu) {
            this.props.getListSuratKeteranganTidakMampu(dtkat.results.data)
          }
        }
      }
    );
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    // //console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i ===  data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  // Cari Data Table
  cariData = e => {
    if (e.key ===  "Enter") {
      let addSuratKeteranganTidakMampu = {
        q: e.target.value
      };
      Post(
        "surat-keterangan/tidak-mampu/" + this.props.location.state.uuid,
        null,
        addSuratKeteranganTidakMampu,
        data => {
          this.setState({
            data: data.data.results.data,
            pagination: data.data.results
          });
        }
      );
    }
  };

  // ========================================================================

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    if (this.state.nikStat) {
      this.setState({
        status:{
          ...this.state.status,
          btnForm: true,
        },
      })
      this.forceUpdate();
      let tgl_lhr = this.state.dt.tanggal_lahir.split("/");
      let tanggal_lahir = tgl_lhr[2] + "-" + tgl_lhr[1] + "-" + tgl_lhr[0];

      let detailSurat = {
        jenis_tanah : document.getElementById("jenis_tanah").value,
        lokasi_tanah : document.getElementById("lokasi_tanah").value,
        luas_tanah : document.getElementById("luas_tanah").value,
        batas_utara : document.getElementById("batas_utara").value,
        batas_timur : document.getElementById("batas_timur").value,
        batas_selatan : document.getElementById("batas_selatan").value,
        batas_barat : document.getElementById("batas_barat").value,
      }

      let addSurat = {
        uuid: document.getElementById("uuid").value,
        // nomor : document.getElementById("nomor").value,
        id_jenis: this.props.location.state.uuid,
        nik_pelapor: this.state.nikStat.value,
        nik: this.state.nikStat.value,
        kk: this.state.dt.kk,
        nama_pemohon: this.state.dt.nama,
        tempat_lahir: this.state.dt.tempat_lahir,
        tanggal_lahir: tanggal_lahir,
        pekerjaan: this.state.dt.pekerjaan,
        pendidikan_terakhir: this.state.dt.pendidikan_terakhir,
        agama: this.state.dt.agama,
        status_perkawinan: this.state.dt.status_perkawinan,
        jenis_kelamin: this.state.dt.jenis_kelamin,
        alamat: this.state.dt.alamat,
        kewarganegaraan: this.state.dt.kewarganegaraan,
        tujuan_pembuatan : document.getElementById("tujuan_pembuatan").value,
        detail_surat: detailSurat
      };

      let psn = "";
      let resstat = 204;
      let metode = "store";
      if (addSurat.uuid === "") {
        psn = "Tambah";
        resstat = 201;
        addSurat.uuid = null;
      } else {
        psn = "Ubah";
        resstat = 200;
        metode = "update";
      }
      
      console.log("Data Simpan Penyaksian Tanah")
      console.log(addSurat)
      console.log(metode)

      Post(
        "surat-keterangan/new-format/" + metode,
        addSurat.uuid,
        addSurat,
        res => {
          // For testing
          if (this.props.resSubmit) {
            this.props.resSubmit(res.status)
          }
          this.tutupForm();
          this.setState({
            status:{
              ...this.state.status,
              btnForm: false,
              form: false,
            },
          })
          this.forceUpdate();
          if (res.status === resstat) {
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Data Surat Keterangan Penyaksian Tanah",
              pesanAlert: "Berhasil " + psn + " Data",
              successAlert: true
            });
          } else {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Data Surat Keterangan Penyaksian Tanah",
              pesanAlert: "Gagal " + psn + " Data"
            });
          }
          this.componentDidMount();
        }
      );
    } else {
      this.setState({
        show: true,
        basicType: "warning",
        basicTitle: "Data Belum Lengkap",
        pesanAlert: "Mohon pilih data NIK Pembuat"
      });
    }
  };
  // ========================================================================
  // list disposisi

  listDisposisi = data => {
    //console.log(data);
    Get("alur-disposisi/" + data.id_jenis, null, dtkat => {
      //console.log(dtkat);
      if (dtkat) {
        //console.log(dtkat.results);

        this.setState({
          idSurat: data.uuid,
          datDisposisi: dtkat.results,
          formDisposisi: true,
          riwayatDisposisi: data.data_status_disposisi
        });
      }
    });
  };

  //disposisi
  disposisi = (label, id) => {
    //console.log(label);
    Get("disposisi/tidak-mampu/" + label + "/" + id, null, dtkat => {
      if (dtkat.code === 201) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Disposisi",
          pesanAlert: "Berhasil  Disposisi Data"
        });
        this.componentDidMount();
        this.tutupForm();
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Disposisi",
          pesanAlert: "Disposisi telah dilakukan"
        });
        this.tutupForm();
      }
    });
    //console.log(id);
  };

  // ============================== Ubah Data ===============================

  ubahData = data => {
    this.setState({
      judul: "Ubah Surat Penyaksian Tanah",
    })
    this.forceUpdate();

    console.log('Data Ubah')
    console.log(data)
    
    let detailData = JSON.parse(data.detail_surat)
    console.log('Data Detail')
    console.log(detailData)
    Get("penduduk/kk/"+data.kk, null, dtkk => {
      let frmNik3 = [];
      dtkk.results.forEach(dt => {
        frmNik3.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
      });

      this.setState({
        dt:{
          // ...this.state.dt,
          id_jenis: data.id_jenis,
          nomor: data.nomor,
          tanggal_surat: data.tanggal_surat,
          uuid: data.uuid,
          agama: data.agama,
          alamat: data.alamat,
          jenis_kelamin: data.jenis_kelamin,
          kewarganegaraan: data.kewarganegaraan,
          kk: data.kk,
          nama: data.nama_pemohon,
          nik: data.nik,
          pekerjaan: data.pekerjaan,
          pendidikan_terakhir: data.pendidikan_terakhir,
          status_perkawinan: data.status_perkawinan,
          tanggal_lahir: new Date(
            data.tanggal_lahir
          ).toLocaleDateString("id-ID"),
          tempat_lahir: data.tempat_lahir,
          tujuan_pembuatan: data.tujuan_pembuatan,
          jabatan_pengganti_ttd: data.jabatan_pengganti_ttd,
          jabatan_ttd: data.jabatan_ttd,
          nama_ttd: data.nama_ttd,
          nip_ttd: data.nip_ttd,
          status_ttd: data.status_ttd,

          jenis_tanah: detailData.jenis_tanah,
          lokasi_tanah: detailData.lokasi_tanah,
          luas_tanah: detailData.luas_tanah,
          batas_utara: detailData.batas_utara,
          batas_timur: detailData.batas_timur,
          batas_selatan: detailData.batas_selatan,
          batas_barat: detailData.batas_barat,
        },
        status:{
          ...this.state.status,
          dataPenduduk: "block",
        },
        tanggal_surat: new Date(data.tanggal_surat),
        nikStat: { value: data.nik, label: data.nik + ' (' + data.nama_pemohon + ')' },
        frmNik3: frmNik3,
      })

      this.bukaForm();
    })
  };
  // ========================================================================
  // ============================= Approve Data ===============================
  konfirmApprove = id => {
    this.setState({
      judul: "Persetujuan Data ",
      formAcc: true,
      btnFormAcc: true,
      idaccSurat: id
    });
  };
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapus = i => {
    this.state.detail.splice(i, 1);
    this.forceUpdate();
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("surat-keterangan/tidak-mampu/delete", id, res => {
      if (res ===  200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Surat Keterangan Penyaksian Tanah",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Surat Keterangan Penyaksian Tanah",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.setState({
      status:{
        ...this.state.status,
        form: true,
      },
    })
    this.forceUpdate();
  };

  tutupForm = () => {
    this.setState({
      dt: frmDef,
      status:{
        ...this.state.status,
        dataPenduduk: "none",
        form: false,
      },
      nikStat: null,
      detail: [],
      shdk_aw: null,
      accStat: [],
      tableAcc: "none",
      formAcc: false,
      formDisposisi: false,
    })

    this.forceUpdate();
  };

  persetujuan = (id, jawaban) => {
    this.hideAlert();
    Get(
      "surat-keterangan/tidak-mampu/persetujuan/" + jawaban + "/" + id,
      null,
      res => {
        if (res.code === 201) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
  };

  tombolAksi = (cell, row) => {
    // let btnApproval = false;
    // let btnCetak = false;
    let btnAksi = false;
    let status = "";
    row.data_status.forEach(dt => {
      status = dt.status;
    });
    if (status ===  "Diajukan") {
      // btnCetak = true;
      btnAksi = true;
      // btnApproval = true;
    } else {
      btnAksi = true;
      // btnApproval = true;
      if (status ===  "Ditolak") {
        // btnCetak = true;
      } else if (status ===  "Disetujui") {
        btnAksi = false;
        // btnCetak = true;
        // btnApproval = false;
      } else if ((status ===  "Menunggu Penerbitan") | (status ===  "Selesai")) {
        // btnAksi = false;
        // btnCetak = false;
        // btnApproval = false;
      } else {
        // btnCetak = true;
      }
    }
    return (
      <>
        {/* <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={() => this.konfirmApprove(row.uuid)}
          disabled={btnApproval}
        >
          <i className="fa fa-check"></i>
        </Button>{" "}
        &nbsp;&nbsp; */}
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row)}
          disabled={btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        {/* &nbsp;&nbsp;
        <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>{" "}
        &nbsp;&nbsp;
        <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={e => this.cetakSurat(e, row)}
          disabled={btnCetak}
        >
          <i className="fa fa-print"></i>
        </Button>{" "}
        &nbsp;&nbsp; */}
        {/* //list */}
        {/* <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={e => this.listDisposisi(row)}
        >
          <i className="fa fa-list"></i>
        </Button> */}
      </>
    );
  };

  componentDidMount() {
    if (this.props.location.state) {
      this.fetch({ page: null, where: [] });
      // let frmJam = [];
      // let jamnya = "";
      // for (let i = 1; i <= 24; i++) {
      //   jamnya = i;
      //   if (i < 10) {
      //     jamnya = "0" + i;
      //   }
      //   jamnya += ":00";
      //   frmJam.push({ value: jamnya, label: jamnya });
      // }
      // this.setState({ frmJam });
      // Get("pekerjaan/list", null, data => {
      //   let frmPkr = [];
      //   data.results.forEach(dt => {
      //     frmPkr.push({ value: dt.uuid, label: dt.nama });
      //   });
      //   this.setState({ frmPkr });
      // });
      // Get("agama/list", null, data => {
      //   let frmAgm = [];
      //   data.results.forEach(dt => {
      //     frmAgm.push({ value: dt.uuid, label: dt.nama });
      //   });
      //   this.setState({ frmAgm });
      // });
      Get("penduduk", null, data => {
        let frmNik = [];
        let frmNik2 = [];
        let frmNik3 = [];
        data.results.data.forEach(dt => {
          frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          frmNik2.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          // frmNik3.push({ value: dt.id, label: dt.id });
        });
        this.setState({ frmNik, frmNik2, frmNik3 });

        // For testing
        if (this.props.getListNIK) {
          this.props.getListNIK(frmNik)
        }
      });
      // Get("tanda-tangan/list", null, data => {
      //   data.results.forEach((dt, i) => {
      //     if (dt.aktif ===  1) {
      //       let temp = this.state.accButton.map(x => 1);
      //       temp[i] = 0;
      //       this.setState({
      //         accButton: temp,
      //         accuuid: dt.uuid,
      //       })
      //     }
      //   });
      //   this.forceUpdate();
      //   this.setState({ frmTTD: data.results });
      // });
    } else {
      this.setState({ redirect: true });
    }
  }

  handleInputChangeNIK = (e, fld) => {
    if (e) {
      if (fld ===  "nik") {
        let nikStat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmNik = [];
          datanik.data.results.data.forEach(dt => {
            frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          });
          this.setState({ frmNik, nikStat });
        });
      } else {
        let nik2Stat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmNik2 = [];
          datanik.data.results.data.forEach(dt => {
            frmNik2.push({ value: dt.id, label: dt.id });
          });
          this.setState({ frmNik2, nik2Stat });
        });
      }
    }
  };

  getPenduduk = (e, sel) => {
    this.setState({
      status:{
        ...this.state.status,
        btnForm: true,
      },
    })
    this.forceUpdate();
    if (e) {
      this.changeSelectValue(e, sel);
      Get("penduduk/find", e.value, data => {
        let alamat = '';
        if (localStorage.getItem('JENIS_DESA').toLowerCase() ===  'kelurahan') {
          alamat =
          " RT. " +
          data.results.data_kk.data_rt.nama +
          "/RW. " +
          data.results.data_kk.data_rt.data_rw.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_keldes.nama +
          ", Kec. " +
          data.results.data_kk.data_rt.data_rw.data_keldes
            .data_kecamatan.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_keldes
            .data_kecamatan.data_kabkota.nama; 
        } else {
          if (data.results.data_kk.id_rt ===  '0') {
            alamat = localStorage.getItem('JENIS_DESA') + " " + data.results.data_keldes.nama + 
            ", Kec. " + data.results.data_keldes.data_kecamatan.nama + 
            ", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
            ", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
          } else {
            alamat =
            " RT. " + 
            data.results.data_kk.data_rt.nama +
            "/RW. " +
            data.results.data_kk.data_rt.data_rw.nama +
            ", " + JENIS_DUSUN + " " +
            data.results.data_kk.data_rt.data_rw.data_dusun.nama +
            ", " + localStorage.getItem('JENIS_DESA') + " " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
            ", Kec. " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
              .data_kecamatan.nama +
            ", " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
              .data_kecamatan.data_kabkota.nama; 
          }
        }

        if (sel ===  "nik2") {
          this.setState({
            dt:{
              ...this.state.dt,
              nama2: data.results.nama,
              tanggal_lahir2: new Date(
                data.results.tanggal_lahir
              ).toLocaleDateString("id-ID"),
              tempat_lahir2: data.results.tempat_lahir,
              jenis_kelamin2: data.results.jk,
              status_perkawinan2: data.results.status_perkawinan,
              agama2: data.results.data_agama.nama,
              pekerjaan2: data.results.data_pekerjaan.nama,
              kewarganegaraan2: "Indonesia",
              alamat2: alamat,
            },
            status:{
              ...this.state.status,
              dataPenduduk2: "block",
            },
            tanggal_lahir2: data.results.tanggal_lahir,
          })
        } else {
          if (sel ===  "nik3") {
          } else {
            Get("catatan-penduduk/nik", data.results.id, dpddk => {
              let pctt = 0;
              let catatan = "";
              if (dpddk.results.data) {
                dpddk.results.data.forEach(dtp => {
                  if (dtp.status ===  0 && dtp.penalti ===  1) {
                    pctt = 1;
                    catatan = dtp.catatan;
                  }
                });
              }
              if (pctt ===  0) {
                Get("penduduk/kk/"+data.results.id_kk, null, dtkk => {
                  // let frmNik = [];
                  // let frmNik2 = [];
                  let frmNik3 = [];
                  dtkk.results.forEach(dt => {
                    // frmNik.push({ value: dt.id, label: dt.id });
                    // frmNik2.push({ value: dt.id, label: dt.id });
                    frmNik3.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
                  });

                  // For testing
                  if (this.props.getListNIK3) {
                    this.props.getListNIK3(frmNik3)
                  }

                  this.setState({
                    dt:{
                      ...this.state.dt,
                      kk: data.results.id_kk,
                      nama: data.results.nama,
                      tanggal_lahir: new Date(
                        data.results.tanggal_lahir
                      ).toLocaleDateString("id-ID"),
                      tempat_lahir: data.results.tempat_lahir,
                      jenis_kelamin: data.results.jk,
                      status_perkawinan: data.results.status_perkawinan,
                      agama: data.results.data_agama.nama,
                      pekerjaan: data.results.data_pekerjaan.nama,
                      pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
                      kewarganegaraan: "Indonesia",
                      alamat: alamat,
                    },
                    status:{
                      ...this.state.status,
                      btnForm: false,
                      dataPenduduk: "block",
                    },
                    tanggal_lahir: data.results.tanggal_lahir,
                    frmNik3: frmNik3,
                  })
                  this.forceUpdate();
                  // this.setState({ frmNik3 });
                });
              } else {
                this.setState({
                  dt:{
                    ...this.state.dt,
                    nama: "",
                    tanggal_lahir: "",
                    tempat_lahir: "",
                    agama: "",
                    pekerjaan: "",
                    pendidikan_terakhir: "",
                    kewarganegaraan: "",
                    alamat: "",
                    jenis_kelamin: "",
                    status_perkawinan: "",
                  },
                  status:{
                    ...this.state.status,
                    dataPenduduk: "none",
                    btnForm: true,
                  },
                  nikStat: null,
                })
                // this.state.frmNik = [];
                this.forceUpdate();
                this.setState({
                  show: true,
                  basicType: "danger",
                  basicTitle: "Peringatan Catatan Warga",
                  pesanAlert:
                    "Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r" +
                    catatan
                });
              }
              
              // For testing
              if (this.props.selectOnChange) {
                this.props.selectOnChange(this.state.dt)
              }
              if (this.props.selectOnChange3) {
                this.props.selectOnChange3(this.state.dt)
              }
            });
          }
        }
        this.forceUpdate();
      });
    } else {
      if (sel ===  "nik2") {
        this.setState({
          dt:{
            ...this.state.dt,
            nama2: "",
            tanggal_lahir2: "",
            tempat_lahir2: "",
            agama2: "",
            pekerjaan2: "",
            kewarganegaraan2: "",
            alamat2: "",
            jenis_kelamin2: "",
            status_perkawinan2: "",
            tempat_meninggal2: "",
            sebab_meninggal2: "",
          },
          status:{
            ...this.state.status,
            dataPenduduk2: "none",
          },
          nik2Stat: null,
        })
      } else {
        this.setState({
          dt:{
            ...this.state.dt,
            nama: "",
            tanggal_lahir: "",
            tempat_lahir: "",
            agama: "",
            pekerjaan: "",
            pendidikan_terakhir: "",
            kewarganegaraan: "",
            alamat: "",
            jenis_kelamin: "",
            status_perkawinan: "",
          },
          status:{
            ...this.state.status,
            dataPenduduk: "none",
          },
          nikStat: null,
        })
      }
      this.forceUpdate();
    }
  };

  changeInputValue = (e, sel) => {
    switch (sel) {
      default:
        // no default
        break;
    }
  };

  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "jam":
          this.setState({ 
            jamStat: e, 
            dt:{
              ...this.state.dt,
              jam: e.value,
            },
          });
          break;
        case "pekerjaan":
          this.setState({ 
            pkrStat: e, 
            dt:{
              ...this.state.dt,
              pekerjaan: e.label,
            },
          });
          break;
        case "agama":
          this.setState({ 
            agmStat: e, 
            dt:{
              ...this.state.dt,
              agama: e.label,
            },
          });
          break;
        case "nik":
          this.setState({ 
            nikStat: e, 
            dt:{
              ...this.state.dt,
              nik: e.value,
            },
          });
          break;
        case "nik2":
          this.setState({ 
            nik2Stat: e, 
            dt:{
              ...this.state.dt,
              nik2: e.value,
            },
          });
          break;
        case "nik3":
          this.setState({ 
            nik3Stat: e, 
            dt:{
              ...this.state.dt,
              nik3: e.value,
            },
          });
          break;
        case 'shdk_aw':
          this.setState({ shdk_aw: e });
          
          // For testing
          if (this.props.selectOnChangeShdk) {
            this.props.selectOnChangeShdk(e.value)
          }
          break;
        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        case "jam":
          this.setState({ 
            jamStat: e, 
            dt:{
              ...this.state.dt,
              jam: 0,
            },
          });
          break;
        case "pekerjaan":
          this.setState({ 
            pkrStat: e, 
            dt:{
              ...this.state.dt,
              pekerjaan: "",
            },
          });
          break;
        case "agama":
          this.setState({ 
            agmStat: e, 
            dt:{
              ...this.state.dt,
              agama: "",
            },
          });
          break;
        case "nik":
          this.setState({ 
            nikStat: e, 
            dt:{
              ...this.state.dt,
              nik: 0,
            },
          });
          break;
        case "nik2":
          this.setState({ 
            nik2Stat: e, 
            dt:{
              ...this.state.dt,
              nik2: 0,
            },
          });
          break;
        case "nik3":
          this.setState({ 
            nik3Stat: e, 
            dt:{
              ...this.state.dt,
              nik3: 0,
            },
          });
          break;
        case "shdk_aw":
          this.setState({ shdk_aw: null });
          break;
        default:
          // no default
          break;
      }
    }

    this.forceUpdate();
  };

  // cek redirect jiksa state kosong
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };

  //change select
  changeAcc = (e, sel) => {
    if (e) {
      switch (sel) {
        case "status-acc":
          this.setState({
            accStat: e,
          })
          break;
        default:
          // no default
          break;
      }
      if (e.value ===  "Disetujui") {
        this.setState({
          tableAcc: "",
          btnFormAcc: true,
        })
        if (this.state.accButton.length > 0) {
          this.setState({
            btnFormAcc: false,
          })
        }
      } else {
        this.setState({
          tableAcc: "none",
          btnFormAcc: false,
        })
      }
    } else {
      switch (sel) {
        case "status-acc":
          this.setState({
            accStat: e,
          })
          break;
        default:
          // no default
          break;
      }
      this.setState({
        tableAcc: "none",
        btnFormAcc: true,
      })
    }
    this.setState({
      status:{
        ...this.state.status,
        select: false,
      },
    })
    this.forceUpdate();
  };

  simpanAcc = e => {
    e.preventDefault();
    this.setState({
      btnFormAcc: true,
    })

    let add = {
      uuid: this.state.accuuid,
      tanggal_surat: new Date(this.state.tanggal_surat)
        .toISOString()
        .slice(0, 10)
    };
    Post(
      "surat-keterangan/tidak-mampu/persetujuan/Selesai/" +
        this.state.idaccSurat,
      null,
      add,
      res => {
        if (res.status === 201) {
          this.setState({
            formAcc: false,
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            btnFormAcc: false,
            show: true,
            basicType: "warning",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );

    this.tutupForm();
  };

  pilih = (uuid, id) => {
    let temp = this.state.accButton.map(x => 1);
    temp[id] = 0;
    this.setState({
      accButton: temp,
      accuuid: uuid,
    })
    if (this.state.accStat) {
      this.setState({
        btnFormAcc: false,
      })
    }
    this.forceUpdate();
  };

  render() {
    
    //console.log("Data Render")
    var data = this.state.data;

    const frmDef = {
      uuid: "",
      nomor: "",
      tanggal_surat: new Date(),

      id_jenis : "",
      nik : "",
      kk : "",
      nama_pemohon : "",
      tempat_lahir : "",
      tanggal_lahir : "",
      pekerjaan : "",
      pendidikan_terakhir : "",
      agama : "",
      status_perkawinan : "",
      jenis_kelamin : "",
      alamat : "",
      kewarganegaraan : "",
      tujuan_pembuatan : "",
      nik_pelapor : "",
      detail_surat : {
          jenis_tanah : "",
          lokasi_tanah : "",
          luas_tanah : "",
          batas_utara : "",
          batas_timur : "",
          batas_selatan : "",
          batas_barat : ""
      },

      status_ttd: "",
      jabatan_ttd: "",
      jabatan_pengganti_ttd: "",
      nama_ttd: "",
      nip_ttd: ""
    };
    let no = 0;

    const columns = [
      {
          dataField: "no",
          text: "No",
          isDummyField: true,
          csvExport: false,
          headerAlign: 'center',
				  align: 'center',
          formatter: (key, obj, index) =>{          
              let current_pagenum = this.state.pagination.current_page;
              let total_records_per_page = this.state.pagination.per_page;
              let row_index = (index+1);
              let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              return serial_num; 
          }
      },
      {
        dataField: "tanggal_surat",
        headerAlign: 'center',
				align: 'center',
        text: "Tanggal",
        formatter: (cell, obj) => {
          let plainTime = '-'
					if (obj.created_at) {
						let date = new Date(obj.created_at)
						date = date.toLocaleString('id-ID')
						
						const datetime = date.split(' ')
						
	
						date = datetime[0]
						let time = datetime[1]
	
						const splitDate = date.split('/')
						time = time.replaceAll('.', ':')
	
						console.log(date)
						const list_bulan = [
							'Januari',
							'Februari',
							'Maret',
							'April',
							'Mei',
							'Juni',
							'Juli',
							'Agustus',
							'September',
							'Oktober',
							'November',
							'Desember'
						];

						plainTime = `${splitDate[0]} ${list_bulan[parseInt(splitDate[1]) - 1]} ${splitDate[2]}  ${time}`;
					}

					return plainTime
        },
        isDummyField: true,

        sort: true
      },
      {
        dataField: "nomor",
        headerAlign: 'center',
				align: 'center',
        text: "Nomor surat",
        formatter: (cell, obj) => {
          return obj.nomor ===  "" ? "-" : obj.nomor;
        },
        sort: true
      },
      {
        dataField: "nik",
        headerAlign: 'center',
				align: 'center',
        text: "NIK",
        sort: true
      },
      {
        dataField: "nama_pemohon",
        headerAlign: 'center',
				align: 'center',
        text: "Nama",
        sort: true
      },
      {
        dataField: "status",
        headerAlign: 'center',
				align: 'center',
        text: "Status",
        isDummyField: true,
        formatter: (cell, obj) => {
          let status = "-";
          obj.data_status.forEach(dt => {
            status = dt.status;
          });
          return status;
        },
        sort: true
      },
      {
        dataField: "aksi",
        headerAlign: 'center',
				align: 'center',
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    no = 0;

    const tableTTD = this.state.frmTTD.map((dt, i) => {
      no++;
      return (
        <tr key={i}>
          <td>{no}</td>
          <td>{dt.jabatan}</td>
          <td>{dt.jabatan_pengganti ? dt.jabatan_pengganti : "-"}</td>
          <td>{dt.nama}</td>
          <td>{dt.nip ? dt.nip : "-"}</td>
          <td>{dt.status ? dt.status : "-"}</td>
          <td>
            <Button
              size="xs"
              onClick={() => this.pilih(dt.uuid, i)}
              color={
                this.state.accButton.length > 0
                  ? this.state.accButton[i] ===  0
                    ? "default"
                    : "danger"
                  : "danger"
              }
              className="btn-icon"
            >
              {this.state.accButton.length > 0
                ? this.state.accButton[i] ===  0
                  ? "Dipilih"
                  : "Pilih"
                : "Pilih"}
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <div data-testid="page-surat keterangan penyaksian tanah">
        {this.renderRedirect()}
        {/* <Breadcrumb title="Data Surat Keterangan Penyaksian Tanah" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="info"
          onCancel={this.closeAlert}
          showConfirm={false}
        >
          {this.state.pesanAlert}
          {this.state.successAlert ? (
            <div>
              <br />
              <Button
                color="success"
                onClick={() => {
                  this.closeAlert();
                  this.bukaForm();
                  this.setState({
                    dt: frmDef,
                  })
                }}
              >
                Tambah Data
              </Button>{" "}
              <Button
                color="info"
                className="btn btn-secondary-custom"
                // onClick={() => {
                //   this.closeAlert();
                // }}
              >
                <Link
                  data-testid="btn-eksekusi_surat"
                  onClick={() => {
                    // For testing
                    if (this.props.clickEksekusiSurat) {
                      this.props.clickEksekusiSurat()
                    }
                  }}
                  to={{
                    pathname: process.env.PUBLIC_URL + "/admin/disposisi",
                    tabProps: "2"
                  }}
                >
                  <span style={{ color: "white" }}>Eksekusi Surat</span>
                </Link>
              </Button>
            </div>
          ) : (
            ""
          )}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Surat KeteranganPenyaksian Tanah</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      <Button
                        data-testid="tblCreateSuratKeteranganTidakMampu"
                        size="sm"
                        color="success"
                        // className="btn-square"
                        onClick={() => {
                          this.setState({
                            judul: "Tambah Surat Keterangan Penyaksian Tanah",
                            dt: frmDef,
                            nikStat: null,
                            nik2Stat: null,
                            detail: []
                          });
                          this.setState({
                            status:{
                              ...this.state.status,
                              dataPenduduk: "none",
                              dataPenduduk2: "none",
                            },
                          })
                          this.bukaForm();
                        }}
                      >
                        Tambah Data
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <BootstrapTable keyField="id" data={data} columns={columns} />
                  <div className="pull-right text-white">
                    {this.state.awal}
                    {this.state.sebelum}
                    {this.state.hal.map(dt => {
                      return dt;
                    })}
                    {this.state.setelah}
                    {this.state.akhir}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          open={this.state.status.form}
          onClose={this.tutupForm}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form  
            data-testid="form-create" 
            className="theme-form" 
            onSubmit={this.simpan}
          >
            <div className="modal-header">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>
            <div className="modal-body">
              <p>
                Data identitas akan terisi secara otomatis saat NIK dipilih.
                Jika ada ketidak sesuaian data, silakan ubah di data master
                penduduk.
              </p>
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="nik"
                >
                  NIK
                </Label>
                <Col sm="9">
                  <div data-testid="selectNIK">
                    <Select
                      inputId="nik"
                      name="nik"
                      classNamePrefix="select"
                      onChange={e => {
                        this.getPenduduk(e, "nik");
                      }}
                      defaultValue={this.state.nikStat}
                      value={this.state.nikStat}
                      options={this.state.frmNik}
                      placeholder="Pilih NIK"
                      onInputChange={e => this.handleInputChangeNIK(e, "nik")}
                      isClearable
                    />
                  </div>
                </Col>
              </FormGroup>
              <div style={{ display: this.state.status.dataPenduduk }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama Pemohon
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="nama"
                      type="text"
                      placeholder="Nama Pemohon"
                      value={this.state.dt.nama}
                      readOnly
                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tempat Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tempat_lahir"
                      type="text"
                      placeholder="Tempat Lahir"
                      value={this.state.dt.tempat_lahir}
                      readOnly
                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tanggal Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tanggal_lahir"
                      type="text"
                      placeholder="Tanggal Lahir"
                      value={this.state.dt.tanggal_lahir}
                      readOnly
                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
              </div>
              <div style={{ display: "none" }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    KK
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="kk"
                      type="text"
                      placeholder="Nomor KK"
                      value={this.state.dt.kk}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Jenis Kelamin
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="jenis_kelamin"
                      type="text"
                      placeholder="Jenis Kelamin"
                      value={this.state.dt.jenis_kelamin}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="pekerjaan"
                      type="text"
                      placeholder="Pekerjaan"
                      value={this.state.dt.pekerjaan}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pendidikan Terakhir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="pendidikan_terakhir"
                      type="text"
                      placeholder="Pendidikan Terakhir"
                      value={this.state.dt.pendidikan_terakhir}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Kewarganegaraan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="kewarganegaraan"
                      type="text"
                      placeholder="Pekerjaan"
                      value={this.state.dt.kewarganegaraan}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Status Perkawinan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="status_perkawinan"
                      type="text"
                      placeholder="Status Perkawinan"
                      value={this.state.dt.status_perkawinan}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Agama
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="agama"
                      type="text"
                      placeholder="Agama"
                      value={this.state.dt.agama}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="alamat"
                      type="text"
                      placeholder="Alamat"
                      value={this.state.dt.alamat}
                      readOnly
                    />
                  </Col>
                </FormGroup>
              </div>

              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  Keperluan Untuk
                </Label>
                <Col sm="9">
                  <textarea
                    data-testid="tujuan pembuatan"
                    name="tujuan_pembuatan"
                    className="form-control"
                    id="tujuan_pembuatan"
                    type="text"
                    placeholder="Keperluan Untuk"
                    defaultValue={this.state.dt.tujuan_pembuatan}
                    required
                  />
                  <small> <font color='red'><b>Contoh:</b> Sebahagian/tanah Kebun tersebut dengan ukuran 2 Are dijadikan maskawin atas pernikahan antara Lk. Fulan dengan perempuan An. Fulanah Binti Dika.</font></small>
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  Jenis Tanah
                </Label>
                <Col sm="9">
                  <input
                    data-testid="jenis tanah"
                    name="jenis_tanah"
                    className="form-control"
                    id="jenis_tanah"
                    type="text"
                    placeholder="Contoh: Perkebunan, dll"
                    defaultValue={this.state.dt.jenis_tanah}
                    required
                  />
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  Lokasi Tanah
                </Label>
                <Col sm="9">
                  <input
                    data-testid="lokasi tanah"
                    name="lokasi_tanah"
                    className="form-control"
                    id="lokasi_tanah"
                    type="text"
                    placeholder="Lokasi Tanah"
                    defaultValue={this.state.dt.lokasi_tanah}
                    required
                  />
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  Luas Tanah
                </Label>
                <Col sm="9">
                  <input
                    data-testid="luas tanah"
                    name="luas_tanah"
                    className="form-control"
                    id="luas_tanah"
                    type="text"
                    placeholder="Luas Tanah"
                    defaultValue={this.state.dt.luas_tanah}
                    required
                  />
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  Batas Utara
                </Label>
                <Col sm="9">
                  <input
                    data-testid="batas utara"
                    name="batas_utara"
                    className="form-control"
                    id="batas_utara"
                    type="text"
                    placeholder="Batas Utara"
                    defaultValue={this.state.dt.batas_utara}
                    required
                  />
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  Batas Timur
                </Label>
                <Col sm="9">
                  <input
                    data-testid="batas timur"
                    name="batas_timur"
                    className="form-control"
                    id="batas_timur"
                    type="text"
                    placeholder="Batas Timur"
                    defaultValue={this.state.dt.batas_timur}
                    required
                  />
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  Batas Selatan
                </Label>
                <Col sm="9">
                  <input
                    data-testid="batas selatan"
                    name="batas_selatan"
                    className="form-control"
                    id="batas_selatan"
                    type="text"
                    placeholder="Batas Selatan"
                    defaultValue={this.state.dt.batas_selatan}
                    required
                  />
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  Batas Barat
                </Label>
                <Col sm="9">
                  <input
                    data-testid="batas barat"
                    name="batas_barat"
                    className="form-control"
                    id="batas_barat"
                    type="text"
                    placeholder="Batas Barat"
                    defaultValue={this.state.dt.batas_barat}
                    required
                  />
                </Col>
              </FormGroup>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                data-testid="btnSubmitCreate"
                type="submit"
                className="btn btn-primary-custom"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formAcc}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpanAcc}>
            <div className="modal-header">
              <h5 className="modal-title">Konfirmasi Surat</h5>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Surat
                </Label>
                <Col sm="9">
                  {/* <Datepicker
                    id="tanggal_surat"
                    todayButton={"Today"}
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.tanggal_surat}
                    onChange={e => this.gantiTgl(e, "tanggal_surat")}
                    className="form-control"
                  /> */}
                </Col>
              </FormGroup>

              <Col sm="12">
                <table className="table table-bordered table-stripes table-hovered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Jabatan</th>
                      <th>Jabatan Pengganti</th>
                      <th>Nama</th>
                      <th>NIP</th>
                      <th>Status</th>
                      <th>
                        <i className="fa fa-check"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{tableTTD}</tbody>
                </table>
              </Col>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary-custom">
                Selesai
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formDisposisi}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form">
            <div className="modal-header">
              <h5 className="modal-title">Disposisi Surat</h5>
            </div>
            {/* <div className="modal-body">
              <table className="table table-bordered table-stripes table-hovered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Jabatan</th>
                    <th>Level</th>
                    <th>TTD</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.datDisposisi.map((dt, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{dt.data_jabatan.nama}</td>
                        <td>{dt.level}</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-info"
                            onClick={() => {
                              this.disposisi(dt.uuid, this.state.idSurat);
                            }}
                            disabled={this.state.status.btnForm}
                          >
                            Disposisi
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
             */}
            <div className="modal-body">
              <table className="table table-bordered table-stripes table-hovered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Status</th>
                    <th>Pejabat</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.riwayatDisposisi.map((dt, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          {dt.status ===  0
                            ? "Menunggu Disposisi"
                            : dt.status ===  1
                            ? "Telah di disposisi"
                            : "Ditolak"}
                        </td>
                        <td>{dt.data_perangkat.data_jabatan.nama}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.btnFormAcc}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.btnFormAcc}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default BasicTable;