import React, { Component } from "react";
// import { TabContent, TabPane, Nav, NavItem, NavLink, Col, Row, Button } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import Slider from "react-slick";
import "../assets/css/kiosk.css";

// import classnames from 'classnames';


import Modal from 'react-responsive-modal';
// import { unstable_batchedUpdates } from "react-dom";

export default class Beranda7 extends Component {
  state = {
    navigate: false,
    modalPanduan: false,
    modalPanduanLanjut: false,
    jenisPanduan:"",
    title:"",
    
    //Tab Pane
    active_tab_icon: '1',
  };

  logOut(e) {
    e.preventDefault();
    sessionStorage.removeItem("access_token");
    this.setState({ navigate: true });
  }

  // Bootstrap tabs function
	toggle_icon(tab) {
		if (this.state.active_tab_icon !== tab) {
			this.setState({
				active_tab_icon: tab
			});
		}
	}

  panduanLanjut = (jenis) => {
    if(jenis === "pembuatan-surat"){
      this.setState({
        title: "Panduan Pembuatan Surat",
        jenisPanduan: "pembuatan-surat",
      })
    }
    else if(jenis === "pendaftaran"){
      this.setState({
        title: "Panduan Pendaftaran",
        jenisPanduan: "pendaftaran",
      })
    }
    else if(jenis === "ubah-data"){
      this.setState({
        title: "Panduan Ubah Data",
        jenisPanduan: "ubah-data",
      })
    }
    this.setState({
      modalPanduan: false,
      modalPanduanLanjut: true,
    })
    this.forceUpdate();
  };

  render() {
    let logoAplikasi = <img src={require('../assets/images/digdes.webp').default} alt="" style={{  width: "fit-content", height: '70px' }} />;
    
    switch (localStorage.getItem("JENIS_APLIKASI").toLowerCase()) {
        case "halaman kampung":
          logoAplikasi = (
            <img
              src={require("../assets/images/hk_icon.png")}
              alt=""
              style={{
                width: "fit-content", 
                height: '70px'
              }}
            />
          );
          break;
        case "amanat nagari":
          logoAplikasi = (
            <img
              src={require("../assets/images/kiosk/amanat-nagari.png")}
              alt=""
              style={{
                width: "fit-content", 
                height: '70px'
              }}
            />
          );
          break;
        default:
          logoAplikasi = (
            <img
              src={require('../assets/images/digdes.webp').default}
              alt=""
              style={{
                width: "fit-content", 
                height: '70px'
              }}
            />
          );
          break;
    }

    const { navigate } = this.state;
    // //console.log(akun);
    if (navigate) {
      return <Redirect to="/" push={true} />;
    }

    const sliderStyle = {
      margin: "0px auto",
      width: "95%",
      // maxWidth: '1280px',
      maxWidth: "800px",
      // height: '357px',
      borderRadius: "21px",
      objectFit: "cover",
      objectPosition: "50% 50%"
    };

    var Merge_settings = {
      dots: true,
      infinite: true,
      speed: 100,
      slidesToShow: 1,
      slidesToScroll: 1,
      margin: 0,
      padding: 0
      // centerMode: true,
      // variableWidth: true,
      // adaptiveHeight:true,
    };

    // let gambarList = (
    //     <div>
    //       <div className="p-2" align="center">
    //         <img className="p-2" style={{ width: '500px' }} src={require("../assets/images/banner/kampung-tengah.png")} alt=""  />
    //       </div>
    //       <div className="p-2" align="center">
    //         <img className="p-2" style={{ width: '500px' }} src={require("../assets/images/banner/kampung-tengah.png")} alt=""  />
    //       </div>
    //       <div className="p-2" align="center">
    //         <img className="p-2" style={{ width: '500px' }} src={require("../assets/images/banner/kampung-tengah.png")} alt=""  />
    //       </div>
    //     </div>
    //   );

    return (
      <>
        <div className="bg-beranda-kiosk7">
          <div className="lingkaran">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="selamat-datang">
                      {logoAplikasi}
                      <div>
                        <span>
                          <b>SELAMAT DATANG DI</b>
                          <br />
                          <b> ANJUNGAN PELAYANAN MANDIRI </b>
                          <br />
                          <b>{localStorage.getItem('Nama_DESA').toUpperCase()}</b>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Link to="/login-masyarakat">
                      <div className="masuk">
                        <img
                          src={require("../assets/images/kiosk/masuk.png").default}
                          alt=""
                          style={{
                            maxHeight: "118px",
                            maxWidth: "100%",
                            width: 'fit-content',
                            borderRadius: "10px"
                          }}
                        />
                        <div>
                          <span>MULAI</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-6">
                    {/* <Link to="/login-masyarakat"> */}
                    <div className="keluar">
                      <img
                        src={require("../assets/images/kiosk/panduan.png").default}
                        alt=""
                        style={{
                          height: "118px",
                          maxHeight: "118px",
                          width: 'fit-content',
                          maxWidth: "100%",
                          borderRadius: "10px"
                        }}
                        onClick={() => {
                          this.setState({
                            modalPanduan: true,
                          })
                          this.forceUpdate();
                        }}
                      />
                      <div>
                        <span>PANDUAN</span>
                      </div>
                    </div>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6 gambar-laptop">
                <div className="gambar-laptop">
                  <img
                    src={require("../assets/images/kiosk/bg-kiosk-beranda.png")}
                    alt=""
                    style={{
                      height: 520,
                      width: "100%",
                      borderRadius: "10px",
                      objectFit: "scale-down"
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>


        <Modal
					open={this.state.modalPanduan}
					// open={true}
					// open={true}
					onClose={false}
					styles={{
            modal: {
              width: '80%',
              // maxWidth: 'unset',
            }
          }}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<div
						className="modal-body"
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							alignItems: 'center'
						}}
					>
						<div className="row" style={{ textAlign: 'center' }}>
							<h3>Panduan Penggunaan Layanan KiosK</h3>
						</div>

            <div className="row">
              <div className="col-md-6" 
                onClick={() => this.panduanLanjut("pembuatan-surat")}
              >
                    <div>
                      &nbsp;
                    </div>
                    <div className="masuk">
                      <br/>
                      <i className="fa fa-clipboard fa-lg" style={{ fontSize: '85px' }}></i>
                    <div>
                      &nbsp;
                    </div>
                    <div>
                      <span>Pembuatan Surat</span>
                    </div>
                  </div>
              </div>
              {/* <div className="col-md-4" 
                onClick={() => this.panduanLanjut("pendaftaran")}
              >
                  <div>
                    &nbsp;
                  </div>
                  <div className="keluar">
                    <br/>
                    <i className="fa fa-user-plus fa-lg" style={{ fontSize: '85px' }}></i>
                  <div>
                      &nbsp;
                  </div>
                  <div>
                    <span>Pendaftaran</span>
                  </div>
                  <div>
                      &nbsp;
                  </div>
                </div>
              </div> */}
              <div className="col-md-6" 
                onClick={() => this.panduanLanjut("ubah-data")}
              >
                  <div>
                    &nbsp;
                  </div>
                  <div className="keluar">
                    <br/>
                    <i className="fa fa-edit fa-lg" style={{ fontSize: '85px' }}></i>
                  <div>
                      &nbsp;
                  </div>
                  <div>
                    <span>Ubah Data</span>
                  </div>
                  <div>
                      &nbsp;
                  </div>
                </div>
              </div>
            </div>


            {/* <Nav tabs className="tabs-color">
              <NavItem>
                <NavLink className={classnames({ active: this.state.active_tab_icon === '1' })}
                  onClick={() => { this.toggle_icon('1'); }} >
                  <i className="icofont icofont-pay"></i>Membuat Surat
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={classnames({ active: this.state.active_tab_icon === '2' })}
                  onClick={() => { this.toggle_icon('2'); }} >
                  <i className="icofont icofont-truck-alt"></i>Pendaftaran
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={classnames({ active: this.state.active_tab_icon === '3' })}
                  onClick={() => { this.toggle_icon('3'); }} >
                  <i className="icofont icofont-user"></i>Ubah Data
                </NavLink>
              </NavItem>
            </Nav>
             */}
            {/* ===============================================================										 */}
            {/* <TabContent activeTab={this.state.active_tab_icon}>
              <TabPane tabId="1">
                <div style={{ marginTop: 15 }}>
                  <Row>
                    <Col sm="12">
                      <br />
                      
                      <div><br /><br /><h2 align="center">Jenis Pembayaran Desa</h2><hr /><br /><br /></div>

                    </Col>
                  </Row>

                </div>
              </TabPane> */}

              {/* =========================================================================== */}
              {/* <TabPane tabId="2">
                <div style={{ marginTop: 15 }}>
                  <Row>
                    <Col sm="12">
                      <br />
                      <div><br /><br /><h2 align="center">Jenis Pengiriman Desa</h2><hr /><br /><br /></div>
                    </Col>
                  </Row>

                </div>
              </TabPane> */}

              {/* =========================================================================== */}
              {/* <TabPane tabId="3">
                <div style={{ marginTop: 15 }}>
                  <Row>
                    <Col sm="12">
                      <br />
                      <div><br /><br /><h2 align="center">Jenis Pengiriman Desa</h2><hr /><br /><br /></div>
                    </Col>
                  </Row>

                </div>
              </TabPane>
            </TabContent> */}
					
          </div>
					<div
						className="modal-footer"
						style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
					>
						<button 
              onClick={() => {
                this.setState({
                  modalPanduan: false,
                })
                this.forceUpdate();
              }}
              className="btn btn-success"
            >
							Tutup
						</button>
					</div>
				</Modal>


        <Modal
					open={this.state.modalPanduanLanjut}
					onClose={() => {
            this.setState({ modalPanduanLanjut: false })
          }}
					styles={{
            modal: {
              width: '90%',
              maxWidth: 'unset',
              maxHeight: '100%'
            }
          }}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<div
						className="modal-body"
						style={{
							// display: 'flex',
							// justifyContent: 'center',
							// flexDirection: 'column',
							// alignItems: 'center'
						}}
					>
					
            <p style={{ textAlign: 'center', align: 'center', fontSize: '26px' }}><b>{this.state.title}</b></p>
					
          {
            this.state.jenisPanduan === "pembuatan-surat"
            ?
              <Slider {...Merge_settings}>
                <div className="p-2">
                  <img
                    className="p-2"
                    style={sliderStyle}
                    src={require(`../assets/images/kiosk/panduan/${localStorage.getItem('JENIS_APLIKASI').toLowerCase()}/cetaksurat/1.webp`).default}
                    alt=""
                  />
                </div>
                <div className="p-2">
                  <img
                    className="p-2"
                    style={sliderStyle}
                    src={require(`../assets/images/kiosk/panduan/${localStorage.getItem('JENIS_APLIKASI').toLowerCase()}/cetaksurat/2.webp`).default}
                    alt="" 
                  />
                </div>
                <div className="p-2">
                  <img
                    className="p-2"
                    style={sliderStyle}
                    src={require(`../assets/images/kiosk/panduan/${localStorage.getItem('JENIS_APLIKASI').toLowerCase()}/cetaksurat/3.webp`).default}
                    alt=""
                  />
                </div>
                <div className="p-2">
                  <img
                    className="p-2"
                    style={sliderStyle}
                    src={require(`../assets/images/kiosk/panduan/${localStorage.getItem('JENIS_APLIKASI').toLowerCase()}/cetaksurat/4.webp`).default}
                    alt=""
                  />
                </div>
              </Slider>
            : this.state.jenisPanduan === "pendaftaran"
            ?
              <Slider {...Merge_settings}>
                <div className="p-2">
                  <img
                    className="p-2"
                    style={sliderStyle}
                    src={require(`../assets/images/kiosk/panduan/${localStorage.getItem('JENIS_APLIKASI').toLowerCase()}/pendaftaran/1.jpg`)}
                    alt=""
                  />
                </div>
                <div className="p-2">
                  <img
                    className="p-2"
                    style={sliderStyle}
                    src={require(`../assets/images/kiosk/panduan/${localStorage.getItem('JENIS_APLIKASI').toLowerCase()}/pendaftaran/2.jpg`)}
                    alt=""
                  />
                </div>
                <div className="p-2">
                  <img
                    className="p-2"
                    style={sliderStyle}
                    src={require(`../assets/images/kiosk/panduan/${localStorage.getItem('JENIS_APLIKASI').toLowerCase()}/pendaftaran/3.jpg`)}
                    alt=""
                  />
                </div>
              </Slider>
            : this.state.jenisPanduan === "ubah-data"
            ?
              <Slider {...Merge_settings}>
                <div className="p-2">
                  <img
                    className="p-2"
                    style={sliderStyle}
                    src={require(`../assets/images/kiosk/panduan/${localStorage.getItem('JENIS_APLIKASI').toLowerCase()}/ubahdata/1.jpg`)}
                    alt=""
                  />
                </div>
                <div className="p-2">
                  <img
                    className="p-2"
                    style={sliderStyle}
                    src={require(`../assets/images/kiosk/panduan/${localStorage.getItem('JENIS_APLIKASI').toLowerCase()}/ubahdata/2.jpg`)}
                    alt=""
                  />
                </div>
                <div className="p-2">
                  <img
                    className="p-2"
                    style={sliderStyle}
                    src={require(`../assets/images/kiosk/panduan/${localStorage.getItem('JENIS_APLIKASI').toLowerCase()}/ubahdata/3.jpg`)}
                    alt=""
                  />
                </div>
              </Slider>
            :
            <>
            </>

          }
            

            {/* <Slider {...Merge_settings}>
              {gambarList}
            </Slider> */}
					
          </div>
					<div
						className="modal-footer"
						style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 0 }}
					>
						<button 
              onClick={() => {
                this.setState({ modalPanduanLanjut: false })
              }}
              className="btn btn-success"
            >
							Tutup
						</button>
					</div>
				</Modal>
      </>
    );
  }
}
