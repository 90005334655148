import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../../../components/common/breadcrumb.component";
// import BootstrapTable from "react-bootstrap-table-next";
// import Datetime from 'react-datetime';

// import Modal from "react-responsive-modal";
// import {
//   Button,
//   Form,
//   FormGroup,
//   Label,
//   Input,
//   FormText,
//   ButtonGroup,
//   CustomInput,
//   Row,
//   Col,
//   Nav,
//   NavItem,
//   NavLink,
//   TabContent,
//   TabPane
// } from "reactstrap";
// import classnames from "classnames";

import SweetAlert from "react-bootstrap-sweetalert";
// import Select from "react-select";




// koneksi
import { Post, Get, Delete, } from "../../../function/Koneksi";
// import { Tanggal, Hari, HariTanggal, HariTanggalJam } from "../../../function/Format";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// import ReactDatePicker from "react-datepicker";
import { Link } from "react-router-dom";

// koneksi
import { LAYANAN_KUA } from "../../../function/Koneksi";



let LIST_LAYANAN = JSON.parse(LAYANAN_KUA)

const frmDef = {
  id: 0,
  idbaru: "",
  id_keldes: "",
  nama: ""
};



class DafarPelayananKUA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Data table
      data: [],
      dataVerifikasi: [],
      dataDisetujui: [],
      dataDitolak: [],

      data_detail: [],

      // Pagination data table
      pagination: [],
      paginationDitolak: [],
      paginationDiverifikasi: [],
      paginationDisetujui: [],

      status: {
        form: false,
        formTambahVerifikasi: false,
        formKeterangan: false,
        formDetailGambar: false,
        btnForm: false,
        btnAksi: false,
        btnAksiTidakGelar: false,
        select: false,
      },

      // Diajukan
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      // Diverifikasi
      awal2: "",
      sebelum2: "",
      hal2: [],
      setelah2: "",
      akhir2: "",
      // Disetujui
      awal3: "",
      sebelum3: "",
      hal3: [],
      setelah3: "",
      akhir3: "",
      // Ditolak
      awal4: "",
      sebelum4: "",
      hal4: [],
      setelah4: "",
      akhir4: "",

      dt: frmDef,
      judul: "",
      // =========== Select 2 ============
      frmPrv: [],
      prvStat: [],
      frmKbkt: [],
      kbktStat: [],
      frmKec: [],
      kecStat: [],
      frmKlds: [],
      kldsStat: [],
      frmAgm: [],
      // =================================
      // =========== Alert ============
      alert: null,
      show: false,
      showCetakSurat: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      // ==============================
      active_tab_icon: "1",

      // Cari Data Gelar
      dataCariGelar: {
				nama: ''
			},

      // Detail Layanan
      detailLayanan: [],

      cek_posisi:'Pertama'
    };
  }

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      showCetakSurat: false,
      alert: null
    });
  };

  // Bootstrap tabs function
  toggle_icon(tab) {
    if (this.state.active_tab_icon !== tab) {
      if(tab === '1'){
        this.state.cek_posisi = 'Pertama'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      else if(tab === '2'){
        this.state.cek_posisi = 'Kedua'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      else if(tab === '3'){
        this.state.cek_posisi = 'Ketiga'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      else if(tab === '4'){
        this.state.cek_posisi = 'Keempat'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      
      this.setState({
        active_tab_icon: tab,
      });
    }
    
    this.forceUpdate();
    this.componentDidMount();
  }

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          switch (params.tab) {
            case "Diajukan":
              link =
                "?page=" +
                Math.ceil(
                  this.state.pagination.total / this.state.pagination.per_page
                );
              break;

            case "Diverifikasi":
              link =
                "?page=" +
                Math.ceil(
                  this.state.paginationDiverifikasi.total /
                  this.state.paginationDiverifikasi.per_page
                );
              break;

            case "Disetujui":
              link =
                "?page=" +
                Math.ceil(
                  this.state.paginationDisetujui.total /
                  this.state.paginationDisetujui.per_page
                );
              break;

            case "Ditolak":
              link =
                "?page=" +
                Math.ceil(
                  this.state.paginationDitolak.total /
                  this.state.paginationDitolak.per_page
                );
              break;
            default:
              // no default
              break;
          }
          break;
        case ">":
          switch (params.tab) {
            case "Diajukan":
              link =
                "?page=" + (parseInt(this.state.pagination.current_page) + 1);
              break;

            case "Diverifikasi":
              link =
                "?page=" +
                (parseInt(this.state.paginationDiverifikasi.current_page) +
                  1);
              break;

            case "Disetujui":
              link =
                "?page=" +
                (parseInt(this.state.paginationDisetujui.current_page) + 1);
              break;

            case "Ditolak":
              link =
                "?page=" +
                (parseInt(this.state.paginationDitolak.current_page) + 1);
              break;
            default:
              // no default
              break;

          }
          break;
        case "<":
          switch (params.tab) {
            case "Diajukan":
              link =
                "?page=" + (parseInt(this.state.pagination.current_page) - 1);
              break;

            case "Diverifikasi":
              link =
                "?page=" +
                (parseInt(this.state.paginationDiverifikasi.current_page) -
                  1);
              break;

            case "Disetujui":
              link =
                "?page=" +
                (parseInt(this.state.paginationDisetujui.current_page) - 1);
              break;

            case "Ditolak":
              link =
                "?page=" +
                (parseInt(this.state.paginationDitolak.current_page) - 1);
              break;
            default:
              // no default
              break;
          }
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }

    // Get data from db
    if(this.state.cek_posisi === "Pertama"){
      params.tab = "Diajukan"
    }
    else if(this.state.cek_posisi === "Kedua"){
      params.tab = "Diverifikasi"
    }
    else if(this.state.cek_posisi === "Ketiga"){
      params.tab = "Disetujui"
    }
    else if(this.state.cek_posisi === "Keempat"){
      params.tab = "Ditolak"
    }

    Get("pelayanan-external?status=" + params.tab + link, null, (dtkat) => {
      //console.log("COba tampil bos")
      //console.log(dtkat)
      switch (params.tab) {
        case "Diajukan":
          this.setState({
            data: dtkat.results.data,
            pagination: dtkat.results
          });
          break;

        case "Diverifikasi":
          this.setState({
            dataVerifikasi: dtkat.results.data,
            paginationDiverifikasi: dtkat.results
          });
          break;
        
        case "Disetujui":
          this.setState({
            dataDisetujui: dtkat.results.data,
            paginationDisetujui: dtkat.results
          });
          break;
        case "Ditolak":
          this.setState({
            dataDitolak: dtkat.results.data,
            paginationDitolak: dtkat.results
          });
          break;
        default:
          // no default
          break;
      }
      // //console.log(dtkat.results);
      this.pagination(dtkat.results, params.tab);
    });
  };

  // Pagination
  pagination = (data, tab) => {
    // const data = this.state.pagination;
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            key={i}
            onClick={() => this.fetch({ page: i, tab: tab })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p, tab: tab })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n, tab: tab })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }

      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1, tab: tab })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page, tab: tab })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }

    switch (tab) {
      case "Diajukan":
        this.setState({ awal, sebelum, hal, setelah, akhir });
        break;
      case "Diverifikasi":
        this.setState({
          awal2: awal,
          sebelum2: sebelum,
          hal2: hal,
          setelah2: setelah,
          akhir2: akhir
        });
        break;
      case "Disetujui":
        this.setState({
          awal3: awal,
          sebelum3: sebelum,
          hal3: hal,
          setelah3: setelah,
          akhir3: akhir
        });
        break;
      case "Ditolak":
        this.setState({
          awal4: awal,
          sebelum4: sebelum,
          hal4: hal,
          setelah4: setelah,
          akhir4: akhir
        });
        break;
      default:
        // no default
        break;
    }
  };


  // Simpan Verifikasi
  simpanVerifikasi = (e) => {
		e.preventDefault();
      this.forceUpdate();
      let id = this.state.data_detail.uuid;
      let addData = {
          status: "Diverifikasi",
          keterangan: "-",
      };

          let psn = "Verifikasi";
          let resstat = 200;
          
      Post('pelayanan-external/verifikasi/'+ id, null , addData, (res) => {
          this.forceUpdate();
          //console.log(res.status);
          if (res.status === resstat) {
              this.setState({ show: true, basicType: 'success', basicTitle: 'Verifikasi Pendaftaran Layanan', pesanAlert: 'Berhasil ' + psn + ' Data' });
              this.state.status.formTambahVerifikasi = false;
              this.forceUpdate()
          }
          else {
              this.setState({ show: true, basicType: 'danger', basicTitle: 'Verifikasi Pendaftaran Layanan', pesanAlert: 'Gagal ' + psn + ' Data' });
          }
          this.componentDidMount();
      });
	};
 
  // ========================================================================

  // ====================== Hapus Data =============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("kesukuan/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Suku",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Suku",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ====================== Hapus Data =============================

  bukaForm = jenis => {
    switch (jenis) {
      case "catatan":
        this.state.status.form = true;
        break;

      case "update":
        this.state.status.formUpdateSurat = true;
        break;

      default:
        break;
    }
    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.status.formTambahVerifikasi = false;
    this.state.status.form = false;

    this.state.data_detail.uuid = "";
    this.state.data_detail.nik = "";
    this.state.data_detail.nama = "";
    this.state.id_suku= null
    this.state.id_datuak= null
    this.state.data_detail.nama_gelar = "";



    this.state.status.accStat = false;
    this.state.status.tableAcc = "none";

    this.forceUpdate();
  };


  tombolAksiVerifikasi = (cell, row) => {
    return (
      <>
      {this.state.cek_posisi==="Pertama"
        ?
        <div>
            <button onClick={() => this.tambahDataVerifikasi(row)} color="info" className="btn  btn-secondary-custom"><i className="fa fa-check"></i> Verifikasi</button>
        </div>
        :this.state.cek_posisi==="Kedua"
        ?
        <div>
            <button onClick={() => this.tambahDataVerifikasi(row)} color="info" className="btn  btn-secondary-custom"><i className="fa fa-eye"></i> Detail</button>
        </div>
         :this.state.cek_posisi==="Ketiga"
         ?
         <div>
             <button onClick={() => this.tambahDataVerifikasi(row)} color="info" className="btn  btn-secondary-custom"><i className="fa fa-eye"></i> Detail</button>
         </div>
         :this.state.cek_posisi==="Keempat"
         ?
         <div>
             <button onClick={() => this.tambahDataVerifikasi(row)} color="info" className="btn  btn-secondary-custom"><i className="fa fa-eye"></i>  Detail</button>
         </div>
         :
         <>
         </>
      }
     
    </>
    )
}

  componentDidMount() {

    LIST_LAYANAN = JSON.parse(localStorage.getItem('LAYANAN_KUA'))

    if (typeof this.props.location.tabProps !== "undefined") {
      this.state.active_tab_icon = this.props.location.tabProps;
    }
    if (typeof this.props.location.state !== "undefined") {
      this.state.active_tab_icon = this.props.location.state.tab;

    }

    let tabnya = ""
    if(this.state.cek_posisi==="Pertama"){
      tabnya="Diajukan"
    }
    else if(this.state.cek_posisi==="Kedua"){
      tabnya="Diverifikasi"
    }
    else if(this.state.cek_posisi==="Ketiga"){
      tabnya="Disetujui"
    }
    else if(this.state.cek_posisi==="Keempat"){
      tabnya="Ditolak"
    }
    // Fetching data diajukan
    this.fetch({ page: null, where: [], tab: tabnya });
    // Fetcing data diverifikasi
    // this.fetch({ page: null, where: [], tab: "Diverifikasi" });
    // // Fetcing data Disetujui
    // this.fetch({ page: null, where: [], tab: "Disetujui" });
    // // Fetcing data Ditolak
    // this.fetch({ page: null, where: [], tab: "Ditolak" });
    
    
    this.forceUpdate();
  }


  // Cari Data penduduk gelar
	cariDataGelar = (e) => {
    
    //console.log("Tab Keberapa")
    //console.log(this.state.active_tab_icon)

    if(this.state.active_tab_icon===1){
      this.state.cek_posisi = "Pertama"
    }
    else{
      this.state.cek_posisi = "Non-Gelar"
    }

		if (e.key === 'Enter') {
      //console.log("Search")
			//console.log(e.target.value)
			let dataCariGelar = {
        q: e.target.value
			};
			this.setState({ dataCariGelar });
			setTimeout(
        function() {
          // mywindow.close();
					this.fetch();
				}.bind(this),
				500
        );
      }
      else{
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      this.forceUpdate();
	};


  // ============================== Ubah Data ===============================
    
//   ubahData = (id) => {
//     this.state.status.btnAksi = true;
//     this.state.status.formTambahVerifikasi = true;
//     this.state.judul = "Ubah Suku";
//     this.forceUpdate();
//     Get('kesukuan/find',id, (data) => {
//       //console.log("Data Ubah")
//       //console.log(data)
//       this.state.data_detail.uuid = data.results.uuid;
//       this.state.data_detail.nik = data.results.nik;
//       this.state.data_detail.nama = data.results.data_penduduk.nama;
//       this.state.id_suku= { value: data.results.nama_suku, label: data.results.nama_suku }
//       this.state.id_datuak= { value: data.results.nama_datuak, label: data.results.nama_datuak }
//       this.state.data_detail.nama_gelar = data.results.nama_gelar;

//           this.forceUpdate();
//         this.state.status.btnAksi = false;
//         this.forceUpdate();
//         this.bukaForm();
//     });
// }


  formKeterangan = (e) => {
		e.preventDefault();
			this.state.status.formKeterangan = true;
      this.state.data_detail.keterangan_layanan = "";
			this.forceUpdate();
	};

  detailGambar = (id, judul) => {
		// e.preventDefault();
			this.state.status.formDetailGambar = true;
      this.state.data_detail.show_gambar = id
      this.state.data_detail.judul_gambar = judul
      //console.log("Detail Link Gambar")
      //console.log(id)
			this.forceUpdate();
	};


  tutupKeterangan = (e) => {
		e.preventDefault();
		this.state.status.formKeterangan = false;
    document.getElementById("keterangan_tolak").value = ""
		this.forceUpdate();
	};

  tutupDetailGambar = (e) => {
		e.preventDefault();
		this.state.status.formDetailGambar = false;
		this.forceUpdate();
	};

  tambahKeterangan = (e) => {
		e.preventDefault();
      this.forceUpdate();
      let id = this.state.data_detail.uuid;
      let addData = {
          status: "Ditolak",
          keterangan: document.getElementById("keterangan_tolak").value,
      };

          let psn = "Tolak";
          let resstat = 200;

          //console.log("Data Tambah Keterangan")
          //console.log(id)
          //console.log(addData)
          
      Post('pelayanan-external/verifikasi/'+ id, null , addData, (res) => {
          this.forceUpdate();
          //console.log(res.status);
          if (res.status === resstat) {
              this.setState({ show: true, basicType: 'success', basicTitle: 'Verifikasi Pendaftaran Layanan', pesanAlert: 'Berhasil ' + psn + ' Verifikasi' });
		          this.state.status.formKeterangan = false;
              this.state.status.formTambahVerifikasi = false;
              document.getElementById("keterangan_tolak").value = ""
              this.forceUpdate()
          }
          else {
              this.setState({ show: true, basicType: 'danger', basicTitle: 'Verifikasi Pendaftaran Layanan', pesanAlert: 'Gagal ' + psn + ' Verifikasi' });
          }
          this.componentDidMount();
      });
	};
  
  render() {
    //console.log("Detail Layanan")
    //console.log(LIST_LAYANAN)
    // var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
    // var size = this.state.pagination.total;
    // //console.log("Tab Keberapa?")
    // //console.log(this.state.active_tab_icon)
    // //console.log(this.state.cek_posisi)

    // //console.log("Datanya")
    // //console.log(this.state.data)
    // //console.log(this.state.dataVerifikasi)
    // //console.log(this.state.dataDisetujui)
    // //console.log(this.state.dataDitolak)

    // var from = this.state.pagination.from;
    // var page = this.state.pagination.current;
    // var to = this.state.pagination.to;
    // var total = this.state.pagination.total;
    // var data = this.state.data;
    // var dataVerifikasi = this.state.dataVerifikasi;
    // var dataDisetujui = this.state.dataDisetujui;
    // var dataDitolak = this.state.dataDitolak;

    // var no = 0;
    // const frmDef = {
    //   id: 0,
    //   idbaru: "",
    //   id_keldes: "",
    //   nama: ""
    // };
    // const { SearchBar } = Search;

    // Data diproses
    // const columns = [

    //   {
    //     dataField: "tgl_melapor",
    //     text: "Tanggal Melapor"
    //     // sort: true
    //   },

    //   {
    //     dataField: "nik_pelapor",
    //     text: "NIK"
    //     // sort: true
    //   },

    //   {
    //     dataField: "nama_pelapor",
    //     text: "Nama"
    //     // sort: true
    //   },

    //   {
    //     dataField: "jenis_layanan",
    //     text: "Layanan"
    //     // sort: true
    //   },

    //   {
    //     dataField: "data_status_baru.status",
    //     text: "Status"
    //     // sort: true
    //   },

    //   {
    //     dataField: "aksi",
    //     text: "Aksi",
    //     isDummyField: true,
    //     csvExport: false,
    //     formatter: this.tombolAksiVerifikasi
    //   }
    // ];

    // Data ditolak
    // const columns2 = [

    //   {
    //     dataField: "nik_pelapor",
    //     text: "NIK"
    //     // sort: true
    //   },

    //   {
    //     dataField: "nik_pelapor",
    //     text: "NIK"
    //     // sort: true
    //   },

    //   {
    //     dataField: "nama_pelapor",
    //     text: "Nama"
    //     // sort: true
    //   },

    //   {
    //     dataField: "tgl_melapor",
    //     text: "Tanggal Melapor"
    //     // sort: true
    //   },

    //   {
    //     dataField: "data_status.status",
    //     text: "Status"
    //     // sort: true
    //   },

    //   {
    //     dataField: "aksi",
    //     text: "Aksi",
    //     isDummyField: true,
    //     csvExport: false,
    //     formatter: this.tombolAksiVerifikasi
    //   }
    // ];


    // const defaultSorted = [
    //   {
    //     dataField: "nama",
    //     order: "asc"
    //   }
    // ];

  

    return (
      <div>
        {/* <Breadcrumb title="Disposisi Surat" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Disposisi Surat</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      {/* <Button size="sm" color="success" className="btn-square" onClick={() => {
                        this.setState({ judul: "Tambah Dusun", dt: frmDef, prvStat: [], kbktStat: [], kecStat: [], kldsStat: [] }); this.bukaForm();
                      }}>Tambah Data</Button> */}
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                <div
											className="row"
											style={{
												display: 'flex',
												flexDirection: 'row',
												flexWrap: 'wrap',
												justifyContent: 'start'
											}}
										>
											{/* {tampilLayanan} */}
                      {
                        LIST_LAYANAN.pernikahan === true
                        ?
                          <div className="col-sm-3 mb-4">
                            <Link to={{ pathname: '/admin/layanan/kua/pernikahan' }}>
                              <div className="card o-hidden mb-0">
                                <div
                                  className="card-body p-3 mr-0 text-white"
                                  style={{ backgroundColor: '#2e99b0' }}
                                >
                                  <div className="media static-top-widget">
                                    <div
                                      className="media-body p-0"
                                      style={{
                                        lineHeight: '1.5em',
                                        minHeight: '6.5em',
                                        height: '100%',
                                        fontWeight: 'bold',
                                        paddingTop: '0px',
                                        paddingBottom: '0px',
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <span
                                        className="m-0 p-0"
                                        style={{ fontSize: '18px' }}
                                      >
                                        Pendaftaran Pernikahan
                                      </span>
                                      <i className="icofont icofont-ebook icon-bg text-white" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        :
                          <div className="col-12">
                            <h4 align="center">Layanan Tidak Tersedia</h4>
                          </div>
                      }
											{/* <div className="col-sm-3 mb-4">
												<Link to={{ pathname: '/admin/layanan/capil/pindah-datang' }}>
													<div className="card o-hidden mb-0">
														<div
															className="card-body p-3 mr-0 text-white"
															style={{ backgroundColor: '#2e99b0' }}
														>
															<div className="media static-top-widget">
																<div
																	className="media-body p-0"
																	style={{
																		lineHeight: '1.5em',
																		minHeight: '6.5em',
																		height: '100%',
																		fontWeight: 'bold',
																		paddingTop: '0px',
																		paddingBottom: '0px',
																		display: 'flex',
																		alignItems: 'center'
																	}}
																>
																	<span style={{ fontSize: '18px' }}>
																		Penerbitan Pindah / Datang Keluar Daerah
																	</span>
																	<i className="icofont icofont-ebook icon-bg text-white" />
																</div>
															</div>
														</div>
													</div>
												</Link>
											</div> */}
										
											
										</div>
									
                
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

      </div>
    );
  }
}

export default DafarPelayananKUA;