import React, { Component } from 'react';

// import Custom Componenets
// import Breadcrumb from '../components/common/breadcrumb.component';
// import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
// 	PaginationProvider,
// 	PaginationTotalStandalone,
// 	PaginationListStandalone
// } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Table } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import Dropzone from 'react-dropzone';
import Resizer from 'react-image-file-resizer';

// koneksi
import { Post, Get, ID_DESA, JENIS_DESA } from '../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';



// const frmDef = {
// 	uuid: '',
// 	nama: ''
// };
class BasicTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			data_detail: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			foto_desa: '',
			ket: null,
			foto_info: '',
			input: 'none',
			view: 'inherit',
			judul: 'Tambah Profile',
			alert: null,
			show: false,
			loading: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: ''
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = () => {
		this.setState({ loading: true })
		Get('profile-desa', null, (dtkat) => {
			console.log("data profil desa bos");
			console.log(dtkat);
			if (dtkat.results) {
				let defaultKet = {
					no_wa: '',
					testimoni: '',
					narasi: '',
				}

				let getKet = dtkat.results.ket ? dtkat.results.ket : JSON.stringify(defaultKet)
				this.setState({ 
					data: dtkat.results,
					foto_desa: dtkat.results.foto_desa,
					ket: JSON.parse(getKet)
				});
			} else {
				this.setState({
					input: 'none',
				})
				this.forceUpdate();
			}
			this.setState({ loading: false })
		});
	};

	// ========================================================================

	tutupForm = (e) => {
		this.setState({
			input: 'none',
			view: 'inherit',
			status: {
				...this.state.status,
				form: false,
			},
		});
		this.forceUpdate()
	};

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();
		this.setState({
			status: {
				...this.state.status,
				btnForm: true,
			},
		})
		this.forceUpdate();
		let uuid = this.state.data.uuid;
		let addProfile = {
			id_keldes: ID_DESA,
			nama_desa: document.getElementById('nama_desa').value,
			// kode_desa: document.getElementById('kode_desa').value,
			kode_desa: ID_DESA + document.getElementById('nama_desa').value,
			alamat_desa: document.getElementById('alamat_desa').value,
			kode_pos: document.getElementById('kode_pos').value,
			notelp: document.getElementById('notelp').value,
			email: document.getElementById('email').value,
			fax: this.state.data.fax,
			website: document.getElementById('website').value,
			batas_utara: document.getElementById('batas_utara').value,
			batas_timur: document.getElementById('batas_timur').value,
			batas_selatan: document.getElementById('batas_selatan').value,
			batas_barat: document.getElementById('batas_barat').value,
			ket: JSON.stringify({
				no_wa: document.getElementById('whatsapp').value,
				testimoni: document.getElementById('testimoni').value,
				narasi: document.getElementById('narasi').value,
				link_fb: document.getElementById('link_fb').value,
				link_ig: document.getElementById('link_ig').value,
				link_yt: document.getElementById('link_yt').value,
			})
		};

		let psn = '';
		let resstat = 204;
		let metode = "create";
		if (uuid === '' || uuid === 'undefined' || uuid === null) {
			psn = "Tambah";
			resstat = 201;
			addProfile.uuid = null;
			addProfile.foto_desa = this.state.foto_desa;

		}
		else {
			psn = "Ubah";
			resstat = 200;
			metode = "update";
			addProfile.uuid = uuid

			if (this.state.foto_desa) {
				addProfile.foto_desa = this.state.foto_desa;
			}
			else {
				addProfile.foto_desa = this.state.data.foto_desa;
			}
		}

		//console.log("Data Simpan")
		//console.log(addProfile)
		//console.log(uuid)
		//console.log(psn+ '/'+resstat+'/'+metode)

		Post('profile-desa/' + metode, addProfile.uuid, addProfile, (res) => {
			if (res.status === resstat) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Profile',
					pesanAlert: 'Berhasil ' + psn + ' Data',
					status: {
						...this.state.status,
						form: false,
					},
				});
				this.tutupForm()
				this.forceUpdate()
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Profile',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
			// this.setState({ input: 'none', view: 'inherit' });
			this.fetch();
		});
	};
	// ========================================================================

	// ============================== Ubah Data ===============================

	ubahData = (status, uuid) => {
		this.setState({
			status: {
				...this.state.status,
				form: true,
			},
			judul: 'Ubah Profil Desa',
		})
		// this.setState({ view: 'none', input: 'inherit' });
		this.forceUpdate();
	};
	// ========================================================================

	componentDidMount() {
		this.fetch({ page: null, where: [] });
	}

	processGbr = (d) => {
		// //console.log(d[0].type);
		let type = d[0].type.split('/');
		if (
			type[1] === 'jpg' ||
			type[1] === 'jpeg' ||
			type[1] === 'png' ||
			type[1] === 'bmp' ||
			type[1] === 'tiff' ||
			type[1] === 'webp'
		) {
			Resizer.imageFileResizer(
				d[0],
				300,
				300,
				type[1],
				100,
				0,
				(uri) => {
					this.setState({ foto_desa: uri, foto_info: '' });
				},
				'base64'
			);
		} else {
			this.setState({
				foto_desa: '',
				foto_info: <div className="text-danger font-size-10">Type file tidak valid</div>
			});
		}
	};

	render() {
		return (
			<div>
				{/* <Breadcrumb title="Data Profile" parent="Admin"/> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				{this.state.loading ? (
					<div align="center">
						<h5 className="modal-title" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
						<br />
						<img
							alt="loading"
							src={require("../assets/images/tes-loading.gif")}
							style={{
								// width: "100%",
								borderRadius: "10px",
								width: '150px'
							}}
						/>
						<br /><br />
					</div>
				) : (
					<div className="container-fluid">
						<div className="row">
							<div className="col-sm-12">
								<div className="card mb-0">
									<div className="card-body" style={{padding: '19px'}}>
										<div className="row">
											<div className="col-md-12 text-right mb-3">
												{
													this.state.data.uuid
														?
														<Button size="md" color="success" className="btn-icon" onClick={() => this.ubahData('update', this.state.data.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-edit"></i> Ubah Data Profil</Button>
														:
														<Button size="md" color="success" className="btn-icon" onClick={() => this.ubahData('create', '')} disabled={this.state.status.btnAksi}><i className="fa fa-plus"></i> Tambah Data Profil</Button>
												}
											</div>
										</div>
										<div
											className="row"
											style={{ border: '3px outset black', borderRadius: '25px', padding: '20px', margin: '0', paddingTop: '45px' }}
										>
											{/* <div className="col-md-12 text-right mb-4">
												{
													this.state.data.uuid
														?
														<Button size="md" color="success" className="btn-icon" onClick={() => this.ubahData('update', this.state.data.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-edit"></i> Ubah Data Profil</Button>
														:
														<Button size="md" color="success" className="btn-icon" onClick={() => this.ubahData('create', '')} disabled={this.state.status.btnAksi}><i className="fa fa-plus"></i> Tambah Data Profil</Button>
												}
											</div> */}
											{
												this.state.data.uuid
													?
													<>
														<div className="col-md-4 text-center">
															<img alt="fotodesa" src={this.state.data.foto_desa} style={{ maxHeight: '330px', maxWidth: '100%', height: 'auto' }} />
														</div>
														<div className="col-md-8">
															<table>
																<thead style={{ fontSize: "1rem", verticalAlign: "top" }}>
																	<tr>
																		<th width="180">ID  {JENIS_DESA}</th>
																		<th width="50" className="text-center">:</th>
																		<td><span>{this.state.data.id_keldes}</span></td>
																	</tr>
																	<tr>
																		<th>Nama {JENIS_DESA}</th>
																		<th width="50" className="text-center">:</th>
																		<td><span>{this.state.data.nama_desa}</span></td>
																	</tr>
																	<tr>
																		<th>Alamat {JENIS_DESA}</th>
																		<th width="50" className="text-center">:</th>
																		<td><span>{this.state.data.alamat_desa}</span></td>
																	</tr>
																	<tr>
																		<th>Kode POS</th>
																		<th width="50" className="text-center">:</th>
																		<td><span>{this.state.data.kode_pos}</span></td>
																	</tr>
																	<tr>
																		<th>No. Telp.</th>
																		<th width="50" className="text-center">:</th>
																		<td><span>{this.state.data.notelp}</span></td>
																	</tr>
																	<tr>
																		<th>No. WhatsApp</th>
																		<th width="50" className="text-center">:</th>
																		<td><span>{this.state.ket.no_wa}</span></td>
																	</tr>
																	<tr>
																		<th>Email</th>
																		<th width="50" className="text-center">:</th>
																		<td><span>{this.state.data.email}</span></td>
																	</tr>
																	<tr>
																		<th>Website</th>
																		<th width="50" className="text-center">:</th>
																		<td><span>{this.state.data.website}</span></td>
																	</tr>
																	<tr>
																		<th>Link Facebook</th>
																		<th width="50" className="text-center">:</th>
																		<td>
																			<span>
																				{this.state.ket && this.state.ket.link_fb 
																					? (<a target='_blank' href={this.state.ket.link_fb}>{this.state.ket.link_fb}</a>)
																					: '-'}
																			</span>
																		</td>
																	</tr>
																	<tr>
																		<th>Link Instagram</th>
																		<th width="50" className="text-center">:</th>
																		<td>
																			<span>
																				{this.state.ket && this.state.ket.link_ig 
																					? (<a target='_blank' href={this.state.ket.link_ig}>{this.state.ket.link_ig}</a>)
																					: '-'}
																			</span>
																		</td>
																	</tr>
																	<tr>
																		<th>Link Youtube</th>
																		<th width="50" className="text-center">:</th>
																		<td>
																			<span>
																				{this.state.ket && this.state.ket.link_yt 
																					? (<a target='_blank' href={this.state.ket.link_yt}>{this.state.ket.link_yt}</a>)
																					: '-'}
																			</span>
																		</td>
																	</tr>
																	<tr>
																		<th>Batas Wilayah Utara</th>
																		<th width="50" className="text-center">:</th>
																		<td><span>{this.state.data.batas_utara}</span></td>
																	</tr>
																	<tr>
																		<th>Batas Wilayah Timur</th>
																		<th width="50" className="text-center">:</th>
																		<td><span>{this.state.data.batas_timur}</span></td>
																	</tr>
																	<tr>
																		<th>Batas Wilayah Selatan</th>
																		<th width="50" className="text-center">:</th>
																		<td><span>{this.state.data.batas_selatan}</span></td>
																	</tr>
																	<tr>
																		<th>Batas Wilayah Barat</th>
																		<th width="50" className="text-center">:</th>
																		<td><span>{this.state.data.batas_barat}</span></td>
																	</tr>
																	<tr>
																		<th>Testimoni Kades</th>
																		<th width="50" className="text-center">:</th>
																		<td><span>{this.state.ket && this.state.ket.testimoni ? this.state.ket.testimoni : '-'}</span></td>
																	</tr>
																	<tr>
																		<th>Narasi Singkat {JENIS_DESA}</th>
																		<th width="50" className="text-center">:</th>
																		<td><span>{this.state.ket && this.state.ket.narasi ? this.state.ket.narasi : '-'}</span></td>
																	</tr>
																</thead>
															</table>
														</div>
													</>
													:
													(
														<div className="col-md-12 text-center">
															<h4 align="center">Data Tidak Dapat Ditemukan</h4>
														</div>
													)
											}
										</div>
									</div>

									

								</div>
							</div>
						</div>
						<Modal
							open={this.state.status.form}
							onClose={this.tutupForm}
							styles={{ modal: { width: '75%', maxWidth: 'unset' } }}
							closeOnEsc={false}
							closeOnOverlayClick={false}
						>
							<Form className="theme-form" onSubmit={this.simpan}>
								<div className="modal-header">
									<h4 className="modal-title">{this.state.judul}</h4>
								</div>

								<div className="modal-body">
									<input className="form-control" id="uuid" type="hidden" placeholder="UUID" defaultValue={this.state.data.uuid} />

									<div className="row">
										<div className="col-md-12">
											<div className="row text-center" style={{ justifyContent: "center" }}>
												<div className="col-md-4">
													<Label className="col-form-label" htmlFor="inputEmail3">
														Logo Kab/Kota
													</Label>
													<Dropzone
														onDrop={(acceptedFiles) => this.processGbr(acceptedFiles)}
														maxFiles={1}
													>
														{({ getRootProps, getInputProps }) => (
															<section>
																<div
																	{...getRootProps()}
																	style={{
																		minHeight: '100px',
																		paddingTop: '40px',
																		padding: '5px 5px 5px 5px',
																		border: '1px solid #d2d2d2'
																	}}
																>
																	<input {...getInputProps()} />
																	<p>
																		{this.state.foto_desa ? (
																			<img
																				alt="fotodesa2"
																				src={this.state.foto_desa}
																				style={{
																					minHeight: '50px',
																					minWidth: '50px',
																					width: '100%'
																				}}
																			/>
																		) : (
																			"Drag 'n' drop some files here, or click to select files"
																		)}
																		{this.state.foto_info ? (
																			this.state.foto_info
																		) : (
																			''
																		)}
																	</p>
																</div>
															</section>
														)}
													</Dropzone>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<Label className="col-form-label" htmlFor="inputEmail3">
												Nama {JENIS_DESA}
											</Label>
											<input
												className="form-control"
												id="nama_desa"
												type="text"
												placeholder={'Nama ' + JENIS_DESA}
												defaultValue={this.state.data.nama_desa}
												required
											/>
										</div>
										<div className="col-lg-4">
											<Label className="col-form-label" htmlFor="inputEmail3">
												Telp
											</Label>
											<input
												className="form-control"
												id="notelp"
												type="text"
												placeholder={'Telp ' + JENIS_DESA}
												defaultValue={this.state.data.notelp}
												required
											/>
										</div>
										<div className="col-lg-4">
											<Label className="col-form-label" htmlFor="inputEmail3">
												Email
											</Label>
											<input
												className="form-control"
												id="email"
												type="text"
												placeholder="Email"
												defaultValue={this.state.data.email}
												required
											/>
										</div>
										<div className="col-lg-4">
											<Label className="col-form-label" htmlFor="inputEmail3">
												Kode POS
											</Label>
											<input
												className="form-control"
												id="kode_pos"
												type="text"
												placeholder="Email"
												defaultValue={this.state.data.kode_pos}
												required
											/>
										</div>
										<div className="col-lg-4">
											<Label className="col-form-label" htmlFor="inputEmail3">
												No. WhatsApp
											</Label>
											<input
												className="form-control"
												id="whatsapp"
												type="number"
												placeholder="Nomor WhatsApp"
												defaultValue={this.state.ket ? this.state.ket.no_wa : ''}
												required
											/>
										</div>
										<div className="col-lg-4">
											<Label className="col-form-label" htmlFor="inputEmail3">
												Website
											</Label>
											<input
												className="form-control"
												id="website"
												type="text"
												placeholder="Website"
												defaultValue={this.state.data.website}
												required
											/>
										</div>
										<div className="col-lg-4">
											<Label className="col-form-label" htmlFor="inputEmail3">
												Link Facebook
											</Label>
											<input
												className="form-control"
												id="link_fb"
												type="text"
												placeholder="Link Facebook"
												defaultValue={this.state.ket && this.state.ket.link_fb ? this.state.ket.link_fb : ''}
												// required
											/>
										</div>
										<div className="col-lg-4">
											<Label className="col-form-label" htmlFor="inputEmail3">
												Link Instagram
											</Label>
											<input
												className="form-control"
												id="link_ig"
												type="text"
												placeholder="Link Instagram"
												defaultValue={this.state.ket && this.state.ket.link_ig ? this.state.ket.link_ig : ''}
												// required
											/>
										</div>
										<div className="col-lg-4">
											<Label className="col-form-label" htmlFor="inputEmail3">
												Link Youtube
											</Label>
											<input
												className="form-control"
												id="link_yt"
												type="text"
												placeholder="Link Youtube"
												defaultValue={this.state.ket && this.state.ket.link_yt ? this.state.ket.link_yt : ''}
												// required
											/>
										</div>
										<div className="col-lg-12">
											<Label className="col-form-label" htmlFor="inputEmail3">
												Alamat
											</Label>
											<textarea
												className="form-control"
												id="alamat_desa"
												placeholder={'Alamat ' + JENIS_DESA}
												defaultValue={this.state.data.alamat_desa}
												required
											/>
										</div>
										<div className="col-lg-6">
											<Label className="col-form-label" htmlFor="inputEmail3">
												Batas Wilayah Utara
											</Label>
											<textarea
												className="form-control"
												id="batas_utara"
												placeholder={'Batas Sebelah Utara ' + JENIS_DESA}
												defaultValue={this.state.data.batas_utara}
												required
											/>
										</div>
										<div className="col-lg-6">
											<Label className="col-form-label" htmlFor="inputEmail3">
												Batas Wilayah Timur
											</Label>
											<textarea
												className="form-control"
												id="batas_timur"
												placeholder={'Batas Sebelah Timur ' + JENIS_DESA}
												defaultValue={this.state.data.batas_timur}
												required
											/>
										</div>
										<div className="col-lg-6">
											<Label className="col-form-label" htmlFor="inputEmail3">
												Batas Wilayah Selatan
											</Label>
											<textarea
												className="form-control"
												id="batas_selatan"
												placeholder={'Batas Sebelah Selatan ' + JENIS_DESA}
												defaultValue={this.state.data.batas_selatan}
												required
											/>
										</div>
										<div className="col-lg-6">
											<Label className="col-form-label" htmlFor="inputEmail3">
												Batas Wilayah Barat
											</Label>
											<textarea
												className="form-control"
												id="batas_barat"
												placeholder={'Batas Sebelah Barat ' + JENIS_DESA}
												defaultValue={this.state.data.batas_barat}
												required
											/>
										</div>
										<div className="col-lg-12">
											<Label className="col-form-label" htmlFor="inputEmail3">
												Narasi Singkat {JENIS_DESA}
											</Label>
											<textarea
												className="form-control"
												id="narasi"
												type="text"
												rows={5}
												placeholder={"Narasi tentang " + JENIS_DESA}
												defaultValue={this.state.ket ? this.state.ket.narasi : ''}
												required
											/>
										</div>
										<div className="col-lg-12">
											<Label className="col-form-label" htmlFor="inputEmail3">
												Testimoni
											</Label>
											<textarea
												className="form-control"
												id="testimoni"
												type="text"
												rows={5}
												placeholder="Testimoni"
												defaultValue={this.state.ket ? this.state.ket.testimoni : ''}
												required
											/>
										</div>

									</div>
								</div>

								<div className="modal-footer">
									<button type="button" className="btn btn-warning" onClick={this.tutupForm}>
										Batal
									</button>
									<button
										type="submit"
										className="btn btn-success"
									// disabled={this.state.status.btnFormAcc}
									>
										Simpan
									</button>
								</div>
							</Form>
						</Modal>
					</div>
				)}
			</div>
		);
	}
}

export default BasicTable;