import React, {Component} from 'react';
import { Link } from 'react-router-dom'

// import Custom Componenets
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
// import Select from 'react-select';

// koneksi
import {Post, Get, Delete} from '../../function/Koneksi';

import NumberFormat from 'react-number-format';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';




const frmDef = {
    uuid: '',
    uuid_bansos: '',
    periode: '',
    bantuan_nominal: '',
    bantuan_barang: [{isi:[""]}],
}
class BasicTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pagination: [],
            status: {
                form:false,
                formDetail:false,
                btnForm:false,
                btnAksi:false,
                select:false
            },
            awal: "",
            sebelum: "",
            hal: [],
            setelah: "",
            akhir: "",
            dt:frmDef,
            judul: "Tambah Data Bantuan Sosial",
            judulDetail: "Tambah Data Detail Bantuan Sosial",
           
            // =================================
            // =========== Alert ============
            alert:null,
            show: false,
						loading: false,
            basicTitle:'',
            basicType:"default",
            pesanAlert:'',
            // ==============================

            jenisForm: '',

            // Pencarian
            dataCari: {
				q: ''
			},
        };
    }
    
    // ================== Sweet Alert ==================
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    // =================================================

    // ================== Ambil data dari db untuk table ==================
    fetch = (params = {}) => 
    {    
				this.setState({ loading: true })
        let link = "";
        if (params.page)
        {
            switch(params.page)
            {
                case '>>': link = "?page="+(Math.ceil(this.state.pagination.total / this.state.pagination.per_page));break;
                case '>': link = "?page="+(parseInt(this.state.pagination.current_page) + 1);break;
                case '<': link = "?page="+(parseInt(this.state.pagination.current_page) - 1);break;
                case '<<': link = "?page=1";break;
                default: link = "?page="+params.page;break;
            }
        }
        
        let setLink = '';
        if(link){
            setLink = `${link}&q=${this.state.dataCari.q}`
        }
        else{
            setLink = `?q=${this.state.dataCari.q}`
        }

		let getData = this.props.location.state;
        
        Get('bansos/periode/'+getData.uuid+setLink, null, (dtkat) => {
            this.setState({data:dtkat.results.data, pagination:dtkat.results, loading: false});
            this.pagination(dtkat.results);
        });
    }

    // Pagination
    pagination = (data) => {
        let awal = "";
        let hal = [];
        let sebelum = "";
        let setelah = "";
        let akhir = "";
        if (data.total > 0)
        {
            let start = 1;
            let end = 5;
            let n = 0;
            let p = 0;
            if (data.current_page <= 3)
            {   
                start = 1;
                end = 5;
                if (data.last_page > data.current_page)
                {
                    n = data.current_page + 1;
                }
            }
            else 
            {
                p = data.current_page - 1;
                n = data.current_page + 1;
                start = data.current_page - 2;
                end = data.current_page + 2;
            }
            if (end >= data.last_page - 2)
            {
                p = data.current_page - 1;
                if (start >= 5)
                {
                    start = data.last_page - 4;
                }
                end = data.last_page;
            }

            for (let i=start;i<=end;i++) 
            {
                let warna = "primary-custom";
                if (i === data.current_page)
                {
                    warna = "danger"
                }
                hal.push(<a href={() => false} onClick={()=>this.fetch({page:i})} className={"btn btn-"+warna+" btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
            }
            if (p > 0)
            {
                sebelum = (<a href={() => false} onClick={()=>this.fetch({page:p})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
            }
            if (n > 0)
            {
                setelah = (<a href={() => false} onClick={()=>this.fetch({page:n})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
            }
            awal = (<a href={() => false} onClick={()=>this.fetch({page:1})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
            akhir = (<a href={() => false} onClick={()=>this.fetch({page:data.last_page})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
        }
        this.setState({awal,sebelum,hal,setelah,akhir});
    }
    
     // Cari Data Table
	cariData = (e) => {
		if (e.key === 'Enter') {
			// console.log("Search")
			// console.log(e.target.value)
			let dataCari = {
				q: e.target.value
			};
			this.setState({ dataCari });
			setTimeout(
				function() {
					// mywindow.close();
					this.fetch();
				}.bind(this),
				500
			);
		}
	}

    // ========================================================================

    // ================== simpan data ==================
    simpan = e => {
        e.preventDefault();

        let cek_validasi = true
        let getBantuanBarang = JSON.stringify(this.state.dt.bantuan_barang[0].isi)
        let getBantuanNominal = ''

        // console.log('this.state.dt.bantuan_nominal')
        // console.log(this.state.dt.bantuan_nominal)

        if(
            this.state.dt.bantuan_nominal ==='' &&
            this.state.dt.bantuan_barang[0].isi[0]===''
        ){
            cek_validasi = false
        }

        if(this.state.dt.bantuan_barang[0].isi[0]===''){
            getBantuanBarang = '[]'
        }

        if(this.state.dt.bantuan_nominal !=='' || this.state.dt.bantuan_nominal !== 0 || this.state.dt.bantuan_nominal !== '0'){
            getBantuanNominal = this.state.dt.bantuan_nominal.replace(/[^\d]/g, '')
        }
        
        let cek_periode = false
        if(this.state.jenisForm === 'update'){
            if(document.getElementById("periode").value !==''){
                cek_periode = true
            }
        }
        else{
            cek_periode = true
        }

        if (cek_validasi && cek_periode)
        {
            this.setState({
                status:{
                    ...this.state.status,
                    btnForm: true,
                },
            })
            this.forceUpdate();
            let addData = {
                uuid_bansos: this.props.location.state.uuid,
                bantuan_nominal: getBantuanNominal,
                bantuan_barang: getBantuanBarang,
            }
            
            let psn = '';
            let resstat = 204;
            let metode = "";
            if (this.state.jenisForm === 'create')
            {
                psn = "Tambah"; 
                resstat = 201;
                addData.uuid = null;
                metode = "create";
                addData.periode = this.state.pagination.total+1;
            } 
            else if(this.state.jenisForm === 'update')
            {
                psn = "Ubah"; 
                resstat = 200;
                metode = "update";
                addData.uuid = this.state.dt.uuid
                addData.periode = document.getElementById("periode").value
            }

            // console.log('Data SImpan')
            // console.log(addData)
            // console.log(metode)

            Post('bansos/periode/'+ metode,addData.uuid, addData, (res) => {
                this.setState({
                    status:{
                        ...this.state.status,
                        btnForm: false,
                        form: false,
                    },
                })
                this.forceUpdate();
                if (res.status === resstat)
                {
                    this.setState({ show: true, basicType:'success', basicTitle:'Data Periode Bantuan Sosial', pesanAlert:'Berhasil '+psn+' Data' });
                }
                else
                {
                    this.setState({ show: true, basicType:'danger', basicTitle:'Data Periode Bantuan Sosial', pesanAlert:'Gagal '+psn+' Data' });
                }
                if (this.state.jenisForm === 'create'){
                    this.componentDidMount();
                } 
                else if(this.state.jenisForm === 'update'){
                    this.fetch({page: this.state.pagination.current_page});
                }
            });
        }
        else
        {
            this.setState({ show: true, basicType:'danger', basicTitle:'Data Periode Bantuan Sosial', pesanAlert:"Data tidak lengkap!" });
        }
    }
    // ========================================================================
    
    // ============================== Ubah Data ===============================
    
    ubahData = (data) => {
        let bantuan_barang = JSON.parse(data.bantuan_barang)
        // console.log('Data Detail')
        // console.log(data)
        // console.log(data.bantuan_nominal.toLocaleString())
        this.setState({
            jenisForm: 'update',
            status:{
                ...this.state.status,
                btnAksi: true,
            },
            dt:{
                uuid: data.uuid,
                uuid_bansos: data.uuid_bansos,
                periode: data.periode,
                bantuan_nominal: data.bantuan_nominal === 0?'':data.bantuan_nominal.toLocaleString(),
                bantuan_barang: [
                    bantuan_barang.length === 0? {isi:[""]}
                    : {isi:bantuan_barang}
                ],
            },
            judul: "Ubah Data Periode Bantuan",
        })
        this.bukaForm();
        this.forceUpdate();
    }
    // ========================================================================
    // ============================= Hapus Data ===============================
    konfirmHapus = (id) => {
        this.setState({
            alert: (
                <SweetAlert
                    showCancel
                    confirmBtnText="Hapus"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    type="danger"
                    title="Yakin ingin hapus data ?"
                    onCancel={this.hideAlert}
                    onConfirm={()=> this.hapusData(id)}
                >
                    Proses ini tidak dapat dibatalkan!
                </SweetAlert>
            )
        });
    }

    hapusData = (id) => {
        this.hideAlert();
        let psn = "Hapus";
        Delete('bansos/periode/delete', id, (res) => {
            if (res === 200)
            {
                this.setState({ show: true, basicType:'success', basicTitle:'Data Periode Bantuan', pesanAlert:'Berhasil '+psn+' Data' });
            }
            else
            {
                this.setState({ show: true, basicType:'danger', basicTitle:'Data Periode Bantuan', pesanAlert:'Gagal '+psn+' Data' });
            }
            this.fetch({page: this.state.pagination.current_page});
        })
    }
    // ========================================================================

    bukaForm = () => {
        this.setState({
            status:{
                ...this.state.status,
                form: true,
            },
        })
        this.forceUpdate();
    }

    tutupForm = () => {
        this.setState({
            status:{
                ...this.state.status,
                form: false,
            },
            jenisForm: '',
        })
        this.forceUpdate();
    }

    tombolAksi = (cell, row) => {
        return (
            <>
                <Link to={{ pathname: "/admin/bansos/detail-bansos-penduduk", state: row }}>
                    <Button  size="xs" color="info" className="btn-icon" title='Detail Data'><i className="fa fa-eye"></i></Button> &nbsp;&nbsp;
                </Link>
                <Button  size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row)} title='Edit Data' disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
                <Button  size="xs" title='Hapus Data' color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>  
            </>
        )
    }

    changeSelectValue = (e,sel) =>
    {
        if (e){
            switch(sel)
            {
                case 'rw': 
                    this.setState({
                        rwStat:e,
                        dt:{
                            ...this.state.dt,
                            id_rw: e.value,
                        },
                    }); 
                    break;
                default:
                    // no default
                    break;
            }
        }
        else 
        {
            switch(sel)
            {
                case 'rw': 
                    this.setState({
                        rwStat:[],
                        dt:{
                            ...this.state.dt,
                            id_rw: 0,
                        },
                    }); 
                    break;
                default:
                    // no default
                    break;
            }
        }
        
        this.setState({
            status:{
                ...this.state.status,
                select: false,
            },
        })
        this.forceUpdate();
    }

    componentDidMount() {
        this.fetch({page:null,where:[]});
    }

    render() {
        // var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
        // var size = this.state.pagination.total;
        // var from = this.state.pagination.from;
        // var page = this.state.pagination.current;
        // var to = this.state.pagination.to;
        // var total = this.state.pagination.total;
        // console.log('data DT Render')
        // console.log(this.state.dt)
        // console.log('Data Props Periode Bansos')
        // console.log(this.props.location.state)
        var data = this.state.data;
        const frmDef = {
            uuid: '',
            uuid_bansos: '',
            periode: '',
            bantuan_nominal: '',
            bantuan_barang: [{isi:[""]}],
        }   
        // const { SearchBar } = Search;
        const columns = [
            {
                dataField: "no",
                text: "No",
                headerAlign: 'center',
                align: 'center',
								style: {
									width: '80px'
								},
                formatter: (key, obj, index) =>{          
                    let current_pagenum = this.state.pagination.current_page;
                    let total_records_per_page = this.state.pagination.per_page;
                    let row_index = (index+1);
                    let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
                    return serial_num; 
                },
                headerStyle: (colum, colIndex) => {
                    return { width: '15px', textAlign: 'left' };
                }
            },
            {
                dataField: 'periode',
                text: 'Periode', 
								headerAlign: 'center',
                align: 'center',
                sort: true
            },
            {
                dataField: 'bantuan_nominal',
                text: 'Bantuan Nominal', 
								headerAlign: 'center',
                align: 'center',
                sort: true,
                // formatter: (cell, obj) => {
                //     let showData = obj.bantuan_nominal? obj.bantuan_nominal : '-'
                //     return showData;
                // },
                formatter: (cell, obj) => {
                    let showData = ''
                    if(obj.bantuan_nominal){
                        showData = 'Rp. '+ obj.bantuan_nominal.toLocaleString("id-ID", {minimumFractionDigits: 0})
                    }
                    else{
                        showData = '-'
                    }
                    return showData;
                },
            },
            {
                dataField: 'bantuan_barang',
                text: 'Bantuan Barang', 
								headerAlign: 'center',
                sort: true,
                formatter: (cell, obj) => {
                    let data = obj.bantuan_barang
                    let showData = ''
                    if(data === '[]'){
                        showData = '-'
                    }
                    else{
                        let getDataParse = JSON.parse(obj.bantuan_barang)
                        showData = getDataParse.map((detail, i) => {
                            return (
                                        <ul>
                                            <li>{i+1}. {detail}</li>
                                        </ul>
                                    )
                        })
                                    
                    }
                    // console.log('showData')
                    // console.log(showData)
                    return showData;
                },
            },
            {
                dataField: 'aksi',
                text: 'Aksi',
                headerAlign: 'center',
                align: 'center',
								style: {
									width: '130px'
								},
                formatter: this.tombolAksi,
            },
        ];

        return (
            <div>
                {/* <Breadcrumb title="Data RT" parent="Admin"/> */}
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    confirmBtnBsStyle="success"
                    onConfirm={this.closeAlert}
                >
                    {this.state.pesanAlert}
                </SweetAlert>

                {this.state.alert}

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card mb-0">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <Button  size="sm" color="success" className="btn-square" onClick={()=> {
                                                this.setState({
                                                    judul:"Tambah Data Bantuan Sosial", 
                                                    dt:frmDef, 
                                                    jenisForm: 'create',
                                                });
                                                this.bukaForm();}}>
                                                Tambah Data
                                            </Button>   
                                        </div>
                                        <div className="col-sm-6 text-right">
                                            <button onClick={this.props.history.goBack} color="primary" className="btn btn-secondary"><i className="fa fa-arrow-left"></i> Kembali</button>                                   
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="row">
                                        <div className="col-sm-11 text-right">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="cariTable"
                                                placeholder="Cari Data berdasarkan periode"
                                                onKeyPress={this.cariData}
                                            />                                 
                                        </div>
                                        <div className="col-sm-1 text-left">
                                            <Button  size="sm" color="success" className="btn-icon" title='Cari Data'><i className="fa fa-search"></i></Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body datatable-react">
																	{this.state.loading ? (
																		<div className="row">
																			<div className="col-sm-12" align="center">
																				<img
																					alt="loading"
																					src={require("../../assets/images/tes-loading.gif")}
																					style={{
																						borderRadius: "10px",
																						width: '100px'
																					}} />
																			</div>
																		</div>
																	) : data.length > 0 ? (
																		<>
                                    <BootstrapTable
                                        keyField="id"
                                        data={ data }
                                        columns={ columns }
                                        />
                                    <div className="pull-right text-white">
                                        {this.state.awal}{this.state.sebelum}{this.state.hal.map(dt=> { return dt})}{this.state.setelah}{this.state.akhir}
                                    </div>
																		</>
																	) : (
																		<h6 className="text-center mb-2 mt-2">Belum ada data</h6>
																	)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <Modal open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false} styles={{ modal: { width: '60%' } }}>
                    <Form className="theme-form" onSubmit={this.simpan}>
                        <div className="modal-header">
                            <h5 className="modal-title">{this.state.judul}</h5>
                        </div>
                        <div className="modal-body">
                            <input className="form-control" id="uuid" type="hidden" placeholder="UUID" defaultValue={this.state.dt.uuid}/>
                            <div className="col-lg-12 pl-3">
                                {this.state.dt.uuid?
                                    <>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Periode</Label>
                                        <Col sm='9'>
                                            <input className="form-control" id="periode" type="text" placeholder="Periode" defaultValue={this.state.dt.periode}/>
                                        </Col>
                                    </FormGroup>
                                    </>
                                    : <></>
                                }
                                <FormGroup className="row">
                                    <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Bantuan Nominal</Label>
                                    <Col sm='9'>
                                        <NumberFormat
                                            id="penghasilan"
                                            placeholder="Contoh : 150.000.000"
                                            thousandSeparator={true}
                                            prefix={'Rp. '}
                                            className="form-control"
                                            // onFocus={() => this.setActiveInput('penghasilan')}
                                            value={this.state.dt.bantuan_nominal}
                                            onChange={(e) =>{
                                                this.setState({
                                                    dt:{
                                                        ...this.state.dt,
                                                        bantuan_nominal: e.target.value,
                                                    }
                                                })
                                            }} 
                                        />
                                    </Col>
                                </FormGroup>
                            </div>

                            <div className="col-lg-12 pl-3">
                                {this.state.dt.bantuan_barang.length > 0 ? (
                                <>
                                    <FormGroup className="row">
                                    <Label className="col-sm-6 col-form-label" htmlFor="inputEmail3">
                                        <b>Bantuan Barang</b>
                                    </Label>
                                    </FormGroup>
                                    {this.state.dt.bantuan_barang.map((dti, i) => {
                                    return (
                                        <div style={{ border: '1px solid black', borderRadius: '10px', padding: '10px', marginBottom: '15px', paddingRight:'30px'}}>
                                        {/* Tombol hapus petunjuk */}
                                        
                                        {dti.isi.map((dt_isi, j) => {
                                            return (
                                            <>
                                                <FormGroup className="row">
                                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                                                    Barang {j+1}
                                                </Label>
                                                <Col sm="8">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Contoh: Beras"
                                                        required
                                                        defaultValue={dt_isi}
                                                        value={dt_isi}
                                                        onChange={(event2) => {
                                                            let isi = [...this.state.dt.bantuan_barang[i].isi]
                                                            let get_data = event2.target.value
                                                            
                                                            isi[j] = get_data

                                                            let bantuan_barang = [...this.state.dt.bantuan_barang]
                                                            bantuan_barang[i] = {...bantuan_barang[i], isi}
                            
                                                            this.setState({ 
                                                                dt: {...this.state.dt, 
                                                                    bantuan_barang
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </Col>
                                                {j > 0 ? (
                                                    <Col lg="1">
                                                    <Button
                                                        className="btn btn-danger"
                                                        title="Hapus Barang"
                                                        style={{ padding: '6px 15px' }}
                                                        onClick={() => {
                                                            let isi = [...this.state.dt.bantuan_barang[i].isi]
                                                            isi.splice(j, 1)

                                                            let bantuan_barang = [...this.state.dt.bantuan_barang]
                                                            bantuan_barang[i] = {...bantuan_barang[i], isi}
                            
                                                            this.setState({ 
                                                                dt: {
                                                                        ...this.state.dt, 
                                                                        bantuan_barang
                                                                    }
                                                                })
                                                            }}
                                                    >-</Button>
                                                    </Col>
                                                ) : ''}
                                                </FormGroup>
                                            </>
                                            )
                                        })}
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <Button
                                                    type="button"
                                                    className="btn btn-info"
                                                    // disabled={this.state.status.btnForm}
                                                    onClick={() => {
                                                    let isi = [...this.state.dt.bantuan_barang[i].isi]
                                                    isi.push('')
                                                    let bantuan_barang = [...this.state.dt.bantuan_barang]
                                                    bantuan_barang[i].isi = isi
                                                    this.setState({ 
                                                            dt: {
                                                                    ...this.state.dt,
                                                                    bantuan_barang
                                                            }
                                                        })
                                                    }}
                                                >
                                                    Tambah Barang
                                                </Button>
                                            </div>
                                        </div>
                                        </div>
                                    )
                                    })}
                                </>
                                ) : ''}
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
                            <button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
                        </div>
                    </Form>
                </Modal>
            </div>
        )
    }
}


export default BasicTable;
