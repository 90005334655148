import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Col,
} from 'reactstrap';
// import Datepicker from 'react-datepicker';
import Select from 'react-select';
import Datetime from 'react-datetime';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { Get, JENIS_DUSUN, Post } from '../../function/Koneksi';
import { Tanggal } from '../../function/Format';

const ModalSuratAhliWaris = ({ data, optionData, setAlert, isOpen, setIsOpen, statusRefresh, setStatusRefresh, propsData }) => {
	const dateFormat = require('dateformat');

	const frmDex = {
		uuid: '',
		id_jenis: '',
		nik_pelapor: '',
		nik: '',
		kk: '',
		nama: '',
		tempat_lahir: '',
		tanggal_lahir: '',
		jenis_kelamin: '',
		pekerjaan: '',
		pendidikan_terakhir: '',
		kewarganegaraan: '',
		agama: '',
		alamat: '',
		status_perkawinan: '',
		waktu_meninggal: '',
		tempat_meninggal: '',
		sebab_meninggal: '',
		
		nik2: '',
		nama2: '',
		tempat_lahir2: '',
		tanggal_lahir2: '',
		jenis_kelamin2: '',
		pekerjaan2: '',
		pendidikan_terakhir2: '',
		kewarganegaraan2: '',
		agama2: '',
		alamat2: '',
		status_perkawinan2: '',
		waktu_meninggal2: '',
		tempat_meninggal2: '',
		sebab_meninggal2: '',
		detail: []
	}

	const initFormData = {
		frmNik: optionData.frmNik,
		frmShdk: optionData.frmShdk,
		frmNik3: optionData.frmNik3,
		nikStat: null,
		nik2Stat: null,
		nik3Stat: null,
		shdk_aw: null,
		detail: [],
		status: {
			form: false,
			btnForm: false,
			btnAksi: [],
			dataPenduduk: "none",
			dataPenduduk2: "none",
		},
		meninggal2: "none",
		show: false,
		basicTitle: "",
		basicType: "default",
		pesanAlert: "",
	}

	const [formData, setFormData] = useState(initFormData)
	const [props, setProps] = useState(propsData)
	const [dt, setDt] = useState(data ? data : frmDex)

	useEffect(() => {
		if (document.getElementById("modalForm")) {
			document.getElementById("modalForm").reset()
		}
		console.log(data);
		console.log(optionData);

		setDt(data ? data : frmDex)
		setFormData(data ? {
			...initFormData,
			nikStat: optionData.nikStat,
			nik2Stat: optionData.nik2Stat,
			frmNik2: optionData.frmNik2,
			frmNik3: optionData.frmNik3,
			meninggal2: optionData.meninggal2,
			status: optionData.status,
		} : initFormData)
	}, [isOpen])

	const handleInputChangeNIK = (e, fld) => {
		if (e) {
			let nikStat = { value: e, label: e };

			Post("penduduk", null, { nik: e }, datanik => {
				let frmNik = [];
				datanik.data.results.data.forEach(dt => {
					frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
				});

				switch (fld) {
					case "nik":
						setFormData({
							...formData,
							frmNik,
							nikStat
						})
						break;
					case "nik2":
						setFormData({
							...formData,
							frmNik2: frmNik,
							nik2Stat: nikStat
						})
						break;

					default:
						break;
				}

			});

		}
	};

	const changeInputText = (e, fld) => {
		switch (fld) {
			case "penghasilan":
				setDt({
					...dt,
					penghasilan: e.target.value,
				})
				break;
			default:
				// no default
				break;
		}
	};

	const getPenduduk = (e, sel, jns) => {
		if (e) {
			// this.changeSelectValue(e, sel);
			setAlert({
				loadingPreview: true
			})

			Get("penduduk/find", e.value, data => {
				console.log(data)
				let alamat = '';
				if (localStorage.getItem('JENIS_DESA').toLowerCase() === 'kelurahan') {
					alamat =
						"RT. " +
						data.results.data_kk.data_rt.nama +
						"/RW. " +
						data.results.data_kk.data_rt.data_rw.nama +
						", " +
						data.results.data_kk.data_rt.data_rw.data_keldes.nama +
						", Kec. " +
						data.results.data_kk.data_rt.data_rw.data_keldes
							.data_kecamatan.nama +
						", " +
						data.results.data_kk.data_rt.data_rw.data_keldes
							.data_kecamatan.data_kabkota.nama;
				} else {
					if (data.results.data_kk.id_rt === '0') {
						alamat = localStorage.getItem('JENIS_DESA') + " " + data.results.data_keldes.nama +
							", Kec. " + data.results.data_keldes.data_kecamatan.nama +
							", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
							", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama;
					} else {
						alamat =
							" RT. " +
							data.results.data_kk.data_rt.nama +
							"/RW. " +
							data.results.data_kk.data_rt.data_rw.nama +
							", " + JENIS_DUSUN + " " +
							data.results.data_kk.data_rt.data_rw.data_dusun.nama +
							", " + localStorage.getItem('JENIS_DESA') + " " +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
							", Kec. " +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
								.data_kecamatan.nama +
							", " +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
								.data_kecamatan.data_kabkota.nama;
					}
				}

				if (jns === "pelapor") {
					Get("penduduk/kk/" + data.results.id_kk, null, dtkk => {
						let frmNik3 = [];
						dtkk.results.forEach(val => {
							frmNik3.push({ value: val.id, label: val.id + ' (' + val.nama + ')' });
						});

						setFormData({
							...formData,
							nikStat: e,
							frmNik2: frmNik3,
							frmNik3,
							status: {
								...formData.status,
								dataPenduduk: "block",
							},
						})
						setDt({
							...dt,
							nik: e.value,
							nik_pelapor: e.value,
							kk: data.results.id_kk,
							nama: data.results.nama,
							tempat_lahir: data.results.tempat_lahir,
							tanggal_lahir: new Date(
								data.results.tanggal_lahir
							),
							pekerjaan: data.results.data_pekerjaan.nama,
							pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
							agama: data.results.data_agama.nama,
							status_perkawinan: data.results.status_perkawinan,
							jenis_kelamin: data.results.jk,
							alamat: alamat,
							kewarganegaraan: 'WNI',
							detail: []
						})
						setAlert({
							loadingPreview: false
						})

						// For testing
						// if (props.selectOnChange2) {
						// 	props.selectOnChange2(dt)
						// }

					});

				} else if (jns === "pelapor2") {
					console.log("pelapor2");
					setFormData({
						...formData,
						nik2Stat: e,
						status: {
							...formData.status,
							dataPenduduk2: "block",
						},
					})
					setDt({
						...dt,
						nik2: e.value,
						nama2: data.results.nama,
						tempat_lahir2: data.results.tempat_lahir,
						tanggal_lahir2: new Date(
							data.results.tanggal_lahir
						),
						pekerjaan2: data.results.data_pekerjaan.nama,
						pendidikan_terakhir2: data.results.data_pendidikan_terakhir.nama,
						agama2: data.results.data_agama.nama,
						status_perkawinan2: data.results.status_perkawinan,
						jenis_kelamin2: data.results.jk,
						alamat2: alamat,
						kewarganegaraan2: 'WNI',
					})
					setAlert({
						loadingPreview: false
					})
				} else if (jns === "pengikut") {
					let detail = dt.detail
					detail.push({
						nik: e.value,
						nama: data.results.nama,
						jenis_kelamin: data.results.jk,
						tanggal_lahir: data.results.tanggal_lahir,
						tempat_lahir: data.results.tempat_lahir,
						pekerjaan: data.results.data_pekerjaan.nama,
						shdk: formData.frmShdk[data.results.shdk],
						keterangan: "",
					})

					setDt({
						...dt,
						detail
					})

					setAlert({
						loadingPreview: false
					})

					// For testing
					// if (this.props.getListNIK3) {
					// 	this.props.getListNIK3(frmNik3)
					// }
				}
			});
		} else {
			switch (jns) {
				case "pelapor":
					setDt(frmDex)
					setFormData({
						...formData,
						status: {
							...formData.status,
							dataPenduduk: "none"
						},
						nikStat: null,
						nik2Stat: null,
						frmNik2: [],
						frmNik3: [],
					})
					break;
				case "pengikut":
					setFormData({
						...formData,
						nik3Stat: null
					})
					break;
				default:
					break;
			}
		}
	};

	const hapusDetail = (i) => {
		console.log(i);
		// let currDetail = dt.detail
		let newDetail = dt.detail.splice(i, 1)

		// console.log(currDetail);
		console.log(newDetail);
		console.log(dt.detail);

		setDt({
			...dt,
			detail: dt.detail
		})
	};

	const tambahMeninggal = (e) => {
		// e.preventDefault();
		if (formData.meninggal2 === "none") {
			setDt({
				...dt,
				nama2: '',
				tanggal_lahir2: '',
				tempat_lahir2: '',
				agama2: '',
				pekerjaan2: '',
				kewarganegaraan2: '',
				alamat2: '',
				jenis_kelamin2: '',
				status_perkawinan2: '',
				waktu_meninggal2: '',
				tempat_meninggal2: '',
				sebab_meninggal2: '',
			})
			setFormData({
				...formData,
				meninggal2: "inherit",
				nik2Stat: null,
				status: {
					...formData.status,
					dataPenduduk2: "none"
				}
			})
		} else {
			setDt({
				...dt,
				nama2: '',
				tanggal_lahir2: '',
				tempat_lahir2: '',
				agama2: '',
				pekerjaan2: '',
				kewarganegaraan2: '',
				alamat2: '',
				jenis_kelamin2: '',
				status_perkawinan2: '',
			})
			setFormData({
				...formData,
				meninggal2: "none",
				nik2Stat: null,
				status: {
					...formData.status,
					dataPenduduk2: "none"
				}
			})

			// this.state.status.dataPenduduk2 = "none";
			// this.setState({
			//   meninggal2: "none",
			//   nik2Stat: null,
			// });
			// this.forceUpdate();
		}
	};

	const simpan = (e) => {
		e.preventDefault();
		console.log('tesdfs');
		console.log(formData);
		console.log(dt);

		let bodyRaw = {
			...dt,
			tanggal_lahir: dateFormat(new Date(dt.tanggal_lahir), 'yyyy-mm-dd'),
			// tanggal_lahir2: dateFormat(new Date(dt.tanggal_lahir2), 'yyyy-mm-dd'),
			waktu_meninggal: dateFormat(new Date(dt.waktu_meninggal), 'yyyy-mm-dd HH:MM'),
			// waktu_meninggal2: document.getElementById('waktu_meninggal2').value,
			tempat_meninggal: document.getElementById('tempat_meninggal').value,
			tempat_meninggal2: document.getElementById('tempat_meninggal2').value,
			sebab_meninggal: document.getElementById('sebab_meninggal').value,
			sebab_meninggal2: document.getElementById('sebab_meninggal2').value,
		}
		// Jika ada tambahan data meninggal
		if (formData.nik2Stat) {
			bodyRaw = {
				...bodyRaw,
				tanggal_lahir2: dateFormat(new Date(dt.tanggal_lahir2), 'yyyy-mm-dd'),
				waktu_meninggal2: dateFormat(new Date(dt.waktu_meninggal2), 'yyyy-mm-dd HH:MM'),
			}

			bodyRaw = {
				...bodyRaw,
				nik: bodyRaw.nik += "," + bodyRaw.nik2,
				nama: bodyRaw.nama += "," + bodyRaw.nama2,
				jenis_kelamin: bodyRaw.jenis_kelamin += "," + bodyRaw.jenis_kelamin2,
				tempat_lahir: bodyRaw.tempat_lahir += "," + bodyRaw.tempat_lahir2,
				tanggal_lahir: bodyRaw.tanggal_lahir += "," + bodyRaw.tanggal_lahir2,
				pekerjaan: bodyRaw.pekerjaan += "," + bodyRaw.pekerjaan2,
				kewarganegaraan: bodyRaw.kewarganegaraan += "," + bodyRaw.kewarganegaraan2,
				agama: bodyRaw.agama += "," + bodyRaw.agama2,
				alamat: bodyRaw.alamat += "|" + bodyRaw.alamat2,
				tempat_meninggal: bodyRaw.tempat_meninggal += "," + bodyRaw.tempat_meninggal2,
				sebab_meninggal: bodyRaw.sebab_meninggal += "," + bodyRaw.sebab_meninggal2,
				waktu_meninggal: bodyRaw.waktu_meninggal += "," + bodyRaw.waktu_meninggal2,
			}
		}

		// delete data meninggal2 from body raw
		delete bodyRaw.nik2
		delete bodyRaw.nama2
		delete bodyRaw.tempat_lahir2
		delete bodyRaw.tanggal_lahir2
		delete bodyRaw.jenis_kelamin2
		delete bodyRaw.pekerjaan2
		delete bodyRaw.pendidikan_terakhir2
		delete bodyRaw.kewarganegaraan2
		delete bodyRaw.agama2
		delete bodyRaw.alamat2
		delete bodyRaw.status_perkawinan2
		delete bodyRaw.waktu_meninggal2
		delete bodyRaw.tempat_meninggal2
		delete bodyRaw.sebab_meninggal2

		if (dt.uuid === '') {
			bodyRaw.id_jenis = props.location.state.uuid
		}
		console.log(bodyRaw);

		let validasiPelapor = formData.nikStat;

		if (validasiPelapor && bodyRaw.detail.length > 0) {
			console.log(bodyRaw);

			let psn = "";
			let resstat = 204;
			let metode = "create";
			if (bodyRaw.uuid === "") {
				psn = "Tambah";
				resstat = 201;
				bodyRaw.uuid = null;
			} else {
				psn = "Ubah";
				resstat = 200;
				metode = "update";
			}
			setAlert({
				loadingPreview: true
			})
			Post(
				"surat-keterangan/ahli-waris/" + metode,
				bodyRaw.uuid,
				bodyRaw,
				res => {

					// For testing
					if (props.resSubmit) {
						props.resSubmit(res.status)
					}

					// this.tutupForm();
					setFormData({
						...formData,
						status: {
							...formData.status,
							btnForm: false,
							form: false,
						}
					})
					setIsOpen(false)
					setAlert({
						loadingPreview: false
					})
					if (res.status === resstat) {
						const alertData = {
							show: true,
							basicType: "success",
							basicTitle: "Data Surat Keterangan Ahli Waris",
							pesanAlert: "Berhasil " + psn + " Data",
							successAlert: true, 
						}
						setAlert(alertData)
					} else {
						const alertData = {
							show: true,
							basicType: "danger",
							basicTitle: "Data Surat Keterangan Ahli Waris",
							pesanAlert: "Gagal " + psn + " Data"
						}
						setAlert(alertData)
					}

					// Refresh list
					setStatusRefresh(true)
				}
			);
		} else {
			const alertData = {
				show: true,
				basicType: "info",
				basicTitle: "Data Belum Lengkap",
				pesanAlert: "Mohon isi seluruh data dengan benar"
			}
			setAlert(alertData)
		}
	};

	return (
		<Modal
			open={isOpen}
			onClose={() => {
				setIsOpen(false)
			}}
			closeOnEsc={false}
			closeOnOverlayClick={true}
			style={{
				maxwidth: 'unset'
			}}
		// styles={{
		// 	modal: {
		// 		width: '80%',
		// 		minWidth: '301px'
		// 	}
		// }}
		>
			<Form
				data-testid="form-create"
				className="theme-form"
				onSubmit={simpan}
			>
				<div className="modal-header">
					<h5 className="modal-title">{dt.uuid == '' ? 'Tambah' : 'Edit'} Surat Keterangan Ahli Waris</h5>
				</div>
				<div className="modal-body">
					<p>
						Data identitas akan terisi secara otomatis saat NIK dipilih.
						Jika ada ketidak sesuaian data, silakan ubah di data master
						penduduk.
					</p>
					<input
						className="form-control"
						id="uuid"
						type="hidden"
						placeholder=""
						defaultValue={dt.uuid}
					/>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="nik"
						>
							NIK Penduduk
						</Label>
						<Col sm="9">
							<div data-testid="selectNIK">
								<Select
									inputId="nik"
									classNamePrefix="select"
									onChange={e => {
										getPenduduk(e, "nik", "pelapor");
									}}
									defaultValue={formData.nikStat}
									value={formData.nikStat}
									name="NIK"
									options={formData.frmNik}
									placeholder="Pilih NIK"
									onInputChange={e => handleInputChangeNIK(e, "nik")}
									isClearable
								/>
							</div>
						</Col>
					</FormGroup>
					<div style={{ display: formData.status.dataPenduduk }}>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Nama Penduduk 
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="nama"
									type="text"
									placeholder="Nama Pemohon"
									value={dt.nama}
									readOnly

									style={{ backgroundColor: "#c9d1d8" }}
									disabled
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Tempat Lahir
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="tempat_lahir"
									type="text"
									placeholder="Tempat Lahir"
									value={dt.tempat_lahir}
									readOnly

									style={{ backgroundColor: "#c9d1d8" }}
									disabled
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Tanggal Lahir
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="tanggal_lahir"
									type="text"
									placeholder="Tanggal Lahir"
									value={new Date(dt.tanggal_lahir).toLocaleDateString("id-ID")}
									readOnly

									style={{ backgroundColor: "#c9d1d8" }}
									disabled
								/>
							</Col>
						</FormGroup>
					</div>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="inputEmail3"
						>
							Waktu Meninggal
						</Label>
						<Col sm="9">
							<Datetime
								inputProps={{ "data-testid": "waktu_meninggal", placeholder: 'Waktu Meninggal', required: true }}
								inputId="waktu_meninggal"
								name="waktu_meninggal"
								locale="id-ID"
								timeFormat="HH:mm"
								dateFormat="DD/MM/yyyy"
								input={true}
								closeOnTab={true}
								initialValue={new Date(dt.waktu_meninggal)}
								value={new Date(dt.waktu_meninggal)}
								onChange={date => {
									if (typeof date._isValid !== 'undefined') {
										let tgl = date.format('YYYY-MM-DD HH:mm:ss');
										setDt({
											...dt,
											waktu_meninggal: new Date(tgl),
										})
									}
								}}
							/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="inputEmail3"
						>
							Tempat Meninggal
						</Label>
						<Col sm="9">
							<input
								data-testid="tempat meninggal"
								name="tempat_meninggal"
								className="form-control"
								id="tempat_meninggal"
								type="text"
								placeholder="Tempat Meninggal"
								defaultValue={dt.tempat_meninggal}
								required
							/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="inputEmail3"
						>
							Sebab Meninggal
						</Label>
						<Col sm="9">
							<input
								data-testid="sebab meninggal"
								name="sebab_meninggal"
								className="form-control"
								id="sebab_meninggal"
								type="text"
								placeholder="Sebab Meninggal"
								defaultValue={dt.sebab_meninggal}
								required
							/>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Col sm="12">
							<button
								// href={() => false}
								data-testid="btnInputKematian"
								onClick={(e) => {
									e.preventDefault()
									// testing
									// if (formData.meninggal2 === "none") {
									// 	if (this.props.getJenisInput) {
									// 		this.props.getJenisInput("tambah")
									// 	}
									// }
									// else {
									// 	if (this.props.getJenisInput) {
									// 		this.props.getJenisInput("hapus")
									// 	}
									// }
									tambahMeninggal()
								}}
								className={
									formData.meninggal2 === 'none' ? (
										'btn btn-sm btn-success'
									) : (
										'btn btn-sm btn-danger'
									)
								}
							>
								{formData.meninggal2 === 'none' ? (
									'Tambah Data Meninggal'
								) : (
									'Hapus Data Meninggal'
								)}
							</button>
						</Col>
					</FormGroup>
					<div style={{ display: formData.meninggal2 }}>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="nik2"
							>
								NIK Penduduk
							</Label>
							<Col sm="9">
								<div data-testid="selectNIK2">
									<Select
										inputId="nik2"
										classNamePrefix="select"
										onChange={e => {
											getPenduduk(e, "nik", "pelapor2");
										}}
										defaultValue={formData.nik2Stat}
										value={formData.nik2Stat}
										name="NIK2"
										options={formData.frmNik2}
										placeholder="Pilih NIK"
										// onInputChange={e => handleInputChangeNIK(e, "nik2")}
										isClearable
									/>
								</div>
							</Col>
						</FormGroup>
						<div style={{ display: formData.status.dataPenduduk2 }}>
							<FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									Nama Penduduk
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama2"
										type="text"
										placeholder="Nama Pemohon"
										value={dt.nama2}
										readOnly

										style={{ backgroundColor: "#c9d1d8" }}
										disabled
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									Tempat Lahir
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="tempat_lahir2"
										type="text"
										placeholder="Tempat Lahir"
										value={dt.tempat_lahir2}
										readOnly

										style={{ backgroundColor: "#c9d1d8" }}
										disabled
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									Tanggal Lahir
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="tanggal_lahir2"
										type="text"
										placeholder="Tanggal Lahir"
										value={new Date(dt.tanggal_lahir2).toLocaleDateString("id-ID")}
										readOnly

										style={{ backgroundColor: "#c9d1d8" }}
										disabled
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									Waktu Meninggal
								</Label>
								<Col sm="9">
									<Datetime
										inputProps={{ "data-testid": "waktu_meninggal2", placeholder: 'Waktu Meninggal', required: formData.status.dataPenduduk2 !== "none" }}
										inputId="waktu_meninggal2"
										name="waktu_meninggal2"
										locale="id-ID"
										timeFormat="HH:mm"
										dateFormat="DD/MM/yyyy"
										input={true}
										closeOnTab={true}
										initialValue={new Date(dt.waktu_meninggal2)}
										value={new Date(dt.waktu_meninggal2)}
										onChange={date => {
											if (typeof date._isValid !== 'undefined') {
												let tgl = date.format('YYYY-MM-DD HH:mm:ss');
												setDt({
													...dt,
													waktu_meninggal2: new Date(tgl),
												})
											}
										}}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									Tempat Meninggal
								</Label>
								<Col sm="9">
									<input
										data-testid="tempat_meninggal2"
										name="tempat_meninggal2"
										className="form-control"
										id="tempat_meninggal2"
										type="text"
										placeholder="Tempat Meninggal"
										defaultValue={dt.tempat_meninggal2}
										required={formData.status.dataPenduduk2 !== "none" ? true : false}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									Sebab Meninggal
								</Label>
								<Col sm="9">
									<input
										data-testid="sebab_meninggal2"
										name="sebab_meninggal2"
										className="form-control"
										id="sebab_meninggal2"
										type="text"
										placeholder="Sebab Meninggal"
										defaultValue={dt.sebab_meninggal2}
										required={formData.status.dataPenduduk2 !== "none" ? true : false}
									/>
								</Col>
							</FormGroup>
						</div>
					</div>
					<div style={{ display: formData.status.dataPenduduk }}>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label">
								Tambah Ahli Waris
							</Label>
							<Col sm="9">
								<div data-testid="selectNIK3">
									<Select
										inputId="nik3"
										classNamePrefix="select"
										onChange={e => {
											getPenduduk(e, "nik", "pengikut");
										}}
										defaultValue={formData.nik3Stat}
										value={formData.nik3Stat}
										name="NIK3"
										options={formData.frmNik3}
										placeholder="Pilih Anggota Keluarga"
										// onInputChange={e => this.handleInputChangeNIK(e, "nik3")}
										isClearable
									/>
								</div>
							</Col>
							{dt.detail.length > 0 ? (
								<>
									<Label className="col-sm-3 col-form-label" style={{ fontWeight: "700" }}>
										Daftar Ahli Waris
									</Label>
									<Col sm="12" style={{ overflowX: "auto" }}>
										<table className="table table-bordered table-stripes table-hovered">
											<thead>
												<tr>
													<th>No</th>
													<th>Nama</th>
													<th>Jenis Kelamin</th>
													<th>Tempat/Tanggal Lahir</th>
													<th>Pekerjaan</th>
													<th>Hubungan Keluarga</th>
													<th>
														<i className="fa fa-trash"></i>
													</th>
												</tr>
											</thead>
											<tbody>
												{dt.detail.map((val, i) => {
													let jk = "L";
													if (val.jenis_kelamin === "Perempuan") {
														jk = "P";
													}
													return (
														<tr key={i}>
															<td>{i + 1}</td>
															<td>{val.nama}</td>
															<td>{jk}</td>
															<td>
																{val.tempat_lahir}, {Tanggal(val.tanggal_lahir)}
															</td>
															<td>{val.pekerjaan}</td>
															<td>{val.shdk}</td>
															<td>
																<Button
																	size="xs"
																	onClick={() => hapusDetail(i)}
																	color="danger"
																	className="btn-icon"
																>
																	<i className="fa fa-trash"></i>
																</Button>
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</Col>
								</>
							) : (
								<div className="col-sm-12" style={{ marginTop: '20px' }}>
									<h6 className="text-center">
										Belum ada ahli waris yang ditambahkan
									</h6>
								</div>
							)}
						</FormGroup>
					</div>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-warning"
						onClick={() => {
							// Tutup modal
							setFormData({
								...formData,
								status: {
									...formData.status,
									form: false
								}
							})
							setIsOpen(false)
						}}
					>
						Tutup
					</button>
					<button
						data-testid="btnSubmitCreate"
						type="submit"
						className="btn btn-primary-custom"
						// disabled={formData.status.btnForm}
					>
						Simpan
					</button>
				</div>
			</Form>
		</Modal>
	);
}

export default ModalSuratAhliWaris;
