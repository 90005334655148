import React, { Component } from "react";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { Link } from "react-router-dom";

// koneksi
import { Get, ID_DESA, GetEksternal } from "../../../function/Koneksi";


class HomeKecamatan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layananKecamatan: [],
      loading: true,
    };
  }


  componentDidMount() {
    Get("keldes/find/"+ID_DESA, null, lihat => {
      if(lihat.results){
        GetEksternal("pelayanan-instansi/desa/Kecamatan/"+ lihat.results.id_kecamatan + '/Kantor Camat', null, dtkat => {
          if (dtkat.results) {
            this.setState({ layananKecamatan: dtkat.results, loading: false })
          }
          else{
            this.setState({ loading: false })
          }
        });
      }
    });
  }
  
  render() {
    // console.log("Data Render")
    // console.log(this.state.layananKecamatan)

    const tampilLayanan = this.state.layananKecamatan.map((val, i) => {
      let pathname = "";

      switch (val.data_pelayanan.nama.toLowerCase()) {
        case 'surat izin usaha mikro kecil':
          pathname = '/admin/layanan/kecamatan/iumk'
          break;
        case 'surat rekomendasi izin usaha':
          pathname = '/admin/layanan/kecamatan/rekomendasi-usaha'
          break;
        default:
          break;
      }

      if (pathname !== '') {
        return (
          <div className="col-sm-3 mb-4">
            <Link to={{ pathname, state: val }}>
              <div className="card o-hidden mb-0">
                <div
                  className="card-body p-3 mr-0 text-white"
                  style={{ backgroundColor: '#2e99b0' }}
                >
                  <div className="media static-top-widget">
                    <div
                      className="media-body p-0"
                      style={{
                        lineHeight: '1.5em',
                        minHeight: '6.5em',
                        height: '100%',
                        fontWeight: 'bold',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <span
                        className="m-0 p-0"
                        style={{ fontSize: '18px' }}
                      >
                        {val.data_pelayanan.nama}
                      </span>
                      <i className="icofont icofont-ebook icon-bg text-white" />
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        )
      } else {
        return ''
      }
    });

    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                <br/>
                  {
                    this.state.loading?
                      <div className="row" data-testid="divLoadingDashboard">
                        <div className="col-sm-12" align="center">
                          <br/><br/><br/>
                          <img 
                          src={require("../../../assets/images/tes-loading.gif")} 
                          alt="loading"
                          style={{
                            // width: "100%",
                            borderRadius: "10px",
                            width: '100px'
                          }}/> 
                          <br/><br/><br/><br/>
                        </div>
                      </div>
                    :
                    <>
                      <div
                        className="row"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          justifyContent: 'start'
                        }}
                      >
                        {
                          this.state.layananKecamatan.length===0
                          ?
                          <>
                            <div className="card-body datatable-react">
                              <div className="col-12 text-right">
                                  <button onClick={this.props.history.goBack} color="info" className="btn btn-info"><i className="fa fa-arrow-left"></i> Kembali</button>
                              </div>
                              <h2 align="center">Layanan Tidak Ditemukan!</h2>
                              <h6><p align="center">Wah, layanan yang kamu inginkan akan segera hadir ! <br/> Tunggu update selanjutnya ya !</p></h6>
                              <br/>
                            </div>
                          </>
                          :
                          <>
                            {tampilLayanan}
                          </>
                        }
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

      </div>
    );
  }
}

export default HomeKecamatan;