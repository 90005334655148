import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Col,
} from 'reactstrap';
// import Datepicker from 'react-datepicker';
import Select from 'react-select';
import Datetime from 'react-datetime';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { Get, JENIS_DUSUN, Post } from '../../function/Koneksi';
import { Tanggal } from '../../function/Format';

const ModalSuratTidakMampu = ({ data, optionData, setAlert, isOpen, setIsOpen, statusRefresh, setStatusRefresh, propsData, kiosk }) => {
	const dateFormat = require('dateformat');

	const frmDex = {
		uuid: '',
		id_jenis: '',
		nik_pelapor: '',
		nik: '',
		kk: '',
		nama_pemohon: '',
		tempat_lahir: '',
		tanggal_lahir: '',
		jenis_kelamin: '',
		pekerjaan: '',
		pendidikan_terakhir: '',
		kewarganegaraan: '',
		agama: '',
		alamat: '',
		status_perkawinan: '',
		tujuan_pembuatan: '',
		detail: []
	}

	const initFormData = {
		frmNik: optionData.frmNik,
		frmShdk: optionData.frmShdk,
		frmNik3: optionData.frmNik3,
		tmplistPenduduk: optionData.tmplistPenduduk,
		tmplistPenduduk3: optionData.tmplistPenduduk3,
		nikStat: null,
		nik3Stat: null,
		shdk_aw: null,
		detail: [],
		status: {
			form: false,
			btnForm: false,
			btnAksi: [],
			dataPenduduk: "none",
		},
		show: false,
		basicTitle: "",
		basicType: "default",
		pesanAlert: "",
	}

	const [formData, setFormData] = useState(initFormData)
	const [props, setProps] = useState(propsData)
	const [kioskMode, setKioskMode] = useState(kiosk)
	const [dt, setDt] = useState(data ? data : frmDex)
	const [draggable, setDraggable] = useState({
		isDown: false,
		startY: null,
		scrollUp: null,
	})

	useEffect(() => {
		if (document.getElementById("modalForm")) {
			document.getElementById("modalForm").reset()
		}
		console.log(data);
		console.log(optionData);

		setDt(data ? data : frmDex)
		setFormData(data ? {
			...initFormData,
			nikStat: optionData.nikStat,
			status: optionData.status,
		} : initFormData)
	}, [isOpen])

	const handleInputChangeNIK = (e, fld) => {
		if (e && kioskMode == false) {
			let nikStat = { value: e, label: e };

			Post("penduduk", null, { nik: e }, datanik => {
				let frmNik = [];
				let tmplistPenduduk = {}
				datanik.data.results.data.forEach(dt => {
					frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
					tmplistPenduduk = {
						...tmplistPenduduk,
						[dt.id]: dt
					}
				});

				switch (fld) {
					case "nik":
						setFormData({
							...formData,
							frmNik,
							tmplistPenduduk,
							nikStat
						})
						break;

					default:
						break;
				}

			});

		}
	};

	const changeInputText = (e, fld) => {
		switch (fld) {
			case "penghasilan":
				setDt({
					...dt,
					penghasilan: e.target.value,
				})
				break;
			default:
				// no default
				break;
		}
	};

	const getPenduduk = (e, sel, jns) => {
		if (e) {
			// this.changeSelectValue(e, sel);
			setAlert({
				loadingPreview: true
			})

			let getNik = e.value
			
			if (jns === "pelapor") {
				const tmpDetailPenduduk = formData.tmplistPenduduk[getNik]

				if (kioskMode === false) {
					Get("penduduk/kk/"+tmpDetailPenduduk.id_kk, null, dtkk => {
						let frmNik3 = [];
						let tmplistPenduduk3 = {};
						dtkk.results.forEach(val => {
							frmNik3.push({ value: val.id, label: val.id + ' (' + val.nama + ')' });
							tmplistPenduduk3 = {
								...tmplistPenduduk3,
								[val.id]: val
							}
						});
	
						setFormData({
							...formData,
							nikStat: e,
							frmNik3,
							tmplistPenduduk3,
							status: {
								...formData.status,
								dataPenduduk: "block",
							},
						})
						setDt({
							...dt,
							nik: tmpDetailPenduduk.id,
							nik_pelapor: tmpDetailPenduduk.id,
							kk: tmpDetailPenduduk.id_kk,
							nama_pemohon: tmpDetailPenduduk.nama,
							tempat_lahir: tmpDetailPenduduk.tempat_lahir,
							tanggal_lahir: new Date(
								tmpDetailPenduduk.tanggal_lahir
							),
							pekerjaan: tmpDetailPenduduk.data_pekerjaan.nama,
							pendidikan_terakhir: tmpDetailPenduduk.data_pendidikan_terakhir.nama,
							agama: tmpDetailPenduduk.data_agama.nama,
							status_perkawinan: tmpDetailPenduduk.status_perkawinan,
							jenis_kelamin: tmpDetailPenduduk.jk,
							alamat: tmpDetailPenduduk.alamat,
							kewarganegaraan: 'WNI',
							detail: []
						})
						setAlert({
							loadingPreview: false
						})
					})
				} else { // Jika mode kiosk langsung set tanpa get list kk keluarga krna sudah ada
					setFormData({
						...formData,
						nikStat: e,
						status: {
							...formData.status,
							dataPenduduk: "block",
						},
					})
					setDt({
						...dt,
						nik: tmpDetailPenduduk.id,
						nik_pelapor: tmpDetailPenduduk.id,
						kk: tmpDetailPenduduk.id_kk,
						nama_pemohon: tmpDetailPenduduk.nama,
						tempat_lahir: tmpDetailPenduduk.tempat_lahir,
						tanggal_lahir: new Date(
							tmpDetailPenduduk.tanggal_lahir
						),
						pekerjaan: tmpDetailPenduduk.data_pekerjaan.nama,
						pendidikan_terakhir: tmpDetailPenduduk.data_pendidikan_terakhir.nama,
						agama: tmpDetailPenduduk.data_agama.nama,
						status_perkawinan: tmpDetailPenduduk.status_perkawinan,
						jenis_kelamin: tmpDetailPenduduk.jk,
						alamat: tmpDetailPenduduk.alamat,
						kewarganegaraan: 'WNI',
						detail: []
					})
					setAlert({
						loadingPreview: false
					})
				}
				
			} else if (jns === "pengikut") {
				const tmpDetailPenduduk3 = formData.tmplistPenduduk3[getNik]
				console.log(tmpDetailPenduduk3);

				let detail = dt.detail
				detail.push({
					nik: tmpDetailPenduduk3.id,
					nama: tmpDetailPenduduk3.nama,
					jenis_kelamin: tmpDetailPenduduk3.jk,
					tanggal_lahir: new Date(
						tmpDetailPenduduk3.tanggal_lahir
					),
					tempat_lahir: tmpDetailPenduduk3.tempat_lahir,
					pekerjaan: tmpDetailPenduduk3.data_pekerjaan.nama,
					pendidikan: tmpDetailPenduduk3.data_pendidikan_terakhir.nama,
					shdk: tmpDetailPenduduk3.data_shdk.nama,
					keterangan: "",
				})

				setDt({
					...dt,
					detail
				})
			}
			
			setAlert({
				loadingPreview: false
			})

		} else {
			switch (jns) {
				case "pelapor":
					setDt({
						...dt,
						nama_pemohon: '',
						tempat_lahir: '',
						tanggal_lahir: null,
						pekerjaan: '',
						pendidikan_terakhir: '',
						agama: '',
						status_perkawinan: '',
						jenis_kelamin: '',
						alamat: '',
						detail: []
					})
					setFormData({
						...formData,
						status: {
							...formData.status,
							dataPenduduk: "none"
						},
						nikStat: null,
						frmNik3: [],
						tmplistPenduduk3: null
					})
					break;
				case "pengikut":
					setFormData({
						...formData,
						nik3Stat: null
					})
					break;
				default:
					break;
			}
		}
	};

	const hapusDetail = (i) => {
		console.log(i);
		// let currDetail = dt.detail
		let newDetail = dt.detail.splice(i,1)

		// console.log(currDetail);
		console.log(newDetail);
		console.log(dt.detail);

		setDt({
			...dt,
			detail: dt.detail
		})
  };

	const simpan = (e) => {
		e.preventDefault();
		console.log('tesdfs');
		console.log(formData);

		setAlert({
			loadingPreview: true
		})

		if (kioskMode) {
			props.setLoadingPreview(true)
		}

		let bodyRaw = {
			...dt,
			tanggal_lahir: dateFormat(new Date(dt.tanggal_lahir), 'yyyy-mm-dd'),
			tujuan_pembuatan: document.getElementById('tujuan_pembuatan').value,
		}
		if (dt.uuid === '') {
			if (kioskMode) {
				bodyRaw.id_jenis = props.id_jenis
			} else {
				bodyRaw.id_jenis = props.location.state.uuid
			}
		}
		console.log(bodyRaw);

		let validasiPelapor = formData.nikStat;

		if (validasiPelapor) {
			console.log(bodyRaw);

			let psn = "";
			let resstat = 204;
			let metode = "create";
			if (bodyRaw.uuid === "") {
				psn = "Tambah";
				resstat = 201;
				bodyRaw.uuid = null;
			} else {
				psn = "Ubah";
				resstat = 200;
				metode = "update";
			}
			Post(
				"surat-keterangan/tidak-mampu/" + metode,
				bodyRaw.uuid,
				bodyRaw,
				res => {

					// For testing
					if (props.resSubmit) {
						props.resSubmit(res.status)
					}

					// this.tutupForm();
					setFormData({
						...formData,
						status: {
							...formData.status,
							btnForm: false,
							form: false,
						}
					})
					setIsOpen(false)
					setAlert({
						loadingPreview: false
					})

					if (kioskMode) {
						let dataAntrian = {
							nama: props.data.results.nama,
							nik: props.data.results.id,
							keterangan: '',
							uuid_surat: res.data.results,
						};

						Get('persetujuan/kiosk/' + dataAntrian.uuid_surat + '/' + props.id_jenis, null, (res2) => {
							console.log(res2)
							if (res2.code === 201) {
								props.getAntrian('Surat Keterangan Tidak Mampu', 'berhasil', false, dataAntrian);
							}
							else {
								props.getAntrian('Surat Keterangan Tidak Mampu', 'gagal');
							}
						});

					} else {
						if (res.status === resstat) {
							const alertData = {
								show: true,
								basicType: "success",
								basicTitle: "Data Surat Keterangan Tidak Mampu",
								pesanAlert: "Berhasil " + psn + " Data",
								successAlert: true,
							}
							setAlert(alertData)
						} else {
							const alertData = {
								show: true,
								basicType: "danger",
								basicTitle: "Data Surat Keterangan Tidak Mampu",
								pesanAlert: "Gagal " + psn + " Data"
							}
							setAlert(alertData)
						}
	
						// Refresh list
						setStatusRefresh(true)
					}

				}
			);
		} else {
			const alertData = {
				show: true,
				basicType: "info",
				basicTitle: "Data Belum Lengkap",
				pesanAlert: "Mohon isi seluruh data dengan benar"
			}
			setAlert(alertData)
		}
	};

	return (
		<Modal
			classNames={{ overlay: 'card-scrollable' }}
			open={isOpen}
			onClose={() => {
				setIsOpen(false)
			}}
			closeOnEsc={false}
			closeOnOverlayClick={true}
			style={{
				maxwidth: 'unset'
			}}
		>
			<Form
				id="modalForm"
				data-testid="form-create"
				className="theme-form"
				onSubmit={simpan}
			>
				<div
					onMouseDown={(e) => {
						let slider = document.querySelector('.card-scrollable');
						setDraggable({
							isDown: true,
							startY: e.pageY - slider.offsetTop,
							scrollUp: slider.scrollTop,
						})
					}}
					onMouseLeave={() => {
						setDraggable({
							...draggable,
							isDown: false
						})
					}}
					onMouseUp={() => {
						setDraggable({
							...draggable,
							isDown: false
						})
					}}
					onMouseMove={(e) => {
						e.preventDefault();

						let slider = document.querySelector('.card-scrollable');

						if (!draggable.isDown) {
							return;
						}

						const y = e.pageY - slider.offsetTop;
						const startY = draggable.startY;
						const walk = y - startY;
						slider.scrollTop = draggable.scrollUp - walk;
					}}
				>
				<div className="modal-header">
					<h5 className="modal-title">{dt.uuid == '' ? 'Tambah' : 'Edit'} Surat Keterangan Tidak Mampu</h5>
				</div>
				<div className="modal-body">
					<p>
						Data identitas akan terisi secara otomatis saat NIK dipilih.
						Jika ada ketidak sesuaian data, silakan ubah di data master
						penduduk.
					</p>
					<input
						className="form-control"
						id="uuid"
						type="hidden"
						placeholder=""
						defaultValue={dt.uuid}
					/>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="nik"
						>
							NIK Pemohon
						</Label>
						<Col sm="9">
							<div data-testid="selectNIK">
								<Select
									inputId="nik"
									name="nik"
									classNamePrefix="select"
									onChange={e => {
										getPenduduk(e, "nik", "pelapor");
									}}
									defaultValue={formData.nikStat}
									value={formData.nikStat}
									options={formData.frmNik}
									placeholder="Pilih NIK Pemohon"
									onInputChange={e => handleInputChangeNIK(e, "nik")}
									isClearable
								/>
							</div>
						</Col>
					</FormGroup>
					<div style={{ display: formData.status.dataPenduduk }}>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Nama Pemohon
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="nama"
									type="text"
									placeholder="Nama Pemohon"
									value={dt.nama_pemohon}
									readOnly
									style={{ backgroundColor: "#c9d1d8" }}
									disabled
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Tempat Lahir
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="tempat_lahir"
									type="text"
									placeholder="Tempat Lahir"
									value={dt.tempat_lahir}
									readOnly
									style={{ backgroundColor: "#c9d1d8" }}
									disabled
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Tanggal Lahir
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="tanggal_lahir"
									type="text"
									placeholder="Tanggal Lahir"
									value={new Date(dt.tanggal_lahir).toLocaleDateString("id-ID")}
									readOnly
									style={{ backgroundColor: "#c9d1d8" }}
									disabled
								/>
							</Col>
						</FormGroup>
					</div>
					<FormGroup className="row">
						<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
							Keperluan Untuk
						</Label>
						<Col sm="9">
							<input
								data-testid="tujuan pembuatan"
								name="tujuan_pembuatan"
								className="form-control"
								id="tujuan_pembuatan"
								type="text"
								placeholder="Keperluan Untuk"
								defaultValue={dt.tujuan_pembuatan}
								required
							/>
						</Col>
					</FormGroup>

					<FormGroup className="row">
						<Label className="col-sm-3 col-form-label">
							Tambah Keluarga Yang Ikut (Jika ada)
						</Label>
						<Col sm="9">
							<div data-testid="selectNIK3">
								<Select
									inputId="nik3"
									classNamePrefix="select"
									onChange={e => {
										// this.changeSelectValue(e, "nik3");
										getPenduduk(e, "nik", "pengikut");
									}}
									defaultValue={formData.nik3Stat}
									value={formData.nik3Stat}
									name="NIK3"
									options={formData.frmNik3}
									placeholder="Pilih Anggota Keluarga"
									// onInputChange={e => this.handleInputChangeNIK(e, "nik3")}
									isClearable
								/>
							</div>
						</Col>
						{dt.detail.length > 0 ? (
							<Col sm="12">
								<table className="table table-bordered table-stripes table-hovered">
									<thead>
										<tr>
											<th>No</th>
											<th>Nama</th>
											<th>JK</th>
											<th>Tempat/Tanggal Lahir</th>
											<th>Pekerjaan</th>
											<th>Pendidikan</th>
											<th>Hubungan Keluarga</th>
											<th>
												<i className="fa fa-trash"></i>
											</th>
										</tr>
									</thead>
									<tbody>
										{dt.detail.map((val, i) => {
											let jk = "L";
											if (val.jenis_kelamin === "Perempuan") {
												jk = "P";
											}
											return (
												<tr key={i}>
													<td>{i+1}</td>
													<td>{val.nama}</td>
													<td>{jk}</td>
													<td>
														{val.tempat_lahir}, {Tanggal(val.tanggal_lahir)}
													</td>
													<td>{val.pekerjaan}</td>
													<td>{val.pendidikan}</td>
													<td>{val.shdk}</td>
													<td>
														<Button
															size="xs"
															onClick={() => hapusDetail(i)}
															color="danger"
															className="btn-icon"
														>
															<i className="fa fa-trash"></i>
														</Button>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</Col>
						) : ''}

					</FormGroup>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-warning"
						onClick={() => {
							// Tutup modal
							setFormData({
								...formData,
								status: {
									...formData.status,
									form: false
								}
							})
							setIsOpen(false)
						}}
					>
						Tutup
					</button>
					<button
						data-testid="btnSubmitCreate"
						type="submit"
						className="btn btn-primary-custom"
						disabled={formData.status.btnForm}
					>
						Simpan
					</button>
				</div>
				</div>
			</Form>
		</Modal>
	);
}

export default ModalSuratTidakMampu;
