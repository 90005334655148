import React, { Component } from "react";


// import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";

import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
// import Select from "react-select";

import { DataToken } from "../function/Format";

// koneksi
import { Get, Post } from "../function/Koneksi";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

const frmDef = {
  uuid: "",
  id_desa: 0,
  nama: "",
  id_ukuran_kertas: "",
  nama_table: ""
};  
class PenomoranSurat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: false,
        disKode: "inherit"
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDef,
      loading: false,
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      // =========== Select 2 ============
      frmPrv: [],
      prvStat: [],
      frmKbkt: [],
      kbktStat: [],
      frmKec: [],
      kecStat: [],
      frmKel: [],
      kelStat: [],
      frmUkuran: [],
      ukkStat: [],
      akun: DataToken(sessionStorage.getItem("access_token"))
    };
  }

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    this.setState({ loading: true })
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get("penomoran" + link, null, dtkat => {
      // this.setState({ data: dtkat.results.data, pagination: dtkat.results });
      if (dtkat.results.data && dtkat.results.data.length > 0) {
        this.setState({ data: [dtkat.results.data[0]], pagination: dtkat.results, loading: false });
      }
      this.pagination(dtkat.results);
    });
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    //console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };

  // ========================================================================

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();

    const nomor_surat = document.getElementById("nomor_surat").value
    //console.log(e);

    if (nomor_surat) {
      // this.state.status.btnForm = true;
      // this.forceUpdate();

      const currentTime = new Date()

      let add = {
        tahun: currentTime.getFullYear() + '',
        nomor: nomor_surat,
      };

      //console.log(add);

      let psn = "";
      let resstat = 204;
      let metode = "create";

      psn = "Perbarui";
      resstat = 201;

      // if (add.uuid === "") {
      //   psn = "Tambah";
      //   resstat = 201;
      //   add.uuid = null;
      // } else {
      //   psn = "Ubah";
      //   resstat = 200;
      //   metode = "update";
      // }

      Post("penomoran/" + metode, null, add, res => {
        // this.state.status.btnForm = false;
        this.setState({
          status:{
              ...this.state.status,
              form: false,
          },
        })
        this.forceUpdate();

        const statusCode = res.status

        if (statusCode === resstat) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Nomor Surat",
            pesanAlert: "Berhasil " + psn + " nomor surat"
          });
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Nomor Surat",
            pesanAlert: "Gagal " + psn + " nomor surat"
          });
        }
        this.componentDidMount();
      });
    } else {
      this.setState({
        show: true,
        basicType: "warning",
        basicTitle: "Penomoran Surat",
        pesanAlert: "Isi nomor surat terlebih dahulu"
      });
    }
  };
  // ========================================================================

  // ============================== Ubah Data ===============================
  // ubahData = id => {
  //   //console.log(id);
  //   this.state.status.btnAksi = true;
  //   this.forceUpdate();
  //   Get("jenis-surat/find", id, data => {
  //     //console.log(data);
  //     this.ubahPilihan("keldes", data.results.data_keldes);

  //     this.state.dt.uuid = data.results.uuid;
  //     this.state.dt.id_desa = data.results.id_desa;
  //     this.state.dt.id_ukuran_kertas = data.results.id_ukuran_kertas;
  //     this.state.dt.nama = data.results.nama;
  //     this.state.dt.nama_table = data.results.nama_table;
  //     this.state.dt.kode_surat = data.results.kode_surat;
  //     this.state.ukkStat = {
  //       value: data.results.data_ukuran_kertas.uuid,
  //       label: data.results.data_ukuran_kertas.nama
  //     };
  //     this.state.ktrStat = {
  //       value: data.results.kategori,
  //       label: data.results.kategori
  //     };
  //     this.state.status.btnAksi = false;
  //     this.state.judul = "Ubah Jenis Surat";
  //     this.forceUpdate();
  //   });
  // };
  // ========================================================================

  // ============================= Hapus Data ===============================
  // konfirmHapus = id => {
  //   this.setState({
  //     alert: (
  //       <SweetAlert
  //         showCancel
  //         confirmBtnText="Hapus"
  //         confirmBtnBsStyle="danger"
  //         cancelBtnBsStyle="success"
  //         type="danger"
  //         title="Yakin ingin hapus data ?"
  //         onCancel={this.hideAlert}
  //         onConfirm={() => this.hapusData(id)}
  //       >
  //         Proses ini tidak dapat dibatalkan!
  //       </SweetAlert>
  //     )
  //   });
  // };

  // hapusData = id => {
  //   this.hideAlert();
  //   let psn = "Hapus";
  //   Delete("jenis-surat/delete", id, res => {
  //     if (res === 200) {
  //       this.setState({
  //         show: true,
  //         basicType: "success",
  //         basicTitle: "Data Jenis Surat",
  //         pesanAlert: "Berhasil " + psn + " Data"
  //       });
  //     } else {
  //       this.setState({
  //         show: true,
  //         basicType: "danger",
  //         basicTitle: "Data Jenis Surat",
  //         pesanAlert: "Gagal " + psn + " Data"
  //       });
  //     }
  //     this.componentDidMount();
  //   });
  // };
  // ========================================================================

  bukaForm = () => {
    this.setState({
      status:{
          ...this.state.status,
          form: true,
      },
    })
    this.forceUpdate();
  };

  tutupForm = () => {
    this.setState({
        dt:{
          ...this.state.dt,
          uuid: "",
          id_desa: "",
          id_ukuran_kertas: "",
          nama: "",
          nama_table: "",
          kode_surat: "",
        },
        status:{
          ...this.state.status,
          form: false,
        },
        ukkStat: {value: "", label: ""},
        ktrStat: {value: "", label: ""},
      })
    this.forceUpdate();
  };

  // tombolAksi = (cell, row) => {
  //   return (
  //     <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
  //       <Button
  //         size="xs"
  //         color="success"
  //         className="btn-icon"
  //         onClick={() => this.ubahData(row.uuid)}
  //         disabled={this.state.status.btnAksi}
  //       >
  //         <i className="fa fa-pencil"></i>
  //       </Button>{" "}
  //       &nbsp;&nbsp;
  //       <Button
  //         size="xs"
  //         color="danger"
  //         className="btn-icon"
  //         style={{ display: this.state.status.disKode }}
  //         onClick={() => this.konfirmHapus(row.uuid)}
  //         disabled={this.state.status.btnAksi}
  //       >
  //         <i className="fa fa-trash"></i>
  //       </Button>
  //     </div>
  //   );
  // };

  componentDidMount() {
    this.fetch({ page: null, where: [] });
  }

  render() {
    const { akun } = this.state;
    
    var data = this.state.data;

    const level = akun.sub.data_role.nama_role === "Admin" ? 2 : 1;
    if (level > 1) {
      this.setState({
        status:{
            ...this.state.status,
            disKode: "none",
        },
      })
    }
    const columns = [
      // {
      //   dataField: "nama",
      //   text: "Nama",
      //   sort: true
      // },
      {
        dataField: "tahun",
        text: "Tahun",
        sort: true,
        headerAlign: "center",
        align: "center",
        style: {
          width: '100px'
        }
      },
      {
        dataField: "nomor",
        text: "Nomor Surat",
        sort: true,
        headerAlign: "center",
        align: "center"
      },
      // {
      //   dataField: "aksi",
      //   text: "Aksi",
      //   isDummyField: true,
      //   csvExport: false,
      //   formatter: this.tombolAksi
      // }
    ];

    return (
      <div>
        {/* <Breadcrumb title="Data Jenis Surat" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Jenis Surat</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right" style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                      <Button
                        size="sm"
                        color="success"
                        className="btn-square"
                        style={{ display: this.state.status.disKode }}
                        onClick={() => {
                          this.setState({
                            judul: "Pembaruan Nomor Surat",
                          });
                          this.bukaForm();
                        }}
                      >
                        Perbarui Nomor Surat
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <div className="row" style={{ justifyContent: 'center' }}>
                    <div className="col-sm-4">
                      {this.state.loading ? (
                        <div className="row">
                          <div className="col-sm-12" align="center">
                            <img
                              alt="loading"
                              src={require("../assets/images/tes-loading.gif")}
                              style={{
                                borderRadius: "10px",
                                width: '100px'
                              }} />
                          </div>
                        </div>
                      ) : (
                        <BootstrapTable keyField="id" data={data} columns={columns} />
                      )}
                      {/* <div className="pull-right text-white">
                        {this.state.awal}
                        {this.state.sebelum}
                        {this.state.hal.map(dt => {
                          return dt;
                        })}
                        {this.state.setelah}
                        {this.state.akhir}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
        >
          <div className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-body">
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Nomor Surat
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="nomor_surat"
                    type="number"
                    placeholder="Masukkan nomor surat terakhir yang telah terbit"
                    min={1}
                    required
                    // defaultValue={this.state.dt.kode_surat}
                  />
                </Col>
              </FormGroup>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default PenomoranSurat;
