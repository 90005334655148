import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../../../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
import Datetime from 'react-datetime';

import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from "classnames";

import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";

// import MultiSelect from "react-multi-select-component";



// koneksi
import { ID_DESA, PostEksternal, GetEksternal,  Post, Get, Delete, JENIS_DESA, JENIS_DUSUN, DESA } from "../../../function/Koneksi";
// import { Tanggal, Hari, HariTanggal, HariTanggalJam } from "../../../function/Format";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// import ReactDatePicker from "react-datepicker";
import { Link } from "react-router-dom";



// const frmDef = {
// 	id: 0,
// 	idbaru: '',
// 	id_kk: '',
// 	nama: '',
// 	alamat: '',
// 	id_keldes: '',
// 	tempat_lahir: '',
// 	tanggal_lahir: new Date(),
// 	jk: '',
// 	shdk: '',
// 	agama: '',
// 	pendidikan_terakhir: '',
// 	pekerjaan: '',
// 	nama_ibu_kandung: '',
// 	status_penduduk: '',
// 	status_perkawinan: '',
// 	kode_pos: '',
// 	telepon: '',
// 	alasan_pindah: '',
// };

let strukturGambar = {
  surat_keterangan_usaha: {
    link: '',
    type: '',
  },
}

const dateFormat = require('dateformat');

class KecamatanRekomendasiUsaha extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Data table
      data: [],
      dataVerifikasi: [],
      dataDisetujui: [],
      dataDitolak: [],
      dataSelesai: [],
      dataPemohon: null,

      detail: [],

      // Pagination data table
      pagination: [],
      paginationDitolak: [],
      paginationDiverifikasi: [],
      paginationDisetujui: [],
      paginationSelesai: [],

      status: {
        form: false,
        btnForm: false,
        btnAksi: false,
        btnAksiTidakGelar: false,
        select: false,
        formDetail: false,
        fotoPersyaratan: false,
      },

      uuid_surat: null,

      // Diajukan
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      // Diverifikasi
      awal2: "",
      sebelum2: "",
      hal2: [],
      setelah2: "",
      akhir2: "",
      // Disetujui
      awal3: "",
      sebelum3: "",
      hal3: [],
      setelah3: "",
      akhir3: "",
      // Ditolak
      awal4: "",
      sebelum4: "",
      hal4: [],
      setelah4: "",
      akhir4: "",
      // Selesai
      awal5: "",
      sebelum5: "",
      hal5: [],
      setelah5: "",
      akhir5: "",
      
      dt: [],
      judul: "",
      // =========== Select 2 ============
      frmPend: [],
			pendStat: null,
      // =================================
      // =========== Alert ============
      alert: null,
      show: false,
      showCetakSurat: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      successAlert: false,
      // ==============================
      active_tab_icon: "1",

      // Cari Data Gelar
      dataCariGelar: {
				nama: ''
			},

      // Jenis Perihal
      perihal:{
        izin_usaha: false,
        imb: false,
        siup: false,
        situ: false,
        tdp: false,
      },

      linkGambar: strukturGambar,

      dokumen_input:"",
      dokumen_output:"",
      
      showBio: false,
      tanggal_surat_desa: new Date(),
      tgl_melapor: new Date(),

      selectedValues:[],
      dataLapor:[],

      frmUsaha: [],
      usahaStat: null,
      noSuratSelect: true,
      dusSelect: true,
      titlePersyaratan: '',

      agamaStat: null,
      jenisKelaminStat: null,
      statusPerkawinanStat: null,

      isUpdate: false,

      cek_posisi:'Pertama',

			isKelurahan: false,
			id_jenis_pelayanan: this.props.location.state.uuid

    };
		this.changeCheck = this.changeCheck.bind(this);
		this.handleChange= this.handleChange.bind(this);
  }



  handleChange(e) {
    //console.log("CHANGE Select")
		//console.log(e[0])
    let set = e[0];
    this.state.selectedValues.push({ ...this.state.selectedValues, ...set });
		//console.log("All Data CHange")
		//console.log(this.state.selectedValues)
		// switch (jenis_konfig) {
		// 	// LAYANAN TTD
		// 	case "ketersediaan_ttd":
		// 		if(this.state.ketersedian_ttd === true){
		// 			this.state.ketersedian_ttd = checked
		// 		}
		// 		else{
		// 			this.state.ketersedian_ttd = checked
		// 		}
		// 	  break;
		// 	case "riwayat_disposisi":
		// 		if(this.state.riwayat_disposisi === true){
		// 			this.state.riwayat_disposisi = checked
		// 		}
		// 		else{
		// 			this.state.riwayat_disposisi = checked
		// 		}
		// 	  break;
		// 	case "layanan_capil":
		// 		if(this.state.layanan_capil === true){
		// 			this.state.layanan_capil = checked
		// 		}
		// 		else{
		// 			this.state.layanan_capil = checked
		// 		}
		// 	  break;
		// 	case "layanan_kua":
		// 		if(this.state.layanan_kua === true){
		// 			this.state.layanan_kua = checked
		// 		}
		// 		else{
		// 			this.state.layanan_kua = checked
		// 		}
		// 	  break;
		// 	case "layanan_ppob":
		// 		if(this.state.layanan_ppob === true){
		// 			this.state.layanan_ppob = checked
		// 		}
		// 		else{
		// 			this.state.layanan_ppob = checked
		// 		}
		// 	  break;
		//   }

		// this.forceUpdate();
		// this.setState({ checked });
	  }

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      showCetakSurat: false,
      successAlert: false,
      alert: null
    });
  };

   // Bootstrap tabs function
   toggle_icon(tab) {
    if (this.state.active_tab_icon !== tab) {
      if(tab === '1'){
        this.state.cek_posisi = 'Pertama'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      else if(tab === '2'){
        this.state.cek_posisi = 'Kedua'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      else if(tab === '3'){
        this.state.cek_posisi = 'Ketiga'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      else if(tab === '4'){
        this.state.cek_posisi = 'Keempat'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      else if(tab === '5'){
        this.state.cek_posisi = 'Kelima'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      
      this.setState({
        active_tab_icon: tab,
      });
    }
    
    this.forceUpdate();
    this.componentDidMount();
  }


  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          switch (params.tab) {
            case "Diajukan":
              link =
                "&page=" +
                Math.ceil(
                  this.state.pagination.total / this.state.pagination.per_page
                );
              break;

            case "Diverifikasi":
              link =
                "&page=" +
                Math.ceil(
                  this.state.paginationDiverifikasi.total /
                  this.state.paginationDiverifikasi.per_page
                );
              break;

            case "Disetujui":
              link =
                "&page=" +
                Math.ceil(
                  this.state.paginationDisetujui.total /
                  this.state.paginationDisetujui.per_page
                );
              break;

            case "Ditolak":
              link =
                "&page=" +
                Math.ceil(
                  this.state.paginationDitolak.total /
                  this.state.paginationDitolak.per_page
                );
              break;
              case "Selesai":
                link =
                  "&page=" +
                  Math.ceil(
                    this.state.paginationSelesai.total /
                    this.state.paginationSelesai.per_page
                  );
                break;
              default:
                // no default
                break;
          }
          break;
        case ">":
          switch (params.tab) {
            case "Diajukan":
              link =
                "&page=" + (parseInt(this.state.pagination.current_page) + 1);
              break;

            case "Diverifikasi":
              link =
                "&page=" +
                (parseInt(this.state.paginationDiverifikasi.current_page) +
                  1);
              break;

            case "Disetujui":
              link =
                "&page=" +
                (parseInt(this.state.paginationDisetujui.current_page) + 1);
              break;

            case "Ditolak":
              link =
                "&page=" +
                (parseInt(this.state.paginationDitolak.current_page) + 1);
              break;
            case "Selesai":
              link =
                "&page=" +
                (parseInt(this.state.paginationSelesai.current_page) + 1);
              break;
            default:
              // no default
              break;

          }
          break;
        case "<":
          switch (params.tab) {
            case "Diajukan":
              link =
                "&page=" + (parseInt(this.state.pagination.current_page) - 1);
              break;

            case "Diverifikasi":
              link =
                "&page=" +
                (parseInt(this.state.paginationDiverifikasi.current_page) -
                  1);
              break;

            case "Disetujui":
              link =
                "&page=" +
                (parseInt(this.state.paginationDisetujui.current_page) - 1);
              break;

            case "Ditolak":
              link =
                "&page=" +
                (parseInt(this.state.paginationDitolak.current_page) - 1);
              break;
            case "Selesai":
              link =
                "&page=" +
                (parseInt(this.state.paginationSelesai.current_page) - 1);
              break;
            default:
              // no default
              break;
          }
          break;
        case "<<":
          link = "&page=1";
          break;
        default:
          link = "&page=" + params.page;
          break;
      }
    }

    // Get data from db
    if(this.state.cek_posisi === "Pertama"){
      params.tab = "Diajukan"
    }
    else if(this.state.cek_posisi === "Kedua"){
      params.tab = "Diverifikasi"
    }
    else if(this.state.cek_posisi === "Ketiga"){
      params.tab = "Disetujui"
    }
    else if(this.state.cek_posisi === "Keempat"){
      params.tab = "Ditolak"
    }
    else if(this.state.cek_posisi === "Kelima"){
      params.tab = "Selesai"
    }

    GetEksternal("pelayanan/layanan-desa/"+ID_DESA+"/"+this.state.id_jenis_pelayanan+"?status=" + params.tab + link, null, (dtkat) => {
      switch (params.tab) {
        case "Diajukan":
          this.setState({
            data: dtkat.results.data,
            pagination: dtkat.results
          });
          break;

        case "Diverifikasi":
          this.setState({
            dataVerifikasi: dtkat.results.data,
            paginationDiverifikasi: dtkat.results
          });
          break;
        
        case "Disetujui":
          this.setState({
            dataDisetujui: dtkat.results.data,
            paginationDisetujui: dtkat.results
          });
          break;
        case "Ditolak":
          this.setState({
            dataDitolak: dtkat.results.data,
            paginationDitolak: dtkat.results
          });
          break;
        case "Selesai":
          this.setState({
            dataSelesai: dtkat.results.data,
            paginationSelesai: dtkat.results
          });
          break;
        default:
          // no default
          break;
      }
      // //console.log(dtkat.results);
      this.pagination(dtkat.results, params.tab);
    });
  };

  // Pagination
  pagination = (data, tab) => {
    // const data = this.state.pagination;
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            key={i}
            onClick={() => this.fetch({ page: i, tab: tab })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p, tab: tab })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n, tab: tab })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }

      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1, tab: tab })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page, tab: tab })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }

    switch (tab) {
      case "Diajukan":
        this.setState({ awal, sebelum, hal, setelah, akhir });
        break;
      case "Diverifikasi":
        this.setState({
          awal2: awal,
          sebelum2: sebelum,
          hal2: hal,
          setelah2: setelah,
          akhir2: akhir
        });
        break;
      case "Disetujui":
        this.setState({
          awal3: awal,
          sebelum3: sebelum,
          hal3: hal,
          setelah3: setelah,
          akhir3: akhir
        });
        break;
      case "Ditolak":
        this.setState({
          awal4: awal,
          sebelum4: sebelum,
          hal4: hal,
          setelah4: setelah,
          akhir4: akhir
        });
        break;
      case "Selesai":
        this.setState({
          awal5: awal,
          sebelum5: sebelum,
          hal5: hal,
          setelah5: setelah,
          akhir5: akhir
        });
        break;
      default:
        // no default
        break;
    }
  };


  getChild = (e, self, parent, child) => {
		this.state.status.select = true;
		this.forceUpdate();
    this.changeSelectValue(e, self);
    let formDusun = this.state.frmDus

		if (e) {
			Get(child + '/' + parent + '/' + e.value + '/list', null, (data) => {
				let frm = [];
				data.results.forEach((dt) => {
					frm.push({ value: dt.id, label: dt.nama });
				});
				switch (child) {
					case 'kab-kota':
						this.setState({ frmKbkt: frm, frmKec: [] });
						break;
					case 'kecamatan':
						this.setState({ frmKec: frm });
						break;
					case 'keldes':
						this.setState({ frmKel: frm });
						break;
					case 'dusun':
            if (this.state.id_kategori_penduduk.value === 1) {
              this.setState({ frmDus: frm });
            }
            else {
              this.setState({ frmDus: formDusun });
            }
            break;

					case 'rw':
						this.setState({ frmRw: frm });
						break;

					case 'rt':
						this.setState({ frmRt: frm });
						break;
          default:
            // no default
            break;
				}
				this.state.status.select = false;
				this.forceUpdate();
			});
		}
	};

  changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
        case 'provinsi':
					this.setState({ prvStat: e });
					this.setState({ kbktStat: null });
					this.setState({ kecStat: null });
					break;
				case 'kab-kota':
					this.setState({ kbktStat: e });
					this.setState({ kecStat: null });
					break;
				case 'kecamatan':
					this.setState({ kecStat: e });
					this.setState({ kelStat: null });

					break;
          case 'keldes':
            let idDus = this.state.dusStat
            this.setState({ kelStat: e });
            if (this.state.id_kategori_penduduk.value === 2) {
              this.setState({ dusStat: idDus });
            }
            else {
              this.setState({ dusStat: null });
            }
  
            break;

				case 'dusun':
					this.setState({ dusStat: e });
					this.setState({ rwStat: null });

					break;

				case 'rw':
					this.setState({ rwStat: e });
					this.setState({ rtStat: null });

					break;

				case 'rt':
					this.state.dt.id_rt = e.value;
					this.setState({ rtStat: e });
					// //console.log(this.state.rtStat)
					break;

        // Data KK
				case 'kk':
					this.state.dt.id_kk = e.value;
					this.setState({ kkStat: e });

          Get('kartu-keluarga/find', e.value, (data) => {
            //console.log(data);
            if (data.results) {
              this.setState({ detailKK: data.results })
              let kepalaKeluarga = ''
              // #continuethis
              // coba pke while break
              data.results.data_keluarga.forEach((detail, index) => {
                if (detail.data_shdk.nama === 'Kepala Keluarga') {
                  kepalaKeluarga = detail.nama
                } else if (index === data.results.data_keluarga.length - 1 && kepalaKeluarga === '') {
                  kepalaKeluarga = detail.nama
                }
                this.setState({ kepalaKeluarga })
              })

              Get("penduduk/kk/"+data.results.id, null, dtkk => {
                let frmNik3 = [];
                dtkk.results.forEach(dt => {
                  frmNik3.push({ value: dt.id, label: dt.id });
                });
                this.setState({ frmNik3 })
              })
            }
          })
					break;
        case 'nik': 
          this.setState({ nikStat: e })
          Get("penduduk/find", e.value, data => {
            if (data.results) {
              //console.log(data);
              this.setState({
                dataPemohon: {
                  nik: data.results.id,
                  nama_pemohon: data.results.nama,
                  tempat_lahir: data.results.tempat_lahir,
                  tanggal_lahir: data.results.tanggal_lahir,
                  pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
                  pekerjaan: data.results.data_pekerjaan.nama,
                  agama: data.results.data_agama.nama,
                  status_perkawinan: data.results.status_perkawinan,
                  alamat: data.results.alamat,
                  jenis_kelamin: data.results.jk,
                  kewarganegaraan: "WNI",
                  tujuan_pembuatan: 'Surat Keterangan Pindah Datang WNI',
                  kk: data.results.id_kk,
                }
              })
            }
          });
          break;
        case 'nik3': 
          this.setState({ nik3Stat: e })
          break;
        case 'shdk_aw': 
          this.setState({ shdk_aw: e })
          break;
        case 'alasan pindah': 
          this.setState({ alasanPindah: e })
          if (e.value === '7') {
            this.setState({ dtPindah: {...this.state.dtPindah, alasan_pindah: ''} })
          }
          break;
        case 'klasifikasi pindah': 
          this.setState({ klasifikasiPindah: e })
          break;
        case 'jenis pindah': 
          this.setState({ jenisPindah: e })
          break;
        case 'status tidak pindah': 
          this.setState({ statusTidakPindah: e })
          break;
        case 'status pindah': 
          this.setState({ statusPindah: e })
          break;
        // case 'provinsi':
        //   this.setState({ prvStat: e });
        //   this.setState({ kbktStat: null });
        //   this.setState({ kecStat: null });
        //   break;
        // case 'kab-kota':
        //   this.setState({ kbktStat: e });
        //   this.setState({ kecStat: null });
        //   break;
        // case 'kecamatan':
        //   this.setState({ kecStat: e });
        //   this.setState({ kldsStat: null });
        //   break;
        // case 'keldes':
        //   this.setState({ kldsStat: e });
        //   break;
        default:
          // no default
          break;
			}
		} else {
			switch (sel) {
        case 'provinsi':
					this.setState({ prvStat: null });
					this.setState({ kbktStat: null, frmKbkt: [] });
					this.setState({ kecStat: null, frmKec: [] });
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});
					break;
				case 'kab-kota':
					this.setState({ kbktStat: null });
					this.setState({ kecStat: null, frmKec: [] });
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});

					break;
				case 'kecamatan':
					this.setState({ kecStat: null });
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmKel: [],
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});

					break;
				case 'keldes':
					this.state.dt.id_rt = 0;
					this.setState({
						kelStat: null,
						frmDus: [],
						dusStat: null,
						frmRw: [],
						rwStat: null,
						frmRt: [],
						rtStat: null
					});
					break;
				case 'dusun':
					this.state.dt.id_rt = 0;
					this.setState({ dusStat: null, frmrw: [], rwStat: null, frmRt: [], rtStat: null });
					break;
				case 'rw':
					this.state.dt.id_rt = 0;
					this.setState({ rwStat: null, frmRt: [], rtStat: null });
					break;
				case 'rt':
					this.state.dt.id_rt = 0;
					this.setState({ rtStat: null });
					break;
        
        // Data KK
				case 'kk':
					this.state.dt.id_kk = 0;
					this.setState({ kkStat: null, detailkk: null, frmNik3: [] });
					break;
				case 'nik':
					this.setState({ nikStat: null });
					break;
				case 'nik3':
					this.setState({ nik3Stat: null });
					break;
				case 'shdk_aw':
					this.setState({ shdk_aw: null });
					break;
				case 'alasan pindah':
					this.setState({ alasanPindah: null });
					break;
				case 'klasifikasi pindah':
					this.setState({ klasifikasiPindah: null });
					break;
				case 'jenis pindah':
					this.setState({ jenisPindah: null });
					break;
				case 'status tidak pindah':
					this.setState({ statusTidakPindah: null });
					break;
				case 'status pindah':
					this.setState({ statusPindah: null });
					break;
        // case 'provinsi':
        //   this.setState({
        //     prvStat: null,
        //     kbktStat: null, 
        //     frmKbkt: [],
        //     kecStat: null, 
        //     frmKec: [],
        //     kldsStat: null,
        //     frmKel: [],
        //   });
        //   break;
        // case 'kab-kota':
        //   this.setState({
        //     kbktStat: null, 
        //     frmKbkt: [],
        //     kecStat: null, 
        //     frmKec: [],
        //     kldsStat: null,
        //     frmKel: [],
        //   });
        //   break;
        // case 'kecamatan':
        //   this.setState({
        //     kecStat: null, 
        //     frmKec: [],
        //     kldsStat: null,
        //     frmKel: [],
        //   });
        //   break;
        // case 'keldes':
        //   this.setState({
        //     kldsStat: null
        //   });
        //   break;
        default:
          // no default
          break;
			}
		}

		this.state.status.select = false;
		this.forceUpdate();
	};

  handleInputChangeNIK = (e, fld) => {
    // //console.log(e);
		if (e) {
      if (fld === 'kk') {
				// let kkStat = { value: e, label: e };
				let param = {
					id: e
				};
				Post('kartu-keluarga/select', null, param, (datakk) => {
					let frmKk = [];
					if (datakk.data.results.data.length > 0) {
						datakk.data.results.data.forEach((dt) => {
							frmKk.push({ value: dt.id, label: dt.id });
						});
						// this.setState({ frmKk, kkStat });
						this.setState({ frmKk });
					} else {
						// Get('kartu-keluarga/list', null, (data) => {
						Get('kartu-keluarga', null, (data) => {
							data.results.data.forEach((dt) => {
									frmKk.push({value:dt.id, label:dt.id});
							});

							// if (this.state.kkStat === null) {
								this.setState({
									show: true,
									basicType: 'info',
									basicTitle: 'Data Penduduk',
									pesanAlert: 'Nomor KK tidak tersedia, silahkan pilih Nomor KK yang tersedia',
									frmKk
								});
							// }
						});
					}
				});
			}
		} else {
			if (fld === "kk") {
				Get('kartu-keluarga', null, (data) => {
					let frmKk = [];
					data.results.data.forEach((dt) => {
							frmKk.push({value:dt.id, label:dt.id});
					});
					this.setState({ frmKk });
				});
			}
		}
	};
 
  // ========================================================================

  // ====================== Hapus Data =============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("kesukuan/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Suku",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Suku",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ====================== Hapus Data =============================

  bukaForm = jenis => {
    this.state.status.form = true;
    this.state.perihal={
      izin_usaha: false,
      imb: false,
      siup: false,
      situ: false,
      tdp: false,
    };
    
    this.forceUpdate();

    this.setState({
      pendStat: null,
      kkStat: null,
      alasanPindah: null,
      prvStat: null,
      kbktStat: null,
      kecStat: null,
      kldsStat: null,
      klasifikasiPindah: null,
      jenisPindah: null,
      statusTidakPindah: null,
      statusPindah: null,
      detail: []
    })

  };

  tutupForm = () => {
    this.state.status.formTambahVerifikasi = false;
    this.state.status.form = false;
    this.state.status.formDetail = false;
    
    this.state.showBio = false;

    this.setState({
      usahaStat:null,
      dusStat: null,
      rwStat: null,
      rtStat: null,
      uuid_surat: null,
      dokumen_input: "",
      dokumen_output: "",
      pendStat: null,
      tanggal_surat_desa: new Date(),
      dt:[],
      dataLapor:[],
      perihal:{
        izin_usaha: false,
        imb: false,
        siup: false,
        situ: false,
        tdp: false,
      },
      isUpdate: false,
      agamaStat: null,
      jenisKelaminStat: null,
      statusPerkawinanStat: null,
    })

    this.forceUpdate();
  };

  ubahData = data => {
      // this.componentDidMount();
     this.state.judul = 'Ubah Data Rekomendasi Usaha';
      // console.log("Data Ubah Detail")
      // console.log(data)

      let detail = JSON.parse(data.data_pelayanan)
      // console.log("Detail Datanya")
      // console.log(detail)

      let linkGambar = {}
      Object.keys(detail.linkGambar).forEach((attribute) => {
        let type = detail.linkGambar[attribute].split('.')
        linkGambar[attribute] = {
          link: detail.linkGambar[attribute],
          type: type[type.length - 1],
        }
      })

      Get("penduduk/find/" + data.nik_pelapor, null, dtl => {
        // console.log("Data Set penduduk ubah")
        // console.log(dtl.results)
        let getDusStat = null
        let dusUsaha = ''
        if(this.state.isKelurahan){
          getDusStat = null
          dusUsaha = ''
        }
        else{
          getDusStat = detail.lokasi_usaha.dusun
          dusUsaha = detail.lokasi_usaha.dusun.label
        }

        if (dtl.results) {
          this.setState({
            isUpdate: true,
            showBio: true,
            status: {...this.state.status, form: true},
            noSuratSelect: true,
            uuid_surat: data.uuid,
            dokumen_input: '',
            dokumen_output: '',
            pendStat: {
              value: data.nik_pelapor, 
              label: data.nik_pelapor,
            },
            dusSelect: true,
            dusStat: getDusStat,
            rtStat: detail.lokasi_usaha.rt,
            rwStat: detail.lokasi_usaha.rw,
            tanggal_surat_desa: new Date(detail.tanggal_surat_desa),
            usahaStat: {
              value: detail.nomor_surat_desa, 
              label: detail.nomor_surat_desa,
            },
            dataLapor:{
              nik: data.nik_pelapor,
              tanggal_surat_desa: detail.tanggal_surat_desa,
              // nama: dtl.results.nama,
              nama: data.nama_pelapor,
              // jenis_kelamin: dtl.results.jk,
              jenis_kelamin: detail.jenis_kelamin,
              // agama: dtl.results.data_agama.nama,
              agama: detail.agama,
              // status_perkawinan: dtl.results.status_perkawinan,
              status_perkawinan: detail.status_perkawinan,
              // pekerjaan: dtl.results.data_pekerjaan.nama,
              pekerjaan: detail.pekerjaan,
              // alamat: alamat_pelapor,
              alamat: data.alamat_pelapor,
              // tempat_lahir: dtl.results.tempat_lahir,
              tempat_lahir: detail.tempat_lahir,
              // tanggal_lahir: dtl.results.tanggal_lahir,
              tanggal_lahir: detail.tanggal_lahir,
              no_hp_pelapor: data.no_hp_pelapor,
              nomor_surat_desa: detail.nomor_surat_desa,
            },
            dt:{
              nama_usaha: detail.nama_usaha,
              dusun_usaha: dusUsaha,
              rw_usaha: detail.lokasi_usaha.rw.label,
              rt_usaha: detail.lokasi_usaha.rt.label,
              lokasi_lokasi_pembangunan: detail.lokasi_lokasi_pembangunan,
              jenis_usaha: detail.jenis_usaha
            },
            perihal:{
              izin_usaha: detail.perihal.izin_usaha,
              imb: detail.perihal.imb,
              siup: detail.perihal.siup,
              situ: detail.perihal.situ,
              tdp: detail.perihal.tdp,
            },
            // linkGambar: detail.linkGambar ? detail.linkGambar : strukturGambar
            linkGambar
          })
        } else {
          let dusUsaha = ''
          if(this.state.isKelurahan){
            dusUsaha = ''
          }
          else{
            dusUsaha = detail.lokasi_usaha.dusun.label
          }
          this.setState({
            isUpdate: true,
            showBio: true,
            status: {...this.state.status, form: true},
            noSuratSelect: false,
            uuid_surat: data.uuid,
            dokumen_input: '',
            dokumen_output: '',
            pendStat: {
              value: data.nik_pelapor, 
              label: data.nik_pelapor,
            },
            // dusStat: detail.lokasi_usaha.dusun,
            rtStat: detail.lokasi_usaha.rt,
            rwStat: detail.lokasi_usaha.rw,
            tanggal_surat_desa: new Date(detail.tanggal_surat_desa),
            usahaStat: {
              value: detail.nomor_surat_desa, 
              label: detail.nomor_surat_desa,
            },
            agamaStat: {
              value: detail.agama, 
              label: detail.agama,
            },
            jenisKelaminStat: {
              value: detail.jenis_kelamin, 
              label: detail.jenis_kelamin,
            },
            statusPerkawinanStat: {
              value: detail.status_perkawinan, 
              label: detail.status_perkawinan,
            },
            dataLapor:{
              nik: data.nik_pelapor,
              tanggal_surat_desa: detail.tanggal_surat_desa,
              // nama: dtl.results.nama,
              nama: data.nama_pelapor,
              // jenis_kelamin: dtl.results.jk,
              jenis_kelamin: detail.jenis_kelamin,
              // agama: dtl.results.data_agama.nama,
              agama: detail.agama,
              // status_perkawinan: dtl.results.status_perkawinan,
              status_perkawinan: detail.status_perkawinan,
              // pekerjaan: dtl.results.data_pekerjaan.nama,
              pekerjaan: data.pekerjaan_pelapor,
              // alamat: alamat_pelapor,
              alamat: data.alamat_pelapor,
              // tempat_lahir: dtl.results.tempat_lahir,
              tempat_lahir: detail.tempat_lahir,
              // tanggal_lahir: dtl.results.tanggal_lahir,
              tanggal_lahir: detail.tanggal_lahir,
              no_hp_pelapor: data.no_hp_pelapor,
              nomor_surat_desa: detail.nomor_surat_desa,
            },
            dusSelect: false,
            dt:{
              nama_usaha: detail.nama_usaha,
              dusun_usaha: dusUsaha,
              rw_usaha: detail.lokasi_usaha.rw.label,
              rt_usaha: detail.lokasi_usaha.rt.label,
              lokasi_lokasi_pembangunan: detail.lokasi_lokasi_pembangunan,
              jenis_usaha: detail.jenis_usaha,
              nomor_surat_desa: detail.nomor_surat_desa,
              tanggal_surat_desa: new Date(detail.tanggal_surat_desa),
              tanggal_lahir_pelapor: new Date(detail.tanggal_lahir),
            },
            perihal:{
              izin_usaha: detail.perihal.izin_usaha,
              imb: detail.perihal.imb,
              siup: detail.perihal.siup,
              situ: detail.perihal.situ,
              tdp: detail.perihal.tdp,
            },
            // linkGambar: detail.linkGambar ? detail.linkGambar : strukturGambar
            linkGambar
          })
        }
      });
  };

  detailData = data => {
   this.state.judul = 'Detail Data Rekomendasi Usaha';

    // console.log('Data Ubah')
    // console.log(data)
    let detail = JSON.parse(data.data_pelayanan)
    
        // console.log('Data Detail')
        // console.log(detail)

    let dusUsaha = ''
    if(this.state.isKelurahan){
      dusUsaha = ''
    }
    else{
      dusUsaha = detail.lokasi_usaha.dusun.label
    }
    this.setState({
      isUpdate: false,
      showBio: true,
      status: {...this.state.status, formDetail: true},
      uuid_surat: data.uuid,
      dokumen_input: '',
      dokumen_output: '',
      pendStat: data.nik_pelapor,
      tanggal_surat_desa: detail.tanggal_surat_desa,
      dataLapor:{
        nik: data.nik_pelapor,
        tanggal_surat_desa: detail.tanggal_surat_desa,
        nama: data.nama_pelapor,
        jenis_kelamin: detail.jenis_kelamin,
        agama: detail.agama,
        status_perkawinan: detail.status_perkawinan,
        pekerjaan: detail.pekerjaan,
        alamat: data.alamat_pelapor,
        tempat_lahir: detail.tempat_lahir,
        tanggal_lahir: detail.tanggal_lahir,
        no_hp_pelapor: data.no_hp_pelapor,
        nomor_surat_desa: detail.nomor_surat_desa,
      },
      dt:{
        nama_usaha: detail.nama_usaha,
        dusun_usaha: dusUsaha,
        rw_usaha: detail.lokasi_usaha.rw.label,
        rt_usaha: detail.lokasi_usaha.rt.label,
        lokasi_lokasi_pembangunan: detail.lokasi_lokasi_pembangunan,
        jenis_usaha: detail.jenis_usaha,
      },
      perihal:{
        izin_usaha: detail.perihal.izin_usaha,
        imb: detail.perihal.imb,
        siup: detail.perihal.siup,
        situ: detail.perihal.situ,
        tdp: detail.perihal.tdp,
      }
    })

};

  tombolAksi = (cell, row) => {
    let btnAksi = false
    // if (row.data_status[row.data_status.length - 1].status === 'Selesai') {
    //   btnAksi = true
    // }

    return (
      <>
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row)}
          disabled={btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>
      </>
    )
  }


  tombolAksiVerifikasi = (cell, row) => {
    return (
      <>
      {this.state.cek_posisi==="Pertama"
        ?
        <div>
            <button onClick={() => this.ubahData(row)} color="info" className="btn btn-success"><i className="fa fa-pencil"></i> Ubah</button>{"  "}
            <button onClick={() => this.detailData(row)} color="info" className="btn btn-secondary-custom"><i className="fa fa-eye"></i> Detail</button>
        </div>
        :this.state.cek_posisi==="Kedua"
        ?
        <div>
            <button onClick={() => this.detailData(row)} color="info" className="btn  btn-secondary-custom"><i className="fa fa-eye"></i> Detail</button>
        </div>
         :this.state.cek_posisi==="Ketiga"
         ?
         <div>
             <button onClick={() => this.detailData(row)} color="info" className="btn  btn-secondary-custom"><i className="fa fa-eye"></i> Detail</button>
         </div>
         :this.state.cek_posisi==="Keempat"
         ?
         <div>
             <button onClick={() => this.detailData(row)} color="info" className="btn  btn-secondary-custom"><i className="fa fa-eye"></i>  Detail</button>
         </div>
         
         :this.state.cek_posisi==="Kelima"
         ?
         <div>
             <button onClick={() => this.detailData(row)} color="info" className="btn  btn-secondary-custom"><i className="fa fa-eye"></i>  Detail</button>
             {/* <button onClick={() => this.downloadDokumen(row)} color="info" className="btn btn-secondary-custom"><i className="fa fa-download"></i>  Download</button> */}
         </div>
         :
         <>
         </>
      }
     
    </>
    )
  }

  onFileChange = (event, attribute) => {
    const file = event.target.files[0];
    const fileType = file.type.split('/')
    const type = fileType[1]

		// Disable tombol selesai sampai file berhasil diupload
		this.setState({ status: {...this.state.status, btnForm: true} })
    
    if (type === "pdf" || type === "jpg" || type === "jpeg" || type === "png") {
      let bodyFormData = new FormData();
      bodyFormData.append("files", file);

      let folder = 'img'
			if (type === "pdf") {
				folder = "pdf"
			}
  
      PostEksternal("upload-file/" + folder, null, bodyFormData, (response) => {
        const link = response.data.results
        let linkGambar = {...this.state.linkGambar};

        // linkGambar[attribute] = link
        linkGambar[attribute] = { link, type: folder }
  
        this.setState({ 
          linkGambar,
					status: {...this.state.status, btnForm: false}
				});
      });
    } else {
      // let status = { ...this.state.status };
      // status.alert = true;

      this.setState({
        show: true,
        basicType: "warning",
        basicTitle: "Upload file",
        pesanAlert: "Format file harus berupa jpg/jpeg/png/pdf",
      });
      document.getElementById("file-upload").value = null
    }

  };

  componentDidMount() {
    //console.log(this.props.location);
    
    if (JENIS_DESA.toLowerCase() === 'kelurahan') {
			this.setState({ isKelurahan: true });
      Get('rw/kel/'+ID_DESA+'/list', null, (data) => {
          let frmRw = [];
          data.results.forEach((dt) => {
              frmRw.push({value:dt.id, label:dt.nama});
          });
          this.setState({frmRw});
      })
    }
    else{
      Get('dusun/kel/' + ID_DESA + '/list', null, (data) => {
        //console.log("Data Dusuh Lokal")
        //console.log(data.results)
        let frm = [];
        data.results.forEach((dt) => {
          frm.push({ value: dt.id, label: dt.nama });
        });
        this.setState({ frmDus: frm });
        this.forceUpdate();
      });
    }
      let id_jenis_surat = "a6e75b67-410d-4a44-918f-f742187ca297/list";
      Get("surat-keterangan/grup/"+id_jenis_surat, null, data => {
        let frmUsaha = [];
        data.results.forEach(dt => {
          frmUsaha.push({ value: dt.uuid, label: dt.nomor });
        });
        this.setState({ frmUsaha });
      });

      Get("penduduk", null, data => {
        let frmPend = [];
        data.results.data.forEach(dt => {
          frmPend.push({ value: dt.id, label: dt.id });
        });
        this.setState({ frmPend });
      });
      
      if (typeof this.props.location.tabProps !== "undefined") {
        this.state.active_tab_icon = this.props.location.tabProps;
      }
      if (typeof this.props.location.state !== "undefined") {
        this.state.active_tab_icon = this.props.location.state.tab;
  
      }
  
      let tabnya = ""
      if(this.state.cek_posisi==="Pertama"){
        tabnya="Diajukan"
        this.setState({
          active_tab_icon: '1',
        })
      }
      else if(this.state.cek_posisi==="Kedua"){
        tabnya="Diverifikasi"
        this.setState({
          active_tab_icon: '2',
        })
      }
      else if(this.state.cek_posisi==="Ketiga"){
        tabnya="Disetujui"
        this.setState({
          active_tab_icon: '3',
        })
      }
      else if(this.state.cek_posisi==="Keempat"){
        tabnya="Ditolak"
        this.setState({
          active_tab_icon: '4',
        })
      }
      else if(this.state.cek_posisi==="Kelima"){
        tabnya="Selesai"
        this.setState({
          active_tab_icon: '5',
        })
      }

      // Fetching data diajukan
      this.fetch({ page: null, where: [], tab: tabnya });

      
    
    this.forceUpdate();

  }

  simpan = (e) => {
    e.preventDefault()
    //console.log("Data SImpan")
    //console.log(document.getElementById("no_hp_pelapor").value) 
   
    // validasi data
    let checkUsahaStat = true
    let checkDusStat = true
    let checkRwStat = true
    let checkRtStat = true
    let checkAgamaStat = true
    let checkJenisKelaminStat = true
    let checkStatusPerkawinanStat = true

    if (this.state.noSuratSelect) {
      // No surat
      if (this.state.usahaStat) {
        checkUsahaStat = true
      } else {
        checkUsahaStat = false
      }
      // Dusun
      if(this.state.isKelurahan){
        checkDusStat = true
      }
      else{
        if (this.state.dusStat) {
          checkDusStat = true
        } else {
          checkDusStat = false
        }
      }
      // RW
      if (this.state.rwStat) {
        checkRwStat = true
      } else {
        checkRwStat = false
      }
      // RT
      if (this.state.rtStat) {
        checkRtStat = true
      } else {
        checkRtStat = false
      }
    } else {
      // Agama
      if (this.state.agamaStat) {
        checkAgamaStat = true
      } else {
        checkAgamaStat = false
      }
      // Jenis Kelamin
      if (this.state.jenisKelaminStat) {
        checkJenisKelaminStat = true
      } else {
        checkJenisKelaminStat = false
      }
      // Status Perkawinan
      if (this.state.statusPerkawinanStat) {
        checkStatusPerkawinanStat = true
      } else {
        checkStatusPerkawinanStat = false
      }
    }

    if (
      // this.state.usahaStat
      checkUsahaStat &&
      checkDusStat &&
      checkRwStat &&
      checkRtStat &&
      checkAgamaStat &&
      checkJenisKelaminStat &&
      checkStatusPerkawinanStat
      // this.state.dusStat &&
      // this.state.rtStat &&
      // this.state.rwStat &&
      // this.state.tanggal_surat_desa
    ) {
      console.log('Lolos Simpan')
      // Cek persyaratan 
			let emptyGambar = false
			Object.keys(this.state.linkGambar).forEach((attribute) => {
				if(this.state.linkGambar[attribute].link  === ''){
					emptyGambar = true
				}
			})

      if(this.state.perihal.izin_usaha=== false && 
        this.state.perihal.imb=== false && 
        this.state.perihal.siup=== false && 
        this.state.perihal.situ=== false && 
        this.state.perihal.tdp=== false)
        {
          this.setState({
            show: true,
            basicType: 'warning',
            basicTitle: 'Data Rekomendasi Usaha',
            pesanAlert: 'Pilih Perihal Pembuatan Surat Terlebih Dahulu'
          });
        }
        else if(document.getElementById("no_hp_pelapor").value==="" || document.getElementById("no_hp_pelapor").value === null){
          this.setState({
            show: true,
            basicType: 'warning',
            basicTitle: 'Data Rekomendasi Usaha',
            pesanAlert: 'No HP Wajib Diisi'
          });
        }
        // else if(document.getElementById('dusun_usaha').value === "" || document.getElementById('rt_usaha').value === "" || document.getElementById('rw_usaha').value === "" ) {
        //   this.setState({
        //     show: true,
        //     basicType: 'warning',
        //     basicTitle: 'Data Rekomendasi Usaha',
        //     pesanAlert: 'Lokasi Usaha Wajib Diisi'
        //   });
        // } 
        else if (emptyGambar) {
          this.setState({
            show: true,
            basicType: 'warning',
            basicTitle: 'Data Rekomendasi Usaha',
            pesanAlert: 'Upload File Persyaratan Terlebih Dahulu'
          });
        }
        else{
          //console.log("Data Berhasil Disimpan")
          //console.log(this.state.dt)

          let uuid = this.state.uuid_surat
          let dokumen_input = this.state.dokumen_input
          let dokumen_output = this.state.dokumen_output

          let perihalnya = {
            izin_usaha: this.state.perihal.izin_usaha,
            imb: this.state.perihal.imb,
            siup: this.state.perihal.siup,
            situ: this.state.perihal.situ,
            tdp: this.state.perihal.tdp,
          }

          let lokasi_bangun = "";
          
          if(this.state.perihal.imb===true){
            if(document.getElementById("lokasi_bangunan").value===""|| document.getElementById("lokasi_bangunan").value===null){
              this.setState({
                show: true,
                basicType: 'warning',
                basicTitle: 'Data Rekomendasi Usaha',
                pesanAlert: 'Lokasi Pembangunan Wajib Diisi'
              });
            }
            else{
              lokasi_bangun = document.getElementById("lokasi_bangunan").value;
            }
          }

          let linkGambar = {};
          Object.keys(this.state.linkGambar).forEach((attribute) => {
            linkGambar[attribute] = this.state.linkGambar[attribute].link
          })
          
          let nomor_surat_desa = ''
          let tanggal_surat_desa = ''
          let nik_pelapor = document.getElementById("nik_pelapor").value
          let nama_pelapor = document.getElementById("nama_pelapor").value
          // let tanggal_lahir = document.getElementById("tanggal_lahir_pelapor").value
          let tanggal_lahir = ''
          // let agama_pelapor = document.getElementById("agama_pelapor").value
          let agama = ''
          // let jenis_kelamin_pelapor = document.getElementById("jenis_kelamin_pelapor").value
          let jenis_kelamin = ''
          // let status_perkawinan_pelapor = document.getElementById("status_perkawinan_pelapor").value
          let status_perkawinan = ''
          let tempat_lahir_pelapor = document.getElementById("tempat_lahir").value
          let pekerjaan_pelapor = document.getElementById("pekerjaan_pelapor").value
          let alamat_pelapor = document.getElementById("alamat_pelapor").value
          let no_hp_pelapor = document.getElementById("no_hp_pelapor").value;
          
          if (this.state.noSuratSelect) {
            nomor_surat_desa = this.state.usahaStat.label
            tanggal_surat_desa = this.state.dataLapor.tanggal_surat_desa
            tanggal_lahir = this.state.dataLapor.tanggal_lahir
            agama = this.state.dataLapor.agama
            jenis_kelamin = this.state.dataLapor.jenis_kelamin
            status_perkawinan = this.state.dataLapor.status_perkawinan
          } else {
            nomor_surat_desa = document.getElementById("nomor_surat_desa").value
            tanggal_surat_desa = dateFormat(this.state.dt.tanggal_surat_desa, 'yyyy-mm-dd') 
            tanggal_lahir = dateFormat(this.state.dt.tanggal_lahir_pelapor, 'yyyy-mm-dd') 
            // #continuethis
            agama = this.state.agamaStat.label
            jenis_kelamin = this.state.jenisKelaminStat.label
            status_perkawinan = this.state.statusPerkawinanStat.label
          }

          let dusun = ''
          let rw = ''
          let rt = ''

          if(this.state.isKelurahan){
            rw = this.state.rwStat
            rt = this.state.rtStat
          }
          else{
            dusun = this.state.dusStat
            rw = this.state.rwStat
            rt = this.state.rtStat

            if (!this.state.dusSelect) {
              dusun = {
                value: document.getElementById('dusun_usaha').value,
                label: document.getElementById('dusun_usaha').value
              }
              rw = {
                value: document.getElementById('rw_usaha').value,
                label: document.getElementById('rw_usaha').value
              }
              rt = {
                value: document.getElementById('rt_usaha').value,
                label: document.getElementById('rt_usaha').value
              }
            }
          }
          //console.log(this.state.dusSelect);
          let LokUsaha = null
          if(this.state.isKelurahan){
            LokUsaha= { rw, rt }
          }
          else{
            LokUsaha= { dusun, rw, rt }
          }

          let detail = {
            nomor_surat_desa,
            // tanggal_surat_desa: this.state.dataLapor.tanggal_surat_desa,
            tanggal_surat_desa,
            nomor_surat_kecamatan: "",
            tanggal_surat_kecamatan: "",
            perihal: perihalnya,
            // tempat_lahir: this.state.dataLapor.tempat_lahir,
            tempat_lahir: tempat_lahir_pelapor,
            // tanggal_lahir: this.state.dataLapor.tanggal_lahir,
            tanggal_lahir,
            // jenis_kelamin: this.state.dataLapor.jenis_kelamin,
            jenis_kelamin,
            // agama: this.state.dataLapor.agama,
            agama,
            // status_perkawinan: this.state.dataLapor.status_perkawinan,
            status_perkawinan,
            // pekerjaan: this.state.dataLapor.pekerjaan,
            pekerjaan: pekerjaan_pelapor,
            nama_usaha: document.getElementById("nama_usaha").value,
            jenis_usaha: document.getElementById("jenis_usaha").value,
            lokasi_usaha: LokUsaha,
            jenis_desa: JENIS_DESA,
            lokasi_lokasi_pembangunan: lokasi_bangun,
            nama_ttd: null,
            pangkat_ttd: null,
            nip_ttd: null,
            
            linkGambar,
            id_desa: {
              value: ID_DESA,
              label: DESA,
            }
          }

          let bodyRaw = { 
            id_desa: ID_DESA,
            nik_pelapor,
            nama_pelapor,
            pekerjaan_pelapor,
            alamat_pelapor,
            no_hp_pelapor,
            tgl_melapor: dateFormat(this.state.tgl_melapor, 'yyyy-mm-dd'),
            data_pelayanan: JSON.stringify(detail),
            dokumen_input: dokumen_input,
            dokumen_output: dokumen_output,
            jenis_layanan: "Rekomendasi Izin Usaha",
            uuid_surat_desa: uuid,
            uuid_pelayanan_instansi: this.state.id_jenis_pelayanan,
          }

          //console.log("Data Simpannya berikut:")
          //console.log(bodyRaw)

          let psn = "";
          let resstat = 204;
          let metode = "create";
          if (uuid === "" || uuid === null) {
            psn = "Tambah";
            resstat = 201;
            bodyRaw.uuid = null;
          } else {
            psn = "Ubah";
            resstat = 200;
            metode = "update";
          }

          PostEksternal(
            "pelayanan/" + metode,
            uuid,
            bodyRaw,
            res => {
              this.tutupForm();
              this.state.status.btnForm = false;
              this.state.status.form = false;
              this.forceUpdate();

              //console.log("Respon Simpan")
              //console.log(res)
              if (res.status === resstat) {
                this.setState({
                  showBio: false,
                  show: true,
                  basicType: "success",
                  basicTitle: "Data Rekomendasi Usaha",
                  pesanAlert: "Berhasil " + psn + " Rekomendasi Usaha",
                  // successAlert: true,
                });
                this.componentDidMount();
              } else {
                this.setState({
                  showBio: false,
                  show: true,
                  basicType: "danger",
                  basicTitle: "Data Rekomendasi Usaha",
                  pesanAlert: "Gagal " + psn + " Rekomendasi Usaha"
                });
              }
              // this.componentDidMount();
            }
          );
        }
    } else {
      this.setState({
        show: true,
        basicType: 'warning',
        basicTitle: 'Data Rekomendasi Usaha',
        pesanAlert: 'Isi seluruh data dengan lengkap terlebih dahulu.'
      });
    }

  }

  tambahKeterangan = (e) => {
		e.preventDefault();
      this.forceUpdate();
      let id = this.state.data_detail.uuid;
      let addData = {
          status: "Ditolak",
          keterangan: document.getElementById("keterangan_tolak").value,
      };

          let psn = "Tolak";
          let resstat = 200;

          //console.log("Data Tambah Keterangan")
          //console.log(id)
          //console.log(addData)
          
      Post('pelayanan-external/verifikasi/'+ id, null , addData, (res) => {
          this.forceUpdate();
          //console.log(res.status);
          if (res.status === resstat) {
              this.setState({ show: true, basicType: 'success', basicTitle: 'Verifikasi Pendaftaran Layanan', pesanAlert: 'Berhasil ' + psn + ' Verifikasi' });
		          this.state.status.formKeterangan = false;
              this.state.status.formTambahVerifikasi = false;
              document.getElementById("keterangan_tolak").value = ""
              this.forceUpdate()
          }
          else {
              this.setState({ show: true, basicType: 'danger', basicTitle: 'Verifikasi Pendaftaran Layanan', pesanAlert: 'Gagal ' + psn + ' Verifikasi' });
          }
          this.componentDidMount();
      });
	};

   // Download File Kelahiran
   downloadDokumen = (data) => {

    // Data Download 
    let dokumen_download = JSON.parse(data.dokumen_output)

    //console.log("Data DOwnload")
    //console.log(dokumen_download)
    
   
      const response = {
        file: dokumen_download.dokumen_output_1,
      };
      // server sent the url to the file!
      // now, let's download:
      window.open(response.file);
      // you could also do:
      // window.location.href = response.file;
  

    this.forceUpdate();
    
  }

  // ambil data penduduk
  getPenduduk = (e, label) => {
    if (e) {
      Get("penduduk/find/" + e.value, null, data => {
        let alamat = '';
        // if (JENIS_DESA.toLowerCase() === 'kelurahan') {
        //   alamat =
        //   " RT. " +
        //   data.results.data_kk.data_rt.nama +
        //   "/RW. " +
        //   data.results.data_kk.data_rt.data_rw.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes.nama +
        //   ", Kec. " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes
        //     .data_kecamatan.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes
        //     .data_kecamatan.data_kabkota.nama; 
        // } else {
        //   alamat =
        //   " RT. " + 
        //   data.results.data_kk.data_rt.nama +
        //   "/RW. " +
        //   data.results.data_kk.data_rt.data_rw.nama +
        //   ", " + JENIS_DESA + " " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
        //   ", Kec. " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
        //     .data_kecamatan.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
        //     .data_kecamatan.data_kabkota.nama; 
        // }
        if (JENIS_DESA.toLowerCase() === 'kelurahan') {
          alamat =
          " RT. " +
          data.results.data_kk.data_rt.nama +
          "/RW. " +
          data.results.data_kk.data_rt.data_rw.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_keldes.nama +
          ", Kec. " +
          data.results.data_kk.data_rt.data_rw.data_keldes
            .data_kecamatan.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_keldes
            .data_kecamatan.data_kabkota.nama; 
        } else {
          if (data.results.data_kk.id_rt === '0') {
            alamat = JENIS_DESA + " " + data.results.data_keldes.nama + 
            ", Kec. " + data.results.data_keldes.data_kecamatan.nama + 
            ", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
            ", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
          } else {
            alamat =
            " RT. " + 
            data.results.data_kk.data_rt.nama +
            "/RW. " +
            data.results.data_kk.data_rt.data_rw.nama +
            ", " + JENIS_DUSUN + " " +
            data.results.data_kk.data_rt.data_rw.data_dusun.nama +
            ", " + JENIS_DESA + " " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
            ", Kec. " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
              .data_kecamatan.nama +
            ", " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
              .data_kecamatan.data_kabkota.nama; 
          }
        }

        // let daerah = data.results.data_kk.data_rt.data_rw.data_dusun.nama +
        //       ", " +
        //       data.results.data_kk.data_rt.data_rw.data_keldes.nama +
        //       ", " +
        //       data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
        //       ", " +
        //       data.results.data_kk.results.dataKkbj.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota
        //         .nama +
        //       ", " +
        //       data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota
        //         .data_provinsi.nama

        if (label === "nik") {
          Get("catatan-penduduk/nik", data.results.id, dpddk => {
            let pctt = 0;
            let catatan = "";
            if (dpddk.results.data) {
              dpddk.results.data.forEach(dtp => {
                if (dtp.status === 0 && dtp.penalti === 1) {
                  pctt = 1;
                  catatan = dtp.catatan;
                }
              });
            }
            if (pctt === 0) {
              this.state.dt.nik = data.results.id;
              this.state.dt.kk = data.results.id_kk;
              this.state.dt.nama = data.results.nama;
              this.state.dt.tempat_lahir = data.results.tempat_lahir;
              this.state.dt.tanggal_lahir = data.results.tanggal_lahir;
              this.state.dt.pekerjaan = data.results.data_pekerjaan.nama;
              this.state.dt.jenis_kelamin = data.results.jk;
              this.state.dt.pendidikan_terakhir =
                data.results.data_pendidikan_terakhir.nama;
              this.state.dt.agama = data.results.data_agama.nama;
              this.state.dt.status_perkawinan = data.results.status_perkawinan;
              this.state.dt.alamat = alamat;
              this.state.showBio = true;
              this.state.pendStat = { value: e.value, label: e.label };
              this.forceUpdate();
            } else {
              this.state.dt.nik = "";
              this.state.dt.nama_pemohon = "";
              this.state.dt.tempat_lahir = "";
              this.state.dt.tanggal_lahir = "";
              this.state.dt.pekerjaan = "";
              this.state.dt.pendidikan_terakhir = "";
              this.state.dt.agama = "";
              this.state.dt.status_perkawinan = "";
              this.state.dt.alamat = "";
              this.state.showBio = false;
              this.state.btnForm = true;
              this.forceUpdate();
              this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Peringatan Catatan Warga",
                pesanAlert:
                  "Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r" +
                  catatan,
                pendStat: null,
                // frmPend: []
              });
            }
          });
        }
        if (label === "nik2") {
          this.state.dt.agama2 = data.results.data_agama.nama;
          this.state.dt.alamat2 = alamat;
          this.state.dt.jenis_kelamin2 = data.results.jk;
          this.state.dt.kk2 = data.results.id_kk;
          this.state.dt.nama2 = data.results.nama;
          this.state.dt.nik2 = data.results.id;
          // this.state.dt.pekerjaan2 = data.results.data_pekerjaan.nama;
          this.state.dt.pekerjaan2 = {
            value : data.results.data_pekerjaan.nama,
            label : data.results.data_pekerjaan.nama,
          }
          this.state.dt.pendidikan_terakhir2 =
            data.results.data_pendidikan_terakhir.nama;
          this.state.dt.status_perkawinan2 = data.results.status_perkawinan;
          // this.state.dt.tanggal_lahir2 = data.results.tanggal_lahir;
          this.state.dt.tanggal_lahir2 = new Date(data.results.tanggal_lahir);
          this.state.dt.tempat_lahir2 = data.results.tempat_lahir;
          this.state.pendStat2 = { value: e.value, label: e.label };
          this.state.showBio2 = true;
        }

        // if (
        //   this.state.pendStat2.length !== 0 &&
        //   this.state.pendStat.length !== 0
        // ) {
        //   this.state.status.btnForm = false;
        // }
        // this.state.status.btnAksi = false;
        this.forceUpdate();
      });
    } else {
      if (label === "nik") {
        this.state.dt.nik = "";
        this.state.dt.nama_pemohon = "";
        this.state.dt.tempat_lahir = "";
        this.state.dt.tanggal_lahir = "";
        this.state.dt.pekerjaan = "";
        this.state.dt.pendidikan_terakhir = "";
        this.state.dt.agama = "";
        this.state.dt.status_perkawinan = "";
        this.state.dt.alamat = "";
        this.state.showBio = false;
        this.state.btnForm = true;
        this.state.pendStat = null;
      }
      if (label === "nik2") {
        this.state.dt.nik2 = "";
        this.state.dt.nama_pemohon2 = "";
        this.state.dt.tempat_lahir2 = "";
        // this.state.dt.tanggal_lahir2 = "";
        this.state.dt.tanggal_lahir2 = null;
        this.state.dt.pekerjaan2 = null;
        this.state.dt.pendidikan_terakhir2 = "";
        this.state.dt.agama2 = "";
        this.state.dt.status_perkawinan2 = "";
        this.state.dt.alamat2 = "";
        this.state.showBio2 = false;
        this.state.btnForm = true;
        this.state.pendStat2 = null;
      }
      // this.state.status.btnForm = true;

      this.forceUpdate();

      // this.state.status.btnAksi = false;
    }
  };

  //change nik
  handleInputChangeNIK = (e, fld) => {
    if (e) {
      if (fld === "nik") {
        let pendStat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmPend = [];
          datanik.data.results.data.forEach(dt => {
            frmPend.push({ value: dt.id, label: dt.id });
          });
          this.setState({ frmPend, pendStat });
        });
      } else {
        let nik2Stat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmPend2 = [];
          datanik.data.results.data.forEach(dt => {
            frmPend2.push({ value: dt.id, label: dt.id });
          });
          this.setState({ frmPend2, nik2Stat });
        });
      }
    }
  };
  

  changeCheck = (e, jenis_perihal) => {
			switch (jenis_perihal) {
				case 'izin_usaha':
          if(this.state.perihal.izin_usaha === true){
            this.state.perihal.izin_usaha = false
          }
          else{
            this.state.perihal.izin_usaha = true
          }
				break;
				case 'imb':
          if(this.state.perihal.imb === true){
            this.state.perihal.imb = false
          }
          else{
            this.state.perihal.imb = true
          }
				break;
				case 'siup':
          if(this.state.perihal.siup === true){
            this.state.perihal.siup = false
          }
          else{
            this.state.perihal.siup = true
          }
				break;
				case 'situ':
          if(this.state.perihal.situ === true){
            this.state.perihal.situ = false
          }
          else{
            this.state.perihal.situ = true
          }
				break;
				case 'tdp':
          if(this.state.perihal.tdp === true){
            this.state.perihal.tdp = false
          }
          else{
            this.state.perihal.tdp = true
          }
				break;    
        default:
          // no default
          break;
			}
		this.forceUpdate();
	};

   // ================== Tanggal ===================

   gantiTgl = (date, fld) => {
    switch (fld) {
      case "tgl_lahir_pelapor":
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD HH:mm:ss');
          this.setState({ dt: {...this.state.dt, tanggal_lahir_pelapor: new Date(tgl)} })
        }
        break;
      case "tgl_surat_desa":
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD HH:mm:ss');
          this.setState({ dt: {...this.state.dt, tanggal_surat_desa: new Date(tgl)} })
        }
        break;
      default:
        // no default
        break;
    }
  };

  // ==============================================
  
  getUsaha = (e, jenis) => {
    if (e) {
      switch (jenis) {
        case "usaha":
          this.setState({ usahaStat: e });

          Get("surat-keterangan/grup/find/"+e.value, null, data => {
            console.log("data Detail usaha")
            console.log(data.results)
            if(this.state.isUpdate){
              this.setState({
                dataLapor:{
                  ...this.state.dataLapor,
                  nik: data.results.nik,
                  tanggal_surat_desa: data.results.tanggal_surat,
                  nama: data.results.nama_pemohon,
                  jenis_kelamin: data.results.jenis_kelamin,
                  agama: data.results.agama,
                  status_perkawinan: data.results.status_perkawinan,
                  pekerjaan: data.results.pekerjaan,
                  alamat: data.results.alamat,
                  tempat_lahir: data.results.tempat_lahir,
                  tanggal_lahir: data.results.tanggal_lahir,
                  no_hp_pelapor: '',
                  nomor_surat_desa: data.results.nomor,
                },
                // tanggal_surat_desa: data.results.tanggal_surat,
                showBio:true,
              })
              
            }
            else{
              this.setState({
                dataLapor:{
                  nik: data.results.nik,
                  tanggal_surat_desa: data.results.tanggal_surat,
                  nama: data.results.nama_pemohon,
                  jenis_kelamin: data.results.jenis_kelamin,
                  agama: data.results.agama,
                  status_perkawinan: data.results.status_perkawinan,
                  pekerjaan: data.results.pekerjaan,
                  alamat: data.results.alamat,
                  tempat_lahir: data.results.tempat_lahir,
                  tanggal_lahir: data.results.tanggal_lahir,
                },
                // tanggal_surat_desa: data.results.tanggal_surat,
                showBio:true,
              })
            }
          });

          break;
        default:
          // no default
          break;
      }
    }
    else{
      switch (jenis) {
        case "usaha":
          this.setState({ 
            usahaStat: null,
            dataLapor:[],
            showBio:false,
          });
          break;
        default:
          // no default
          break;
      }
    }
  };

  handleInputUsaha = e => {
    let newArray = this.state.frmUsaha;

    newArray = newArray.filter(day => day !== e);
    this.setState({
      frmUsaha:newArray,
    })
  };

  
  render() {
    console.log('datanyaaa')
    console.log(this.state.id_jenis_pelayanan)
    console.log(this.state.active_tab_icon)
    console.log(this.state.cek_posisi)
    console.log(this.state.data)
    console.log(this.state.dataVerifikasi)
    console.log(this.state.dataDisetujui)
    console.log(this.state.dataDitolak)
    console.log(this.state.dataSelesai)
    console.log(this.state.dataLapor)
    // var from = this.state.pagination.from;
    // var page = this.state.pagination.current;
    // var to = this.state.pagination.to;
    // var total = this.state.pagination.total;
    var data = this.state.data;
    var dataVerifikasi = this.state.dataVerifikasi;
    var dataDisetujui = this.state.dataDisetujui;
    var dataDitolak = this.state.dataDitolak;
    var dataSelesai = this.state.dataSelesai;

    // var no = 0;
    // const frmDef = {
    //   id: 0,
    //   idbaru: "",
    //   id_keldes: "",
    //   nama: ""
    // };
    // const { SearchBar } = Search;
   
    // const perihal = [
    //   { label: "Izin Usaha", value: "Izin Usaha" },
    //   { label: "SITU", value: "SITU" },
    //   { label: "IMB", value: "IMB"},
    //   { label: "TDP", value: "TDP" },
    //   { label: "SIUP", value: "SIUP" },
    // ];

    // const [selected, setSelected] = useState([]);

  

    // Data diproses
    const columns = [
      {
          dataField: "no",
          text: "No",
          isDummyField: true,
          csvExport: false,
          headerAlign: 'center',
				  align: 'center',
          formatter: (key, obj, index) =>{ 
              let current_pagenum = '';
              let total_records_per_page = '';
              let row_index = '';
              let serial_num = '';

              if(this.state.active_tab_icon ==='1'){
                current_pagenum = this.state.pagination.current_page;
                total_records_per_page = this.state.pagination.per_page;
                row_index = (index+1);
                serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              }    
              else if(this.state.active_tab_icon ==='2'){
                current_pagenum = this.state.paginationDiverifikasi.current_page;
                total_records_per_page = this.state.paginationDiverifikasi.per_page;
                row_index = (index+1);
                serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              }    
              else if(this.state.active_tab_icon ==='3'){
                current_pagenum = this.state.paginationDisetujui.current_page;
                total_records_per_page = this.state.paginationDisetujui.per_page;
                row_index = (index+1);
                serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              }    
              else if(this.state.active_tab_icon ==='4'){
                current_pagenum = this.state.paginationDitolak.current_page;
                total_records_per_page = this.state.paginationDitolak.per_page;
                row_index = (index+1);
                serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              }    
              else if(this.state.active_tab_icon ==='5'){
                current_pagenum = this.state.paginationSelesai.current_page;
                total_records_per_page = this.state.paginationSelesai.per_page;
                row_index = (index+1);
                serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              }    
              
              return serial_num; 
          }
      },
      {
        // dataField: "data_pelayanan.tanggal_surat_desa",
        dataField: "create_at",
        text: "Tanggal",
        formatter: (cell, obj) => {
          let plainTime = '-'
					if (obj.create_at) {
						let date = new Date(obj.create_at)
						date = date.toLocaleString('id-ID')
						
						const datetime = date.split(' ')
	
						date = datetime[0]
						let time = datetime[1]
	
						const splitDate = date.split('/')
						time = time.replaceAll('.', ':')
	
						const list_bulan = [
							'Januari',
							'Februari',
							'Maret',
							'April',
							'Mei',
							'Juni',
							'Juli',
							'Agustus',
							'September',
							'Oktober',
							'November',
							'Desember'
						];

						plainTime = `${splitDate[0]} ${list_bulan[parseInt(splitDate[1]) - 1]} ${splitDate[2]}  ${time}`;
					}

					return plainTime
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "data_pelayanan.nomor_surat_desa",
        text: "Nomor surat",
        formatter: (cell, obj) => {
          let isinya = JSON.parse(obj.data_pelayanan)
          return isinya.nomor_surat_desa === "" ? "-" : isinya.nomor_surat_desa;
        },
        sort: true
      },
      {
        dataField: "nik_pelapor",
        text: "NIK Pemohon",
        sort: true
      },
      {
        dataField: "nama_pelapor",
        text: "Nama Pemohon",
        sort: true
      },
      {
        dataField: "data_status_baru.status",
        text: "Status",
        sort: true
      },
      {
        dataField: "aksi",
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksiVerifikasi
      }
    ];

    return (
      <div>
        {/* <Breadcrumb title="Data Surat Keterangan Penghasilan" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="info"
          onCancel={this.closeAlert}
          showConfirm={false}
        >
          {this.state.pesanAlert}
          {this.state.successAlert ? (
            <div>
              <br />
              <Button
                color="success"
                onClick={() => {
                  this.state.showBio= false;
                  this.state.dusStat= null;
                  this.state.rwStat= null;
                  this.state.rtStat =null;
                  this.closeAlert();
                  this.bukaForm();
                  this.forceUpdate();
                }}
              >
                Tambah Data
              </Button>{" "}
              <Button
                color="info"
                // onClick={() => {
                //   this.closeAlert();
                // }}
              >
                <Link
                  to={{
                    pathname: process.env.PUBLIC_URL + "/admin/disposisi",
                    tabProps: "2"
                  }}
                >
                  <span style={{ color: "white" }}>Eksekusi Surat</span>
                </Link>
              </Button>
            </div>
          ) : (
            ""
          )}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                {/* <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      <h5>Data Disposisi Surat</h5>
                    </div>
                    <div className="col-sm-6 text-right">
                    </div>
                  </div>
                </div> */}
                <div className="card-body datatable-react">
                  <Nav tabs className="tabs-color">
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "1"
                        })}
                        onClick={() => {
                          this.toggle_icon("1");
                        }}
                      >
                        <i className="fa fa-hourglass-half"></i>
                        
                        Diajukan
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "2"
                        })}
                        onClick={() => {
                          this.toggle_icon("2");
                        }}
                      >
                        <i className="fa fa-tasks"></i>

                        Diverifikasi
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "3"
                        })}
                        onClick={() => {
                          this.toggle_icon("3");
                        }}
                      >
                        <i className="fa fa-check"></i>

                        Disetujui
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "4"
                        })}
                        onClick={() => {
                          this.toggle_icon("4");
                        }}
                      >
                        <i className="fa fa-times"></i>

                        Ditolak
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "5"
                        })}
                        onClick={() => {
                          this.toggle_icon("5");
                        }}
                      >
                        <i className="fa fa-check"></i>

                        Selesai
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.active_tab_icon}>
                    <TabPane tabId="1">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                          <div className="row" style={{ marginBottom: 15 }}>
                            <div className="col-sm-4 text-left">
                              {/* <button 
                                color="success" 
                                className="btn btn-secondary"
                                onClick={() => {
                                  this.bukaForm()
                                }}
                              >Tambah Surat</button> */}
                              <Button
                                color="success"
                                className="btn btn-success"
                                onClick={() => {
                                  this.setState({ judul: 'Data Rekomendasi Usaha' })
                                  this.setState({ 
                                    uuid_surat: null,
                                    dataPemohon: null,
                                    detailKK: null,
                                    frmKbkt: [],
                                    frmKec: [],
                                    frmKel: [],
                                    kepalaKeluarga: '',
                                    dtPindah: {
                                      alasan_pindah: '',
                                      alamat: '',
                                      rt: '',
                                      rw: '',
                                      kode_pos: '',
                                      telepon: '',
                                      tgl_pindah: null,
                                    },
                                    linkGambar: strukturGambar,
                                    dataLappor: [],
                                    dt: [],
                                    noSuratSelect: true,
                                    dusSelect: true,
                                    isUpdate: false,
                                  })
                                  this.bukaForm();
                                }}
                              >
                                Tambah Surat
                              </Button>
                            </div>
                            <div className="col-sm-8 text-right">
                              <button onClick={this.props.history.goBack} color="info" className="btn btn-info"><i className="fa fa-arrow-left"></i> Kembali</button>
                            </div>
                          </div>
                          
                            <BootstrapTable
                              keyField="uuid"
                              data={data}
                              columns={columns}
                            />
                            <div className="pull-right text-white">
                              {this.state.awal}
                              {this.state.sebelum}
                              {this.state.hal.map(dt => {
                                return dt;
                              })}
                              {this.state.setelah}
                              {this.state.akhir}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                            <div className="row" style={{ marginBottom: 15 }}>
                              <div className="col-sm-4 text-left">
                              </div>
                              <div className="col-sm-8 text-right">
                                <button onClick={this.props.history.goBack} color="info" className="btn btn-info"><i info="fa fa-arrow-left"></i> Kembali</button>
                              </div>
                            </div>
                            
                              <BootstrapTable
                                keyField="uuid"
                                data={dataVerifikasi}
                                columns={columns}
                              />
                              <div className="pull-right text-white">
                                {this.state.awal2}
                                {this.state.sebelum2}
                                {this.state.hal2.map(dt => {
                                  return dt;
                                })}
                                {this.state.setelah2}
                                {this.state.akhir2}
                              </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                            <div className="row" style={{ marginBottom: 15 }}>
                              <div className="col-sm-4 text-left">
                              </div>
                              <div className="col-sm-8 text-right">
                                <button onClick={this.props.history.goBack} color="info" className="btn btn-info"><i info="fa fa-arrow-left"></i> Kembali</button>
                              </div>
                            </div>
                            
                              <BootstrapTable
                                keyField="uuid"
                                data={dataDisetujui}
                                columns={columns}
                              />
                              <div className="pull-right text-white">
                                {this.state.awal3}
                                {this.state.sebelum3}
                                {this.state.hal3.map(dt => {
                                  return dt;
                                })}
                                {this.state.setelah3}
                                {this.state.akhir3}
                              </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="4">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                            <div className="row" style={{ marginBottom: 15 }}>
                              <div className="col-sm-4 text-left">
                              </div>
                              <div className="col-sm-8 text-right">
                                <button onClick={this.props.history.goBack} color="info" className="btn btn-info"><i info="fa fa-arrow-left"></i> Kembali</button>
                              </div>
                            </div>
                            
                              <BootstrapTable
                                keyField="uuid"
                                data={dataDitolak}
                                columns={columns}
                              />
                              <div className="pull-right text-white">
                                {this.state.awal4}
                                {this.state.sebelum4}
                                {this.state.hal4.map(dt => {
                                  return dt;
                                })}
                                {this.state.setelah4}
                                {this.state.akhir4}
                              </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="5">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                            <div className="row" style={{ marginBottom: 15 }}>
                              <div className="col-sm-4 text-left">
                              </div>
                              <div className="col-sm-8 text-right">
                                <button onClick={this.props.history.goBack} color="info" className="btn btn-info"><i info="fa fa-arrow-left"></i> Kembali</button>
                              </div>
                            </div>
                            
                              <BootstrapTable
                                keyField="uuid"
                                data={dataSelesai}
                                columns={columns}
                              />
                              <div className="pull-right text-white">
                                {this.state.awal5}
                                {this.state.sebelum5}
                                {this.state.hal5.map(dt => {
                                  return dt;
                                })}
                                {this.state.setelah5}
                                {this.state.akhir5}
                              </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                  </TabContent>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <Modal
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: "100%" } }}
				>
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-header" align="center">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>

            <div className="modal-body">
              
              {/* <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>NIK Pelapor</b>
                </Label>
                <div className="col-sm-8">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={e => {
                      this.getPenduduk(e, "nik");
                    }}
                    defaultValue={this.state.pendStat}
                    value={this.state.pendStat}
                    name="penduduk"
                    onInputChange={e => this.handleInputChangeNIK(e, "nik")}
                    options={this.state.frmPend}
                    placeholder="Pilih NIK Pelapor"
                    isClearable
                  />
                </div>
              </FormGroup> */}

              {/* Data Surat */}
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>DATA SURAT</b>
                </Label>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>No. Surat {JENIS_DESA}</b>
                  </Label>
                  <div className="col-sm-8">
                    {this.state.noSuratSelect ? (
                      <Select
                        isDisabled={this.state.status.select}
                        classNamePrefix="select"
                        onChange={e => {
                          this.getUsaha(e, "usaha");
                        }}
                        defaultValue={this.state.usahaStat}
                        value={this.state.usahaStat}
                        name="penduduk"
                        onInputChange={e => this.handleInputUsaha(e)}
                        options={this.state.frmUsaha}
                        placeholder="Pilih Nomor Surat"
                        isClearable
                      />
                    ) : (
                      <input
                        className="form-control"
                        id="nomor_surat_desa"
                        type="text"
                        placeholder="Nomor Surat Desa"
                        defaultValue={this.state.dt.nomor_surat_desa}
                        value={this.state.dt.nomor_surat_desa}
                        required
                      />
                    )}
                    <h6 style={{ fontSize: '13px', margin: '0px' }}>
                      <a href="/" 
                        onClick={(e) => { 
                          e.preventDefault()
                          this.setState({ 
                            noSuratSelect: !this.state.noSuratSelect, 
                            usahaStat: null 
                          })
                        }} >{this.state.noSuratSelect ? 'Input Nomor Surat Manual' : 'Pilih Nomor Surat yang tersedia'}</a>
                    </h6>
                  </div>
              </FormGroup>

              <div
                style={{ display: this.state.noSuratSelect && this.state.usahaStat ? 'block' : !this.state.noSuratSelect ? 'block' : 'none' }}
              >
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Tanggal Surat {JENIS_DESA}</b>
                  </Label>
                  <div className="col-sm-8">
                    {this.state.noSuratSelect ? (
                      <input
                        className="form-control"
                        id="tanggal_surat_desa"
                        readOnly
                        type="text"
                        placeholder="tanggal_surat_desa"
                        defaultValue={this.state.dataLapor.tanggal_surat_desa}
                        value={this.state.dataLapor.tanggal_surat_desa}
                        style={{ backgroundColor:"#c9d1d8" }}
                        disabled
                      />
                    ) : (
                      <Datetime
                        locale="id-ID"
                        dateFormat="DD/MM/yyyy"
                        input={true}
                        timeFormat={false}
                        closeOnTab={true}
                        closeOnSelect={true}
                        value={this.state.dt.tanggal_surat_desa}
                        initialValue={this.state.dt.tanggal_surat_desa}
                        onChange={e => {
                          this.gantiTgl(e,'tgl_surat_desa')
                        }}
                        inputProps={{
                          placeholder: 'Tanggal Surat Desa',
                          required: true
                        }}
                      />
                    )}

                  </div>
                </FormGroup>
                {/* Data Pelapor */}
               <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>DATA PELAPOR</b>
                </Label>
               </FormGroup>
               <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    NIK
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  {this.state.noSuratSelect ? (
                    <input
                      className="form-control"
                      id="nik_pelapor"
                      readOnly={this.state.noSuratSelect ? true : false}
                      type="number"
                      placeholder="NIK Pelapor"
                      value={this.state.dataLapor.nik}
                      style={{ backgroundColor: this.state.noSuratSelect ? "#c9d1d8" : "white" }}
                      required
                      // disabled
                    />
                    ):(
                      <input
                        className="form-control"
                        id="nik_pelapor"
                        readOnly={this.state.noSuratSelect ? true : false}
                        type="number"
                        placeholder="NIK Pelapor"
                        defaultValue={this.state.dataLapor.nik}
                        style={{ backgroundColor: this.state.noSuratSelect ? "#c9d1d8" : "white" }}
                        required
                        // disabled
                      />
                    )}
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Nama
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  {this.state.noSuratSelect ? (
                    <input
                      className="form-control"
                      id="nama_pelapor"
                      readOnly={this.state.noSuratSelect ? true : false}
                      type="text"
                      placeholder="Nama Pemohon"
                      value={this.state.dataLapor.nama}
                      style={{ backgroundColor: this.state.noSuratSelect ? "#c9d1d8" : "white" }}
                      required
                    />
                    ):(
                    <input
                      className="form-control"
                      id="nama_pelapor"
                      readOnly={this.state.noSuratSelect ? true : false}
                      type="text"
                      placeholder="Nama Pemohon"
                      defaultValue={this.state.dataLapor.nama}
                      style={{ backgroundColor: this.state.noSuratSelect ? "#c9d1d8" : "white" }}
                      required
                    />
                  )}
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Tanggal Lahir
                  </Label>
                  <div className="col-sm-8">
                    {this.state.noSuratSelect ? (
                      <input
                        className="form-control"
                        id="tanggal_lahir_pelapor"
                        readOnly
                        type="text"
                        placeholder="Tanggal Lahir Pelapor"
                        value={this.state.dataLapor.tanggal_lahir}
                        style={{ backgroundColor:"#c9d1d8" }}
                        // disabled
                      />
                    ) : (
                      <Datetime
                        locale="id-ID"
                        dateFormat="DD/MM/yyyy"
                        input={true}
                        timeFormat={false}
                        closeOnTab={true}
                        closeOnSelect={true}
                        value={this.state.dt.tanggal_lahir_pelapor}
                        initialValue={this.state.dt.tanggal_lahir_pelapor}
                        onChange={e => {
                          this.gantiTgl(e,'tgl_lahir_pelapor')
                        }}
                        inputProps={{
                          placeholder: 'Tanggal Lahir Pelapor',
                          required: true
                        }}
                      />
                    )}
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Agama
                  </Label>
                  <div className="col-sm-8">
                    {this.state.noSuratSelect ? (
                      <input
                        className="form-control"
                        id="agama_pelapor"
                        readOnly
                        type="text"
                        placeholder="Agama Pemohon"
                        defaultValue={this.state.dataLapor.agama}
                        value={this.state.dataLapor.agama}
                        style={{ backgroundColor: "#c9d1d8" }}
                        required
                      />
                    ) : (
                      <Select
												classNamePrefix="select"
												onChange={e => {
													this.setState({ agamaStat: e })
												}}
												defaultValue={this.state.agamaStat}
												value={this.state.agamaStat}
												name="penduduk"
												options={[
													{value: 'Islam', label: 'Islam'},
													{value: 'Kristen Protestan', label: 'Kristen Protestan'},
													{value: 'Katolik', label: 'Katolik'},
													{value: 'Hindu', label: 'Hindu'},
													{value: 'Buddha', label: 'Buddha'},
													{value: 'Kong Hu Cu', label: 'Kong Hu Cu'},
												]}
												placeholder="Pilih Agama Pelapor"
												isClearable
											/>
                    )}
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Jenis Kelamin
                  </Label>
                  <div className="col-sm-8">
                    {this.state.noSuratSelect ? (
                      <input
                        className="form-control"
                        id="jenis_kelamin_pelapor"
                        readOnly
                        type="text"
                        placeholder="Jenis Kelamin Pelapor"
                        defaultValue={this.state.dataLapor.jenis_kelamin}
                        value={this.state.dataLapor.jenis_kelamin}
                        style={{ backgroundColor: "#c9d1d8" }}
                        required
                      />
                    ) : (
                      <Select
												classNamePrefix="select"
												onChange={e => {
													this.setState({ jenisKelaminStat: e })
												}}
												defaultValue={this.state.jenisKelaminStat}
												value={this.state.jenisKelaminStat}
												name="penduduk"
												options={[
													{value: 'Laki-Laki', label: 'Laki-Laki'},
													{value: 'Perempuan', label: 'Perempuan'},
												]}
												placeholder="Pilih Jenis Kelamin Pelapor"
												isClearable
											/>
                    )}
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Status Perkawinan
                  </Label>
                  <div className="col-sm-8">
                    {this.state.noSuratSelect ? (
                      <input
                        className="form-control"
                        id="status_perkawinan_pelapor"
                        readOnly
                        type="text"
                        placeholder="Status Perkawinan Pelapor"
                        defaultValue={this.state.dataLapor.status_perkawinan}
                        value={this.state.dataLapor.status_perkawinan}
                        style={{ backgroundColor: "#c9d1d8" }}
                        required
                      />
                    ) : (
                      <Select
												classNamePrefix="select"
												onChange={e => {
													this.setState({ statusPerkawinanStat: e })
												}}
												defaultValue={this.state.statusPerkawinanStat}
												value={this.state.statusPerkawinanStat}
												name="penduduk"
												options={[
													{value: 'Belum kawin', label: 'Belum kawin'},
													{value: 'Kawin Tercatat', label: 'Kawin Tercatat'},
													{value: 'Kawin Belum Tercatat', label: 'Kawin Belum Tercatat'},
													{value: 'Cerai hidup', label: 'Cerai hidup'},
													{value: 'Cerai mati', label: 'Cerai mati'},
												]}
												placeholder="Pilih Status Perkawinan Pelapor"
												isClearable
											/>
                    )}
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Tempat Lahir
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  {this.state.noSuratSelect ? (
                    <input
                      className="form-control"
                      readOnly={this.state.noSuratSelect ? true : false}
                      id="tempat_lahir"
                      type="text"
                      placeholder="Tempat lahir"
                      value={this.state.dataLapor.tempat_lahir}
                      style={{ backgroundColor: this.state.noSuratSelect ? "#c9d1d8" : "white" }}
                      required
                    />
                    ):(
                    <input
                      className="form-control"
                      readOnly={this.state.noSuratSelect ? true : false}
                      id="tempat_lahir"
                      type="text"
                      placeholder="Tempat lahir"
                      defaultValue={this.state.dataLapor.tempat_lahir}
                      style={{ backgroundColor: this.state.noSuratSelect ? "#c9d1d8" : "white" }}
                      required
                    />

                  )}
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Pekerjaan
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  {this.state.noSuratSelect ? (
                    <input
                      className="form-control"
                      readOnly={this.state.noSuratSelect ? true : false}
                      id="pekerjaan_pelapor"
                      type="text"
                      placeholder="Contoh : Wiraswasta"
                      value={this.state.dataLapor.pekerjaan}
                      style={{ backgroundColor: this.state.noSuratSelect ? "#c9d1d8" : "white" }}
                      required
                    />
                    ):(
                    <input
                      className="form-control"
                      readOnly={this.state.noSuratSelect ? true : false}
                      id="pekerjaan_pelapor"
                      type="text"
                      placeholder="Contoh : Wiraswasta"
                      defaultValue={this.state.dataLapor.pekerjaan}
                      style={{ backgroundColor: this.state.noSuratSelect ? "#c9d1d8" : "white" }}
                      required
                    />

                  )}
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    Alamat
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  {this.state.noSuratSelect ? (
                    <input
                      className="form-control"
                      readOnly={this.state.noSuratSelect ? true : false}
                      id="alamat_pelapor"
                      type="text"
                      placeholder="Contoh : Jalan Garuda Sakti KM. 5 No. 17"
                      value={this.state.dataLapor.alamat}
                      style={{ backgroundColor: this.state.noSuratSelect ? "#c9d1d8" : "white" }}
                      required
                    />
                    ):(
                    <input
                      className="form-control"
                      readOnly={this.state.noSuratSelect ? true : false}
                      id="alamat_pelapor"
                      type="text"
                      placeholder="Contoh : Jalan Garuda Sakti KM. 5 No. 17"
                      defaultValue={this.state.dataLapor.alamat}
                      style={{ backgroundColor: this.state.noSuratSelect ? "#c9d1d8" : "white" }}
                      required
                    />

                  )}
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    No. HP Pelapor
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  {/* {this.state.noSuratSelect ? (
                    <input
                      className="form-control"
                      id="no_hp_pelapor"
                      type="text"
                      placeholder="No. HP Pelapor"
                      value={this.state.dataLapor.no_hp_pelapor}
                      required={(this.state.noSuratSelect && this.state.usahaStat) || !this.state.noSuratSelect}
                    />
                    ):( */}
                    <input
                      className="form-control"
                      id="no_hp_pelapor"
                      type="text"
                      placeholder="No. HP Pelapor"
                      defaultValue={this.state.dataLapor.no_hp_pelapor}
                      required={(this.state.noSuratSelect && this.state.usahaStat) || !this.state.noSuratSelect}
                    />
                  {/* )} */}
                  </div>
                </FormGroup>
              </div>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Perihal</b>
                  </Label>
                  <div className="col-sm-8">
                  {/* <MultiSelect
                    options={perihal}
                    // selected={selected}
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    // onChange={(e) => {
                    //   this.handleChange(e);
                    // }}
                    labelledBy="Pilih Perihal"
                  /> */}

                    <div className="row">
                      <div className="col-sm-4">
                        <input type="checkbox"
                          defaultChecked={this.state.perihal.izin_usaha}
                          checked={this.state.perihal.izin_usaha}
                          onChange={e => {
                            this.changeCheck(e, "izin_usaha");
                          }}
                        /> &nbsp;&nbsp; Izin Usaha
                      </div>
                      <br/>
                      <div className="col-sm-4">
                        <input type="checkbox"
                          defaultChecked={this.state.perihal.imb}
                          checked={this.state.perihal.imb}
                          onChange={e => {
                            this.changeCheck(e, "imb");
                          }}
                        /> &nbsp;&nbsp; IMB
                      </div>
                      <br/>
                      <div className="col-sm-4">
                        <input type="checkbox"
                          defaultChecked={this.state.perihal.siup}
                          checked={this.state.perihal.siup}
                          onChange={e => {
                            this.changeCheck(e, "siup");
                          }}
                        /> &nbsp;&nbsp; SIUP
                      </div>
                      <br/>
                      <div className="col-sm-4">
                        <input type="checkbox"
                          defaultChecked={this.state.perihal.situ}
                          checked={this.state.perihal.situ}
                          onChange={e => {
                            this.changeCheck(e, "situ");
                          }}
                        /> &nbsp;&nbsp; SITU 
                      </div>
                      <br/>
                      <div className="col-sm-4">
                        <input type="checkbox"
                          defaultChecked={this.state.perihal.tdp}
                          checked={this.state.perihal.tdp}
                          onChange={e => {
                            this.changeCheck(e, "tdp");
                          }}
                        /> &nbsp;&nbsp; TDP
                      </div>
                      <br/>
                    </div>
                  </div>
              </FormGroup>

              {/* Data Usaha */}
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>DATA USAHA</b>
                </Label>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Nama Usaha</b>
                  </Label>
                  <div className="col-sm-8">
                    <input
                      id="nama_usaha"
                      className="form-control"
                      style={{
                        marginBottom: '10px',
                        fontWeight: 400,
                        fontSize: 'inherit'
                      }}
                      defaultValue={this.state.dt.nama_usaha}
                      // value={this.state.dt.nama_usaha}
                      required
                      type="text"
                      placeholder="Contoh: CV. Jaya Bersama Abadi"
                    />
                  </div>
              </FormGroup>
              {
                this.state.perihal.imb===true
              ?
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Lokasi Bangunan</b>
                    </Label>
                    <div className="col-sm-8">
                      <input
                        id="lokasi_bangunan"
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                        defaultValue={this.state.dt.lokasi_lokasi_pembangunan}
                        required
                        type="text"
                        placeholder={"Contoh: " + JENIS_DUSUN + " Tani Mulya RT.002/RW.001 Desa Sei Simpang Dua Kec. Kampar Kiri Hilir Kab. Kampar"}
                      />
                    </div>
                </FormGroup>
              : ''}
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Jenis Usaha</b>
                  </Label>
                  <div className="col-sm-8">
                    <input
                      id="jenis_usaha"
                      className="form-control"
                      style={{
                        marginBottom: '10px',
                        fontWeight: 400,
                        fontSize: 'inherit'
                      }}
                      defaultValue={this.state.dt.jenis_usaha}
                      type="text"
                      placeholder="Contoh: Konstruksi Bangunan"
                      required
                    />
                  </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>LOKASI USAHA</b>
                </Label>
               </FormGroup>
              {
                this.state.isKelurahan?
                <>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    RW
                  </Label>
                  <div className="col-sm-8">
                    {/* {this.state.dusSelect ? ( */}
                      <Select
                        isDisabled={this.state.status.select}
                        classNamePrefix="select"
                        onChange={(e) => {
                          this.getChild(e, 'rw', 'rw', 'rt');
                        }}
                        defaultValue={this.state.rwStat}
                        value={this.state.rwStat}
                        name="rw"
                        options={this.state.frmRw}
                        placeholder="Pilih Rw"
                        isClearable
                      /> 
                    {/* ) : (
                      <input
                        id="rw_usaha"
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                        defaultValue={this.state.dt.rw_usaha}
                        type="text"
                        placeholder="Contoh: 006"
                        required
                      />
                    )} */}
                  </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    RT
                  </Label>
                  <div className="col-sm-8">
                    {/* {this.state.dusSelect ? ( */}
                      <Select
                        isDisabled={this.state.status.select}
                        classNamePrefix="select"
                        onChange={(e) => {
                          this.changeSelectValue(e, 'rt');
                        }}
                        defaultValue={this.state.rtStat}
                        value={this.state.rtStat}
                        name="rt"
                        options={this.state.frmRt}
                        placeholder="Pilih Rt"
                        isClearable
                      />
                    {/* ) : (
                      <input
                        id="rt_usaha"
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                        defaultValue={this.state.dt.rt_usaha}
                        type="text"
                        placeholder="Contoh: 012"
                        required
                      />
                    )} */}
                  </div>
                </FormGroup>
                </>
                :
                <>
                  <FormGroup className="row" style={{ marginBottom: '10px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>{JENIS_DUSUN}</b>
                    </Label>
                    <div className="col-sm-8">
                      {this.state.dusSelect ? (
                        <Select
                        isDisabled={this.state.status.select}
                        classNamePrefix="select"
                        onChange={(e) => {
                          this.getChild(e, 'dusun', 'dus', 'rw');
                        }}
                        defaultValue={this.state.dusStat}
                        value={this.state.dusStat}
                        name="lokasi_usaha"
                        options={this.state.frmDus}
                        placeholder={'Pilih ' + JENIS_DUSUN}
                        isClearable
                      />
                      ) : (
                        <input
                          id="dusun_usaha"
                          className="form-control"
                          // style={{
                          //   marginBottom: '10px',
                          //   fontWeight: 400,
                          //   fontSize: 'inherit'
                          // }}
                          defaultValue={this.state.dt.dusun_usaha}
                          type="text"
                          placeholder={"Contoh: " + JENIS_DUSUN + " Kampung Tengah"}
                          required
                        />
                      )}
                      <h6 style={{ fontSize: '13px', margin: '0px' }}>
                        <a href="/" 
                          onClick={(e) => { 
                            e.preventDefault()
                            this.setState({ dusSelect: !this.state.dusSelect })
                          }} >{this.state.dusSelect ? 'Input ' + JENIS_DUSUN + ' Manual' : 'Pilih ' + JENIS_DUSUN + ' yang tersedia'}</a>
                      </h6>
                    </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      RW
                    </Label>
                    <div className="col-sm-8">
                      {this.state.dusSelect ? (
                        <Select
                          isDisabled={this.state.status.select}
                          classNamePrefix="select"
                          onChange={(e) => {
                            this.getChild(e, 'rw', 'rw', 'rt');
                          }}
                          defaultValue={this.state.rwStat}
                          value={this.state.rwStat}
                          name="rw"
                          options={this.state.frmRw}
                          placeholder="Pilih Rw"
                          isClearable
                        /> 
                      ) : (
                        <input
                          id="rw_usaha"
                          className="form-control"
                          style={{
                            marginBottom: '10px',
                            fontWeight: 400,
                            fontSize: 'inherit'
                          }}
                          defaultValue={this.state.dt.rw_usaha}
                          type="text"
                          placeholder="Contoh: 006"
                          required
                        />
                      )}
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '10px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      RT
                    </Label>
                    <div className="col-sm-8">
                      {this.state.dusSelect ? (
                        <Select
                          isDisabled={this.state.status.select}
                          classNamePrefix="select"
                          onChange={(e) => {
                            this.changeSelectValue(e, 'rt');
                          }}
                          defaultValue={this.state.rtStat}
                          value={this.state.rtStat}
                          name="rt"
                          options={this.state.frmRt}
                          placeholder="Pilih Rt"
                          isClearable
                        />
                      ) : (
                        <input
                          id="rt_usaha"
                          className="form-control"
                          style={{
                            marginBottom: '10px',
                            fontWeight: 400,
                            fontSize: 'inherit'
                          }}
                          defaultValue={this.state.dt.rt_usaha}
                          type="text"
                          placeholder="Contoh: 012"
                          required
                        />
                      )}
                    </div>
                  </FormGroup>
                </>
              }
                {Object.keys(this.state.linkGambar).map((attribute) => {
                  let titleFile = 'dokumen'
                  switch (attribute) {
                    case 'surat_keterangan_usaha':
                      titleFile = "Surat Keterangan Usaha"
                      break;
                    default:
                      // no default
                      break;
                  }

                  return (
                    <FormGroup className="row" style={{ marginBottom: '10px' }}>
                      <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                        <b>File {titleFile} (jpg/pdf)</b>
                      </Label>
                      <div
                        className="col-sm-8"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <input id="file-upload" type="file" onChange={(event) => {
                          this.onFileChange(event, attribute)
                        }} accept="image/png, image/jpg, image/jpeg, application/pdf" required={!this.state.isUpdate} />
                        {this.state.linkGambar[attribute].link !== "" ? (
                          this.state.linkGambar[attribute].type === "pdf" ? (
                            <a href={this.state.linkGambar[attribute].link}>Lihat file</a>
                          ) : (
                            <a 
                              href='/'
                              onClick={(e) => {
                                e.preventDefault()
                                let status = { ...this.state.status };
                                status.fotoPersyaratan = true;
                                this.setState({
                                  status,
                                  fotoPersyaratan: this.state.linkGambar[attribute].link,
                                  titlePersyaratan: titleFile,
                                });
                              }}
                              >
                                Lihat file
                            </a>
                          )

                        ) : (
                          ""
                        )}
                      </div>
                    </FormGroup>
                  )
                })}

            </div>
          
            <div className="modal-footer" align="center">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
                >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
                >
                Selesai
              </button>
            </div>
          </Form>
				</Modal>
				
        <Modal
					open={this.state.status.formDetail}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: "100%" } }}
				>
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-header" align="center">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>

            <div className="modal-body">
              {/* Data Pelapor */}
              <div style={{ border: '3px outset #e3641b', borderRadius: '25px', padding:'20px', paddingBottom:'20px',}}>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>DATA PELAPOR</b>
                  </Label>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>NIK Pelapor</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  {this.state.pendStat}
                    {/* <input
                        className="form-control"
                        id="nama_pemohon"
                        readOnly
                        type="text"
                        placeholder="nama_pemohon"
                        // value={this.state.dt.nama}
                        defaultValue={this.state.pendStat}
                        style={{ backgroundColor:"#c9d1d8" }}
                        disabled
                      /> */}
                  </div>
                </FormGroup>
                <div
                  style={{ display: this.state.showBio === false ? "none" : "" }}
                >
                  <input
                    className="form-control"
                    id="nik"
                    type="hidden"
                    readOnly
                    placeholder="NIK"
                    value={this.state.dataLapor.nik}
                  />
                
                  <FormGroup className="row" style={{ marginBottom: '10px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Nama</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    {this.state.dataLapor.nama}
                      {/* <input
                        className="form-control"
                        id="nama_pemohon"
                        readOnly
                        type="text"
                        placeholder="nama_pemohon"
                        // value={this.state.dt.nama}
                        defaultValue={this.state.dt.nama}
                        style={{ backgroundColor:"#c9d1d8" }}
                        disabled
                      /> */}
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '10px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Tempat Lahir</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    {this.state.dataLapor.tempat_lahir}
                      {/* <input
                        className="form-control"
                        readOnly
                        id="tempat_lahir"
                        type="text"
                        placeholder="Tempat lahir"
                        // value={this.state.dt.tempat_lahir}
                        defaultValue={this.state.dt.tempat_lahir}
                        style={{ backgroundColor:"#c9d1d8" }}
                        disabled
                      /> */}
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '10px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Tanggal Lahir</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    {this.state.dataLapor.tanggal_lahir}
                      {/* <input
                        className="form-control"
                        id="tanggal_lahir"
                        readOnly
                        type="text"
                        placeholder="Tanggal Lahir"
                        // value={this.state.dt.tanggal_lahir}
                        defaultValue={this.state.dt.tanggal_lahir}
                        style={{ backgroundColor:"#c9d1d8" }}
                        disabled
                      /> */}
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '10px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>No. HP Pelapor</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {this.state.dataLapor.no_hp_pelapor}
                      {/* <input
                        className="form-control"
                        id="no_hp_pelapor"
                        type="text"
                        placeholder="No. HP Pelapor"
                        // value={this.state.dt.no_hp_pelapor}
                        defaultValue={this.state.dt.no_hp_pelapor}
                        required
                        disabled
                      /> */}
                    </div>
                  </FormGroup>
               
                  </div>
              </div>
              <br/>

              {/* Data Surat */}
              <div style={{ border: '3px outset #e3c21b', borderRadius: '25px', padding:'20px',paddingBottom:'20px',}}>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>DATA SURAT</b>
                  </Label>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>No. Surat {JENIS_DESA}</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    {this.state.dataLapor.nomor_surat_desa}
                      {/* <input
                        id="nomor_surat_desa"
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                        defaultValue={this.state.dt.nomor_surat_desa}
                        required
                        type="text"
                        placeholder="No. Surat Desa"
                        disabled
                      /> */}
                    </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Tanggal Surat {JENIS_DESA}</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    {dateFormat(this.state.tanggal_surat_desa, 'yyyy-mm-dd')}
                    {/* <input
                        className="form-control"
                        id="tanggal_lahir"
                        readOnly
                        type="date"
                        placeholder="Tanggal Lahir"
                        // value={this.state.dt.tanggal_lahir}
                        defaultValue={this.state.tanggal_surat_desa}
                        style={{ backgroundColor:"#c9d1d8" }}
                        disabled
                      /> */}
                    </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Perihal</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <div className="row">
                        <div className="col-sm-4">
                          <input type="checkbox"
                            defaultChecked={this.state.perihal.izin_usaha}
                            checked={this.state.perihal.izin_usaha}
                            onChange={e => {
                              this.changeCheck(e, "izin_usaha");
                            }}
                            disabled
                          />Izin Usaha
                        </div>
                        <div className="col-sm-4">
                          <input type="checkbox"
                            defaultChecked={this.state.perihal.imb}
                            checked={this.state.perihal.imb}
                            onChange={e => {
                              this.changeCheck(e, "imb");
                            }}
                            disabled
                          />IMB
                        </div>
                        <div className="col-sm-4">
                          <input type="checkbox"
                            defaultChecked={this.state.perihal.siup}
                            checked={this.state.perihal.siup}
                            onChange={e => {
                              this.changeCheck(e, "siup");
                            }}
                            disabled
                          />SIUP
                        </div>
                        <div className="col-sm-4">
                          <input type="checkbox"
                            defaultChecked={this.state.perihal.situ}
                            checked={this.state.perihal.situ}
                            onChange={e => {
                              this.changeCheck(e, "situ");
                            }}
                            disabled
                          />SITU 
                        </div>
                        <div className="col-sm-4">
                          <input type="checkbox"
                            defaultChecked={this.state.perihal.tdp}
                            checked={this.state.perihal.tdp}
                            onChange={e => {
                              this.changeCheck(e, "tdp");
                            }}
                            disabled
                          />TDP
                        </div>
                      </div>
                    </div>
                </FormGroup>
              </div>
              <br/>
              
              {/* Data Usaha */}
              <div style={{ border: '3px outset #b4e31b', borderRadius: '25px', paddingBottom:'20px', padding:'20px'}}>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>DATA USAHA</b>
                  </Label>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Nama Usaha</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    {this.state.dt.nama_usaha}
                      {/* <input
                        id="nama_usaha"
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                        defaultValue={this.state.dt.nama_usaha}
                        // value={this.state.dt.nama_usaha}
                        required
                        type="text"
                        placeholder="Contoh: CV. Jaya Bersama Abadi"
                        disabled
                      /> */}
                    </div>
                </FormGroup>
                {
                  this.state.perihal.imb===true
                ?
                  <>
                    <FormGroup className="row" style={{ marginBottom: '10px' }}>
                        <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                          <b>Lokasi Bangunan</b>
                        </Label>
                        <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                        {this.state.dt.lokasi_lokasi_pembangunan}
                          {/* <input
                            id="lokasi_lokasi_pembangunan"
                            className="form-control"
                            style={{
                              marginBottom: '10px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                            defaultValue={this.state.dt.lokasi_lokasi_pembangunan}
                            // value={this.state.dt.lokasi_lokasi_pembangunan}
                            required
                            type="text"
                            placeholder="Contoh: Dusun Tani Mulya RT.002/RW.001 Desa Sei Simpang Dua Kec. Kampar Kiri Hilir Kab. Kampar"
                            disabled
                          /> */}
                        </div>
                    </FormGroup>
                  </>
                :
                  <>
                  </>
                }
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Jenis Usaha</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    {this.state.dt.jenis_usaha}
                      {/* <input
                        id="jenis_usaha"
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                        defaultValue={this.state.dt.jenis_usaha}
                        // value={this.state.dt.jenis_usaha}
                        type="text"
                        placeholder="Contoh: Konstruksi Bangunan"
                        disabled
                      /> */}
                    </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>LOKASI USAHA</b>
                </Label>
               </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>{JENIS_DUSUN}</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                    {this.state.dt.dusun_usaha}
                    {/* <input
                        id="jenis_usaha"
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                        defaultValue={this.state.dusStat}
                        // value={this.state.dt.jenis_usaha}
                        type="text"
                        placeholder="Contoh: Konstruksi Bangunan"
                        disabled
                      /> */}
                    </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '10px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      RW
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {this.state.dt.rw_usaha}
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '10px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      RT
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      {this.state.dt.rt_usaha}
                    </div>
                  </FormGroup>
                </div>

            </div>
          
            <div className="modal-footer" align="center">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
                >
                Tutup
              </button>
            </div>
          </Form>
				</Modal>

        {/* Modal Detail Gambar */}
        <Modal
          open={this.state.status.fotoPersyaratan}
          styles={{
            modal: {
              width: '90%'
            }
          }}
          onClose={() => {
            let status = { ...this.state.status };
            status.fotoPersyaratan = false;
            this.setState({ status });
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title">{this.state.titlePersyaratan}</h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12">
              {this.state.fotoPersyaratan !== '' ? (
                <img
                alt="gambarpersyaratan"
                src={this.state.fotoPersyaratan}
                style={{ width: "100%" }}
              />
              ) : (
                <h6 style={{ textAlign: "center" }}>Tidak ada Foto</h6>
              )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default"
              // disabled={this.state.status.btnForm}
              onClick={() => {
                let status = { ...this.state.status };
                status.fotoPersyaratan = false;
                this.setState({ status });
              }}
              style={{ fontWeight: 600 }}
            >
              Tutup
            </button>
          </div>
        </Modal>
				
      </div>
    );
  }
}

export default KecamatanRekomendasiUsaha;