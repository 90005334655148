import React, { Component } from "react";


import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory, {
//   PaginationProvider,
//   PaginationTotalStandalone
// } from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import { Button, Form, FormGroup, Label, Col } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
// import Select from "react-select";

import { Link, Redirect } from "react-router-dom";
import Datetime from "react-datetime";



// koneksi
import { Post, Get, Delete,Nama_DESA, NAMA_KABUPATEN, KECAMATAN, ALAMAT_KANTOR_DESA, DESA } from "../function/Koneksi";
import { Tanggal, HariTanggal, DataToken } from "../function/Format";
import { logo } from "./asset/base64img";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";



// //console.log(token);
const frmDef = {
  uuid: "",
  id_jenis: "",
  id_pengaju: "",
  sifat: "",
  kepada: "",
  perihal: "",
  nomor: "",
  tanggal_surat: new Date(),
  waktu: new Date(),
  tempat: "",
  acara: [{ nama: "" }],
  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: ""
};
const cetak = {
  nama_desa: "",
  tanggal_surat: "",
  nomor_surat: "",
  sifat: "",
  perihal: "",
  kepada: "",
  tanggal_acara: "",
  jam: "",
  tempat: "",
  acara: [],
  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: ""
};
let jmlAcara = 0;

const dateFormat = require('dateformat');

class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: []
      },
      token: DataToken(sessionStorage.getItem("access_token")),
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDef,
      judul: "Tambah Surat Undangan",
      redirect: false,
      // ============ Jam ==============
      frmJam: [],
      jamStat: { value: "08:00", label: "08:00" },
      // ===================================
      // ============ Alert ==============
      alert: null,
      show: false,
      show2: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      successAlert: false,

      // ===================================
      // ============ Tanggal ==============
      tanggal_surat: new Date(),
      // tanggal_acara: new Date(),
      tanggal_acara: null,
      // ===================================
      // ============ Cetak ==============
      cetak: cetak,
      // ===================================
      // =============== acc ===============
      formAcc: false,
      formSelectAcc: [
        { value: "Disetujui", label: "Disetujui" },
        { value: "Ditolak", label: "Ditolak" }
      ],
      frmTTD: [],
      accButton: [],
      accuuid: "",
      btnFormAcc: true,
      tableAcc: "none"
    };
  }
  // ================== Tanggal ===================

  gantiTgl = (date, fld) => {
    // //console.log(date, fld);
    switch (fld) {
      case "tanggal_surat":
        this.setState({ tanggal_surat: date });
        break;
      case "tanggal_acara":
        if (typeof date._isValid != 'undefined') {
          let tgl = date.format('YYYY-MM-DD HH:mm:ss');
          // this.setState({ tanggal_acara: date });
          this.setState({ tanggal_acara: new Date(tgl) });
        }
        break;
      default:
        // no default
        break;
    }
  };

  // ==============================================

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      show2: false,
      successAlert: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get("surat-undangan" + link, null, dtkat => {
      // //console.log(JSON.parse(dtkat.results.data[0].acara));
      if(dtkat.results){
        this.setState({ data: dtkat.results.data, pagination: dtkat.results });
        this.pagination(dtkat.results);

        // For testing
        if (this.props.getListSuratUndangan) {
          this.props.getListSuratUndangan(dtkat.results.data)
        }
      }
    });
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    //console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false} onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false} onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false} onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false} onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false} onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  // Cari Data Table
  cariData = e => {
    if (e.key === "Enter") {
      let addSuratUndangan = {
        q: e.target.value
      };
      Post("surat-undangan", null, addSuratUndangan, data => {
        this.setState({
          data: data.data.results.data,
          pagination: data.data.results
        });
      });
    }
  };

  // ========================================================================

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    // this.state.status.btnForm = true;
    // this.forceUpdate();

    let acara = [];
    for (let i = 1; i <= jmlAcara; i++) {
      let nama_acara = document.getElementById("acara" + i).value;
      if (nama_acara !== '') {
        acara.push({ nama: nama_acara });
      }
    }

    if (acara.length === 0) {
      this.setState({
        // show: true,
        show2: true,
        basicType: "info",
        basicTitle: "Data Surat Undangan",
        pesanAlert: "Tambahkan data acara terlebih dahulu",
      });
    } else {
      let addSuratUndangan = {
        uuid: document.getElementById("uuid").value,
        id_jenis: this.props.location.state.uuid,
        sifat: document.getElementById("sifat").value,
        perihal: document.getElementById("perihal").value,
        kepada: document.getElementById("kepada").value,
        // nomor : document.getElementById("nomor").value,
        // waktu:
        //   new Date(this.state.tanggal_surat).toISOString().slice(0, 10) +
        //   " " +
        //   this.state.jamStat.value,
        waktu : dateFormat(this.state.tanggal_acara, 'yyyy-mm-dd HH:MM:ss'),
        tempat: document.getElementById("tempat").value,
        acara: JSON.stringify(acara),
        id_pengaju: this.state.token.sub.nik
      };
      
      //console.log("Data Simpan Undangan")
      //console.log(addSuratUndangan)
      let psn = "";
      let resstat = 204;
      let metode = "create";
      if (addSuratUndangan.uuid === "") {
        psn = "Tambah";
        resstat = 201;
        addSuratUndangan.uuid = null;
      } else {
        psn = "Ubah";
        resstat = 200;
        metode = "update";
      }
      Post(
        "surat-undangan/" + metode,
        addSuratUndangan.uuid,
        addSuratUndangan,
        res => {

          // For testing
          if (this.props.resSubmit) {
            this.props.resSubmit(res.status)
          }
          this.tutupForm();
          this.setState({
            status:{
              ...this.state.status,
              btnForm: false,
              form: false,
            }
          })
          this.forceUpdate();
          if (res.status === resstat) {
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Data Surat Undangan",
              pesanAlert: "Berhasil " + psn + " Data",
              successAlert: true
            });
          } else {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Data Surat Undangan",
              pesanAlert: "Gagal " + psn + " Data"
            });
          }
          this.componentDidMount();
        }
      );
    }

  };
  // ========================================================================

  // ============================== Ubah Data ===============================

  ubahData = id => {
    // this.state.status.btnAksi = true;
    this.setState({
      judul: "Ubah Surat Undangan",
    })
    this.forceUpdate();
    // this.componentDidMount();
    Get("surat-undangan/find", id, data => {
      console.log(data)
      let tgl = data.results.tanggal_surat;
      let wkt = data.results.waktu;
      // //console.log(wkt.slice(11,16));
      this.setState({
        tanggal_surat: new Date(tgl),
        tanggal_acara: new Date(wkt),
        jamStat: { value: wkt.slice(11, 16), label: wkt.slice(11, 16) },
        dt:{
          ...this.state.dt,
          uuid: data.results.uuid,
          id_jenis: data.results.id_jenis,
          id_pengaju: data.results.id_pengaju,
          sifat: data.results.sifat,
          kepada: data.results.kepada,
          perihal: data.results.perihal,
          nomor: data.results.nomor,
          tanggal_surat: data.results.tanggal_surat,
          waktu: data.results.waktu,
          tempat: data.results.tempat,
          status_ttd: data.results.status_ttd,
          jabatan_ttd: data.results.jabatan_ttd,
          jabatan_pengganti_ttd: data.results.jabatan_pengganti_ttd,
          nama_ttd: data.results.nama_ttd,
          nip_ttd: data.results.nip_ttd,
          acara: JSON.parse(data.results.acara),
        },
      });
      // this.setState({tanggal_surat : new Date(data.results.tanggal_surat), tanggal_acara : new Date(data.results.waktu), jamStat : {value : new Date(data.results.waktu).slice(11,4), label:new Date(data.results.waktu).slice(11,4)}});
      // this.state.status.btnAksi = false;
      this.forceUpdate();
      // this.componentDidMount();
      this.bukaForm();
      // //console.log(data.results.waktu);
    });
  };
  // ========================================================================
  // ============================= Approve Data ===============================
  konfirmApprove = id => {
    this.setState({
      judul: "Persetujuan Data ",
      formAcc: true,
      btnFormAcc: true,
      idaccSurat: id
    });
  };
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("surat-undangan/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Sura tUndangan",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Surat Undangan",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.setState({
      status:{
        ...this.state.status,
        form: true,
      }
    })
    this.forceUpdate();
  };

  tutupForm = () => {
    this.setState({
      dt:{
        ...this.state.dt,
        acara: [{ nama: "" }],
      },
      status:{
        ...this.state.status,
        form: false,
      },
      tanggal_acara: new Date(),
      jamStat: { value: "08:00", label: "08:00" },
      accStat: [],
      tableAcc: "none",
      formAcc: false,
    })
    this.forceUpdate();
  };

  persetujuan = (id, jawaban) => {
    this.hideAlert();
    Get("surat-undangan/persetujuan/" + jawaban + "/" + id, null, res => {
      if (res.code === 201) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Menyetujui Surat",
          pesanAlert: "Berhasil update status"
        });
      } else {
        this.setState({
          show: true,
          basicType: "warning",
          basicTitle: "Menyetujui Surat",
          pesanAlert: "Gagal update status"
        });
      }
      this.componentDidMount();
    });
  };

  tombolAksi = (cell, row) => {
    // let btnApproval = false;
    // let btnCetak = false;
    let btnAksi = false;
    let status = "";
    row.data_status.forEach(dt => {
      status = dt.status;
    });
    if (status === "Diajukan") {
      // btnCetak = true;
      btnAksi = true;
      // btnApproval = true;
    } else {
      btnAksi = true;
      // btnApproval = true;
      if (status === "Ditolak") {
        // btnCetak = true;
      } else if (status === "Disetujui") {
        // btnCetak = true;
        btnAksi = false;
        // btnApproval = false;
      } else if ((status === "Menunggu Penerbitan") | (status === "Selesai")) {
        // btnAksi = false;
        // btnCetak = false;
        // btnApproval = false;
      } else {
        // btnCetak = true;
      }
    }
    return (
      <>
        {/* <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={() => this.konfirmApprove(row.uuid)}
          disabled={btnApproval}
        >
          <i className="fa fa-check"></i>
        </Button>{" "}
        &nbsp;&nbsp; */}
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        {/* &nbsp;&nbsp;
        <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>{" "}
         */}
        &nbsp;&nbsp;
        {/* <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={e => this.cetakSurat(e, row)}
          disabled={btnCetak}
        >
          <i className="fa fa-print"></i>
        </Button> */}
      </>
    );
  };

  changeAcara = e => {
    document.getElementById(e.target.id).value = e.target.value;
  };

  tambahAcara = e => {
    jmlAcara++;
    let acara = [];
    for (let i = 1; i < jmlAcara; i++) {
      let nama_acara = document.getElementById("acara" + i).value;
      acara.push({ nama: nama_acara });
    }
    acara.push({ nama: "" });
    this.setState({
      dt:{
        ...this.state.dt,
        acara: acara,
      }
    })
    this.forceUpdate();
    // For testing
    if (this.props.getDataAcara) {
      this.props.getDataAcara(acara)
    }
  };

  componentDidMount() {
    if (this.props.location.state) {
      this.fetch({ page: null, where: [] });
      let frmJam = [];
      let jamnya = "";
      for (let i = 1; i <= 24; i++) {
        jamnya = i;
        if (i < 10) {
          jamnya = "0" + i;
        }
        jamnya += ":00";
        frmJam.push({ value: jamnya, label: jamnya });
      }
      this.setState({ frmJam });
      Get("tanda-tangan/list", null, data => {
        data.results.forEach((dt, i) => {
          if (dt.aktif === 1) {
            let temp = this.state.accButton.forEach(x => 1);
            temp[i] = 0;
            this.setState({
              accButton: temp,
              accuuid: dt.uuid,
            })
          }
        });
        this.forceUpdate();
        this.setState({ frmTTD: data.results });
      });
    } else {
      this.setState({ redirect: true });
    }
  }

  // cek redirect jiksa state kosong
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };

  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "jam":
          this.setState({ 
            jamStat: e, 
            dt:{
              ...this.state.dt,
              jam: e.value,
            }
          });
          break;
        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        case "jam":
          this.setState({ 
            jamStat: { value: "08:00", label: "08:00" }, 
            dt:{
              ...this.state.dt,
              jam: 0,
            }
          });
          break;
        default:
          // no default
          break;
      }
    }

    this.forceUpdate();
  };
  cetakSurat = (e, data) => {
    e.preventDefault();
    const acara = JSON.parse(data.acara).forEach((dt, i) => {
      return (
        <tr key={i}>
          <td style={{ width: "2%" }}>{i + 1}</td>
          <td style={{ textTransform: "capitalize" }}>
            {dt.nama.toLowerCase()}
          </td>
        </tr>
      );
    });
    this.setState({
      cetak: {
        nama_desa: Nama_DESA,
        tanggal_surat: data.tanggal_surat,
        nomor_surat: data.nomor,
        sifat: data.sifat.toLowerCase(),
        perihal: data.perihal.toLowerCase(),
        kepada: data.kepada.toLowerCase(),
        tanggal_acara: data.waktu,
        jam: data.waktu.slice(11, 16),
        tempat: data.tempat.toLowerCase(),
        acara: acara,
        status_ttd: data.status_ttd,
        jabatan_ttd: data.jabatan_ttd,
        jabatan_pengganti_ttd: data.jabatan_pengganti_ttd,
        nama_ttd: data.nama_ttd,
        nip_ttd: data.nip_ttd ? "NIP. " + data.nip_ttd : ""
      }
    });
    setTimeout(function() {
      const content = document.getElementById("cetak-surat").innerHTML;
      const mywindow = window.open("", "Print", "height=600,width=1000");

      mywindow.document.write("<html><head><title>Print</title>");
      mywindow.document.write("</head><body >");
      mywindow.document.write(content);
      mywindow.document.write("</body></html>");

      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
      setTimeout(function() {
        mywindow.close();
      }, 1000);
    }, 100);
  };

  //change select
  changeAcc = (e, sel) => {
    if (e) {
      switch (sel) {
        case "status-acc":
          this.setState({
            accStat: e,
          })
          break;
        default:
          // no default
          break;
      }
      if (e.value === "Disetujui") {
        this.setState({
          tableAcc: "",
          btnFormAcc: true,
        })
        if (this.state.accButton.length > 0) {
          this.setState({
            btnFormAcc: false,
          })
        }
      } else {
        this.setState({
          tableAcc: "none",
          btnFormAcc: false,
        })
      }
    } else {
      switch (sel) {
        case "status-acc":
          this.setState({
            accStat: e,
          })
          break;
        default:
          // no default
          break;
      }
      this.setState({
        tableAcc: "none",
        btnFormAcc: true,
      })
    }
    this.setState({
      status:{
        ...this.state.status,
        select: false,
      }
    })
    this.forceUpdate();
  };

  simpanAcc = e => {
    e.preventDefault();
    this.setState({
      btnFormAcc: true,
    })

    let add = {
      tanggal_surat: new Date(this.state.tanggal_surat)
        .toISOString()
        .slice(0, 10),
      uuid: this.state.accuuid
    };

    if (this.state.accStat.value === "Disetujui") {
      Post(
        "surat-undangan/persetujuan/" +
          this.state.accStat.value +
          "/" +
          this.state.idaccSurat,
        null,
        add,
        res => {
          if (res.status === 201) {
            this.setState({
              formAcc: false,
              show: true,
              basicType: "success",
              basicTitle: "Menyetujui Surat",
              pesanAlert: "Berhasil update status"
            });
          } else {
            this.setState({
              btnFormAcc: false,
              show: true,
              basicType: "warning",
              basicTitle: "Menyetujui Surat",
              pesanAlert: "Gagal update status"
            });
          }
          this.componentDidMount();
        }
      );
    } else if (this.state.accStat.value === "Ditolak") {
      Get(
        "surat-undangan/persetujuan/" +
          this.state.accStat.value +
          "/" +
          this.state.idaccSurat,
        null,
        res => {
          if (res.code === 201) {
            this.setState({
              formAcc: false,
              show: true,
              basicType: "success",
              basicTitle: "Tolak Surat",
              pesanAlert: "Berhasil update status"
            });
          } else {
            this.setState({
              show: true,
              btnFormAcc: false,
              basicType: "warning",
              basicTitle: "Tolak Surat",
              pesanAlert: "Gagal update status"
            });
          }
          this.componentDidMount();
        }
      );
    }
    this.tutupForm();
  };

  pilih = (uuid, id) => {
    let temp = this.state.accButton.forEach(x => 1);
    temp[id] = 0;
    this.setState({
      accButton: temp,
      accuuid: uuid,
    })
    if (this.state.accStat) {
      this.setState({
        btnFormAcc: false,
      })
    }
    this.forceUpdate();
    //console.log(this.state.accuuid);
  };
  render() {
    
    //console.log("Data Render")
    //console.log("Data Undangan")
    //console.log("====================")
    // var size = Math.ceil(
    //   this.state.pagination.total / this.state.pagination.per_page
    // );
    // var size = this.state.pagination.total;
    // var from = this.state.pagination.from;
    // var page = this.state.pagination.current;
    // var to = this.state.pagination.to;
    // var total = this.state.pagination.total;
    var data = this.state.data;

    const frmDef = {
      uuid: "",
      id_jenis: "",
      id_pengaju: "",
      sifat: "",
      kepada: "",
      perihal: "",
      nomor: "",
      tanggal_surat: "",
      waktu: "",
      tempat: "",
      acara: [{ nama: "" }],
      status_ttd: "",
      jabatan_ttd: "",
      jabatan_pengganti_ttd: "",
      nama_ttd: "",
      nip_ttd: ""
    };

    // const { SearchBar } = Search;
    const columns = [
      {
          dataField: "no",
          text: "No",
          isDummyField: true,
          csvExport: false,
          headerAlign: 'center',
				  align: 'center',
          formatter: (key, obj, index) =>{          
              let current_pagenum = this.state.pagination.current_page;
              let total_records_per_page = this.state.pagination.per_page;
              let row_index = (index+1);
              let serial_num = ((total_records_per_page*(current_pagenum-1))+row_index);
              return serial_num; 
          }
      },
      // {
      //   dataField: "tanggal_surat",
      //   text: "Tanggal",
      //   formatter: (cell, obj) => {
      //     return obj.tanggal_surat == null
      //       ? "-"
      //       : new Date(obj.tanggal_surat).toLocaleDateString("id-ID");
      //   },
      //   isDummyField: true,
      //   sort: true
      // },
      {
        dataField: "waktu",
        headerAlign: 'center',
				align: 'center',
        text: "Waktu",
        isDummyField: true,
        formatter: (cell, obj) => {
          let plainTime = '-'
					if (obj.created_at) {
						let date = new Date(obj.created_at)
						date = date.toLocaleString('id-ID')
						
						const datetime = date.split(' ')
						
	
						date = datetime[0]
						let time = datetime[1]
	
						const splitDate = date.split('/')
						time = time.replaceAll('.', ':')
	
						console.log(date)
						const list_bulan = [
							'Januari',
							'Februari',
							'Maret',
							'April',
							'Mei',
							'Juni',
							'Juli',
							'Agustus',
							'September',
							'Oktober',
							'November',
							'Desember'
						];

						plainTime = `${splitDate[0]} ${list_bulan[parseInt(splitDate[1]) - 1]} ${splitDate[2]}  ${time}`;
					}

					return plainTime
        },
        sort: true
      },
      {
        dataField: "nomor",
        headerAlign: 'center',
				align: 'center',
        text: "Nomor surat",
        formatter: (cell, obj) => {
          return obj.nomor === "" ? "-" : obj.nomor;
        },
        sort: true
      },
      {
        dataField: "perihal",
        headerAlign: 'center',
				align: 'center',
        text: "Perihal",
        sort: true
      },
      {
        dataField: "tempat",
        headerAlign: 'center',
				align: 'center',
        text: "Tempat",
        sort: true
      },
      {
        dataField: "status",
        headerAlign: 'center',
				align: 'center',
        text: "Status",
        isDummyField: true,
        formatter: (cell, obj) => {
          let status = "-";
          obj.data_status.forEach(dt => {
            status = dt.status;
          });
          this.forceUpdate();
          return status;
        },
        sort: true
      },
      {
        dataField: "aksi",
        headerAlign: 'center',
				align: 'center',
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];
    jmlAcara = 0;
    const acara = this.state.dt.acara.map((dt, i) => {
      let no = i + 1;
      jmlAcara++;
      return (
        <input
          data-testid="acara"
          name="acara"
          className="form-control mb-2"
          key={i}
          id={"acara" + no}
          type="text"
          placeholder={"Acara " + no}
          defaultValue={dt.nama}
        />
      );
    });

    // const defaultSorted = [
    //   {
    //     dataField: "nama",
    //     order: "asc"
    //   }
    // ];

    // const pageButtonRenderer = ({
    //   page,
    //   active,
    //   disable,
    //   title,
    //   onPageChange
    // }) => {
    //   const handleClick = e => {
    //     e.preventDefault();
    //     this.fetch({ where: [], page: page });
    //   };
    //   var classname = "";
    //   classname = "btn btn-primary-custom";
    //   return (
    //     <li className="page-item pl-1" key={page}>
    //       <a href="#" onClick={handleClick} className={classname}>
    //         {page}
    //       </a>
    //     </li>
    //   );
    // };

    // const options = {
    //   alwaysShowAllBtns: true,
    //   pageButtonRenderer,
    //   // custom: true,
    //   paginationTotalRenderer: () => (
    //     <span className="react-bootstrap-table-pagination-total">
    //       Showing {from} to {to} of {total} Results
    //     </span>
    //   ),
    //   paginationSize: size,
    //   totalSize: total
    // };
    let no = 0;
    const tableTTD = this.state.frmTTD.forEach((dt, i) => {
      no++;
      return (
        <tr key={i}>
          <td>{no}</td>
          <td>{dt.jabatan}</td>
          <td>{dt.jabatan_pengganti ? dt.jabatan_pengganti : "-"}</td>
          <td>{dt.nama}</td>
          <td>{dt.nip ? dt.nip : "-"}</td>
          <td>{dt.status ? dt.status : "-"}</td>
          <td>
            <Button
              size="xs"
              onClick={() => this.pilih(dt.uuid, i)}
              color={
                this.state.accButton.length > 0
                  ? this.state.accButton[i] === 0
                    ? "default"
                    : "danger"
                  : "danger"
              }
              className="btn-icon"
            >
              {this.state.accButton.length > 0
                ? this.state.accButton[i] === 0
                  ? "Dipilih"
                  : "Pilih"
                : "Pilih"}
            </Button>
          </td>
        </tr>
      );
    });
    return (
      <div data-testid="page-surat undangan">
        {this.renderRedirect()}
        {/* <Breadcrumb title="Data Surat Undangan" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="info"
          Cancel={this.closeAlert}
          showConfirm={false}
        >
          {this.state.pesanAlert}
          {this.state.successAlert ? (
            <div>
              <br />
              <Button
                color="success"
                onClick={() => {
                  this.closeAlert();
                  this.bukaForm();
                }}
              >
                Tambah Data
              </Button>{" "}
              <Button
                color="info"
                className="btn btn-secondary-custom"
                // onClick={() => {
                //   this.closeAlert();
                // }}
              >
                <Link
                  data-testid="btn-eksekusi_surat"
                  onClick={() => {
                    // For testing
                    if (this.props.clickEksekusiSurat) {
                      this.props.clickEksekusiSurat()
                    }
                  }}
                  to={{
                    pathname: process.env.PUBLIC_URL + "/admin/disposisi",
                    tabProps: "2"
                  }}
                >
                  <span style={{ color: "white" }}>Eksekusi Surat</span>
                </Link>
              </Button>
            </div>
          ) : (
            ""
          )}
        </SweetAlert>

        <SweetAlert
          show={this.state.show2}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Surat Undangan</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      <Button
                        data-testid="tblCreateSuratUndangan"
                        size="sm"
                        color="success"
                        // className="btn-square"
                        onClick={() => {
                          this.setState({
                            judul: "Tambah Surat Undangan",
                            dt: frmDef
                          });
                          this.bukaForm();
                        }}
                      >
                        Tambah Data
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <BootstrapTable keyField="id" data={data} columns={columns} />
                  <div className="pull-right text-white">
                    {this.state.awal}
                    {this.state.sebelum}
                    {this.state.hal.map(dt => {
                      return dt;
                    })}
                    {this.state.setelah}
                    {this.state.akhir}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          open={this.state.status.form}
          onClose={this.tutupForm}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form 
            data-testid="form-create" 
            className="theme-form" 
            onSubmit={this.simpan}
          >
            <div className="modal-header">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>
            <div className="modal-body">
              <p>
                Untuk isian acara, dapat ditambah dengan menekan tombol tambah
                acara dan tidak ada batasan untuk jumlah acara yang ingin
                dimasukkan ke dalam undangan.
              </p>
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Sifat
                </Label>
                <Col sm="9">
                  <input
                    data-testid="sifat"
                    name="sifat"
                    className="form-control"
                    id="sifat"
                    type="text"
                    placeholder="Sifat"
                    defaultValue={this.state.dt.sifat}
                    required
                  />
                </Col>
              </FormGroup>
              {/* <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nomor</Label>
                                <Col sm='9'>
                                    <input className="form-control" id="nomor" type="text" placeholder="Nomor Surat" defaultValue={this.state.dt.nomor}/>
                                </Col>
                            </FormGroup> */}
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Perihal
                </Label>
                <Col sm="9">
                  <input
                    data-testid="perihal"
                    name="perihal"
                    className="form-control"
                    id="perihal"
                    type="text"
                    placeholder="Perihal"
                    defaultValue={this.state.dt.perihal}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Kepada
                </Label>
                <Col sm="9">
                  <input
                    data-testid="kepada"
                    name="kepada"
                    className="form-control"
                    id="kepada"
                    type="text"
                    placeholder="kepada"
                    defaultValue={this.state.dt.kepada}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="tanggal_acara"
                >
                  Tanggal Acara
                </Label>
                <Col sm="9">
                  {/* <Datepicker
                    id="tanggal_acara"
                    todayButton={"Today"}
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.tanggal_acara}
                    onChange={e => this.gantiTgl(e, "tanggal_acara")}
                    className="form-control"
                  /> */}
                  <Datetime
                    inputProps={{ "data-testid": "tanggal_acara", placeholder: 'Waktu Mulai', required: true }}
                    data-testid="tanggal_acara"
                    // name="rencana_berangkat"
                    inputId="tanggal_acara"
                    name="tanggal_acara"
                    id="tanggal_acara"
                    locale="id-ID"
                    input={true}
                    closeOnTab={true}
                    timeFormat="HH:mm"
                    dateFormat="DD/MM/yyyy"
                    // closeOnSelect={true}
                    initialValue={this.state.tanggal_acara}
                    onChange={e => {
                      this.gantiTgl(e, "tanggal_acara");
                    }}
                  />
                </Col>
              </FormGroup>
              {/* <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Jam
                </Label>
                <Col sm="9">
                  <Select
                    isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={e => {
                      this.changeSelectValue(e, "jam");
                    }}
                    defaultValue={this.state.jamStat}
                    value={this.state.jamStat}
                    name="Jam Acara"
                    options={this.state.frmJam}
                    placeholder="Pilih Jam Acara"
                    isClearable
                  />
                </Col>
              </FormGroup> */}
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tempat
                </Label>
                <Col sm="9">
                  <input
                    data-testid="tempat"
                    name="tempat"
                    className="form-control"
                    id="tempat"
                    type="text"
                    placeholder="Tempat Acara"
                    defaultValue={this.state.dt.tempat}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Acara
                </Label>
                <Col sm="9">
                  {acara}
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={this.tambahAcara}
                    data-testid="btnTambahAcara"
                  >
                    Tambah Acara
                  </button>
                </Col>
              </FormGroup>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                data-testid="btnSubmitCreate"
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formAcc}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpanAcc}>
            <div className="modal-header">
              <h5 className="modal-title">Konfirmasi Surat</h5>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Surat
                </Label>
                <Col sm="9">
                  {/* <Datepicker
                    id="tanggal_surat"
                    todayButton={"Today"}
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.tanggal_surat}
                    onChange={e => this.gantiTgl(e, "tanggal_surat")}
                    className="form-control"
                  /> */}
                </Col>
              </FormGroup>

              <Col sm="12">
                <table className="table table-bordered table-stripes table-hovered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Jabatan</th>
                      <th>Jabatan Pengganti</th>
                      <th>Nama</th>
                      <th>NIP</th>
                      <th>Status</th>
                      <th>
                        <i className="fa fa-check"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{tableTTD}</tbody>
                </table>
              </Col>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary-custom">
                Selesai
              </button>
            </div>
          </Form>
        </Modal>
        <div id="cetak-surat" style={{ display: "none" }}>
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    "
            }}
          />
          <table width="100%" border="0">
            <tr>
              <td width="100" align="center">
                <img alt="logo" src={logo} width="80px" />
              </td>
              <td align="center">
                <div style={{ fontWeight: "bold" }}>
                  <span
                    style={{ fontSize: "24px", textTransform: "uppercase" }}
                  >
                    pemerintah {NAMA_KABUPATEN}
                  </span>
                  <br />
                  <span
                    style={{ fontSize: "22px", textTransform: "uppercase" }}
                  >
                    kecamatan {KECAMATAN}
                  </span>
                  <br />
                  <span
                    style={{ fontSize: "28px", textTransform: "uppercase" }}
                  >
                    kantor kepala {Nama_DESA}
                  </span>
                  <br />
                  <span
                    style={{ fontSize: "16px", textTransform: "capitalize" }}
                  >
                    {ALAMAT_KANTOR_DESA}
                  </span>
                </div>
              </td>
            </tr>
          </table>
          <hr style={{ border: "2px solid black" }} />
          <table
            border="0"
            width="90%"
            style={{
              marginTop: "5px",
              marginLeft: "auto",
              marginRight: "auto",
              verticalAlign: "top"
            }}
          >
            <tr>
              <td style={{ paddingBottom: "20px" }}>
                <table border="0" width="100%">
                  <tr>
                    <td width="10%">&nbsp;</td>
                    <td width="1%">&nbsp;</td>
                    <td width="45%">&nbsp;</td>
                    <td width="34%">
                      {DESA}, {Tanggal(new Date(this.state.cetak.tanggal_surat))}
                    </td>
                    <td width="10%">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Nomor</td>
                    <td>:</td>
                    <td>{this.state.cetak.nomor_surat}</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Sifat</td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.sifat}
                    </td>
                    <td>Kepada Yth:</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Perihal</td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.perihal}
                    </td>
                    <td style={{ textTransform: "capitalize" }}>
                      Bapak/Ibu/Sdr {this.state.cetak.kepada}
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td colspan="2">
                      Di-
                      <br />
                      <u style={{ paddingLeft: "30px" }}>TEMPAT</u>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <i>
                  Bissmillahirrahmanirrahim.
                  <br />
                  Assalamu'alaikum Wr wb.
                </i>
                <p style={{ textIndent: "50px" }}>
                  Dengan mengucapkan puji syukur ke hadirat Allah SWT, semoga
                  Bapak/Ibu, Saudara kami jumpai senantiasa dalam lindungan-NYA.
                  Sholawat dan Salam semoga tetap tercurahkan kepada Nabi
                  Muhammad SAW dengan harapan semoga kelak di Yaumil Akhir kita
                  mendapatkan syafa’atnya. Aamiin ya Robbal Alaamiin.
                </p>
                <p style={{ textIndent: "50px" }}>
                  Bersama ini kami mengharapkan kehadiran Bapak/Ibu/Sdr/Sdi pada
                  :
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <table border="0" width="100%">
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Hari/Tanggal
                    </td>
                    <td>:</td>
                    <td>
                      {HariTanggal(new Date(this.state.cetak.tanggal_acara))}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Jam
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.jam}</td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.tempat}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Acara
                    </td>
                    <td>:</td>
                    <td>
                      <table
                        border="0"
                        style={{
                          margin: "0px 0px 0px 0px",
                          padding: "0px 0px 0px 0px"
                        }}
                      >
                        {this.state.cetak.acara}
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <p style={{ textIndent: "50px" }}>
                  Sehubungan dengan hal tersebut di atas diharapkan untuk hadir
                  tempat waktu.
                </p>
                <p style={{ textIndent: "50px" }}>
                  Demikianlah undangan ini kami sampaikan, atas perhatian dan
                  kehadiran Bapak/Ibu/Sdr/Sdi kami ucapkan terima kasih
                </p>
                <i>Wassalamu'alaikum Wr Wb</i>
              </td>
            </tr>
          </table>
          <table border="0" width="100%" style={{ marginTop: "20px" }}>
            <tr>
              <td align="center">&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td width="50%" style={{ textAlign: "center" }}>
                &nbsp;
              </td>
              <td
                width="50%"
                style={{ paddingLeft: "20px", textAlign: "center" }}
              >
                <span style={{ fontWeight: "bold" }}>
                  {this.state.cetak.status_ttd}{" "}
                  <span style={{ textTransform: "uppercase" }}>
                    {this.state.cetak.jabatan_ttd}
                  </span>
                  <br />
                  {this.state.cetak.jabatan_pengganti_ttd}
                </span>
              </td>
            </tr>
            <tr>
              <td height="50">&nbsp;</td>
            </tr>
            <tr>
              <td align="center">&nbsp;</td>
              <td align="center">
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    borderBottom: "1px solid black"
                  }}
                >
                  {this.state.cetak.nama_ttd}
                </span>
                <br />
                {this.state.cetak.nip_ttd}
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
}

export default BasicTable;
