import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Col,
} from 'reactstrap';
// import Datepicker from 'react-datepicker';
import Select from 'react-select';
import Datetime from 'react-datetime';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { Get, JENIS_DUSUN, Post } from '../../function/Koneksi';

const ModalSuratPenghasilan = ({ data, optionData, setAlert, isOpen, setIsOpen, statusRefresh, setStatusRefresh, propsData, kiosk }) => {
	const dateFormat = require('dateformat');

	const frmDex = {
		uuid: '',
		id_jenis: "",
		nik: "",
		nama_pemohon: "",
		tempat_lahir: "",
		tanggal_lahir: "",
		jenis_kelamin: "",
		status_perkawinan: "",
		pekerjaan: "",
		agama: "",
		alamat: "",
		penghasilan: "",
		status_ttd: ''
	}

	const initFormData = {
		frmNik: optionData.frmNik,
		tmplistPenduduk: optionData.tmplistPenduduk,
		nikStat: null,
		frmPkr: optionData.frmPkr,
		status: {
			form: false,
			btnForm: false,
			btnAksi: [],
			dataPenduduk: "none",
		},
		show: false,
		basicTitle: "",
		basicType: "default",
		pesanAlert: "",
	}

	const [formData, setFormData] = useState(initFormData)
	const [props, setProps] = useState(propsData)
	const [kioskMode, setKioskMode] = useState(kiosk)
	const [dt, setDt] = useState(data ? data : frmDex)
	const [draggable, setDraggable] = useState({
		isDown: false,
		startY: null,
		scrollUp: null,
	})

	useEffect(() => {
		if (document.getElementById("modalForm")) {
			document.getElementById("modalForm").reset()
		}
		console.log(data);

		setDt(data ? data : frmDex)
		setFormData(data ? {
			...initFormData,
			nikStat: optionData.nikStat,
			status: optionData.status,
		} : initFormData)
	}, [isOpen])

	const handleInputChangeNIK = (e, fld) => {
		if (e && kioskMode == false) {
			let nikStat = { value: e, label: e };

			Post("penduduk", null, { nik: e }, datanik => {
				let frmNik = [];
        let tmplistPenduduk = {}
				datanik.data.results.data.forEach(dt => {
					frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
					tmplistPenduduk = {
            ...tmplistPenduduk,
            [dt.id]: dt
          }
				});

				switch (fld) {
					case "nik":
						setFormData({
							...formData,
							frmNik,
							tmplistPenduduk,
							nikStat
						})
						break;

					default:
						break;
				}

			});

		}
	};

	const changeInputText = (e, fld) => {
		switch (fld) {
			case "penghasilan":
				setDt({
					...dt,
					penghasilan: e.target.value,
				})
				break;
			default:
				// no default
				break;
		}
	};

	const getPenduduk = (e, sel, jns) => {
		if (e) {
			// this.changeSelectValue(e, sel);
			let getNik = e.value

			const tmpDetailPenduduk = formData.tmplistPenduduk[getNik]
			console.log(formData.tmplistPenduduk);
			console.log(tmpDetailPenduduk);

			// pelapor
			setFormData({
				...formData,
				nikStat: e,
				status: {
					...formData.status,
					dataPenduduk: "block",
				},
			})

			setDt({
				...dt,
				nik: tmpDetailPenduduk.id,
				nik_pelapor: tmpDetailPenduduk.id,
				kk: tmpDetailPenduduk.id_kk,
				nama_pemohon: tmpDetailPenduduk.nama,
				tempat_lahir: tmpDetailPenduduk.tempat_lahir,
				tanggal_lahir: new Date(
					tmpDetailPenduduk.tanggal_lahir
				),
				pekerjaan: tmpDetailPenduduk.data_pekerjaan.nama,
				pendidikan_terakhir: tmpDetailPenduduk.data_pendidikan_terakhir.nama,
				agama: tmpDetailPenduduk.data_agama.nama,
				status_perkawinan: tmpDetailPenduduk.status_perkawinan,
				jenis_kelamin: tmpDetailPenduduk.jk,
				alamat: tmpDetailPenduduk.alamat,
				kewarganegaraan: 'WNI',
			})
			
		} else {
			switch (jns) {
				case "pelapor":
					setDt({
						...dt,
						nama_pemohon: '',
						tempat_lahir: '',
						tanggal_lahir: null,
						pekerjaan: '',
						pendidikan_terakhir: '',
						agama: '',
						status_perkawinan: '',
						jenis_kelamin: '',
						alamat: '',
					})
					setFormData({
						...formData,
						status: {
							...formData.status,
							dataPenduduk: "none"
						},
						nikStat: null
					})
					break;
				default:
					break;
			}
		}
	};

	const simpan = (e) => {
		e.preventDefault();
		console.log('tesdfs');
		console.log(formData);

		if (kioskMode) {
			props.setLoadingPreview(true)
		}

		let bodyRaw = {
			...dt,
			tanggal_lahir: dateFormat(new Date(dt.tanggal_lahir), 'yyyy-mm-dd'),
			penghasilan: document.getElementById('penghasilan').value.replace(/[^\d]/g, ''),
		}
		if (dt.uuid === '') {
			if (kioskMode) {
				bodyRaw.id_jenis = props.id_jenis
			} else {
				bodyRaw.id_jenis = props.location.state.uuid
			}
		}
		console.log(bodyRaw);

		let validasiPelapor = formData.nikStat;

		if (validasiPelapor) {
			//console.log("Data Simpan Pindah Nikah")
			//console.log(bodyRaw)
			console.log(bodyRaw);

			let psn = "";
			let resstat = 204;
			let metode = "create";
			if (bodyRaw.uuid === "") {
				psn = "Tambah";
				resstat = 201;
				bodyRaw.uuid = null;
			} else {
				psn = "Ubah";
				resstat = 200;
				metode = "update";
			}
			Post(
				"surat-keterangan/penghasilan/" + metode,
				bodyRaw.uuid,
				bodyRaw,
				res => {

					// For testing
					if (props.resSubmit) {
						props.resSubmit(res.status)
					}

					// this.tutupForm();
					setFormData({
						...formData,
						status: {
							...formData.status,
							btnForm: false,
							form: false,
						}
					})
					setIsOpen(false)

					// Kalau mode kiosk
					if (kioskMode) {
						let dataAntrian = {
							nama: props.data.results.nama,
							nik: props.data.results.id,
							keterangan: '',
							uuid_surat:res.data.results,
						};

						Get('persetujuan/kiosk/' + dataAntrian.uuid_surat + '/' + props.id_jenis, null,  (res2) => {
							console.log(res2)
							if (res2.code === 201) {
								props.getAntrian('Surat Keterangan Penghasilan', 'berhasil', false, dataAntrian);
							}
							else{
								props.getAntrian('Surat Keterangan Penghasilan', 'gagal');
							}
						});

					} else { // Kalau bukan kiosk
					if (res.status === resstat) {
						const alertData = {
							show: true,
							basicType: "success",
							basicTitle: "Data Surat Keterangan Penghasilan",
							pesanAlert: "Berhasil " + psn + " Data",
							successAlert: true,
						}
						setAlert(alertData)
					} else {
						const alertData = {
							show: true,
							basicType: "danger",
							basicTitle: "Data Surat Keterangan Penghasilan",
							pesanAlert: "Gagal " + psn + " Data"
						}
						setAlert(alertData)
					}

					// Refresh list
					setStatusRefresh(true)
					}
				}
			);
		} else {
			const alertData = {
				show: true,
				basicType: "info",
				basicTitle: "Data Belum Lengkap",
				pesanAlert: "Mohon isi seluruh data dengan benar"
			}
			setAlert(alertData)
		}
	};

	return (
		<Modal
			classNames={{ overlay: 'card-scrollable' }}
			open={isOpen}
			onClose={() => {
				setIsOpen(false)
			}}
			closeOnEsc={false}
			closeOnOverlayClick={false}
			style={{
				maxwidth: 'unset'
			}}
		>
			<Form
				id="modalForm"
				data-testid="form-create"
				className="theme-form"
				onSubmit={simpan} >
					<div
						onMouseDown={(e) => {
							let slider = document.querySelector('.card-scrollable');
							setDraggable({
								isDown: true,
								startY: e.pageY - slider.offsetTop,
								scrollUp: slider.scrollTop,
							})
						}}
						onMouseLeave={() => {
							setDraggable({
								...draggable,
								isDown: false
							})
						}}
						onMouseUp={() => {
							setDraggable({
								...draggable,
								isDown: false
							})
						}}
						onMouseMove={(e) => {
							e.preventDefault();

							let slider = document.querySelector('.card-scrollable');

							if (!draggable.isDown) {
								return;
							}

							const y = e.pageY - slider.offsetTop;
							const startY = draggable.startY;
							const walk = y - startY;
							slider.scrollTop = draggable.scrollUp - walk;
						}}
					>
				<div className="modal-header">
					<h5 className="modal-title">{dt.uuid == '' ? 'Tambah' : 'Edit'} Surat Keterangan Penghasilan</h5>
				</div>
				<div className="modal-body">
					<p>
						Data identitas akan terisi secara otomatis saat NIK dipilih.
						Jika ada ketidak sesuaian data, silakan ubah di data master
						penduduk.
					</p>
					<input
						className="form-control"
						id="uuid"
						type="hidden"
						placeholder=""
						defaultValue={dt.uuid}
					/>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="nik"
						>
							NIK
						</Label>
						<Col sm="9">
							<div data-testid="selectNIK">
								<Select
									inputId="nik"
									classNamePrefix="select"
									onChange={e => {
										getPenduduk(e, "nik", "pelapor");
									}}
									defaultValue={formData.nikStat}
									value={formData.nikStat}
									name="nik"
									options={formData.frmNik}
									placeholder="Pilih NIK"
									onInputChange={e => handleInputChangeNIK(e, 'nik')}
									isClearable
								/>
							</div>
						</Col>
					</FormGroup>
					<div style={{ display: formData.status.dataPenduduk }}>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Nama Pemohon
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="nama_pemohon"
									type="text"
									placeholder="Nama Pemohon"
									value={dt.nama_pemohon}
									readOnly
									style={{ backgroundColor: "#c9d1d8" }}
									disabled
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Tempat Lahir
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="tempat_lahir"
									type="text"
									placeholder="Tempat Lahir"
									value={dt.tempat_lahir}
									readOnly
									style={{ backgroundColor: "#c9d1d8" }}
									disabled
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Tanggal Lahir
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="tanggal_lahir"
									type="text"
									placeholder="Tanggal Lahir"
									value={new Date(dt.tanggal_lahir).toLocaleDateString("id-ID")}
									readOnly
									style={{ backgroundColor: "#c9d1d8" }}
									disabled
								/>
							</Col>
						</FormGroup>
					</div>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="inputEmail3"
						>
							Penghasilan
						</Label>
						<Col sm="9">
							<NumberFormat
								data-testid="nominal penghasilan"
								id="penghasilan"
								name="penghasilan"
								placeholder="Contoh : 1.500.000"
								thousandSeparator={true}
								prefix={'Rp. '}
								className="form-control"
								defaultValue={dt.penghasilan}
								value={dt.penghasilan}
								onChange={e => changeInputText(e, "penghasilan")}
								required
							/>
						</Col>
					</FormGroup>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-warning"
						onClick={() => {
							// Tutup modal
							setFormData({
								...formData,
								status: {
									...formData.status,
									form: false
								}
							})
							setIsOpen(false)
						}}
					>
						Tutup
					</button>
					<button
						data-testid="btnSubmitCreate"
						type="submit"
						className="btn btn-primary-custom"
						disabled={formData.status && formData.status.btnForm}
					>
						Simpan
					</button>
				</div>
				</div>
			</Form>
		</Modal>
	);
}

export default ModalSuratPenghasilan;
