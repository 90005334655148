import React, { } from 'react'
// import React, { useEffect, useState, useContext } from 'react'
// import Modal from 'react-responsive-modal';
// import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
// import Datepicker from 'react-datepicker';
// import Select from 'react-select';

// import { Post, Get, Delete, cekLogin } from '../../function/Koneksi';
import EditKelahiran from './kelahiran';
import EditKeteranganJalan from './jalan';
import EditKematian from './kematian';
import EditSkgr from './skgr';
import EditPernahMenikah from './pernah-menikah';
import EditTidakMenikah from './tidak-menikah';
import EditKehilangan from './kehilangan';
import EditBelumMenikah from './belum-menikah';
import EditPindahNikah from './pindah-nikah';
import EditUsaha from './usaha';
import EditDomisiliUsaha from './domisili-usaha';
import EditPindahDatang from './surat-pindah-datang';
import EditKepemilikanRumah from './kepemilikan-rumah';
import EditSkck from './skck';
import EditBelumMemilikiRumah from './belum-memiliki-rumah';
import EditUmum from './umum';
import EditKeramaian from './keramaian';
import EditKuasa from './kuasa';
import EditUndangan from './undangan';
import EditPenyaksianTanah from './penyaksian-tanah';
import ModalSuratPenghasilanOrtu from '../modal/penghasilan-ortu';
import ModalSuratPenguburan from '../modal/penguburan';
import ModalSuratPenghasilan from '../modal/penghasilan';
import ModalSuratTidakMampu from '../modal/tidak-mampu';
import ModalSuratDomisili from '../modal/domisili';
import ModalSuratAhliWaris from '../modal/ahli-waris';
import ModalSuratIzinKeramaian from '../modal/izin-keramaian';

function ModalUpdate({ jenis_surat, detail_surat_context, data, optionData, setAlert, isOpen, setIsOpen, statusRefresh, setStatusRefresh, propsData }) {

  switch (jenis_surat) {
    case 'Surat Keterangan Kelahiran':
      return (
        <div>
          <EditKelahiran detail_surat_context={detail_surat_context} />
        </div>
      )
      //break;

    case 'Surat Keterangan Jalan':
      return (
        <div>
          <EditKeteranganJalan detail_surat_context={detail_surat_context} />
        </div>
      )
      //break;

    case 'Surat Keterangan Kematian':
      return (
        <div>
          <EditKematian detail_surat_context={detail_surat_context} />
        </div>
      )
      //break;

    case 'Surat Kuasa SKGR':
      return (
        <div>
          <EditSkgr detail_surat_context={detail_surat_context} />
        </div>
      )
      //break;

    case 'Surat Keterangan Tidak Mampu':
      return (
        <div>
          <ModalSuratTidakMampu
            data={data}
            isOpen={isOpen}
            propsData={propsData}
            kiosk={false}
            optionData={optionData}
            setAlert={setAlert}
            setStatusRefresh={setStatusRefresh}
            setIsOpen={setIsOpen}
          />
        </div>
      )
      //break;

    case 'Surat Keterangan Pernah Menikah':
      return (
        <div>
          <EditPernahMenikah detail_surat_context={detail_surat_context} />
        </div>
      )
      //break;

    case 'Surat Pernyataan Tidak Menikah':
      return (
        <div>
          <EditTidakMenikah detail_surat_context={detail_surat_context} />
        </div>
      )
      //break;

    case 'Surat Pernyataan Kehilangan':
      return (
        <div>
          <EditKehilangan detail_surat_context={detail_surat_context} />
        </div>
      )
      //break;

    case 'Surat Pernyataan Belum Menikah':
      return (
        <div>
          <EditBelumMenikah detail_surat_context={detail_surat_context} />
        </div>
      )
      //break;

    case 'Surat Pengantar Pindah Nikah':
      return (
        <div>
          <EditPindahNikah detail_surat_context={detail_surat_context} />
        </div>
      )
      //break;

    case 'Surat Keterangan Usaha':
      return (
        <div>
          <EditUsaha detail_surat_context={detail_surat_context} />
        </div>
      )
      //break;

    case 'Surat Keterangan Penghasilan':
      return (
        <div>
          <ModalSuratPenghasilan
            data={data}
            isOpen={isOpen}
            propsData={propsData}
            kiosk={false}
            optionData={optionData}
            setAlert={setAlert}
            setStatusRefresh={setStatusRefresh}
            setIsOpen={setIsOpen}
          />
        </div>
      )
      //break;

    case 'Surat Keterangan Kepemilikan Rumah':
      return (
        <div>
          <EditKepemilikanRumah detail_surat_context={detail_surat_context} />
        </div>
      )
      //break;

    case 'Surat Pengantar SKCK':
      return (
        <div>
          <EditSkck detail_surat_context={detail_surat_context} />
        </div>
      )
      //break;

    case 'Surat Keterangan Domisili':
      return (
        <div>
          <ModalSuratDomisili
            data={data}
            isOpen={isOpen}
            propsData={propsData}
            kiosk={false}
            optionData={optionData}
            setAlert={setAlert}
            setStatusRefresh={setStatusRefresh}
            setIsOpen={setIsOpen}
          />
        </div>
      )
      //break;

    case 'Surat Keterangan Domisili Usaha':
      return (
        <div>
          <EditDomisiliUsaha detail_surat_context={detail_surat_context} />
        </div>
      )
      //break;

    case 'Surat Keterangan Pindah Datang WNI':
      return (
        <div>
          <EditPindahDatang detail_surat_context={detail_surat_context} />
        </div>
      )
      //break;

    case 'Surat Keterangan Belum Memiliki Rumah':
      return (
        <div>
          <EditBelumMemilikiRumah detail_surat_context={detail_surat_context} />
        </div>
      )
      //break;

    case 'Surat Keterangan Ahli Waris':
      return (
        <div>
          <ModalSuratAhliWaris
						data={data}
						isOpen={isOpen}
						propsData={propsData}
            kiosk={false}
						optionData={optionData}
						setAlert={setAlert}
						setStatusRefresh={setStatusRefresh}
						setIsOpen={setIsOpen}
          />
        </div>
      )
      //break;

    case 'Surat Keterangan':
      return (
        <div>
          <EditUmum detail_surat_context={detail_surat_context} />
        </div>
      )
      //break;

    case 'Surat Izin Keramaian':
      return (
        <div>
          <ModalSuratIzinKeramaian
            data={data}
            isOpen={isOpen}
            propsData={propsData}
            kiosk={false}
            optionData={optionData}
            setAlert={setAlert}
            setStatusRefresh={setStatusRefresh}
            setIsOpen={setIsOpen}
          />
        </div>
      )
      //break;

    case 'Surat Kuasa':
      return (
        <div>
          <EditKuasa detail_surat_context={detail_surat_context} />
        </div>
      )
      //break;

    case 'Surat Undangan':
      return (
        <div>
          <EditUndangan detail_surat_context={detail_surat_context} />
        </div>
      )
      //break;
    
    case 'Surat Keterangan Penyaksian Tanah':
      return (
        <div>
          <EditPenyaksianTanah detail_surat_context={detail_surat_context} />
        </div>
      )
      //break;
    
    case 'Surat Keterangan Penguburan':
      return (
        <div>
          <ModalSuratPenguburan
						data={data}
						isOpen={isOpen}
						propsData={propsData}
            kiosk={false}
						optionData={optionData}
						setAlert={setAlert}
						setStatusRefresh={setStatusRefresh}
						setIsOpen={setIsOpen}
          />
        </div>
      )
      //break;
    
    case 'Surat Keterangan Penghasilan Orang Tua':
      return (
        <div>
          <ModalSuratPenghasilanOrtu
						data={data}
						isOpen={isOpen}
						propsData={propsData}
            kiosk={false}
						optionData={optionData}
						setAlert={setAlert}
						setStatusRefresh={setStatusRefresh}
						setIsOpen={setIsOpen}
          />
        </div>
      )
      //break;

    default:
      return (
        <div>

        </div>
      )
      //break;
  }

}

export default ModalUpdate